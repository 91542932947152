import "./weekly-layout.scss";
import { Table } from "react-bootstrap";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { generateDateRange } from "../../../../api/general";
import InputField from "../../../../components/InputField/InputField";
import { ImSearch } from "react-icons/im";
import { Colors } from "../../../../constants";
import Text from "../../../../components/text/text";
import { BsCaretDownFill, BsPlusSquareDotted } from "react-icons/bs";
// import { IStaff } from "../../../../interface";
import EmployeeBox from "../../../../components/employee-box/employee";
// import ShiftBox from "../../../../components/shift-box/shift-box1";
import Button from "../../../../components/button/Button";
import MultipleShiftBox from "../modals/multiple-shifts-box";
import { FaPaste } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../../hooks/user";
import ShiftBox from "../shift-box";
import Shifts, { IShift } from "../../../../modules/Shifts";
import { IEmployee } from "../../../../modules/Employee";
interface WeeklyLayoutProps {
  timePeriod: { startDate?: Moment; endDate?: Moment };
  staff: Array<IEmployee & { color?: string }>;
  shiftsEmployeeWise: any;
  isFortnight?: boolean;
  onCopyShift?: Function;
  onEditClick?: (date: Moment, shift: Shifts.ShiftReponse) => void;
  onAddClick?: (date: Moment) => void;
  copiedShift?: Shifts.ShiftPayload;
  onPasteShift?: (shift: Shifts.ShiftPayload) => void;
  onSearchTextChanged?: (text: string) => void;
}
export default function WeeklyLayout({
  timePeriod,
  staff,
  shiftsEmployeeWise,
  isFortnight,
  onAddClick,
  onEditClick,
  copiedShift,
  onCopyShift,
  onPasteShift,
  onSearchTextChanged,
}: WeeklyLayoutProps) {
  const navigate = useNavigate();

  const user = useUserProfile();
  const tableParentDivRef = useRef<HTMLDivElement>(null);
  const [days, setDays] = useState<Array<Moment>>([]);
  const [numOfDays, setNumOfDays] = useState<number>(0);

  const [clickedCell, setClickedCell] =
    useState<{ date: Moment; index: number; empId: number }>();

  useEffect(() => {
    timePeriod?.startDate &&
      setDays(generateDateRange(timePeriod?.startDate.clone(), numOfDays));
  }, [timePeriod.startDate, timePeriod.endDate, numOfDays]);
  useEffect(() => {
    setNumOfDays(isFortnight ? 14 : 7);
  }, [isFortnight]);

  // console.log("Weekly", shiftsEmployeeWise);

  return (
    <div className="weekly-table-wrapper" ref={tableParentDivRef}>
      <Table responsive className="weekly-table overflow-auto">
        <colgroup>
          <col style={{ width: 200, minWidth: 200 }} />
          {Array.from({ length: numOfDays || 0 }).map((_, index) => (
            <col
              key={index}
              style={{
                width:
                  (tableParentDivRef.current?.offsetWidth || 0) /
                    (timePeriod?.startDate ? numOfDays : 1) -
                  200 / (timePeriod.startDate ? numOfDays : 1),
                minWidth:
                  (tableParentDivRef.current?.offsetWidth || 0) /
                    (timePeriod?.startDate ? numOfDays : 1) -
                  200 / (timePeriod.startDate ? numOfDays : 1),
              }}
            />
          ))}
        </colgroup>
        <thead>
          <tr className="align-items-center">
            <th>
              <InputField
                fontSize="14px"
                placeHolder="Type to Search"
                backgroundColor="white"
                borderRadius="5px"
                startIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
                wrapperClassName="mx-2 mb-2"
                inputClassName="p-1"
                onChangedText={onSearchTextChanged}
              />
            </th>
            {days.map((day, index) => (
              <th key={index}>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {day.format("ddd Do")} <BsCaretDownFill />
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {staff?.map(({ preferredTitle, id, color, profilePicture }, i) => (
            <tr style={{ height: 1 }} key={i}>
              <td className="first-col">
                <EmployeeBox
                  image={profilePicture}
                  preferredTitle={preferredTitle}
                  isScheduleModeMonthly={false}
                />
              </td>
              {days?.map((day, index) => (
                <td
                  key={index}
                  style={{ height: "inherit" }}
                  onClick={(e) => {
                    setClickedCell({ date: day, index, empId: id });
                  }}
                >
                  {shiftsEmployeeWise &&
                  shiftsEmployeeWise[id]?.hasOwnProperty(
                    day.format("YYYY-MM-DD")
                  ) ? (
                    shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]?.length >
                    1 ? (
                      <div className="p-1 w-100 h-100">
                        <MultipleShiftBox
                          date={day}
                          backgroundColor={color}
                          shifts={
                            shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]
                          }
                          length={
                            shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]
                              ?.length
                          }
                          onShiftClicked={(shift) =>
                            onEditClick && onEditClick(day, shift)
                          }
                          onCopyClicked={(shift) =>
                            onCopyShift && onCopyShift(shift)
                          }
                          onAddShiftClicked={(day) => {
                            onAddClick && onAddClick(day);
                          }}
                          copiedShift={copiedShift}
                          onPasteClicked={() => {
                            copiedShift &&
                              onPasteShift &&
                              onPasteShift({
                                ...copiedShift,
                                startDate: day.unix().toString(),
                                employee: {
                                  id,
                                  employee: preferredTitle,
                                  userId: id,
                                },
                                startTime: moment
                                  .unix(Number(copiedShift.startTime))
                                  .set({
                                    date: day.date(),
                                  })
                                  .unix()
                                  .toString(),
                                endTime: moment
                                  .unix(Number(copiedShift.endTime))
                                  .set({
                                    date: day.date(),
                                  })
                                  .unix()
                                  .toString(),
                              });
                          }}
                        />
                      </div>
                    ) : (
                      shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]?.map(
                        (shift: IShift | any, i: number) => (
                          <div className="p-1" key={i}>
                            <ShiftBox
                              backgroundColor={color}
                              startTime={moment.unix(Number(shift.startTime))}
                              endTime={moment.unix(Number(shift.endTime))}
                              variant="published"
                              // areaName={shift.area.text || shift.area.area}
                              locationName={
                                shift.location?.text || shift.location.location
                              }
                              time={shift.time}
                              scheduleMode={isFortnight ? "2 Weeks" : "Week"}
                              onEdit={() => {
                                onEditClick && onEditClick(day, shift);
                              }}
                              onCopy={() => {
                                onCopyShift && onCopyShift(shift);
                              }}
                              copiedShift={copiedShift && true}
                              onAdd={() => onAddClick && onAddClick(day)}
                              isFortnightly={isFortnight}
                              onPaste={() => {
                                copiedShift &&
                                  onPasteShift &&
                                  onPasteShift({
                                    ...copiedShift,
                                    startDate: day.unix().toString(),
                                    employee: {
                                      id,
                                      employee: preferredTitle,
                                      userId: id,
                                    },
                                    startTime: moment
                                      .unix(Number(copiedShift.startTime))
                                      .set({
                                        date: day.date(),
                                      })
                                      .unix()
                                      .toString(),
                                    endTime: moment
                                      .unix(Number(copiedShift.endTime))
                                      .set({
                                        date: day.date(),
                                      })
                                      .unix()
                                      .toString(),
                                  });
                              }}
                            />
                          </div>
                        )
                      )
                    )
                  ) : (
                    <div className="h-100 d-flex justify-content-center">
                      {!user.roles.includes("ROLE_CLIENT") && (
                        <Button
                          key={i}
                          variant="no_color"
                          onClick={() => onAddClick && onAddClick(day)}
                          centeredText
                        >
                          <BsPlusSquareDotted />
                        </Button>
                      )}
                      {copiedShift &&
                        clickedCell?.date.isSame(day, "day") &&
                        clickedCell.empId === id &&
                        clickedCell.index === index && (
                          <Button
                            variant="plain"
                            className="p-0 w-100 mt-1"
                            onClick={() => {
                              onPasteShift &&
                                onPasteShift({
                                  ...copiedShift,
                                  startDate: day.unix().toString(),
                                  employee: {
                                    id,
                                    employee: preferredTitle,
                                    userId: id,
                                  },
                                  startTime: moment
                                    .unix(Number(copiedShift.startTime))
                                    .set({
                                      date: day.date(),
                                    })
                                    .unix()
                                    .toString(),
                                  endTime: moment
                                    .unix(Number(copiedShift.endTime))
                                    .set({
                                      date: day.date(),
                                    })
                                    .unix()
                                    .toString(),
                                });
                            }}
                          >
                            <FaPaste />
                          </Button>
                        )}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td>
              <div className="d-flex justify-content-center my-3 w-100">
                <Button
                  fontSize="12px"
                  startIcon={<IoMdPersonAdd size={25} />}
                  className="p-2 "
                  fullWidth
                  onClick={() => {
                    navigate(`/app/employee/`);
                  }}
                >
                  Add New Team Member
                </Button>
              </div>
            </td>
            <td colSpan={numOfDays} />
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
