// import axios from "axios";
// const axiosInstance = axios.create({
//   baseURL: "http://localhost:8080",
// });

// export default axiosInstance;

import axiosInstance from "axios";
import { BASE_URL } from "../constants";
axiosInstance.defaults.baseURL = BASE_URL;

axiosInstance.interceptors.request.use(function (config) {
  const data = localStorage.getItem("user");
  if (data) {
    const dataJSON = JSON.parse(data);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${dataJSON.token}`;
      config.headers["Content-Type"] = "application/json";
    }
  }

  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   function (error) {}
// );

export default axiosInstance;
