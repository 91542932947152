import React, { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { BsArrowLeft, BsCalendar3, BsTelephoneFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import Button from "../../components/button/Button";
import CalendarButton from "../../components/calendar-button/calendar-button";
import CalendarInput from "../../components/calendar-input/calendar-input";
import Switch from "../../components/custom-switch/custom-switch";
import InputField from "../../components/InputField/InputField";
import Select from "../../components/select/select";
import Text from "../../components/text/text";
import { Colors, localStorageKeys } from "../../constants";
import { useUserProfile } from "../../hooks/user";
import "./dashboard.scss";

interface IUserProfileSidebar {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}
const UserProfileSidebar = React.forwardRef<
  HTMLDivElement,
  IUserProfileSidebar
>(({ onClose }, ref) => {
  const leaveDivRef = useRef<HTMLDivElement>(null);
  const unavailDivRef = useRef<HTMLDivElement>(null);
  const { title } = useUserProfile();
  const firstName = title.split(" ")[0];
  const lastName = title.split(" ")[1];
  const featuresOptions = [
    {
      label: "Unavailability",
      buttonCallback: () => {
        if (unavailDivRef?.current) {
          unavailDivRef.current.style.display = "block";
          unavailDivRef.current.style.opacity = "1";
        }
      },
      emptyDataMsg: "Employee is Available to work",
    },
    {
      label: "Leave",
      buttonCallback: () => {
        if (leaveDivRef?.current) {
          leaveDivRef.current.style.display = "block";
          leaveDivRef.current.style.opacity = "1";
        }
      },
      emptyDataMsg: "No Leave request to display",
    },
    {
      label: "Recent timesheets",
      emptyDataMsg: "No timesheets to display",
    },
  ];

  function CreateLeaveRequest({}) {
    return (
      <div
        className="p-0 m-0 right-bar-child hidden"
        style={{ backgroundColor: Colors["cyan-12"] }}
        ref={leaveDivRef}
      >
        <Row
          className="p-2 align-items-center"
          style={{ backgroundColor: Colors.BLUE }}
        >
          <Col xs="auto">
            <Button
              noBorderRadius={false}
              variant="plain"
              fontSize="14px"
              startIcon={<BiArrowBack />}
              onClick={() => {
                if (leaveDivRef.current) {
                  leaveDivRef.current.style.display = "none";
                }
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="ms-5 pe-4 justify-content-end d-flex">
            <Text color="white" fontSize="18px">
              Leave Request
            </Text>
          </Col>
        </Row>
        <Row className="px-3 py-2">
          <div className="white-container">
            <Row className="align-items-center">
              <Col>
                {/* <InputField label="From"  /> */}
                <Text>From</Text>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Text>All Day</Text>
                  </Col>
                  <Col>
                    <CalendarInput
                      inputProps={{
                        fontSize: "15px",
                        placeHolder: "Date",
                        borderRadius: "5px",
                        backgroundColor: "white",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                {/* <InputField label="From"  /> */}
                <Text>To</Text>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Text>All Day</Text>
                  </Col>
                  <Col>
                    <CalendarInput
                      inputProps={{
                        fontSize: "15px",
                        placeHolder: "Date",
                        borderRadius: "5px",
                        backgroundColor: "white",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Select
                  styles={{
                    control: (style, state) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                  placeholder="Leave Type"
                  label="Leave Type"
                  labelTextProps={{ fontSize: "14px" }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <InputField
                  inputProps={{ as: "textarea" }}
                  placeHolder="Comment"
                  label="Comment"
                  fontSize="14px"
                  backgroundColor="white"
                  borderRadius="5px"
                  labelTextProps={{ fontSize: "14px" }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <InputField
                  inputProps={{ as: "textarea" }}
                  placeHolder="Notify Manager(s)"
                  label="Notify Manager(s)"
                  backgroundColor="white"
                  borderRadius="5px"
                  fontSize="14px"
                  labelTextProps={{ fontSize: "14px" }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button noBorderRadius={false} variant="cyan-12">
                  Cancel
                </Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button noBorderRadius={false} variant="blue_color">
                  Add
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
  function CreateUnavailability({}) {
    return (
      <div
        className="p-0 m-0 right-bar-child"
        ref={unavailDivRef}
        style={{ backgroundColor: Colors["cyan-12"] }}
      >
        <Row
          className="p-2 align-items-center"
          style={{ backgroundColor: Colors.BLUE }}
        >
          <Col xs="auto">
            <Button
              noBorderRadius={false}
              variant="plain"
              fontSize="14px"
              startIcon={<BiArrowBack />}
              onClick={() => {
                if (unavailDivRef.current) {
                  unavailDivRef.current.style.display = "none";
                }
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="ms-5 pe-4 justify-content-end d-flex">
            <Text color="white" fontSize="18px">
              Unavailability
            </Text>
          </Col>
        </Row>
        <Row className="px-3 py-2">
          <div className="white-container">
            <Row className="align-items-center">
              <Col>Add Unavailability</Col>
              <Col className="d-flex justify-content-end">
                <Button noBorderRadius={false} variant="blue_color">
                  Add
                </Button>
              </Col>
            </Row>
            <Row className="mt-5">
              <Text>All Day</Text>
            </Row>
            <Row>
              <Switch />
            </Row>
            <Row className="mt-3">
              <Col>
                <CalendarInput
                  inputProps={{
                    fontSize: "15px",
                    placeHolder: "Date",
                    backgroundColor: "white",
                    label: "From",
                  }}
                />
              </Col>
              <Col>
                <CalendarInput
                  inputProps={{
                    fontSize: "15px",
                    placeHolder: "Date",
                    backgroundColor: "white",
                    label: "To",
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Text>Repeat</Text>
            </Row>
            <Row>
              <Switch />
            </Row>
            <Row className="mt-3">
              <Col>
                <InputField
                  label="Notes"
                  borderRadius="5px"
                  backgroundColor="white"
                  inputProps={{
                    as: "textarea",
                    placeholder: "Reason for unavailability",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
  function UserProfile({}) {
    return (
      <>
        <Row className="p-3">
          <Col xs="auto">
            <Button
              variant="plain"
              onClick={onClose}
              startIcon={<IoIosArrowBack size={18} />}
            >
              Back
            </Button>
          </Col>
          <Col className="justify-content-end d-flex">
            <CalendarButton
              mode="Day"
              fontSize="14px"
              style={{ padding: "5px" }}
            />
          </Col>
        </Row>
        <Row style={{ backgroundColor: "white" }} className="p-4">
          <Col xs="12">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src="https://picsum.photos/200/300"
                  width={100}
                  height={100}
                  className="rounded-circle"
                />
              </Col>
              <Col>
                <Text fontSize="30px">{`${firstName} ${lastName}`}</Text>
                <Text fontSize="20px" light>
                  System Admin
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xs="12" className="mt-3">
            <Button variant="no_color" startIcon={<BsCalendar3 />}>
              Set your date of birth
            </Button>
            <Button variant="no_color" startIcon={<BsTelephoneFill />}>
              Set your mobile
            </Button>
            <Button variant="no_color" startIcon={<FiMail />}>
              accounts@essentialefs.com
            </Button>
          </Col>
        </Row>
        <Row className="p-2">
          <Button noBorderRadius={false} fullWidth fontSize="14px">
            Add your shift to your personal calender app
          </Button>
        </Row>

        {featuresOptions.map(({ buttonCallback, label, emptyDataMsg }) => (
          <Row className="border-bottom border-grey px-2 py-2">
            <Col>
              <Row className="align-items-center">
                <Col>
                  <Text fontSize="14px">{label}</Text>
                </Col>
                <Col className="justify-content-end d-flex">
                  {buttonCallback && (
                    <Button
                      variant="plain"
                      fontSize="14px"
                      noBorderRadius={false}
                      onClick={buttonCallback}
                    >
                      Add new
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <div className="p-5 d-flex justify-content-center align-items-center">
                  <Text
                    fontSize="14px"
                    color="#000000"
                    light
                    style={{ fontStyle: "italic" }}
                  >
                    {emptyDataMsg}
                  </Text>
                </div>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    );
  }
  return (
    <div className="right-bar-container" ref={ref}>
      <Container className="" fluid>
        <Row>
          <Col
            style={{ background: Colors.HEX_TO_RGBA("#000000", 0.3) }}
            md="9"
            onClick={onClose}
          ></Col>
          <Col
            style={{
              background: Colors["cyan-12"],
              height: "100vh",
              position: "relative",
            }}
            md="3"
          >
            <CreateLeaveRequest />
            <CreateUnavailability />
            <UserProfile />
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default UserProfileSidebar;
