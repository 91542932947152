import moment, { Moment } from "moment";
import TimePicker from "rc-time-picker";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaClock } from "react-icons/fa";
import DropDown from "../../../components-v2/dropdown/dropdown";
import DateInput from "../../../components-v2/input/date-input";
import TextInput from "../../../components-v2/input/input";
import TimeInput from "../../../components-v2/input/time-input";
// import CalendarInput from "../../../components/calendar-input/calendar-input";
import InputField from "../../../components/InputField/InputField";
import Text from "../../../components/text/text";
import { useEmployees } from "../../../hooks/employee";
import { useLocations } from "../../../hooks/location";
import { IOption } from "../../../interface";
import { ITimesheet } from "../../../modules/Timesheet";
import "./../timesheet.styles.scss";
interface NewTimesheetFormProps {
  onTimesheetSubmit: (data: any) => void;
  defaultValues?: ITimesheet;
}
export default function NewTimesheetForm({
  onTimesheetSubmit,
  defaultValues,
}: NewTimesheetFormProps) {
  const { employees } = useEmployees();
  const { locations } = useLocations();
  const [selectedEmployee, setSelectedEmployee] = useState<{
    label?: string;
    value?: string | number;
  }>(
    defaultValues
      ? {
          label: defaultValues.employeeName,
          value: defaultValues.employeeId,
        }
      : {
          label: "Select Employee",
          value: "",
        }
  );
  const [breaksBreakdown, setBreaksBreakdown] = useState<
    | Array<{
        breakType?: "mealBreak" | "restBreak";
        breakTime: number;
        haveStartEndTime: boolean;
        startTime?: string;
        endTime?: string;
      }>
    | undefined
  >(defaultValues?.breaks);

  const [timings, setTimings] = useState<
    { startTime?: string; endTime?: string } | undefined
  >(
    defaultValues
      ? {
          startTime: defaultValues?.startTime,
          endTime: defaultValues?.endTime,
        }
      : undefined
  );
  const [totalRestBreak, setTotalRestBreak] = useState<number | undefined>(
    defaultValues?.totalRestBreak
  );
  const [totalMealBreak, setTotalMealBreak] = useState<number | undefined>(
    defaultValues?.totalMealBreak
  );
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
    defaultValues && moment.unix(Number(defaultValues.date))
  );
  const [selectedLocation, setSelectedLocation] = useState<IOption | undefined>(
    defaultValues && {
      label: defaultValues.locationName,
      value: defaultValues.locationId,
    }
  );

  function submit() {
    const getRandomInt = (min: number = 0, max: number = 100) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const payload = {
      employeeId: selectedEmployee.value,
      employeeName: selectedEmployee.label,
      date: selectedDate?.unix().toString(),
      locationId: selectedLocation?.value,
      locationName: selectedLocation?.label,
      breaks: breaksBreakdown,
      startTime: timings?.startTime,
      endTime: timings?.endTime,
      totalMealBreak,
      totalRestBreak,
      id: getRandomInt(),
      status: "Pending",
      progress: "Submitted",
    };

    onTimesheetSubmit(payload);
  }
  const fields = [
    {
      label: "Employee",
      component: (
        <DropDown
          variant="cyan-12"
          searchable
          label={selectedEmployee.label || "Select Employee"}
          menu={
            employees?.map(({ preferredTitle, id }) => ({
              label: preferredTitle,
              value: id,
            })) || []
          }
          onChange={(option) => {
            setSelectedEmployee(option);
          }}
        />
      ),
    },
    {
      label: "Date",
      component: (
        <DateInput
          defaultValue={selectedDate?.format("YYYY-MM-DD")}
          placeholder="Date"
          controlProps={{
            validation: () => selectedDate !== undefined,
          }}
          onDateChanged={(date) => {
            setSelectedDate(date);
          }}
        />
      ),
    },
    {
      label: "Location",
      component: (
        <DropDown
          variant="cyan-12"
          searchable
          label={selectedLocation?.label || "Location"}
          menu={
            locations?.map(({ name, id }) => ({
              label: name,
              value: id,
            })) || []
          }
          onChange={(option) => {
            setSelectedLocation(option);
          }}
        />
      ),
    },
    {
      label: "Time",
      component: (
        <Row className="align-items-center">
          <Col xs="auto">
            <TimeInput
              label="Start Time"
              defaultDate={selectedDate}
              defaultTime={
                timings?.startTime
                  ? moment.unix(Number(timings?.startTime))
                  : undefined
              }
              onTimeChanged={(time) => {
                time &&
                  setTimings({ ...timings, startTime: time.unix().toString() });
              }}
            />
          </Col>
          <Col xs="auto">
            <TimeInput
              label="End Time"
              defaultTime={
                timings?.endTime
                  ? moment.unix(Number(timings?.endTime))
                  : undefined
              }
              onTimeChanged={(time) => {
                time &&
                  setTimings({ ...timings, endTime: time.unix().toString() });
              }}
            />
          </Col>
        </Row>
      ),
    },
    {
      label: "Break",
      component: (
        <Row className="align-items-center">
          <Col xs="auto">
            <TextInput
              label="Total Rest Break"
              validation={(e) => e.target.value}
              controlProps={{
                defaultValue: totalRestBreak?.toString(),
                onBlur: (e) => {
                  const value = Number(e.target.value);
                  if (
                    value !== totalRestBreak &&
                    e.target.value &&
                    value >= 0
                  ) {
                    setTotalRestBreak(value);

                    setBreaksBreakdown((prev) => {
                      if (prev) {
                        return [
                          ...prev.filter(
                            (item) => item.breakType !== "restBreak"
                          ),
                          {
                            breakType: "restBreak",
                            breakTime: value,
                            haveStartEndTime: false,
                          },
                        ];
                      } else {
                        prev = [
                          {
                            breakType: "restBreak",
                            breakTime: parseInt(e.target.value),
                            haveStartEndTime: false,
                          },
                        ];
                      }
                      return prev;
                    });
                  }
                },
                type: "number",
              }}
            />
          </Col>
          <Col xs="auto">
            <TextInput
              label="Total Meal Break"
              validation={(e) => e.target.value}
              controlProps={{
                defaultValue: totalMealBreak?.toString(),

                type: "number",
                onBlur: (e) => {
                  const value = Number(e.target.value);
                  if (
                    e.target.value &&
                    value !== totalMealBreak &&
                    value >= 0
                  ) {
                    setTotalMealBreak(value);
                    setBreaksBreakdown((prev) => {
                      if (prev) {
                        return [
                          ...prev.filter(
                            (item) => item.breakType !== "mealBreak"
                          ),
                          {
                            breakType: "mealBreak",
                            breakTime: parseInt(e.target.value),
                            haveStartEndTime: false,
                          },
                        ];
                      } else {
                        prev = [
                          {
                            breakType: "mealBreak",
                            breakTime: parseInt(e.target.value),
                            haveStartEndTime: false,
                          },
                        ];
                      }
                      return prev;
                    });
                  }
                },
              }}
            />
          </Col>
        </Row>
      ),
    },
    {
      label: "Break Breakdown",
      component: (
        <div key={(totalMealBreak || 0) + (totalRestBreak || 0) + 1}>
          {breaksBreakdown?.map(
            (
              { breakTime, breakType, haveStartEndTime, endTime, startTime },
              i
            ) => (
              <Row className="align-items-center w-100" key={i}>
                <Col xs="auto">
                  <Form.Group className="mb-1">
                    <Text fontSize="12px">Break</Text>
                    <Form.Select
                      className="rounded qs-select my-1"
                      onChange={(e: any) => {
                        setBreaksBreakdown((prev) => {
                          if (prev) {
                            const newBreaksBreakdown = [...prev];
                            newBreaksBreakdown[i] = {
                              ...newBreaksBreakdown[i],
                              breakType: e.target.value,
                            };
                            return [...newBreaksBreakdown];
                          } else {
                            return prev;
                          }
                        });
                      }}
                    >
                      <option disabled selected>
                        Select Break
                      </option>
                      <option
                        value="mealBreak"
                        selected={breakType === "mealBreak"}
                      >
                        Meal Break
                      </option>
                      <option
                        value="restBreak"
                        selected={breakType === "restBreak"}
                      >
                        Rest Break
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <TextInput
                    label="Duration"
                    controlProps={{
                      defaultValue: breakTime,
                      type: "number",
                      onChange: (e) => {
                        const value = Number(e.target.value);
                        if (value !== NaN) {
                          setBreaksBreakdown((prev) => {
                            if (prev) {
                              console.log(value);

                              const newBreaksBreakdown = [...prev];
                              newBreaksBreakdown[i] = {
                                ...newBreaksBreakdown[i],
                                breakTime: value,
                              };
                              return [...newBreaksBreakdown];
                            } else {
                              return prev;
                            }
                          });
                        }
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Row className="align-items-end w-100">
                    <Col
                      xs="auto"
                      className="d-flex justify-content-center align-items-center m-auto"
                    >
                      <Form.Check
                        // className="mb-3"
                        checked={haveStartEndTime}
                        onChange={() => {
                          setBreaksBreakdown((prev) => {
                            if (prev) {
                              let newBreaksBreakdown = [...prev];
                              newBreaksBreakdown[i] = {
                                ...newBreaksBreakdown[i],
                                haveStartEndTime: !haveStartEndTime,
                              };

                              return [...newBreaksBreakdown];
                            } else {
                              return prev;
                            }
                          });
                        }}
                      />
                    </Col>
                    {haveStartEndTime ? (
                      <>
                        <Col>
                          <TimeInput
                            label="Start Time"
                            defaultDate={selectedDate}
                            onTimeChanged={(time) => {
                              setBreaksBreakdown((prev) => {
                                if (prev) {
                                  let newBreaksBreakdown = [...prev];
                                  newBreaksBreakdown[i] = {
                                    ...newBreaksBreakdown[i],
                                    startTime: time?.unix().toString(),
                                  };

                                  return [...newBreaksBreakdown];
                                } else {
                                  return prev;
                                }
                              });
                            }}
                          />
                        </Col>
                        <Col>
                          <TimeInput
                            label="End Time"
                            defaultDate={selectedDate}
                            onTimeChanged={(time) => {
                              setBreaksBreakdown((prev) => {
                                if (prev) {
                                  let newBreaksBreakdown = [...prev];
                                  newBreaksBreakdown[i] = {
                                    ...newBreaksBreakdown[i],
                                    endTime: time?.unix().toString(),
                                  };

                                  return [...newBreaksBreakdown];
                                } else {
                                  return prev;
                                }
                              });
                            }}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col xs="auto">
                        <Text>Set break start and end time</Text>
                      </Col>
                    )}
                    <Col
                      xs="auto"
                      className="d-flex justify-content-start align-items-center m-auto"
                    >
                      <AiFillDelete
                        size={25}
                        color="red"
                        onClick={() => {
                          setBreaksBreakdown(
                            breaksBreakdown.filter(({}, index) => i !== index)
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          )}
          <Button
            variant="cyan-12"
            size="sm"
            onClick={() => {
              if (breaksBreakdown) {
                setBreaksBreakdown([
                  ...breaksBreakdown,
                  {
                    breakType: "mealBreak",
                    breakTime: 0,
                    haveStartEndTime: false,
                  },
                ]);
              } else {
                setBreaksBreakdown([
                  {
                    breakType: "mealBreak",
                    breakTime: 0,
                    haveStartEndTime: false,
                  },
                ]);
              }
            }}
          >
            Add Break
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div
      className="border shadow-sm m-2 rounded px-3 py-2 overflow-auto"
      style={{ minHeight: "200px", height: "500px" }}
    >
      <Container className="p-0 overflow-auto">
        <Row>
          <Col xs="9">
            {fields.map(({ label, component }, i) => (
              <Row className=" mb-2" key={i}>
                <Col xs="2" className="border-end">
                  <Text fontSize="15px" rightAlign>
                    {label}
                  </Text>
                </Col>
                <Col>{component || <FormControl />}</Col>
              </Row>
            ))}
          </Col>
          <Col xs="3">
            <Container className="border rounded shadow-sm p-2" fluid>
              <Row>
                <Col xs="12">
                  <Button
                    size="sm"
                    variant="success"
                    style={{ color: "white" }}
                    className="w-100"
                  >
                    Approve & Next
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-end align-items-center my-3">
                <Col>
                  <Text>
                    <FaClock /> Time Approval
                  </Text>
                </Col>
                <Col className="justify-content-end d-flex">
                  <Badge bg="dark_orange">Pending</Badge>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Button
                    size="sm"
                    variant="cyan-12"
                    // style={{ color: "white" }}
                    className="w-100"
                    onClick={submit}
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
