import TimePicker, { TimePickerProps } from "rc-time-picker";
import { Form } from "react-bootstrap";
import "./input.styles.scss";
import "rc-time-picker/assets/index.css";
import moment, { Moment } from "moment";
import { Fragment, useState } from "react";
import Text from "../../components/text/text";

interface TimeInputProps {
  onTimeChanged: (time: Moment | undefined) => void;
  label?: string;
  timepickerProps?: TimePickerProps;
  defaultDate?: Moment;
  defaultTime?: Moment;
  isValid?: boolean;
  feedbackText?: string;
}
export default function TimeInput({
  label,
  onTimeChanged,
  timepickerProps,
  defaultDate,
  defaultTime,
  isValid = true,
  feedbackText,
}: TimeInputProps) {
  const [isTimeSet, setIsTimeSet] = useState<"InitState" | "YES" | "NO">(
    "InitState"
  );

  return (
    <Form.Group>
      {label && (
        <Fragment>
          <Form.Label className="rc-time-picker-label">{label}</Form.Label>
          <br />
        </Fragment>
      )}
      {/* <div style={{ border: isTimeSet === "NO" ? "1px solid red" : "" }}> */}
      <TimePicker
        className={isTimeSet === "NO" || !isValid ? "invalid-time" : ""}
        placeholder={label}
        showSecond={false}
        use12Hours
        onClose={({ open }) => {
          if (isTimeSet === "InitState") {
            setIsTimeSet("NO");
          }
        }}
        onChange={(time) => {
          if (time) {
            setIsTimeSet("YES");
            onTimeChanged(
              moment(defaultDate).set({
                hour: time.hour(),
                minute: time.minute(),
              })
            );
          } else {
            onTimeChanged(undefined);
          }
        }}
        defaultValue={defaultTime}
        {...timepickerProps}
      />
      {feedbackText && (
        <Text
          color="red"
          fontSize="0.8rem"
          style={{ minHeight: "0.3rem", marginTop: "0.5rem" }}
        >
          {!isValid ? feedbackText : ""}
        </Text>
      )}
      {/* </div> */}
    </Form.Group>
  );
}
