import { Fragment, MouseEventHandler, useCallback, useState } from "react";
import { Row, Col, Container, Table, Form, Dropdown } from "react-bootstrap";
import PageBanner from "../../components/page-banner/page-banner";
import Text from "../../components/text/text";
import BannerImage from "./assets/1.png";
import Button from "./../../components/button/Button";
import { Colors } from "../../constants";
import { BsThreeDots } from "react-icons/bs";
import { IStaff } from "../../interface";
import NewEmployee from "./new-employee";
import DropdownButton from "../../components/DropdownMenu/DropdownMenu";
import { useAxios } from "../../api/useAxios";
import Loader from "../../components/loader";
import axiosInstance from "../../api/axiosInstance";
import useModal from "../../hooks/modal";
import EmployeeDetails from "./employee-details";
import { useUserProfile } from "../../hooks/user";
import TextInput from "../../components-v2/input/input";
import "./employee.scss";
import { ImSearch } from "react-icons/im";
import PopoverButton from "../../components-v2/popover-button/popover-button";
import FiltersMainPageHeader from "./employee-main-page-header/filters";
import StessProfileModal from "./employee-main-page-modals/stress-profile";
import AddTrainingsModal from "./employee-main-page-modals/add-trainings";
import SetPayRatesModal from "./employee-main-page-modals/pay-rates";
import AddLeaveEntitlementModal from "./employee-main-page-modals/add-leave-entitlement";
import SetStandardHours from "./employee-main-page-modals/set-standard-hours";
import SetAccess from "./employee-main-page-modals/set-access";

export default function Employee({}) {
  const { showModal } = useModal();
  const user = useUserProfile();
  const [isAllSelected, setisAllSelected] = useState<boolean>(false);
  const [selectedStaff, setSelectedStaff] = useState<any>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [openNewEmployeeModal, setOpenNewEmployeeModal] = useState(false);
  const [openEmployeeDetailModal, setOpenEmployeeDetailModal] = useState(false);
  const [modalRenderCount, setModalRenderCount] = useState<number>(0);

  const [tableHeaders, setTableHeaders] = useState<{
    [key: string]: {
      label: string;
      key: string;
      show: boolean;
    };
  }>({
    email: {
      label: "Email",
      key: "email",
      show: false,
    },
    access: {
      label: "Access",
      key: "access",
      show: true,
    },
    fullyTrained: {
      label: "Training",
      key: "fullyTrained",
      show: false,
    },
    phone: {
      label: "Phone",
      key: "phone",
      show: false,
    },
    position: {
      label: "Position",
      key: "position",
      show: false,
    },

    registered: {
      label: "Registration Status",
      key: "registered",
      show: true,
    },
  });

  const [staff, setStaff] = useState<Array<IStaff>>([]);
  const filteredStaff = useCallback(() => {
    if (searchInput) {
      return staff?.filter(({ id, preferredTitle }) =>
        preferredTitle?.toLowerCase().includes(searchInput?.toLowerCase())
      );
    }
    return staff;
  }, [searchInput, staff]);
  const [selectedStaffIndexes, setSelectedStaffIndexes] = useState<
    Array<number>
  >([]);

  const getEmployees = useAxios(
    { url: "/comply-tsfm/api/employee" },
    [modalRenderCount],
    (data, error) => {
      if (data) {
        setStaff(data);
      }
    }
  );

  function getEmployeeDetails(id: number) {
    axiosInstance
      .get(`/comply-tsfm/api/employee/${id}`)
      .then(({ data, status }) => {
        if (data) {
          const {
            abn,
            address,
            contactNumber,
            contractor,
            contractorAbn,
            contractorAcn,
            contractorCompanyAddress,
            contractorCompanyName,
            contractorContactNumber,
            contractorEmail,
            contractorGstRegistered,
            contractorName,
            dateOfBirth,
            email,
            endDate,
            firstName,
            fullyTrained,
            id,
            lastName,
            phone,
            position,
            registered,
            startDate,
            tfn,
            access,
            accessKey,
            modules,
          } = data;
          setOpenEmployeeDetailModal((prev) => {
            setSelectedStaff({
              contractorInfo: {
                contractorAbn,
                contractorAcn,
                contractorCompanyAddress,
                contractorCompanyName,
                contractorContactNumber,
                contractorEmail,
                contractorGstRegistered,
                contractorName,
              },
              employeeInfo: {
                dateOfBirth,
                email,
                endDate,
                firstName,
                fullyTrained,
                id,
                lastName,
                phone,
                position,
                registered,
                startDate,
                tfn,
                abn,
                address,
                contactNumber,
                contractor,
                access,
                modules,
                accessKey,
              },
            });

            return !prev;
          });
        }
      })
      .catch((error) => {
        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }
  const displayOptions = [
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Access",
      key: "access",
    },
    {
      label: "Training",
      key: "fullyTrained",
    },
    {
      label: "Phone",
      key: "phone",
    },
    {
      label: "Position",
      key: "position",
    },

    {
      label: "Registration Status",
      key: "registered",
    },
  ];

  const [bulkActionsOptions, setBulkActionsOptions] = useState<
    Array<{
      label: string;
      onClick?: MouseEventHandler<HTMLButtonElement>;
    }>
  >([
    {
      label: "Set Access",
      onClick: () => {
        document.body.click();
        setShowSetAccessModal(true);
      },
    },
    {
      label: "Set stress profile",
      onClick: () => {
        document.body.click();
        setShowStressProfileModal(true);
      },
    },
    {
      label: "Add training",
      onClick: () => {
        document.body.click();
        setShowAddTrainingsModal(true);
      },
    },
    {
      label: "Send message",
      // onClick: () => {},
    },
    {
      label: "Set pay rates",
      onClick: () => {
        document.body.click();
        setShowPayRateModal(true);
      },
    },
    {
      label: "Set leave Balance",
      // onClick: () => {},
    },
    {
      label: "Add leave entitlement",
      onClick: () => {
        document.body.click();
        setShowAddLeaveEntitlementModal(true);
      },
    },
    {
      label: "Archive Team Member",
      // onClick: () => {},
    },
    {
      label: "Send Invitation",
      // onClick: () => {},
    },
    {
      label: "Set Standard Hours",
      onClick: () => {
        document.body.click();
        setShowSetStandardHoursModal(true);
      },
    },
  ]);

  //Modal States
  const [showStressProfileModal, setShowStressProfileModal] = useState(false);
  const [showAddTrainingsModal, setShowAddTrainingsModal] = useState(false);
  const [showPayRateModal, setShowPayRateModal] = useState(false);
  const [showAddLeaveEntitlementModal, setShowAddLeaveEntitlementModal] =
    useState(false);
  const [showSetStandardHoursModal, setShowSetStandardHoursModal] =
    useState(false);
  const [showSetAccessModal, setShowSetAccessModal] = useState(false);

  return (
    <div style={{ minWidth: "500px" }}>
      <EmployeeDetails
        show={openEmployeeDetailModal}
        onClose={() => {
          setOpenEmployeeDetailModal(!openEmployeeDetailModal);
          setSelectedStaff(undefined);
        }}
        defaultValue={selectedStaff}
        onEditClicked={() => {
          setOpenNewEmployeeModal(true);
        }}
      />
      <NewEmployee
        // key={moment().unix()}
        show={openNewEmployeeModal}
        onClose={() => {
          setOpenNewEmployeeModal(false);
          setOpenEmployeeDetailModal(false);
          setSelectedStaff(undefined);

          // setSelectedStaff(undefined);
          setModalRenderCount((prev) => prev + 1);
        }}
        defaultValue={selectedStaff}
      />
      {selectedStaffIndexes.length > 0 && (
        <StessProfileModal
          show={showStressProfileModal}
          onHide={() => {
            setShowStressProfileModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      {selectedStaffIndexes.length > 0 && (
        <AddTrainingsModal
          show={showAddTrainingsModal}
          onHide={() => {
            setShowAddTrainingsModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      {selectedStaffIndexes.length > 0 && (
        <SetPayRatesModal
          show={showPayRateModal}
          onHide={() => {
            setShowPayRateModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      {selectedStaffIndexes.length > 0 && (
        <AddLeaveEntitlementModal
          show={showAddLeaveEntitlementModal}
          onHide={() => {
            setShowAddLeaveEntitlementModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      {selectedStaffIndexes.length > 0 && (
        <SetStandardHours
          show={showSetStandardHoursModal}
          onHide={() => {
            setShowSetStandardHoursModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      {selectedStaffIndexes.length > 0 && (
        <SetAccess
          show={showSetAccessModal}
          onHide={() => {
            setShowSetAccessModal(false);
          }}
          numOfEmployees={selectedStaffIndexes.length}
        />
      )}
      <div className="white-container p-4">
        <Container fluid>
          <Row className="align-items-center">
            <Col>
              <Text fontSize="28px" bold>
                Employee
              </Text>
            </Col>
            <Col className="justify-content-end d-flex">
              {!user.roles.includes("ROLE_CLIENT") && (
                <Button
                  onClick={() => {
                    setOpenNewEmployeeModal(true);
                  }}
                >
                  Add Employee
                </Button>
              )}
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <PageBanner
                imageDimensions={{ height: "150px", width: "150px" }}
                heading="Store your team details all in one place"
                image={BannerImage}
                paragraph={
                  <Text>
                    Easily add or import your team's personal details and set
                    their access levels. You can manage their training
                    requirements, leave requests and balances, and employement
                    information such as pay rate and working hours.
                  </Text>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md="12" lg="auto" className="align-items-center d-flex">
              <TextInput
                placeholder="Type to Search"
                wrapperClassName="employee-main"
                rightIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
                onChangeText={setSearchInput}
              />
            </Col>
            {/* <Col className="align-items-center d-flex" xs="12" md="auto">
              <PopoverButton
                variant="blue_color"
                size="sm"
                className="employee-main__header_button"
                overlayProps={{
                  placement: "bottom",
                }}
                popoverProps={{
                  style: {
                    minWidth: "500px",
                    minHeight: "400px",
                  },

                  placement: "bottom",
                }}
                popover={<FiltersMainPageHeader />}
              >
                Filters
              </PopoverButton>
            </Col> */}
            <Col className="align-items-center d-flex" xs="12" md="auto">
              <PopoverButton
                variant="blue_color"
                size="sm"
                className="employee-main__header_button"
                overlayProps={{
                  placement: "bottom",
                }}
                popoverProps={{
                  placement: "bottom",
                }}
                popover={
                  <div className="p-2">
                    {displayOptions.map(({ label, key }, i) => (
                      <Form.Check
                        key={i}
                        label={label}
                        checked={tableHeaders[key].show}
                        onChange={(e: any) => {
                          setTableHeaders((prev) => {
                            const newTableHeaders = { ...prev };
                            newTableHeaders[key].show = e.target.checked;
                            return newTableHeaders;
                          });
                        }}
                      />
                    ))}
                  </div>
                }
              >
                Display
              </PopoverButton>
            </Col>
            {/* <Col className="align-items-center d-flex" xs="12" md="auto">
               <DropdownButton
                buttonProps={{ fontSize: "12px" }}
                noBorderRadius={false}
                variant="blue_color"
                label="Bulk Actions"
              /> 

              <PopoverButton
                variant="blue_color"
                size="sm"
                className="employee-main__header_button"
                overlayProps={{
                  placement: "bottom",
                }}
                popoverProps={{
                  placement: "bottom",
                }}
                disabled={selectedStaffIndexes.length === 0}
                popover={
                  <div className="py-2">
                    {bulkActionsOptions.map(({ label, onClick }, i) => (
                      <Dropdown.Item
                        disabled={onClick === undefined}
                        onClick={onClick}
                        className={`
                        employee-main__bulk_action ${
                          onClick === undefined
                            ? "employee-main__bulk_action__inactive"
                            : ""
                        }`}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </div>
                }
              >
                Bulk Actions
              </PopoverButton>
            </Col> */}
          </Row>

          <Row>
            <Col>
              {getEmployees.loading ? (
                <Loader />
              ) : (
                <Table responsive className="employee-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>
                        <Form.Check
                          type="checkbox"
                          checked={
                            filteredStaff().length ===
                            selectedStaffIndexes.length
                          }
                          onChange={(e: any) => {
                            setisAllSelected(!isAllSelected);
                            setSelectedStaffIndexes(
                              e.target.checked
                                ? filteredStaff().map((_, i) => i)
                                : []
                            );
                          }}
                        />
                      </th>
                      <th>
                        <Text fontSize={14} bold color={Colors.NAVY_BLUE}>
                          Name
                        </Text>
                      </th>

                      {Object.values(tableHeaders).map(
                        (value, i) =>
                          value.show && (
                            <th>
                              <Text fontSize={14} bold color={Colors.NAVY_BLUE}>
                                {value.label}
                              </Text>
                            </th>
                          )
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staff.length > 0 ? (
                      filteredStaff()?.map((staff, i) => (
                        <tr key={i}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={selectedStaffIndexes.includes(i)}
                              onChange={() => {
                                if (selectedStaffIndexes.includes(i)) {
                                  setSelectedStaffIndexes(
                                    selectedStaffIndexes.filter(
                                      (index) => index !== i
                                    )
                                  );
                                } else {
                                  setSelectedStaffIndexes([
                                    ...selectedStaffIndexes,
                                    i,
                                  ]);
                                }
                              }}
                            />
                          </td>
                          <td>
                            <Row className="align-items-center">
                              <Col xs="auto">
                                <Text
                                  className="user-initials p-0 m-0 d-flex justify-content-center align-items-center "
                                  style={{
                                    backgroundColor: Colors.BLUE,
                                    height: "30px",
                                    width: "30px",
                                  }}
                                  fontSize="14px"
                                >
                                  {staff?.preferredTitle
                                    .charAt(0)
                                    .toUpperCase()}
                                </Text>
                              </Col>
                              <Col xs="auto">
                                <Text fontSize="14px" color={Colors.NAVY_BLUE}>
                                  {staff?.preferredTitle}
                                </Text>
                              </Col>
                            </Row>
                          </td>
                          {Object.values(tableHeaders).map(
                            (value, i) =>
                              value.show && (
                                <td>
                                  <Text fontSize={14} color={Colors.NAVY_BLUE}>
                                    {typeof staff[value.key as keyof IStaff] ===
                                    "boolean"
                                      ? staff[value.key as keyof IStaff]
                                        ? "Yes"
                                        : "No"
                                      : (staff[
                                          value.key as keyof IStaff
                                        ] as string)}
                                  </Text>
                                </td>
                              )
                          )}
                          {/* <td>
                            <Text fontSize="14px" color={Colors.NAVY_BLUE}>
                              {staff?.access}
                            </Text>
                          </td>

                          <td>
                            <Text fontSize="14px" color={Colors.NAVY_BLUE}>
                              {staff?.fullyTrained ? "Trained" : "Untrained"}
                            </Text>
                          </td> */}

                          <td>
                            <Button
                              variant="plain"
                              onClick={() => {
                                getEmployeeDetails(staff.id);
                              }}
                            >
                              <BsThreeDots size={16} />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <Text centered heading bold>
                            No Employees Registered yet!
                          </Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
