import { Button, Modal } from "react-bootstrap";
import Text from "../../../components/text/text";
import EmployeeMainModalProps from "./modal-common.props";
import Select from "react-select";
import SelectTheme from "../../../components-v2/Select/select-theme";
import Employee, { EmployeeAccess } from "../../../modules/Employee";

export default function SetAccess({
  onHide,
  show,
  numOfEmployees,
}: EmployeeMainModalProps) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Set Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text className="mb-2">{`${numOfEmployees} people selected`}</Text>
        <Text bold fontSize={16}>
          Access
        </Text>
        <Select
          styles={SelectTheme}
          options={[
            {
              label: EmployeeAccess.ROLE_ADMIN,
              value: EmployeeAccess.ROLE_ADMIN,
            },
            {
              label: EmployeeAccess.ROLE_EMPLOYER,
              value: EmployeeAccess.ROLE_EMPLOYER,
            },
            {
              label: EmployeeAccess.ROLE_EMPLOYEE,
              value: EmployeeAccess.ROLE_EMPLOYEE,
            },
            {
              label: EmployeeAccess.ROLE_CLIENT,
              value: EmployeeAccess.ROLE_CLIENT,
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="blue_color" size="sm" onClick={() => onHide()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
