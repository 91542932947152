import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormCheck,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import BootstrapTable, {
  ColumnDescription,
  ExpandRowProps,
} from "react-bootstrap-table-next";
import { CgCheck } from "react-icons/cg";
import { RiUserSearchFill } from "react-icons/ri";
import Calendar from "../../components-v2/Calendar/calendar";
import DropDown from "../../components-v2/dropdown/dropdown";
import Divider from "../../components/divider";
import Text from "../../components/text/text";
import { Colors } from "../../constants";
import { useBusinessProfile } from "../../hooks/business";
import { useEmployees } from "../../hooks/employee";
import "./timesheet.styles.scss";
import { useLocations } from "../../hooks/location";
import TextInput from "../../components-v2/input/input";

export default function ExportTimesheet({}) {
  const { businesses } = useBusinessProfile();
  const { locations } = useLocations();
  const { employees } = useEmployees();
  const [employeeSearchText, setEmployeeSearchText] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState<Array<number>>([]);

  const [selectedLocation, setSelectedLocation] = useState<{
    label?: string;
    value?: string | number;
  }>({
    label: "Select Location",
    value: "",
  });
  const filteredEmployees = useCallback(() => {
    return employees?.filter((employee) =>
      employee.preferredTitle
        ?.toLowerCase()
        .includes(employeeSearchText.toLowerCase())
    );
  }, [employeeSearchText, employees]);

  // Fitler State
  const [groupBy, setGroupBy] = useState<string>();
  const [selection, setSelection] = useState<string>();
  const [viewMode, setViewMode] = useState<string>();
  useEffect(() => {
    if (viewMode === "expand") {
      setOpenedRows(data.map(({ empId }) => empId));
    } else {
      setOpenedRows([]);
    }
  }, [viewMode]);

  useEffect(() => {
    if (selection === "all") {
      let ids: Array<number> = [];
      for (let item of data) {
        for (let timesheet of item.timesheets) {
          ids.push(timesheet.id);
        }
      }
      setSelectedTimesheets(ids);
    }
    if (selection === "none") {
      setSelectedTimesheets([]);
    }
  }, [selection]);

  // Table Data
  const [selectedTimesheets, setSelectedTimesheets] = useState<Array<number>>(
    []
  );
  const empIdRef = useRef<any>(null);
  const [timesheetData, setTimesheetData] = useState<Array<RowType>>([
    {
      empId: 142,
      name: "ABC",
      totalAmount: 12,
      totalHours: 12,
      timesheets: [
        {
          amount: 100,
          date: "1668385830",
          endTime: "1668385830",
          hours: 12,
          id: 46,
          location: "loc2",
          startTime: "1668385830",
        },
      ],
    },
  ]);
  const columnsGBTM: Array<ColumnDescription> = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "totalHours",
      text: "Total Hours",
    },
    {
      dataField: "totalAmount",
      text: "Amount ($)",
    },
  ];
  const columnsGBPR: Array<ColumnDescription> = [
    {
      dataField: "payRateType",
      text: "Pay Rate Type",
    },
    {
      dataField: "payRate",
      text: "Pay Rate",
    },
    {
      dataField: "exportCode",
      text: "Export Code",
    },
    {
      dataField: "hoursOrUnits",
      text: "Hours / Units",
    },
    {
      dataField: "cost",
      text: "cost",
    },
  ];
  type RowType = {
    empId: number;
    name: string;
    totalHours: number;
    totalAmount: number;
    timesheets: Array<{
      id: number;
      date: string;
      hours: number;
      amount: number;
      location: string;
      startTime: string;
      endTime: string;
    }>;
  };

  const [data, setData] = useState<Array<RowType>>([
    {
      empId: 118,
      name: "EFSA Support",
      totalHours: 8,
      totalAmount: 100,
      timesheets: [
        {
          date: "1654041600",
          hours: 8,
          amount: 100,
          location: "Location 1",
          startTime: "1654041600",
          endTime: "1654077600",
          id: 983,
        },
      ],
    },
    {
      empId: 139,
      name: "Saoud Client",
      totalHours: 10,
      totalAmount: 120,
      timesheets: [
        {
          date: "1654041600",
          hours: 10,
          amount: 120,
          location: "Location 2",
          startTime: "1654041600",
          endTime: "1654077600",
          id: 3345,
        },
        {
          date: "1654041600",
          hours: 10,
          amount: 120,
          location: "Location 4",
          startTime: "1654041600",
          endTime: "1654077600",
          id: 1248,
        },
      ],
    },
  ]);
  useEffect(() => {
    setTimesheetData(
      data.filter(({ empId }) => selectedEmployees.includes(empId))
    );
  }, [selectedEmployees]);

  const [openedRows, setOpenedRows] = useState<Array<number>>([]);
  const expandedRow: ExpandRowProps<RowType> = {
    renderer: ({ timesheets }: RowType) => (
      <Container fluid>
        {timesheets.map(
          ({ date, location, startTime, endTime, hours, amount, id }) => {
            const dateMoment = moment.unix(Number(date));
            const startTimeMoment = moment.unix(Number(startTime));
            const endTimeMoment = moment.unix(Number(endTime));

            const diff = endTimeMoment.diff(startTimeMoment);
            const duration = moment.duration(diff);

            return (
              <Row className="my-2 border-bottom">
                <Col xs="auto" className="p-0">
                  <FormCheck
                    checked={selectedTimesheets.includes(id)}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setSelectedTimesheets([...selectedTimesheets, id]);
                      } else {
                        setSelectedTimesheets(
                          selectedTimesheets.filter((item) => item !== id)
                        );
                      }
                    }}
                  />
                </Col>
                <Col className="p-0">
                  <Text>{`${dateMoment.format(
                    "ddd, DD/MM"
                  )} | ${location} | ${startTimeMoment.format(
                    "hh:mm A"
                  )} - ${endTimeMoment.format(
                    "hh:mm A"
                  )} | ${duration.hours()}:${duration.minutes()}`}</Text>
                </Col>
                <Col style={{ width: "100px" }} xs="auto">
                  <Text centered>{hours} Hours</Text>
                </Col>
                <Col style={{ width: "100px" }} xs="auto">
                  <Text centered>${amount}</Text>
                </Col>
              </Row>
            );
          }
        )}
      </Container>
    ),
    expanded: openedRows,
    onExpand: (row) => {
      if (openedRows.includes(row.empId)) {
        setOpenedRows(openedRows.filter((i) => i !== row.empId));
      } else {
        setOpenedRows([...openedRows, row.empId]);
      }
    },
    className: "expanded-row-custom",
  };
  return (
    <Container fluid className="timesheet-container">
      <Row className="border-bottom py-2">
        <Col>
          <Row>
            <Col xs="auto">
              <DropDown
                label={selectedLocation?.label || "Select Location"}
                menu={
                  locations?.map((location) => ({
                    label: location.name,
                    value: location.id,
                  })) || []
                }
                onChange={(option) => {
                  setSelectedLocation(option);
                }}
              />
            </Col>
            <Col xs="auto">
              <Calendar
                mode="Month"
                backgroundColor="#e6e6e6"
                onDateChanged={(date) => {
                  // console.log(date);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <Col xs="auto">
              <DropDown
                // dropdownProps={{ style: { width: "160px" } }}
                label="More Actions"
                variant="cyan-12"
                menu={[
                  {
                    label: "Mark selected items as Not Paid",
                    callback: () => {},
                    value: 1,
                  },
                  {
                    label: "Print selected items",
                    callback: () => {},
                    value: 2,
                  },
                ]}
                onChange={() => {}}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Body Start */}

      <Row className="h-100">
        <Col className="border-end p-0" xs="2">
          <TextInput
            noBorderRadius
            leftIcon={<RiUserSearchFill />}
            placeholder="Search"
            onChangeText={(text) => {
              setEmployeeSearchText(text);
            }}
          />
          {/* <InputGroup className="p-0 border-bottom">
            <InputGroup.Text
              style={{ backgroundColor: "transparent" }}
              className="border-0"
            >
              <RiUserSearchFill />
            </InputGroup.Text>
            <FormControl
              placeholder="Search"
              onChange={(e: any) => {
                setEmployeeSearchText(e.target.value);
              }}
            />
          </InputGroup> */}
          <Row className="py-2">
            <Col className="d-flex justify-content-center">
              <Button
                size="sm"
                variant="cyan-12"
                onClick={() => {
                  employees &&
                    setSelectedEmployees(
                      employees.map((employee) => employee.id)
                    );
                }}
              >
                Select All
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                size="sm"
                variant="cyan-12"
                onClick={() => {
                  setSelectedEmployees([]);
                }}
              >
                Select None
              </Button>
            </Col>
          </Row>

          {filteredEmployees()?.map(
            ({ preferredTitle, profilePicture, id }) => (
              <div
                className="px-2 border-bottom"
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedEmployees.indexOf(id) !== -1
                      ? Colors["cyan-12"]
                      : "",
                }}
                onClick={() => {
                  setSelectedEmployees((prev) => {
                    if (prev.indexOf(id) === -1) {
                      return [...prev, id];
                    } else {
                      return prev.filter((item) => item !== id);
                    }
                  });
                }}
              >
                <Text bold>{preferredTitle}</Text>
                <Text>{`1 items | 7.5 hrs | $0.00`}</Text>
              </div>
            )
          )}
        </Col>
        <Col xs="10">
          <Container fluid className="p-2">
            <Row>
              <Col>
                <Text>Selected Items Summary</Text>
              </Col>
            </Row>
            <div className="summary-wrapper px-4 py-4 rounded">
              <Row>
                <Col>
                  <Text>
                    <span
                      style={{ fontWeight: "bold" }}
                    >{`${selectedEmployees.length}/${employees?.length}`}</span>{" "}
                    TEAM MEMBERS SELECTED
                  </Text>
                </Col>
                <Col>
                  <Text>CURRENTLY SELECTED</Text>
                </Col>
                <Col>
                  <Text color="red" fontSize={18}>
                    7.50 hrs
                  </Text>
                </Col>
                <Col>
                  <Text color="red" fontSize={18}>
                    900.0%
                  </Text>
                </Col>
                <Col>
                  <Text color="green" fontSize={18}>
                    $0.00
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text>
                    <span
                      style={{ fontWeight: "bold" }}
                    >{`${selectedEmployees.length}/${employees?.length}`}</span>{" "}
                    ITEMS SELECTED
                  </Text>
                </Col>
                <Col>
                  <Text>PREVIOUS</Text>
                </Col>
                <Col>
                  <Text>0.75 hrs</Text>
                </Col>
                <Col>
                  <Text>900.0%</Text>
                </Col>
                <Col>
                  <Text>$0.00</Text>
                </Col>
              </Row>
            </div>
            <Row className="mt-2">
              <Col xs="auto">
                <Button size="sm" variant="cyan-12">
                  Export Selected Items
                </Button>
              </Col>
              <Col xs="auto">
                <Button size="sm" variant="no_color" style={{ color: "black" }}>
                  Mark Selected Items as Paid
                </Button>
              </Col>
            </Row>
          </Container>
          <Divider />
          {timesheetData.length > 0 && (
            <Container fluid className="p-2">
              <Row>
                <Col>
                  <Text>Selected Items Breakdown</Text>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <ToggleButtonGroup type="radio" name="selection">
                    {[
                      { label: "Group by Team Member", value: "GBTM" },
                      { label: "Group by Pay Rate", value: "GBPR" },
                    ].map((item, index) => (
                      <ToggleButton
                        key={index}
                        value={item.value}
                        size="sm"
                        variant="cyan-12"
                        className={`align-items-center d-flex`}
                        onClick={() => {
                          if (item.value === groupBy) {
                            setGroupBy(undefined);
                          } else {
                            setGroupBy(item.value);
                          }
                        }}
                      >
                        {item.value === groupBy && (
                          <CgCheck style={{ marginRight: 5 }} />
                        )}
                        {item.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Col>
                <Col xs="auto">
                  <ToggleButtonGroup type="radio" name="selection">
                    {[
                      { label: "Select All", value: "all" },
                      { label: "Select None", value: "none" },
                      { label: "Invert Selected", value: "invert" },
                    ].map((item, index) => (
                      <ToggleButton
                        key={index}
                        value={item.value}
                        size="sm"
                        variant="cyan-12"
                        className={`align-items-center d-flex`}
                        onClick={() => {
                          if (item.value === selection) {
                            setSelection(undefined);
                          } else {
                            setSelection(item.value);
                          }
                        }}
                      >
                        {item.value === selection && (
                          <CgCheck style={{ marginRight: 5 }} />
                        )}
                        {item.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Col>
                <Col className="d-flex justify-content-end">
                  <ToggleButtonGroup type="radio" name="selection">
                    {[
                      { label: "Expand All", value: "expand" },
                      { label: "Detail All", value: "detail" },
                      { label: "Collapse All", value: "collapse" },
                    ].map((item, index) => (
                      <ToggleButton
                        key={index}
                        value={item.value}
                        size="sm"
                        variant="cyan-12"
                        className={`align-items-center d-flex`}
                        onClick={() => {
                          if (item.value === viewMode) {
                            setViewMode(undefined);
                          } else {
                            setViewMode(item.value);
                          }
                        }}
                      >
                        {item.value === viewMode && (
                          <CgCheck style={{ marginRight: 5 }} />
                        )}
                        {item.label}{" "}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Col>
              </Row>

              {groupBy === "GBTM" && (
                <BootstrapTable
                  key={timesheetData.length}
                  columns={columnsGBTM}
                  data={data}
                  keyField="empId"
                  bordered={false}
                  hover
                  expandRow={expandedRow}
                  headerClasses="export-table-header"
                  classes="export-table"
                  // filter={filterFactory()}
                />
              )}
              {groupBy === "GBPR" && (
                <BootstrapTable
                  columns={columnsGBPR}
                  data={data}
                  keyField="id"
                  bordered={false}
                  headerClasses="export-table-header"
                  classes="export-table"
                  hover
                />
              )}
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
}
