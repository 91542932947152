import { AxiosError } from "axios";
import axiosInstance from "../api/axiosInstance";
import { IArea, ILocation } from "./Location";
export type ShiftType =
  | "unallocated"
  | "published"
  | "unpublished"
  | "openshift"
  | "warning"
  | "leave-approved"
  | "leave-pending"
  | "people-unavailable";
export interface IShift {
  remarks?: string;
  locationId?: number;
  startTime: string;
  endTime: string;
  mealBreak?: number;
  restBreak?: number;
  areaId?: number | IArea;
  id?: number;
  startDate: string;
  areaName?: string;
  locationName?: string;
  type?: ShiftType;
  time?: string;
  employee?: {
    id: number;
    text?: string;
    employee?: string;
    userId: number;
  };
  // area: {
  //   id: number;
  //   text?: string;
  //   area?: string;
  // };
  areas: Array<number>;
  location?: { id: number; text?: string; location?: string };

  employeeId?: number;
}

module Shifts {
  export type ShiftPayload = {
    areaList?: Array<number>;
    employeeId?: number;
    endTime: string;
    locationId?: number;
    mealBreak: number;
    remarks: string;
    restBreak: number;
    startDate: string;
    startTime: string;

    id?: number;
    location?: {
      id: number;
      location: string;
    };
    // "area": null,
    employee?: {
      userId: number;
      id: number;
      employee: string;
    };

    areas?: Array<{
      id: number;
      areaId: number;
      area: string;
    }>;
  };
  export type ShiftReponse = ShiftPayload;
  export type BulkShiftsPayload = {
    areaList: Array<number>;
    employeeId: number;
    endTime: string;
    locationId: number;
    mealBreak: number;
    remarks: string;
    restBreak: number;
    startDate: Array<string>;
    startTime: string;
    location?: {
      id: number;
      location: string;
    };
    // "area": null,
    employee?: {
      userId: number;
      id: number;
      employee: string;
    };

    areas?: Array<{
      id: number;
      areaId: number;
      area: string;
    }>;
  };
  export function createShift(
    payload: ShiftPayload,
    success: (success: ShiftReponse) => void,
    error: (error: AxiosError) => void
  ) {
    const objToRemove = ["employee", "location", "areas", "area"];

    let key: keyof ShiftPayload;
    for (key in payload) {
      if (objToRemove.includes(key)) delete payload[key];
    }

    axiosInstance
      .post("/comply-tsfm/api/schedule", payload)
      .then(({ data }) => {
        success(data);
      })
      .catch(error);
  }

  export function updateShift(
    payload: ShiftPayload,
    success: (success: ShiftReponse) => void,
    error: (error: AxiosError) => void
  ) {
    axiosInstance
      .put(`/comply-tsfm/api/schedule/${payload.id}`, payload)
      .then(({ data }) => {
        success(data);
      })
      .catch(error);
  }

  export function deleteShift(
    id: number,
    success: Function,
    error: (error: AxiosError) => void
  ) {
    axiosInstance
      .delete(`/comply-tsfm/api/schedule/${id}`)
      .then(() => {
        success();
      })
      .catch(error);
  }

  export function bulkShifts(
    payload: BulkShiftsPayload,
    success: (success: Array<ShiftReponse>) => void,
    error: (error: AxiosError) => void
  ) {
    const objToRemove = ["employee", "location", "areas", "area", "numOfWeeks"];

    let key: keyof BulkShiftsPayload;
    for (key in payload) {
      if (objToRemove.includes(key)) delete payload[key];
    }

    axiosInstance
      .post("/comply-tsfm/api/schedule/bulk", payload)
      .then(({ data }) => {
        success(data);
      })
      .catch(error);
  }
}
export default Shifts;
