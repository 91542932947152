import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Countries from "../../../api/countries";
import { removeEmptyOrNull } from "../../../api/general";
import CalendarInput from "../../../components/calendar-input/calendar-input";
import InputField from "../../../components/InputField/InputField";
import Select from "../../../components/select/select";

interface IProfileTab {
  defaultValue: any;
  onUpdate: (data: any) => void;
}
export default function ProfileTab({ defaultValue, onUpdate }: IProfileTab) {
  const selectStyle = {
    control: (style: any) => ({
      ...style,
      borderRadius: "30px",
      backgroundColor: "whitesmoke",
      fontSize: "15px",
    }),
  };
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [city, setCity] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();

  const [gender, setGender] = useState<string>();
  const [kioskPin, setKioskPin] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [postCode, setPostCode] = useState<string>();
  const [preferredPronouns, setPreferredPronouns] = useState<string>();
  const [preferredFullName, setPreferredFullName] = useState<string>();

  useEffect(() => {
    onUpdate({
      ...removeEmptyOrNull(defaultValue),
      ...removeEmptyOrNull({
        firstName,
        lastName,
        email,
        address,
        city,
        country,
        dateOfBirth,
        gender,
        kioskPin,
        phoneNumber,
        postCode,
        preferredPronouns,
        preferredFullName,
      }),
    });
  }, [
    firstName,
    lastName,
    email,
    address,
    city,
    country,
    dateOfBirth,
    gender,
    kioskPin,
    phoneNumber,
    postCode,
    preferredPronouns,
    preferredFullName,
  ]);

  return (
    <Container className="px-5 ">
      <Row className="pt-4 mb-3">
        <Col md="5" xs="12">
          <InputField
            placeHolder="Set your preferred full name to be shown in QSComply"
            label="Preferred full name optional"
            fontSize="15px"
            inputProps={{ defaultValue: defaultValue?.preferredFullName }}
            onChangedText={setPreferredFullName}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <InputField
            label="First Name"
            inputProps={{ defaultValue: defaultValue?.firstName }}
            fontSize="15px"
            onChangedText={setFirstName}
          />
        </Col>
        <Col md="5" xs="12" className="ms-5">
          <InputField
            label="Last Name"
            inputProps={{ defaultValue: defaultValue?.lastName }}
            fontSize="15px"
            onChangedText={setLastName}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <InputField
            label="Email Address"
            inputProps={{ defaultValue: defaultValue?.email }}
            fontSize="15px"
            onChangedText={setEmail}
          />
        </Col>
        <Col md="5" xs="12" className="ms-5">
          <InputField
            label="Kiosk PIN"
            fontSize="15px"
            inputProps={{ defaultValue: defaultValue?.kioskPin }}
            onChangedText={setKioskPin}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <InputField
            label="Phone Number"
            fontSize="15px"
            inputProps={{ defaultValue: defaultValue?.phoneNumber }}
            onChangedText={setPhoneNumber}
          />
        </Col>
        <Col md="5" xs="12" className="ms-5">
          <CalendarInput
            inputProps={{
              label: "Date of Birth",
              fontSize: "15px",
              fullwidth: true,
              // defaultValue: defaultValue.phoneNumber
              inputProps: { defaultValue: defaultValue?.dateOfBirth },
              onChangedText: setDateOfBirth,
            }}
            onDateChanged={(date: any) => {
              setDateOfBirth(date?.format("YYYY-MM-DD"));
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <Select
            label="Gender (Optional)"
            styles={selectStyle}
            defaultValue={
              defaultValue?.gender && {
                label: defaultValue?.gender,
                value: defaultValue?.gender,
              }
            }
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Other", value: "Other" },
            ]}
            onChange={(value: any) => {
              setGender(value.value);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <InputField
            label="Preferred Pronouns Optional"
            fontSize="15px"
            onChangedText={setPreferredPronouns}
            inputProps={{ defaultValue: defaultValue?.preferredPronouns }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md="5" xs="12">
          <InputField
            label="PostCode"
            fontSize="15px"
            onChangedText={setPostCode}
            inputProps={{ defaultValue: defaultValue?.postCode }}
          />
        </Col>
        <Col md="5" xs="12" className="ms-5">
          <InputField
            label="City/Suburb"
            fontSize="15px"
            onChangedText={setCity}
            inputProps={{ defaultValue: defaultValue?.city }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="5" xs="12">
          <Select
            label="Country"
            styles={selectStyle}
            defaultValue={
              defaultValue?.country && {
                label: defaultValue?.country,
                value: defaultValue?.country,
              }
            }
            options={Countries}
            onChange={(value: any) => {
              setCountry(value.value);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}
