import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "../../components/button/Button";
import { InputField } from "../../components/InputField/InputField";
import Text from "../../components/text/text";
import "./SignIn.scss";
import { FaLock } from "react-icons/fa";
import { RiRotateLockLine } from "react-icons/ri";
import useQuery from "../../hooks/query";
import axiosInstance from "../../api/axiosInstance";
import { useState } from "react";
import useModal from "../../hooks/modal";
import { useNavigate } from "react-router-dom";
export default function NewPassword() {
  const query = useQuery();
  const token = query.get("token");
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();

  function handleChangePassword() {
    if (confirmPassword && password) {
      if (confirmPassword === password) {
        axiosInstance
          .post("/comply-tsfm/api/forgotPassword/updatePassword", {
            token,
            password,
          })
          .then(({ data, status }) => {
            if (status) {
              showModal({
                Component: <Text>Password updated successfully.</Text>,
                FooterComponent: (
                  <Container>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          onClick={() => {
                            closeModal();
                            navigate("/");
                          }}
                        >
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                ),
              });
            }
          })
          .catch((error) => {
            showModal({ APIError: error, APIType: "APIEXCEPTION" });
          });
      } else {
        showModal({ Component: <Text>Passwords do not match</Text> });
      }
    } else {
      showModal({ Component: <Text>Please fill all required fields</Text> });
    }
  }
  return (
    <>
      <section className="overlaybg">
        <Container className="">
          <Row className="justify-content-center  fullscreen">
            <Col xl={4} lg={5} md={7} sm={8} xs={10} className="my-auto ">
              <Row className="justify-content-center align-items-center  ">
                <Col xs="auto" className="p-0 ">
                  <RiRotateLockLine
                    style={{ color: "white", fontSize: "130px" }}
                  />
                </Col>
                <Col lg={7} md={7} sm={7} xs={7} className="p-0">
                  <Text
                    bold
                    children="New Credentials"
                    responsive
                    fontSize="22px"
                    color="#ffffff"
                  />
                  <Text
                    light
                    children="Your identity has been verified set your new password"
                    responsive
                    fontSize="16px"
                    color="#ffffff"
                  />
                </Col>
              </Row>
              <Form>
                <Row className="align-items-center FormStyle my-3 justify-content-center">
                  <Col lg={12} md={12}>
                    <InputField
                      borderRadius="5px"
                      inputProps={{ type: "password" }}
                      placeHolder="New password"
                      startIcon={<FaLock />}
                      label="New Password"
                      labelColor="white"
                      fontSize="12px"
                      inputClassName="p-2"
                      onChangedText={setPassword}
                    />
                  </Col>
                  <Col lg={12} md={12}>
                    <InputField
                      borderRadius="5px"
                      placeHolder="Confirm password"
                      inputProps={{ type: "password" }}
                      startIcon={<FaLock />}
                      label="Confirm Password"
                      labelColor="white"
                      fontSize="12px"
                      inputClassName="p-2"
                      onChangedText={setConfirmPassword}
                    />
                  </Col>

                  <Col lg={12} md={12}>
                    <Button
                      fullWidth
                      noBorderRadius
                      fontSize="20px"
                      className="py-1 mt-4"
                      variant="dark_orange"
                      onClick={handleChangePassword}
                    >
                      NEXT
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
