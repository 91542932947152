import React, { Ref, useEffect } from "react";
import "./button.css";
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
} from "react-bootstrap";
import Text, { IText } from "../text/text";
export type variants =
  | "blue_color"
  | "green_color"
  | "dark_orange"
  | "light_orange"
  | "highlighted"
  | "dark"
  | "plain"
  | "no_color"
  | "grey"
  | "danger"
  | "cyan-6"
  | "cyan-12"
  | "light"
  | "primary"
  | "white";
export interface ButtonProps extends BootstrapButtonProps {
  label?: string;
  backgroundColor?: string;
  textColor?: string;
  ref?: any;
  startIcon?: any;
  endIcon?: any;
  fullWidth?: boolean;
  className?: string;
  textProps?: IText;
  centeredText?: boolean;
  variant?: variants;
  borderRadius?: string;
  fontSize?: string;
  noBorderRadius?: boolean;
  bold?: boolean;
  small?: boolean;
  textRef?: Ref<HTMLDivElement>;
  containerProps?: React.HtmlHTMLAttributes<HTMLDivElement>;
}

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { children: string | React.ReactElement }
>(
  (
    {
      children,
      label,
      variant = "dark_orange",
      borderRadius = "50px",
      size = "sm",
      fullWidth,
      fontSize,
      noBorderRadius = true,
      textProps,
      textColor,
      bold,
      textRef,
      startIcon,
      endIcon,
      containerProps,
      backgroundColor,
      ...restOfProps
    },
    ref
  ) => {
    return (
      <div {...containerProps}>
        <BootstrapButton
          ref={ref}
          variant={variant}
          size={size}
          {...restOfProps}
          className={`d-flex justify-content-center align-items-center customButton  ${restOfProps.className} `}
          style={{
            borderRadius: noBorderRadius ? "5px" : borderRadius,
            width: fullWidth ? "100%" : "auto",
            padding: "4px 24px",
            backgroundColor,
            ...restOfProps.style,
          }}
        >
          <div style={{ color: textColor }}>
            <Text
              ref={textRef}
              bold={bold}
              {...textProps}
              style={{
                fontSize,
                ...textProps?.style,
                color: textColor,
              }}
              className=" justify-content-between align-items-center d-flex"
            >
              {startIcon && (
                <div className=" d-flex align-items-center justify-content-center  p-0 m-0">
                  {startIcon}
                </div>
              )}
              <span>{children || label}</span>
              {endIcon && (
                <div className="ms-2 d-flex align-items-center  p-0 m-0">
                  {endIcon}
                </div>
              )}
            </Text>
          </div>
        </BootstrapButton>
      </div>
    );
  }
);
export default Button;

// export default function Button({
//   label,
//   gridBtn,
//   variant = "primary",
//   children,
//   backgroundColor,
//   textColor,
//   small,
//   ref,
//   callback,
//   startIcon,
//   endIcon,
//   fullWidth,
//   className,
//   textProps,
//   centeredText,
// }: ButtonProps) {
//   return (
//     <div className={gridBtn ? "d-grid gap-2" : undefined}>
//       <BootstrapButton
//         ref={ref}
//         onClick={callback}
//         className={`${className || ""} ${fullWidth ? "w-100" : ""} ${
//           variant === "primary" ? "gradient-btn" : ""
//         } buttonHover p-2`}
//         variant={variant}
//         size={small ? "sm" : "lg"}
//         style={{
//           backgroundColor: backgroundColor || "none",
//           border: "none",

//           color: textColor || (variant === "light" ? "black" : "white"),
//         }}
//       >
//         <Row
//           className={`p-0 m-0 d-flex justify-content-${
//             startIcon || endIcon ? "between" : "center"
//           } align-items-center w-100`}
//         >
//           {startIcon && (
//             <Col xs="auto" style={{ margin: 0, padding: 0 }}>
//               {startIcon}
//             </Col>
//           )}
//           <Col style={{ margin: 0, padding: 0 }}>
//             <Text
//               color={textColor || "white"}
//               fontSize="18px"
//               style={centeredText ? { textAlign: "center" } : undefined}
//               className="w-100"
//               {...textProps}
//             >
//               <span>{label || children}</span>
//             </Text>
//           </Col>
//           {endIcon && (
//             <Col xs="auto" style={{ margin: 0, padding: 0 }}>
//               {endIcon}
//             </Col>
//           )}
//         </Row>
//       </BootstrapButton>
//     </div>
//   );
// }
