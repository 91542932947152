import { Fragment } from "react";
import {
  Button,
  ButtonProps,
  OverlayTrigger,
  Popover,
  PopoverProps,
  OverlayTriggerProps,
} from "react-bootstrap";

interface PopoverButtonProps extends Omit<ButtonProps, "variant"> {
  popover: React.ReactNode;
  popoverProps?: PopoverProps;
  overlayProps?: Omit<OverlayTriggerProps, "children" | "overlay">;
  variant?: QSTheme.ThemeVariant;
}
export default function PopoverButton({
  children,
  popover,
  popoverProps,
  overlayProps,
  variant = "blue_color",
  ...restOfProps
}: PopoverButtonProps) {
  const popoverComponent = (
    <Popover
      {...popoverProps}
      className={`shadow-sm ${popoverProps?.className}`}
    >
      {popover}
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      {...overlayProps}
      overlay={popoverComponent}
    >
      <Button
        {...restOfProps}
        style={{
          ...restOfProps.style,
        }}
        variant={variant}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
}
