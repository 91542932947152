import { MouseEventHandler } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FaPaperclip } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import Button from "../../components/button/Button";
import Switch from "../../components/custom-switch/custom-switch";
import { InputField } from "../../components/InputField/InputField";
import Select from "../../components/select/select";
import Text from "../../components/text/text";
import { Colors } from "../../constants";

interface ICreateNewsFeed {
  show: boolean;
  onClose: () => void;
}
export default function CreateNewsFeed({ onClose, show }: ICreateNewsFeed) {
  return (
    <Modal show={show} centered onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Text heading bold>
          Create Post
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="align-items-center ">
            <Col>
              <Row className="align-items-center ">
                <Col xs="auto">
                  <Text>Share with</Text>
                </Col>
                <Col>
                  <Select
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: Colors["cyan-12"],
                        borderColor: "#9e9e9e",
                        minHeight: "30px",
                        height: "30px",
                        boxShadow: "none",
                        border: state.isFocused ? "none" : "none",
                        borderRadius: "20px",
                        fontSize: "12px",
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: "30px",
                      }),

                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: "30px",
                      }),
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="auto" className="justify-content-end">
              <Text rightAlign>Select Location or person</Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputField
                borderRadius="10px"
                placeHolder="What's happening?"
                backgroundColor={Colors["cyan-12"]}
                inputProps={{ as: "textarea" }}
              />
            </Col>
          </Row>
          <Row className="mt-3 align-items-center">
            <Col xs="auto">
              <Button
                variant="blue_color"
                textColor="white"
                startIcon={<FaPaperclip color="white" className="me-2" />}
              >
                Add Media
              </Button>
            </Col>
            <Col>
              <Text fontSize="8px">
                Drag and Drop files here: JPG, PNG, MP4, MOV and PDFs are
                accepted Maximum of 4 files.
              </Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="auto">
              <Switch />
            </Col>
            <Col>
              <Text>Require confirmation from all readers</Text>
              <Text color={Colors.HEX_TO_RGBA("#000000", 0.5)}>
                All readers will be required to mark this post as confirmed. You
                can track who has and hasn't confirmed
              </Text>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row className="justify-content-center ">
            <Col xs="auto">
              <Button noBorderRadius={false} variant="light" onClick={onClose}>
                Close
              </Button>
            </Col>
            <Col xs="auto">
              <Button noBorderRadius={false} variant="blue_color">
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
