import { ReactNode, useCallback, useRef, useState } from "react";
import {
  Form,
  FormControlProps,
  FormGroupProps,
  FormLabelProps,
  InputGroup,
} from "react-bootstrap";
import axiosInstance from "../../api/axiosInstance";
import AddressModal from "../../components/addressModal";
import Text from "../../components/text/text";
import useModal from "../../hooks/modal";
import "./input.styles.scss";

export interface TextInputProps {
  groupProps?: FormGroupProps;
  label?: string;
  labelProps?: FormLabelProps;
  bottomLabel?: React.ReactNode;
  bottomLabelProps?: FormLabelProps;
  controlProps?: FormControlProps;
  feedbackText?: string;
  wrapperClassName?: string;
  placeholder?: string;
  leftIcon?: React.ReactNode;
  leftIconProps?: React.HTMLProps<HTMLSpanElement> & {
    as?: React.ElementType;
  };
  rightIcon?: React.ReactNode;
  rightIconProps?: React.HTMLProps<HTMLSpanElement> & {
    as?: React.ElementType;
  };
  noBorderRadius?: boolean;
  onChangeText?: (text: string) => void;
  validation?: (e: any) => boolean;
  isValid?: boolean;
  noBorder?: boolean;
  addressInput?: boolean;
  className?: string;
}
export default function TextInput({
  label,
  groupProps,
  labelProps,
  controlProps,
  addressInput,
  feedbackText,
  wrapperClassName,
  placeholder,
  leftIcon,
  noBorderRadius = false,
  onChangeText,
  validation,
  rightIcon,
  isValid = true,
  bottomLabel,
  bottomLabelProps,
  noBorder = false,
  leftIconProps,
  rightIconProps,
  className,
}: TextInputProps) {
  const { showModal } = useModal();
  const [showAddressModal, setshowAddressModal] = useState(false);

  const [areAddressesLoading, setAreAddressesLoading] = useState(true);
  const [addresses, setAddresses] = useState<Array<any>>([]);
  const inputRef = useRef<HTMLInputElement>();

  const fetchAddresses = useCallback(() => {
    if (controlProps?.value) {
      setshowAddressModal(true);
      setAreAddressesLoading(true);

      axiosInstance
        .post("/comply-tsfm/api/utility/addresses", {
          address: controlProps?.value,
        })
        .then(({ status, data }) => {
          if (status === 200) {
            setAddresses(data);
            setAreAddressesLoading(false);
          }
        })
        .catch((error) => {
          setAreAddressesLoading(false);

          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    }
  }, [controlProps?.value]);

  return (
    <Form.Group
      key={label}
      {...groupProps}
      className={`${wrapperClassName} ${className} generic-input`}
    >
      {addressInput && (
        <AddressModal
          show={showAddressModal}
          areAddressesLoading={areAddressesLoading}
          formattedAddresses={addresses}
          onAddressSelect={(address) => {
            if (inputRef.current && (label || placeholder)) {
              const inputSet = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
              )?.set;
              if (inputSet) {
                inputSet.call(inputRef.current, address.address);
                inputRef.current.dispatchEvent(
                  new Event("input", { bubbles: true })
                );
              }
            }

            setshowAddressModal(false);
          }}
          onClose={() => {
            setshowAddressModal(false);
          }}
        />
      )}
      {label && (
        <Form.Label
          {...labelProps}
          className={`${wrapperClassName}__label generic-input__label ${
            labelProps?.className || ""
          }`}
        >
          {label}
        </Form.Label>
      )}
      <InputGroup className={`${isValid ? "" : "generic-input__invalid"}`}>
        {leftIcon && (
          <InputGroup.Text
            {...leftIconProps}
            style={{ backgroundColor: "white", ...leftIconProps?.style }}
          >
            {leftIcon}
          </InputGroup.Text>
        )}
        <Form.Control
          id={label || placeholder}
          ref={inputRef}
          placeholder={placeholder || label}
          onChange={(e: any) => {
            onChangeText && onChangeText(e.target.value);
          }}
          {...controlProps}
          onBlur={(e: any) => {
            if (validation) {
              // setValid(validation(e));
            }
            controlProps?.onBlur && controlProps?.onBlur(e);
            addressInput && fetchAddresses();
          }}
          className={`${noBorder ? "border-0" : "border"} ${
            noBorderRadius ? "rounded-0" : ""
          } ${wrapperClassName}__input generic-input__input ${
            controlProps?.className || ""
          }`}
        />
        {rightIcon && (
          <InputGroup.Text
            {...rightIconProps}
            style={{ backgroundColor: "white", ...rightIconProps?.style }}
          >
            {rightIcon}
          </InputGroup.Text>
        )}
      </InputGroup>
      {bottomLabel && (
        <Form.Label
          {...bottomLabelProps}
          className={`${wrapperClassName}__label generic-input__label m-0 ${
            bottomLabelProps?.className || ""
          }`}
        >
          {bottomLabel}
        </Form.Label>
      )}

      {feedbackText && (
        <Text
          color="red"
          fontSize="0.8rem"
          style={{ minHeight: "0.3rem", marginTop: "0.5rem" }}
        >
          {!isValid ? feedbackText : ""}
        </Text>
      )}
    </Form.Group>
  );
}
