import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageKeys } from "../constants";
import EmployeeModule, { IEmployee } from "../modules/Employee";
import { useBusinessProfile } from "./business";
// import { IEmployee } from "../interface";
interface EmployeeHookProps {
  employees: Array<IEmployee>;
}
const EmployeeContext = React.createContext<EmployeeHookProps>({
  employees: [],
});

export function EmployeeProvider({ children }: any) {
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const { id } = useBusinessProfile();

  useEffect(() => {
    EmployeeModule.FetchEmployees(
      (employees) => {
        setEmployees(employees);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [id]);

  return (
    <EmployeeContext.Provider value={{ employees }}>
      {children}
    </EmployeeContext.Provider>
  );
}

export const useEmployees = () =>
  React.useContext<EmployeeHookProps>(EmployeeContext);
