import { useState } from "react";
import { Container, Row, Col, ProgressBar, Tabs, Tab } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { checkRequiredFields, removeEmptyOrNull } from "../../api/general";
import { useAxios } from "../../api/useAxios";
import Button from "../../components/button/Button";
import Loader from "../../components/loader";
import Text from "../../components/text/text";
import { Colors } from "../../constants";
import useModal from "../../hooks/modal";
import { useUserProfile } from "../../hooks/user";
import "./profile.scss";
import Address from "./tabs/address";
import EmergencyContact from "./tabs/emergency";
import ProfilePhoto from "./tabs/profile-photo";
import ProfileTab from "./tabs/profile-tab";
import Security from "./tabs/security";
import Social from "./tabs/social";
export default function Profile({}) {
  // const neededKeys = [
  //   "firstName",
  //   "lastName",
  //   "kioskPin",
  //   "phoneNumber",
  //   "dateOfBirth",
  //   "address",
  //   "city",
  //   "country",
  //   "postCode",
  //   "emergencyContactName",
  //   "emergencyContactPhone",
  // ];

  const neededKeys = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Kiosk Pin", key: "kioskPin" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Date Of Birth", key: "dateOfBirth" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Post Code", key: "postCode" },
    { label: "Emergency Contact Name", key: "emergencyContactName" },
    { label: "Emergency Contact Phone", key: "emergencyContactPhone" },
  ];
  const { showModal, closeModal } = useModal();
  const [activeTab, setActiveTab] = useState<string | null>("profile");
  const [userDefaultValues, setUserDefaultValues] = useState<{
    [key: string]: any;
  }>({});
  const [updatedData, setUpdatedData] = useState<{ [key: string]: any }>({});
  const TabTitle = ({ title, eventKey }: any) => {
    return (
      <div
        style={{
          borderBottom:
            eventKey === activeTab ? `2px solid ${Colors.DARK_ORANGE}` : "none",
        }}
      >
        <Text>{title}</Text>
      </div>
    );
  };
  const tabs = [
    {
      title: "Profile",
      eventKey: "profile",
      Compoent: ProfileTab,
      onUpdate: (data: any) => {
        setUpdatedData({
          ...updatedData,
          ...data,
        });
      },
    },
    {
      title: "Profile Photo",
      eventKey: "profile-photo",
      Compoent: ProfilePhoto,
      onUpdate: (data: any) => {
        setUpdatedData({
          ...updatedData,
          ...data,
        });
      },
    },
    {
      title: "Address",
      eventKey: "address",
      Compoent: Address,
      onUpdate: (data: any) => {
        setUpdatedData({
          ...updatedData,
          ...data,
        });
      },
    },
    {
      title: "Emergency Contact",
      eventKey: "emergency",
      Compoent: EmergencyContact,
      onUpdate: (data: any) => {
        setUpdatedData({
          ...updatedData,
          ...data,
        });
      },
    },

    // {
    //   title: "Security",
    //   eventKey: "security",
    //   Compoent: Security,
    //   onUpdate: (data: any) => {
    //     setUpdatedData({
    //       ...updatedData,
    //       ...data,
    //     });
    //   },
    // },
    // {
    //   title: "Social",
    //   eventKey: "social",
    //   Compoent: Social,
    //   onUpdate: (data: any) => {
    //     setUpdatedData({
    //       ...updatedData,
    //       ...data,
    //     });
    //   },
    // },
  ];
  const navigate = useNavigate();

  const userdetails = useAxios(
    {
      url: "/comply-tsfm/api/setting/details",
      method: "GET",
    },
    [],
    (data, error) => {
      if (data) {
        const {
          address,
          city,
          country,
          dateOfBirth,
          email,
          firstName,
          gender,
          kioskPin,
          lastName,
          phoneNumber,
          preferredFullName,
          emergencyContactName,
          emergencyContactPhone,
          postCode,
          photoUrl,
          preferredPronouns,
        } = data;
        setUpdatedData(removeEmptyOrNull(data));
        setUserDefaultValues({
          profile: {
            address,
            city,
            country,
            dateOfBirth,
            email,
            firstName,
            gender,
            kioskPin,
            lastName,
            phoneNumber,
            preferredFullName,
            postCode,
            preferredPronouns,
          },
          emergency: {
            emergencyContactName,
            emergencyContactPhone,
          },
          address: {
            address,
            city,
            country,
            postCode,
          },
          "profile-photo": {
            photoUrl,
          },
        });
      }
    }
  );

  function updateUserDetails() {
    const { allFilled, missingekeys } = checkRequiredFields(
      neededKeys,
      updatedData
    );
    if (allFilled) {
      axiosInstance
        .post("/comply-tsfm/api/setting/updateDetails", updatedData)
        .then(({ data, status }) => {
          if (status) {
            showModal({
              Component: (
                <Text heading bold>
                  User Profile Updated Successfully!
                </Text>
              ),
              FooterComponent: (
                <Button
                  onClick={() => {
                    closeModal();
                    navigate(-1);
                  }}
                >
                  Close
                </Button>
              ),
            });
          }
        })
        .catch((error) => {
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    } else {
      showModal({
        Component: (
          <Text>
            {`Please fill the required fields: ${missingekeys.join(", ")}`}
          </Text>
        ),
      });
    }
  }

  return (
    <Container style={{ width: "100vw", height: "100vh" }} className="">
      <Row className="h-100 align-items-center">
        <Col>
          <Row className="my-2 mx-5">
            <Col>
              <Button
                variant="no_color"
                className="p-0 m-0"
                fontSize="16px"
                textColor={Colors.BLUE}
                startIcon={<IoIosArrowBack />}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </Col>
          </Row>

          <Row className="mx-5">
            <Col>
              {userdetails.loading ? (
                <Loader />
              ) : (
                <Tabs
                  onSelect={(k) => setActiveTab(k)}
                  variant="tabs"
                  style={{ backgroundColor: "white" }}
                >
                  {tabs.map(({ Compoent, eventKey, title, onUpdate }, i) => (
                    <Tab
                      key={i}
                      style={{ backgroundColor: "white" }}
                      title={<TabTitle title={title} eventKey={eventKey} />}
                      eventKey={eventKey}
                      className="profile-tab-content overflow-auto"
                    >
                      <Compoent
                        defaultValue={userDefaultValues[eventKey]}
                        onUpdate={onUpdate}
                      />
                    </Tab>
                  ))}
                </Tabs>
              )}
              <div style={{ background: "white" }} className="p-3 pt-4">
                <Row
                  style={{ background: Colors["cyan-12"] }}
                  className="m-2 p-2 align-items-center"
                >
                  <Col xs="auto">
                    <Button variant="no_color" fontSize="12px" className="p-0">
                      Re-subscribe to Email notifications
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="no_color"
                      fontSize="12px"
                      textColor="red"
                      className="p-0"
                    >
                      Delete my account
                    </Button>
                  </Col>
                  <Col className="justify-content-end d-flex">
                    <Button noBorderRadius={false} onClick={updateUserDetails}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
