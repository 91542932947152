import { MouseEventHandler } from "react";
import { Container, Modal, Col, Row } from "react-bootstrap";
import Button from "../../../../components/button/Button";
import Text from "../../../../components/text/text";

interface IRemoveTeamMembers {
  show: boolean;
  onClose: () => void;
}
export default function RemoveTeamMembers({
  onClose,
  show,
}: IRemoveTeamMembers) {
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton>
        <Text fontSize="18px">Remove All Team Members</Text>
      </Modal.Header>
      <Modal.Body>
        <Text className=" ">
          Warning! Are you sure you want to{" "}
          <span style={{ color: "red" }}>
            remove all the team members from shifts you are viewing?
          </span>
        </Text>
        <Text>
          This action will remove the Team Members and preserve the shifts. This
          action is permanent, please proceed with caution.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button noBorderRadius={false} variant="cyan-6" onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button noBorderRadius={false} variant="blue_color">
                Continue
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
