import { Col, Container, Form, Row } from "react-bootstrap";
import { Colors } from "../../constants";
import Text from "../../components/text/text";
import "./../SignIn/SignIn.scss";
import { BsFillPhoneFill, BsPhone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { InputField } from "../../components/InputField/InputField";
import Button from "../../components/button/Button";
import { useEffect, useState } from "react";
import Strings from "../../messages";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
export default function VerificationMethod(props: any) {
  const navigate = useNavigate();
  const params = useLocation();
  const state: { phone?: string; userId?: string; email?: string } =
    params.state as any;
  const [code, setCode] = useState<string>();
  const [message, setMessage] = useState<string>();

  function verifyUser() {
    if (code && code?.length === 6) {
      axiosInstance
        .post("/user/verifyPhone", {
          code,
          number: state?.phone,
          userId: state?.userId,
          email: state?.email,
        })
        .then(({ data }) => {
          if (data?.error) {
            setMessage(data?.message);
          } else {
            if (data?.isValid) {
              navigate("/");
            } else {
              setMessage("Code not valid!");
            }
          }
        })
        .catch((error) => {
          setMessage(Strings.RANDOM_NETWORK_ERROR);
        });
    } else {
      setMessage("Please enter 6 digit code.");
    }
  }

  const verificationOptions = [
    {
      label: "VIA SMS",
      value: state?.phone || "0412599903",
      icon: (
        <BsFillPhoneFill
          color="lightgrey"
          className="respverifyIcon "
          style={{
            fontSize: "50px",
            marginRight: "10px",
          }}
        />
      ),
    },
    {
      label: "VIA EMAIL",
      value: state?.email || "mirzasaoud60@gmail.com",
      icon: (
        <MdEmail
          color="lightgrey"
          className="respverifyIcon "
          style={{
            fontSize: "50px",
            marginRight: "10px",
          }}
        />
      ),
    },
  ];

  return (
    <>
      <section className="overlaybg">
        <Container className="">
          <Row className="justify-content-center  fullscreen">
            <Col xl={7} lg={9} md={9} sm={10} xs={10} className="my-auto ">
              <Row className="justify-content-center">
                <Col lg={9} md={10} sm={12} xs={12}>
                  <Text
                    bold
                    children="Make Selection"
                    responsive
                    fontSize="40px"
                    color="#ffffff"
                    className="text-center"
                  />
                  <Text
                    children="Select which contact detail should we use to reset your password?"
                    responsive
                    fontSize="20px"
                    color="#ffffff"
                    className="text-center"
                  />
                </Col>
              </Row>
              <Row className="align-items-center FormStyle pt-5 pb-5 justify-content-center">
                <Col>
                  <Row>
                    {verificationOptions.map(({ icon, label, value }, i) => (
                      <Col xs="12" md="6" className="" key={i}>
                        <Link
                          to="/verify"
                          className="white-container d-flex align-items-center verify-option"
                        >
                          {icon}
                          <Text
                            light
                            responsive
                            style={{ fontWeight: "lighter", color: "grey" }}
                            fontSize="18px"
                          >
                            <>
                              {label}
                              <br />
                              {value}
                            </>
                          </Text>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
