import {Col, Container, Modal, Row} from "react-bootstrap";
import {BiQrScan} from "react-icons/bi";
import Button from "../../../components/button/Button";
import Link from "../../../components/link/link";
import "./../location.scss";
import QRCode from "qrcode.react";
import logo from "./../../../assets/smalllogo.png";
import {useRef} from "react";
import html2canvas from "html2canvas";
import useModal from "../../../hooks/modal";

interface IAreaQRCode {
  show: boolean;
  onClose: () => void;
  data?: {
    locationName?: string;
    areaName: string;
    locationId?: string;
    areaId?: number;
    locationAddress?: string;
  };
}
export default function AreaQRCode({onClose, show, data}: IAreaQRCode) {
  const li = [
    {
      text: data?.locationName,
    },
    {
      text: data?.areaName,
    },
    {
      text: data?.locationAddress,
    },
  ];
  const {showModal} = useModal();
  const printRef = useRef<HTMLDivElement>(null);

  const handleDownloadImage = async () => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);

      const QRcontainer = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = QRcontainer;
        link.download = `${data?.locationName}-${data?.areaName}`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(QRcontainer);
      }
    } else {
      showModal({Component: <div>No data to download</div>});
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton />
      <Modal.Body>
        <Container className="mt-5">
          <div
            className="p-4"
            style={{backgroundColor: "white", borderRadius: "25px"}}
          >
            <Row id="QR-container">
              <Col className="p-5" xl={8} lg={7}>
                <div className="pb-3">
                  <h3>Scan QR code with QSComply app</h3>
                </div>

                {li.map(({text}) => {
                  return (
                    <>
                      <div className="d-flex align-items-center pt-2 pb-3">
                        <div className="dot" />
                        <h5 className="m-0">{text}</h5>
                      </div>
                    </>
                  );
                })}
              </Col>
              <Col xl={4} lg={5} ref={printRef}>
                <div
                  className="text-center pt-2 pb-4"
                  style={{
                    backgroundColor: "rgb(236 236 236 / 45%)",
                    borderRadius: "10px",
                  }}
                >
                  <QRCode
                    value={JSON.stringify(data)}
                    size={300}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={true}
                    renderAs={"canvas"}
                  />
                  <div className="d-flex align-items-center justify-content-center pt-3">
                    <div className="box" />
                    <h5 className="m-0">
                      {data?.locationName} | {data?.areaName}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="justify-content-center ">
            {/* <Col>
              <Row>
                <Col xs="auto">
                  <Button noBorderRadius={false} className="px-5">
                    Edit
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    className="px-5"
                    noBorderRadius={false}
                    variant="no_color"
                    backgroundColor="grey"
                    textColor="white"
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Col> */}
            <Col xs="auto">
              <Button onClick={handleDownloadImage}>
                Download QR Code now!
              </Button>
            </Col>
            <Col xs="auto">
              <Button onClick={onClose}>Close</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
