export const Colors = {
  BLUE: "#1da5d4",
  GREEN: "#11928a",
  DARK_ORANGE: "#f79523",
  LIGHT_ORANGE: "#f79523",
  HIGHLIGHTED: "#2e3192",
  PLAIN: "#ffffff",
  TRANSPARENT: "transparent",
  HEX_TO_RGBA: (hex: string, opacity = 1) => {
    const { r, g, b }: any = hexToRgb(hex);
    return `rgba(${r},${g},${b},${opacity})`;
  },
  "cyan-12": "#ebf7fd",
  "cyan-6": "#ecf8fe",
  OUTLINE: "#ebebec",
  LIGHT_GREY: "#e6e6e6",
  NAVY_BLUE: "#00165A",
};

export const ShiftTypeColors = [
  "#e6e5e8",
  "#2ea147",
  "#89878e",
  "#ba4993",
  "#e0281c",
  "#b1160b",
  "#da928c",
  "#4a4463",
];

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const localStorageKeys = {
  USER: "user",
  BUSINESS_LOGO: "businessLogo",
  BUSINESS_NAME: "businessName",
  BUSINESS_ID: "businessId",
  BUSINESS: "business",
};
export const mapKey = "AIzaSyB-QBSUzTF3m6Q9E3tOSR6Qi-knOxeBWuE";
export const placesAPIKEY = "AIzaSyBWjv4i0NfmSq_Og0LsjKbbxQt6UxzAgK8";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const shiftColorPalette = ["green", "blue", "purple", "orange"];
export const BUILD_NO = `16 | 12 May 2022 ${BASE_URL}`;
