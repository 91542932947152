import moment from "moment";
import React, { MouseEventHandler } from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { FaClock } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { variants } from "../../../components/button/Button";
import Text from "../../../components/text/text";
import { ITimesheet } from "../../../modules/Timesheet";
import "../timesheet.styles.scss";

interface TimesheetDetailProps {
  timesheet?: ITimesheet;
  onUnapproveTime?: MouseEventHandler<HTMLButtonElement>;
  onUnapprovePay?: MouseEventHandler<HTMLButtonElement>;
}
export default function TimesheetDetail({
  timesheet,
  onUnapprovePay,
  onUnapproveTime,
}: TimesheetDetailProps) {
  const startTimeMoment = moment
    .unix(Number(timesheet?.startTime))
    .format("h:mm A");
  const endTimeMoment = moment
    .unix(Number(timesheet?.endTime))
    .format("h:mm A");
  const duration = moment.duration(
    moment(endTimeMoment, "h:mm A").diff(moment(startTimeMoment, "h:mm A"))
  );
  const formatedTimesheet = {
    Date: moment.unix(Number(timesheet?.date)).format("dddd, Do MMMM YYYY"),
    "Employee Name": timesheet?.employeeName,
    Location: timesheet?.locationName,
    Time: `${startTimeMoment} - ${endTimeMoment} | ${duration.hours()} hours ${duration.minutes()} min`,
    "Total Meal Break": `${timesheet?.totalMealBreak} mins`,
    "Total Rest Break": `${timesheet?.totalRestBreak} mins`,
    // breaks: timesheet?.breaks,
  };
  const ApprovalStatus = (
    label: {
      text: string;
      icon?: React.ReactChild;
    },
    badge: React.ReactNode,
    button: {
      label: string;
      onClick?: MouseEventHandler<HTMLButtonElement>;
      variant: string;
      textColor?: string;
    }
  ) => (
    <React.Fragment>
      <Row className="justify-content-end align-items-center my-2">
        <Col>
          <Text icon={label.icon}>{label.text}</Text>
        </Col>
        <Col className="justify-content-end d-flex">{badge}</Col>
      </Row>
      <Row>
        <Col xs="12">
          <Button
            size="sm"
            variant={button.variant}
            style={{ color: button.textColor }}
            className="w-100"
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
  return (
    <div className="p-2 py-4 m-2 rounded border shadow-sm">
      <Container fluid>
        <Row>
          <Col xs="9">
            <Row className="border-bottom pb-3 pt-2">
              <Col>
                <Text heading>Timesheet Details</Text>
              </Col>
            </Row>
            {Object.entries(formatedTimesheet).map(([key, value]) => (
              <Row className="border-bottom p-2 timesheet-details-row">
                <Col xs="2" className="border-end">
                  <Text rightAlign fontSize="14px">
                    {key}
                  </Text>
                </Col>
                <Col xs="10">
                  <Text fontSize="14px">{value}</Text>
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs="3">
            <Container className="border rounded shadow-sm p-2" fluid>
              <Row>
                <Col xs="12">
                  <Button
                    disabled
                    size="sm"
                    variant="success"
                    style={{ color: "white" }}
                    className="w-100"
                  >
                    Approve & Next
                  </Button>
                </Col>
              </Row>

              {ApprovalStatus(
                { text: "Time Approval", icon: <FaClock /> },
                <Badge bg="success">Approved</Badge>,
                {
                  label: "Unapprove Time",
                  variant: "cyan-12",
                  onClick: onUnapproveTime,
                }
              )}
              {ApprovalStatus(
                { text: "Pay Approval", icon: <MdOutlineAttachMoney /> },
                <Badge bg="success">Approved</Badge>,
                {
                  label: "Unapprove Pay",
                  variant: "cyan-12",
                  onClick: onUnapprovePay,
                }
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
