import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Calendar as ModernCalendar } from "@hassanmojab/react-modern-calendar-datepicker";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { variants } from "../../components/button/Button";
import Text from "../../components/text/text";
import "./calendar.styles.scss";

interface CalendarProps {
  mode: "Day" | "Week" | "2 Weeks" | "Month";
  onDateChanged: (date: { startDate: Moment; endDate: Moment }) => void;
  defaultDate?: { startDate: Moment; endDate: Moment };
  variant?: variants;
  backgroundColor?: string;
  textColor?: string;
  calenderOnly?: boolean;
}
export default function Calendar({
  mode,
  onDateChanged,
  variant = "no_color",
  defaultDate,
  textColor = "black",
  backgroundColor,
  calenderOnly,
}: CalendarProps) {
  const navigatorButtonGroupRef = useRef<HTMLDivElement>(null);
  const [currentStartDate, setCurrentStartDate] = useState<Moment>();
  const [currentEndDate, setCurrentEndDate] = useState<Moment>();
  const [selectedDate, setSelectedDate] = useState<Moment>();

  // const [modeState, setModeState] = useState<string>(mode);
  // const [unit, setUnit] = useState<"day" | "weeks" | "month">(
  //   mode === "Day"
  //     ? "day"
  //     : mode === "Week" || mode === "2 Weeks"
  //     ? "weeks"
  //     : "month"
  // );

  // const unit =
  //   mode === "Day"
  //     ? "day"
  //     : mode === "Week" || mode === "2 Weeks"
  //     ? "weeks"
  //     : "month";

  const [unit, setUnit] = useState<"d" | "w" | "M">("M");
  useEffect(() => {
    if (mode) {
      if (mode === "Day") {
        setUnit("d");
      } else if (mode === "2 Weeks" || mode === "Week") {
        setUnit("w");
      } else {
        setUnit("M");
      }
    }
  }, [mode]);

  function DateRangeDropdown({}) {
    return (
      <Container
        fluid
        // className="p-2"
        style={
          {
            // width: navigatorButtonGroupRef.current?.clientWidth,
            // backgroundColor: "grey",
          }
        }
      >
        <Row>
          <Col className="p-1">
            <DropdownButton
              title="Current"
              drop="end"
              size="sm"
              variant="cyan-12"
              className="calendar-dropdown-btn"
              as="div"
              onSelect={(eventKey) => {
                if (eventKey === "today") {
                  setCurrentStartDate(moment().startOf("day"));
                  setCurrentEndDate(moment().endOf("day"));
                }
                if (eventKey === "week") {
                  setCurrentStartDate(moment().startOf("week"));
                  setCurrentEndDate(moment().endOf("week"));
                }
                if (eventKey === "fortnight1") {
                  setCurrentStartDate(moment().startOf("week"));
                  setCurrentEndDate(moment().endOf("isoWeek").add(1, "weeks"));
                }
                if (eventKey === "month") {
                  setCurrentStartDate(moment().startOf("month"));
                  setCurrentEndDate(moment().endOf("month"));
                }
              }}
            >
              <Dropdown.Item eventKey="today">Today</Dropdown.Item>
              <Dropdown.Item eventKey="week">Week</Dropdown.Item>
              <Dropdown.Item eventKey="fortnight1">Fortnight</Dropdown.Item>
              {/* <Dropdown.Item eventKey="fortnight2">Fortnight 2</Dropdown.Item> */}
              <Dropdown.Item eventKey="month">Month</Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col className="p-1">
            <DropdownButton
              title="Last"
              drop="end"
              size="sm"
              variant="cyan-12"
              className="calendar-dropdown-btn"
              as="div"
              onSelect={(eventKey) => {
                if (eventKey === "yesterday") {
                  const yesterday = moment().subtract(1, "day");
                  setCurrentStartDate(yesterday.startOf("day"));
                  setCurrentEndDate(yesterday.endOf("day"));
                }
                if (eventKey === "7days") {
                  let last7days = moment().subtract(7, "days");
                  setCurrentStartDate(last7days.startOf("day"));
                  setCurrentEndDate(
                    last7days.clone().add(1, "week").endOf("day")
                  );
                }
                if (eventKey === "14days") {
                  let last14days = moment().subtract(13, "days");
                  setCurrentStartDate(last14days.startOf("day"));
                  setCurrentEndDate(
                    last14days.clone().add(13, "days").endOf("day")
                  );
                }
                if (eventKey === "30days") {
                  let last30days = moment().subtract(29, "days");
                  setCurrentStartDate(last30days.startOf("day"));
                  setCurrentEndDate(
                    last30days.clone().add(29, "days").endOf("day")
                  );
                }
                if (eventKey === "90days") {
                  let last90days = moment().subtract(89, "days");
                  setCurrentStartDate(last90days.startOf("day"));
                  setCurrentEndDate(
                    last90days.clone().add(89, "days").endOf("day")
                  );
                }
              }}
            >
              <Dropdown.Item eventKey="yesterday">Yesterday</Dropdown.Item>
              <Dropdown.Item eventKey="7days">7 Days</Dropdown.Item>
              <Dropdown.Item eventKey="14days">14 Days</Dropdown.Item>
              <Dropdown.Item eventKey="30days">30 Days</Dropdown.Item>
              <Dropdown.Item eventKey="90days">90 Days</Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col className="p-1">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="right"
              overlay={
                <div
                  // fluid
                  className="shadow-sm p-2 rounded"
                  style={{ zIndex: 1001, backgroundColor: "#fff" }}
                >
                  <Row xs="auto">
                    <Col>
                      <Text>Start Date</Text>
                      <ModernCalendar
                        shouldHighlightWeekends
                        calendarClassName="QS-calender"
                        // value={
                        //   currentStartDate && {
                        //     day: currentStartDate?.day(),
                        //     month: currentStartDate?.month() + 1,
                        //     year: currentStartDate?.year(),
                        //   }
                        // }
                        onChange={(date) => {
                          setSelectedDate(
                            moment({
                              year: date?.year,
                              month: date!.month - 1,
                              day: date?.day,
                            })
                          );
                          // document.body.click();
                        }}
                      />
                    </Col>
                    <Col>
                      <Text>End Date</Text>
                      <ModernCalendar
                        shouldHighlightWeekends
                        calendarClassName="QS-calender"
                        onChange={(date) => {
                          // setSelectedDate(
                          //   moment({ year: date?.year, month: date!.month - 1, day: date?.day })
                          // );
                          // document.body.click();
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              }
            >
              <Button
                variant="cyan-12"
                size="sm"
                className="calendar-dropdown-btn"
              >
                Select Date Range
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    );
  }
  const popover = (
    <Popover className="shadow-sm border-0 mw-100">
      {calenderOnly ? (
        <ModernCalendar
          shouldHighlightWeekends
          onChange={(date) => {
            setSelectedDate(
              moment({
                year: date?.year,
                month: date!.month - 1,
                day: date?.day,
              })
            );
          }}
        />
      ) : (
        <DateRangeDropdown />
      )}
      {/* <ModernCalendar
        shouldHighlightWeekends
        onChange={(date) => {
          setSelectedDate(
            moment({ year: date?.year, month: date!.month - 1, day: date?.day })
          );
          document.body.click();
        }}
      /> */}
    </Popover>
  );
  function calculateTimePeriod() {
    if (mode === "Day") {
      return `${currentStartDate?.format("DD MMM YYYY")}`;
    } else {
      return `${currentStartDate?.format("DD MMM")} - ${currentEndDate?.format(
        "DD MMM"
      )}`;
    }
  }

  useEffect(() => {
    if (currentStartDate && currentEndDate && onDateChanged) {
      onDateChanged({ startDate: currentStartDate, endDate: currentEndDate });
    }
  }, [currentStartDate, currentEndDate]);

  useEffect(() => {
    if (defaultDate && !currentEndDate && !currentStartDate) {
      setCurrentStartDate(defaultDate.startDate);
      setCurrentEndDate(defaultDate.endDate);
    } else {
      if (mode === "Day") {
        setCurrentStartDate(
          moment(selectedDate).set({ hour: 0, minute: 0, second: 0 })
        );
        setCurrentEndDate(
          moment(selectedDate).set({ hour: 23, minute: 59, second: 59 })
        );
      }
      if (mode === "Week") {
        setCurrentStartDate(moment(selectedDate).startOf("isoWeek"));
        setCurrentEndDate(moment(selectedDate).endOf("isoWeek"));
      }
      if (mode === "2 Weeks") {
        setCurrentStartDate(moment(selectedDate).startOf("isoWeek"));
        setCurrentEndDate(moment(selectedDate).add(1, "week").endOf("isoWeek"));
      }
      if (mode === "Month") {
        setCurrentStartDate(moment(selectedDate).startOf("month"));
        setCurrentEndDate(moment(selectedDate).endOf("month"));
      }
    }
  }, [mode, selectedDate]);
  return (
    <ButtonGroup size="sm" ref={navigatorButtonGroupRef}>
      <Button
        variant={variant}
        style={{ backgroundColor }}
        onClick={() => {
          setCurrentStartDate(
            currentStartDate
              ?.clone()
              .subtract(mode === "2 Weeks" ? 2 : 1, unit)
              .startOf(unit === "w" ? "isoWeek" : unit)
          );
          setCurrentEndDate(
            currentEndDate
              ?.clone()
              .subtract(mode === "2 Weeks" ? 2 : 1, unit)
              .endOf(unit === "w" ? "isoWeek" : unit)
          );
        }}
      >
        <MdOutlineArrowBackIos
          color={textColor}
          style={{ fontWeight: "bold" }}
        />
      </Button>

      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        // rootClose
      >
        <Button variant={variant} style={{ backgroundColor, width: "150px" }}>
          <div className="d-flex justify-content-center align-items-center">
            <AiTwotoneCalendar color={textColor} />
            <div className="mx-2">
              <Text color={textColor}>{calculateTimePeriod()}</Text>
            </div>
            <IoMdArrowDropdown color={textColor} />
          </div>
        </Button>
      </OverlayTrigger>
      <Button
        variant={variant}
        style={{ backgroundColor }}
        onClick={() => {
          setCurrentStartDate(
            currentStartDate
              ?.clone()
              .add(mode === "2 Weeks" ? 2 : 1, unit)
              .startOf(unit === "w" ? "isoWeek" : unit)
          );
          setCurrentEndDate(
            currentEndDate
              ?.clone()
              .add(mode === "2 Weeks" ? 2 : 1, unit)
              .endOf(unit === "w" ? "isoWeek" : unit)
          );
        }}
      >
        <MdOutlineArrowForwardIos
          color={textColor}
          style={{ fontWeight: "bold" }}
        />
      </Button>
    </ButtonGroup>
  );
}
