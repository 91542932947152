import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import InputField from "../../../../components/InputField/InputField";
import { Colors, shiftColorPalette } from "../../../../constants";
import { ImSearch } from "react-icons/im";
import Text from "../../../../components/text/text";
import { IShift } from "../../../../interface";
import EmployeeBox from "../../../../components/employee-box/employee";
import Button from "../../../../components/button/Button";
import moment, { Moment } from "moment";
import { BsPlusSquareDotted } from "react-icons/bs";
import "./daily-layout.scss";
import { AiTwotoneEdit } from "react-icons/ai";
import { IoMdPersonAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../../hooks/user";
import { IEmployee } from "../../../../modules/Employee";
import Shifts from "../../../../modules/Shifts";

interface DailyLayoutProps {
  timePeriod: { startDate: Moment; endDate: Moment };
  staff: Array<IEmployee>;
  shiftsEmployeeWise: any;
  onEditClick?: (date: Moment, shift: Shifts.ShiftPayload) => void;
  onAddClick?: (date: Moment) => void;
  onSearchTextChanged?: (text: string) => void;
}
export default function DailyLayout({
  shiftsEmployeeWise,
  staff,
  timePeriod,
  onEditClick,
  onAddClick,

  onSearchTextChanged,
}: DailyLayoutProps) {
  const navigate = useNavigate();
  const user = useUserProfile();
  const tableParentDivRef = useRef<HTMLDivElement>(null);
  const [tableWrapperWidth, setTableWrapperWidth] = useState<number>(0);
  const hours = Array.from({ length: 24 }).map((_, i) =>
    moment({
      minute: 0,
      hour: i,
      second: 0,
      millisecond: 0,
      date: timePeriod.startDate?.date(),
    })
  );
  useEffect(() => {
    setTableWrapperWidth(tableParentDivRef.current?.offsetWidth || 0);
  }, [tableParentDivRef.current?.offsetWidth]);

  function RenderRosteredShifts({ empId }: any) {
    let user = useUserProfile();
    let isEmployer = !user?.roles?.includes("ROLE_CLIENT");
    return shiftsEmployeeWise[empId][
      timePeriod.startDate.format("YYYY-MM-DD")
    ]?.map((shift: Shifts.ShiftReponse, index: number) => (
      <div
        key={index}
        className="daily-roster-item daily-roster-item-wrapper"
        style={{
          position: "absolute",
          left:
            202 +
            (tableWrapperWidth / 24 - 200 / 24) *
              // moment
              // .duration(
              //   moment
              //     .unix(Number(shift.startTime))
              //     .diff(timePeriod.startDate)
              // )
              // .asHours() === 0
              // ? moment
              //     .duration(
              //       moment
              //         .unix(Number(shift.startTime))
              //         .diff(timePeriod.startDate)
              //     )
              //     .asHours()
              // :
              moment
                .duration(
                  moment
                    .unix(Number(shift.startTime))
                    .diff(timePeriod.startDate)
                )
                .asHours(),
          width:
            (tableWrapperWidth / 24 - 220 / 24) *
            moment
              .duration(
                moment
                  .unix(Number(shift.endTime))
                  .diff(moment.unix(Number(shift.startTime)))
              )
              .asHours(),
          background: shiftColorPalette[index],
        }}
      >
        <div className="position-relative h-100 w-100">
          <div
            // style={{
            //   backgroundColor: Colors.HEX_TO_RGBA(
            //     shiftColorPalette[index],
            //     0.5
            //   ),
            // }}
            className="position-absolute daily-roster-item-overlay d-flex justify-content-center align-items-center"
          >
            {isEmployer && (
              <div
                className="shift-box-icon-container d-inline-block"
                onClick={() =>
                  onEditClick && onEditClick(timePeriod.startDate, shift)
                }
              >
                <AiTwotoneEdit size={30} /> Edit
              </div>
            )}
          </div>
          {shift.location?.location}
          {isEmployer && moment.unix(Number(shift.startTime)).format("hh:mm A")}
          {isEmployer && moment.unix(Number(shift.endTime)).format("hh:mm A")}
        </div>
      </div>
    ));
  }

  return (
    <div className="daily-table-wrapper" ref={tableParentDivRef}>
      <Table responsive className="daily-table overflow-auto">
        <colgroup>
          <col style={{ width: 200, minWidth: 200 }} />
          {Array.from({ length: 24 }).map((_, index) => (
            <col
              style={{
                width: tableWrapperWidth / 24 - 200 / 24,
                minWidth: tableWrapperWidth / 24 - 200 / 24,
              }}
            />
          ))}
        </colgroup>
        <thead>
          <tr className="align-items-center">
            <th>
              <InputField
                fontSize="14px"
                placeHolder="Type to Search"
                backgroundColor="white"
                borderRadius="5px"
                startIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
                wrapperClassName="mx-2 mb-2"
                inputClassName="p-1"
                onChangedText={onSearchTextChanged}
              />
            </th>
            {hours.map((hour, index) => (
              <th key={index}>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {hour.format("HHmm")}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="position-relative">
          {staff?.map(({ preferredTitle, id }, i) => (
            <>
              {tableWrapperWidth &&
                shiftsEmployeeWise[id]?.hasOwnProperty(
                  timePeriod?.startDate?.format("YYYY-MM-DD")
                ) && <RenderRosteredShifts empId={id} key={i} />}
              <tr>
                <td className="first-col">
                  <EmployeeBox
                    // image="https://picsum.photos/200/300"
                    preferredTitle={preferredTitle}
                    isScheduleModeMonthly={false}
                  />
                </td>

                {hours?.map((hour, index) => (
                  <td key={index} className="">
                    {/* {shiftsEmployeeWise &&
                    shiftsEmployeeWise[id]?.hasOwnProperty(
                      hour.format("YYYY-MM-DD")
                    ) ? (
                      shiftsEmployeeWise[id][
                        timePeriod.startDate.format("YYYY-MM-DD")
                      ]?.map((shift: IShift, i: number) => (
                        <ShiftBox
                          key={i}
                          startTime={moment.unix(Number(shift.startTime))}
                          endTime={moment.unix(Number(shift.endTime))}
                          variant="published"
                          areaName={shift.areaName}
                          locationName={shift.locationName}
                          time={shift.time}
                        />
                      ))
                    ) : ( */}
                    <div className="h-100 d-flex justify-content-center">
                      {!user.roles.includes("ROLE_CLIENT") && (
                        <Button
                          key={i}
                          variant="plain"
                          className="p-0"
                          onClick={() =>
                            onAddClick && onAddClick(timePeriod.startDate)
                          }
                          centeredText
                        >
                          <BsPlusSquareDotted />
                        </Button>
                      )}
                    </div>
                    {/* )} */}
                  </td>
                ))}
              </tr>
            </>
          ))}
          <tr>
            <td>
              <div className="d-flex justify-content-center my-3 w-100">
                <Button
                  fontSize="12px"
                  startIcon={<IoMdPersonAdd size={25} />}
                  className="p-2 "
                  fullWidth
                  onClick={() => {
                    navigate(`/app/employee/`);
                  }}
                >
                  Add New Team Member
                </Button>
              </div>
            </td>
            <td colSpan={24} />
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
