import { AxiosError } from "axios";
import axiosInstance from "../api/axiosInstance";
export interface IArea {
  name?: string;
  note?: string;
  locationId?: ILocation;
  color?: string;
  _id?: string;
}
export interface ILocation {
  id: number;
  name: string;
  code: string;
  address: string;
  longitude: string;
  latitude: string;

  timezone?: string;
  //   "documentPath": null,
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  website?: string;
  industry?: string;
  remarks?: string;
  startDate?: string;
  endDate?: string;
  area?: Array<{
    id: number;
    name: string;
    branding: string;
  }>;
  areas?: Array<{
    id: number;
    name: string;
  }>;
  schedule?: Array<{
    id: number;
    day: string;
    startTime: string;
    endTime: string;
  }>;

  attachment?: Array<{
    fileKey: string;
    fileName: string;
  }>;
  deletedAttachmentKeys?: Array<string>;
}

module Location {
  export function FetchLocations(
    success: (data: Array<ILocation>) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get("/comply-tsfm/api/location/list")
      .then(({ data }) => success(data))
      .catch(error);
  }
  export function FetchLocationById(
    id: number,
    success: (data: Array<ILocation>) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get(`/comply-tsfm/api/location/${id}`)
      .then(({ data }) => success(data))
      .catch(error);
  }
}

export default Location;
