import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageKeys } from "../constants";
import LocationModule, { ILocation } from "../modules/Location";
import { useBusinessProfile } from "./business";
// import { ILocation } from "../interface";
interface LocationHookProps {
  locations: Array<ILocation>;
}
const LocationContext = React.createContext<LocationHookProps>({
  locations: [],
});

export function LocationProvider({ children }: any) {
  const [locations, setLocations] = useState<ILocation[]>([]);
  const { id } = useBusinessProfile();

  useEffect(() => {
    LocationModule.FetchLocations(
      (locations) => {
        setLocations(locations);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [id]);

  return (
    <LocationContext.Provider value={{ locations }}>
      {children}
    </LocationContext.Provider>
  );
}

export const useLocations = () =>
  React.useContext<LocationHookProps>(LocationContext);
