import moment, { Moment } from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { CalenderMode } from "../../../../components/calendar-button/calendar-button";
import { useLocations } from "../../../../hooks/location";
import ShiftItem from "./shift-item";
import "./area-schedule.scss";
import Text from "../../../../components/text/text";
import Shifts, { IShift } from "../../../../modules/Shifts";
import { MdOutlineFileCopy } from "react-icons/md";
import { ImPaste } from "react-icons/im";
interface AreaWeeklyScheduleProps {
  timeperiod: {
    startDate: Moment;
    endDate: Moment;
  };
  shifts: {
    [key: number]: {
      [key: number]: { [key: string]: Array<Shifts.ShiftPayload> };
    };
  };
  onAddClicked: (
    date: Moment,
    location: { id: number; name: string },
    area: { id: number; name: string }
  ) => void;
  onEditClicked: (date: Moment, shift: Shifts.ShiftPayload) => void;
  onCopyClicked: (shift: Shifts.ShiftPayload) => void;
  scheduleMode: CalenderMode;
  copiedShift?: Shifts.ShiftPayload;
  onPasteShift: (shift: Shifts.ShiftPayload) => void;
}
export default function AreaSchedule({
  timeperiod,
  scheduleMode,
  shifts,
  onAddClicked,
  onCopyClicked,
  onEditClicked,
  copiedShift,
  onPasteShift,
}: AreaWeeklyScheduleProps) {
  const { locations } = useLocations();
  const [open, setOpen] = useState<{
    [locationId: number]: { isOpen: boolean; [areaId: number]: boolean };
  }>({});

  const dayHeaderRef = useRef<HTMLDivElement>(null);

  const dayFormat =
    scheduleMode === "2 Weeks" || scheduleMode === "Month" ? "ddd" : "dddd";

  const [days, setDays] = useState<Array<Moment>>([]);

  useEffect(() => {
    if (timeperiod) {
      const numberOfDays = timeperiod.endDate.diff(
        timeperiod.startDate,
        "days"
      );

      const days: Array<Moment> = [];
      for (let i = 0; i <= numberOfDays; i++) {
        days.push(timeperiod.startDate.clone().add(i, "days"));
      }

      setDays(days);
    }
  }, [timeperiod]);

  const columnWidth = (dayHeaderRef.current?.offsetWidth || 0) / days.length;

  const mainContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.addEventListener("scroll", () => {
        if (
          mainContainerRef?.current &&
          mainContainerRef?.current.scrollTop > 60
        ) {
          dayHeaderRef.current?.classList.add("position-fixed");
        } else {
          dayHeaderRef.current?.classList.remove("position-fixed");
        }
      });
    }
  }, [mainContainerRef.current]);

  const [clickedCell, setClickedCell] = useState<{
    date: Moment;
    locationId: number;
    areaId: number;
    cellId: string;
  }>();

  return (
    <Container
      fluid
      className="border-start border-end border-bottom overflow-auto position-relative"
      style={{ height: "93%" }}
      ref={mainContainerRef}
    >
      <Row
        ref={dayHeaderRef}
        className="flex-nowrap "
        style={{ zIndex: 1, backgroundColor: "white" }}
      >
        {days.map((day, dayIndex) => (
          <Col style={{ width: columnWidth }} className="p-1" key={dayIndex}>
            <div
              className="area-schedule-weekly-days-header"
              style={{
                borderBottom: `2px solid ${
                  day.isSame(moment(), "date") ? "var(--bs-blue_color)" : "grey"
                } `,
                color: day.isSame(moment(), "date")
                  ? "var(--bs-blue_color)"
                  : "grey",
                fontSize: scheduleMode === "Month" ? "13px" : "16px",
              }}
            >
              {day.format(dayFormat)}{" "}
              {(scheduleMode === "Month" || scheduleMode === "2 Weeks") && (
                <br />
              )}
              <span
                style={{
                  fontSize: scheduleMode === "Month" ? "10px" : "12px",
                }}
              >
                {day.format("Do")}
              </span>
            </div>
          </Col>
        ))}
      </Row>
      <div>
        {locations?.map((location, locationIndex) => (
          <Row className="area-schedule-container" key={locationIndex}>
            <Col>
              <div
                className={`area-schedule-collapse-container-title py-2 `}
                onClick={() => {
                  setOpen({
                    ...open,
                    [location.id]: {
                      ...open[location.id],
                      isOpen: !open[location.id]?.isOpen,
                    },
                  });
                }}
              >
                <span>
                  {open[location.id]?.isOpen ?? true ? (
                    <IoIosArrowDown />
                  ) : (
                    <IoIosArrowForward />
                  )}
                </span>
                {location.name}
              </div>
              <Collapse
                in={open[location.id]?.isOpen ?? true}
                className="area-schedule-collapse-container"
              >
                <div>
                  {location !== undefined ? (
                    location.areas?.map((area, areaIndex) => (
                      <Fragment key={areaIndex}>
                        <div
                          className="area-schedule-collapse-container-area-title px-2 mt-2"
                          style={{
                            borderBottom: `1px solid ${
                              open[location.id]?.[area.id]
                                ? "var(--bs-blue_color)"
                                : "transparent"
                            }`,
                          }}
                          onClick={() => {
                            setOpen({
                              ...open,
                              [location.id]: {
                                ...open[location.id],

                                [area.id]: !open[location.id]?.[area.id],
                              },
                            });
                          }}
                        >
                          <span>
                            {open[location.id]?.[area.id] ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowForward />
                            )}
                          </span>
                          {area.name} -{" "}
                          <span style={{ fontSize: 12 }}>{location.name}</span>
                        </div>
                        <Collapse in={open[location.id]?.[area.id] ?? true}>
                          <Row
                            className={`
                          flex-nowrap
                          ${
                            areaIndex < (location?.areas?.length || 0) - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          >
                            {days.map((day, areaDayIndex) => {
                              return (
                                <Col
                                  key={areaDayIndex}
                                  style={{
                                    width: columnWidth,
                                    backgroundColor:
                                      clickedCell?.cellId ===
                                      `${location.id}-${area.id}-${areaDayIndex}`
                                        ? "var(--bs-cyan-12)"
                                        : "white",
                                  }}
                                  onClick={() => {
                                    setClickedCell({
                                      date: day,
                                      locationId: location.id,
                                      areaId: area.id,
                                      cellId: `${location.id}-${area.id}-${areaDayIndex}`,
                                    });
                                  }}
                                  className={`
                                focusable-col
                                ${scheduleMode === "Month" ? "p-0" : "p-1"} ${
                                    areaDayIndex < days?.length - 1
                                      ? "border-end"
                                      : ""
                                  }`}
                                >
                                  {shifts[location.id]?.[area.id]?.[
                                    day.format("YYYY-MM-DD")
                                  ]?.map((shiftObj, shiftIndex) => {
                                    const startTimeMoment = moment.unix(
                                      Number(shiftObj.startTime)
                                    );
                                    const endTimeMoment = moment.unix(
                                      Number(shiftObj.endTime)
                                    );

                                    return (
                                      <ShiftItem
                                        key={shiftIndex}
                                        scheduleMode={scheduleMode}
                                        startTime={startTimeMoment}
                                        endTime={endTimeMoment}
                                        employeeName={
                                          shiftObj.employee?.employee
                                        }
                                        onCopyClicked={() => {
                                          onCopyClicked(shiftObj);
                                        }}
                                        onEditClicked={() =>
                                          onEditClicked(
                                            timeperiod.startDate,
                                            shiftObj
                                          )
                                        }
                                      />
                                    );
                                  })}
                                  <Row>
                                    <Col>
                                      <div
                                        className="p-1 area-schedule-plus-icon my-1 align-self-center"
                                        onClick={() =>
                                          onAddClicked(
                                            day,
                                            {
                                              id: location.id,
                                              name: location.name,
                                            },
                                            {
                                              id: area.id,
                                              name: area.name,
                                            }
                                          )
                                        }
                                      >
                                        <GoPlus size={12} className="" />
                                      </div>
                                    </Col>

                                    {copiedShift &&
                                      clickedCell?.cellId ===
                                        `${location.id}-${area.id}-${areaDayIndex}` && (
                                        <Col>
                                          <div
                                            className="p-1 area-schedule-plus-icon my-1 align-self-center"
                                            onClick={() => {
                                              Shifts.createShift(
                                                {
                                                  ...copiedShift,
                                                  startDate: day
                                                    .unix()
                                                    .toString(),
                                                  // areaId: area.id,

                                                  locationId: location.id,
                                                  employeeId:
                                                    copiedShift.employee?.id,
                                                  areaList: [area.id],
                                                },
                                                onPasteShift,
                                                (error) => {
                                                  console.log(error);
                                                }
                                              );
                                            }}
                                          >
                                            <ImPaste size={12} />
                                          </div>
                                        </Col>
                                      )}
                                  </Row>
                                </Col>
                              );
                            })}
                          </Row>
                        </Collapse>
                      </Fragment>
                    ))
                  ) : (
                    <div>No Areas Found</div>
                  )}
                </div>
              </Collapse>
            </Col>
          </Row>
        ))}
      </div>
    </Container>
  );
}
