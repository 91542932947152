import { FormControlProps, OverlayTrigger, Popover } from "react-bootstrap";
import Calendar, {
  DayValue,
  CalendarProps,
  Calendar as DateCalendar,
} from "@hassanmojab/react-modern-calendar-datepicker";
import moment, { Moment } from "moment";
import { useState } from "react";
import TextInput, { TextInputProps } from "./input";
import "./input.styles.scss";

interface DateInputProps {
  onDateChanged?: (date: Moment) => void;
  label?: string;
  controlProps?: TextInputProps;
  placeholder?: string;
  defaultValue?: string;
}

export default function DateInput({
  onDateChanged,
  label,
  placeholder,
  controlProps,
  defaultValue,
}: DateInputProps) {
  const [date, setDate] = useState<string | undefined>(defaultValue);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isDateSet, setIsDateSet] = useState<"InitState" | "YES" | "NO">(
    "InitState"
  );

  return (
    <OverlayTrigger
      trigger="click"
      // placement={placement}
      placement="auto"
      rootClose
      onExited={() => {
        if (isDateSet === "InitState") {
          setIsDateSet("NO");
        }
      }}
      overlay={
        <Popover className="calendar-popover">
          <DateCalendar
            onChange={(date) => {
              const dateMoment = moment({
                day: date?.day,
                month: date?.month ? date?.month - 1 : 0,
                year: date?.year,
              });
              onDateChanged && onDateChanged(dateMoment);
              setDate(dateMoment.format("YYYY-MM-DD"));
              setIsDateSet("YES");
              controlProps?.validation &&
                setIsValid(
                  controlProps.validation({
                    e: { target: { value: dateMoment.format("YYYY-MM-DD") } },
                  })
                );
              document.body.click();
            }}
          />
        </Popover>
      }
    >
      <span>
        <TextInput
          label={label}
          //   wrapperClassName="date-input"
          //   validation={controlProps?.validation}
          isValid={isDateSet !== "NO"}
          placeholder={placeholder}
          controlProps={{
            value: date || "",
            ...controlProps?.controlProps,
          }}
        />
      </span>
    </OverlayTrigger>
  );
}
