import { Col, Container, Row } from "react-bootstrap";
import CalendarButton, {
  CalenderMode,
} from "../../../components/calendar-button/calendar-button";
import DateSelector from "../../../components/date-selector";
import DropDownInput from "../../../components/InputField/InputField-dropdown";
import "./../report.scss";
import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import { useEffect, useRef, useState } from "react";
import Button from "../../../components/button/Button";
import InputField, {
  IInputField,
} from "../../../components/InputField/InputField";
import CalendarInput from "../../../components/calendar-input/calendar-input";
import Text from "../../../components/text/text";
import { ILocation } from "../../../interface";
import { Moment } from "moment";
import {
  extractBase64Only,
  removeEmptyOrNull,
  toBase64,
} from "../../../api/general";
import useModal from "../../../hooks/modal";
import { ReportTypes } from "../report-types/generic-report";

interface ReportFiltersProps {
  handleAs: "FILTER" | "UPLOAD";
  locations: Array<ILocation>;
  reportType: ReportTypes;
  uploadCallback: (data: any, callback?: Function) => void;
  filterCallback: (data: any, callback?: Function) => void;
}
export default function ReportFilters({
  handleAs,
  locations,
  reportType,
  uploadCallback,
  filterCallback,
}: ReportFiltersProps) {
  const defaultReportState = {
    description: "",
    fileBase64: "",
    fileName: "",
    locationId: undefined,
    reportDate: "",
    reportDuration: "",
    reportType,
  };
  const inputProps: IInputField = {
    wrapperClassName: "report-filter-container__search-input",
    backgroundColor: "white",
    borderRadius: "3px",
    fontSize: 12,
  };
  const fileRef = useRef<HTMLInputElement>(null);
  const [report, setReport] = useState<{
    description: string;
    fileBase64: string;
    fileName: string;
    locationId?: number;
    reportDate: string;
    reportDuration: string;
    reportType: string;
    endDate?: string;
    startDate?: string;
    keyword?: string;
  }>(defaultReportState);
  const { showModal } = useModal();

  const [count, setCount] = useState(0);

  return (
    <Container
      key={count}
      className="report-filter-container p-2 shadow-sm border rounded h-100"
      style={{ width: "55%" }}
    >
      <Row>
        <Col xs="auto">
          <DropDownInput
            inputProps={{
              wrapperClassName: "report-filter-container__search-input",
              backgroundColor: "white",
              borderRadius: "3px",
              fontSize: 12,
              placeHolder: "Select Location",
            }}
            menu={locations?.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            autoCompleteProps={{ autoHighlight: true }}
            onValueSelect={(value, label) => {
              setReport({
                ...report,
                locationId: Number(value),
              });
            }}
            label="Locations"
          />
        </Col>

        <Col xs="auto">
          <DropDownInput
            inputProps={{
              wrapperClassName: "report-filter-container__search-input",
              backgroundColor: "white",
              borderRadius: "3px",
              fontSize: 12,
              placeHolder: "Duration",
              wrapperStyle: { width: "100px" },
            }}
            menu={[
              { label: "Daily", value: "DAILY" },
              { label: "Weekly", value: "WEEKLY" },
              { label: "Monthly", value: "MONTHLY" },
              { label: "Quaterly", value: "QUATERLY" },
              { label: "Bi-Annually", value: "BI_ANNUALLY" },
              { label: "Annualy", value: "ANNUALLY" },
            ]}
            autoCompleteProps={{
              autoHighlight: true,
            }}
            onValueSelect={(value) => {
              setReport({ ...report, reportDuration: value });
            }}
            label="Duration"
          />
        </Col>

        {handleAs === "FILTER" && (
          <>
            <Col xs="auto" className="d-flex align-items-baseline">
              <CalendarInput
                inputProps={{
                  ...inputProps,
                  placeHolder: "Start Date",
                  label: "Start Date",
                }}
                onDateChanged={(date: Moment) => {
                  setReport({ ...report, startDate: date.unix().toString() });
                }}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-baseline">
              <CalendarInput
                inputProps={{
                  ...inputProps,
                  placeHolder: "End Date",
                  label: "End Date",
                }}
                onDateChanged={(date: Moment) => {
                  setReport({ ...report, endDate: date.unix().toString() });
                }}
              />
            </Col>
          </>
        )}

        {handleAs === "UPLOAD" && (
          <Col xs="auto" className="d-flex align-items-baseline">
            <CalendarInput
              inputProps={{
                ...inputProps,
                placeHolder: "Date",
                label: " Date",
              }}
              onDateChanged={(date: Moment) => {
                setReport({ ...report, reportDate: date.unix().toString() });
              }}
            />
          </Col>
        )}

        {handleAs === "FILTER" ? (
          <Col xs="auto">
            <InputField
              {...inputProps}
              wrapperStyle={{ width: "200px" }}
              placeHolder="Search by Keywords"
              label="Search by Keywords"
              onChangedText={(text: string) => {
                setReport({ ...report, keyword: text });
              }}
            />
          </Col>
        ) : (
          <Col xs="auto">
            <InputField
              {...inputProps}
              wrapperStyle={{ width: "200px" }}
              placeHolder="Description"
              label="Description"
              onChangedText={(text: string) => {
                setReport({ ...report, description: text });
              }}
            />
          </Col>
        )}

        <Col xs="auto" className="">
          <div className="h-100 d-flex align-items-end">
            <input
              type="file"
              hidden
              ref={fileRef}
              accept=".pdf"
              onChange={async (e) => {
                if (handleAs === "UPLOAD") {
                  const file = e.target.files?.[0];

                  if (file) {
                    let data = removeEmptyOrNull({
                      ...report,
                      fileName: file.name,
                      fileBase64: extractBase64Only(
                        (await toBase64(file)) as string
                      ),
                    });
                    if (Object.keys(data).length === 7) {
                      uploadCallback(data, () => {
                        setReport(defaultReportState);
                        setCount((count) => count + 1);
                      });
                    } else {
                      showModal({
                        Component: (
                          <Text bold heading>
                            Please fill in the all fields
                          </Text>
                        ),
                      });
                      if (fileRef.current) fileRef.current.value = "";
                    }
                  }
                }
              }}
            />
            <Button
              className=" my-1"
              onClick={() => {
                if (handleAs === "UPLOAD") {
                  fileRef.current?.click();
                }

                if (handleAs === "FILTER") {
                  filterCallback(
                    removeEmptyOrNull({
                      ...report,
                    }),
                    () => {
                      setReport(defaultReportState);
                      setCount((count) => count + 1);
                    }
                  );
                }
              }}
            >
              {handleAs === "FILTER" ? "Update" : "Upload File"}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
