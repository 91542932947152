import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "../../components/button/Button";
import { InputField } from "../../components/InputField/InputField";
import Text from "../../components/text/text";
import "./SignIn.scss";
import { IoIosLock, IoMdLock } from "react-icons/io";
import { BsEnvelope } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import useModal from "../../hooks/modal";
export default function ForgetPassword() {
  const [email, setEmail] = useState<string>();
  const { showModal } = useModal();

  function initiateForgotPassword() {
    if (!email) {
      alert("Please enter email");
    } else {
      axiosInstance
        .post("/comply-tsfm/api/forgotPassword/initiate", { email })
        .then(({ data, status }) => {
          if (status === 200) {
            showModal({
              Component: <Text>Reset password link sent in your e-mail.</Text>,
            });
          }
        })
        .catch((error) => {
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    }
  }
  return (
    <>
      <section className="overlaybg">
        <Container className="">
          <Row className="justify-content-center  fullscreen">
            <Col xl={4} lg={5} md={7} sm={8} xs={10} className="my-auto ">
              <Row className="  align-items-center ">
                <Col xs={5} className="p-0 d-flex justify-content-end ">
                  <IoMdLock style={{ color: "white" }} size={"100%"} />
                </Col>
                <Col xs={7} className="p-0 mb-0  justify-content-end">
                  <Text
                    bold
                    children="Forget Password"
                    responsive
                    fontSize="22px"
                    color="#ffffff"
                  />
                  <Text
                    light
                    children="Provide yout accounts email for which you want to reset your password"
                    responsive
                    fontSize="16px"
                    color="#ffffff"
                  />
                </Col>
              </Row>
              <Form>
                <Row className="align-items-center FormStyle py-3 justify-content-center">
                  <Col lg={12} md={12}>
                    <InputField
                      borderRadius="5px"
                      inputClassName="p-2"
                      fontSize="12px"
                      placeHolder="Enter your email"
                      label="Email"
                      labelColor="white"
                      startIcon={<MdEmail color="lightgrey" size={25} />}
                      onChangedText={setEmail}
                    />
                  </Col>

                  <Col lg={12} md={12}>
                    <Button
                      className="py-1 mt-2"
                      variant="dark_orange"
                      fullWidth
                      noBorderRadius
                      fontSize="25px"
                      onClick={initiateForgotPassword}
                    >
                      NEXT
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
