import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import logo from "../../assets/logo-vertical.svg";
import inlinelogo from "../../assets/logo-horizontal.svg";

import { IInputField } from "../../components/InputField/InputField";
import { FaKey, FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import Text from "../../components/text/text";
import "./SignUp.scss";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { MdEmail, MdLocationPin } from "react-icons/md";
import axiosInstance from "../../api/axiosInstance";
import useModal from "../../hooks/modal";
import AddressModal from "../../components/addressModal";
import useQuery from "../../hooks/query";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../components-v2/input/input";
export default function Signup() {
  const query = useQuery();
  const referenceToken = query.get("token");
  type SignUpPayload = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    address: string;
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SignUpPayload>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      address: "",
    },
    mode: "onBlur",
  });

  // const [firstName, setFirstName] = useState<string>();
  // const [lastName, setLastName] = useState<string>();
  // const [email, setEmail] = useState<string>();
  // const [address, setAddress] = useState<string>();
  // const [password, setPassword] = useState<string>();
  // const [phone, setPhone] = useState<string>();
  // const [message, setMessage] = useState<string>();
  // const [formattedAddresses, setFormattedAddresses] = useState<
  //   Array<{
  //     address: string;
  //     country: string;
  //     longitude: string;
  //     latitude: string;
  //   }>
  // >();
  // const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  // const [areAddressesLoading, setAreAddressesLoading] =
  //   useState<boolean>(false);

  useEffect(() => {
    if (referenceToken) {
      axiosInstance
        .get(`/comply-tsfm/api/auth/validateReferenceToken/${referenceToken}`)
        .then(({ data }) => {
          if (data) {
            setValue("firstName", data.firstName);
            setValue("lastName", data.lastName);
            setValue("email", data.email);
            setValue("address", data.address);
            setValue("phone", data.phone);
          }
        })
        .catch((error) => {
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    }
  }, []);

  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const navigate = useNavigate();

  const { showModal, closeModal } = useModal();

  // const fetchAddresses = useCallback(() => {
  //   if (address) {
  //     setIsAddressModalOpen(true);
  //     setAreAddressesLoading(true);

  //     axiosInstance
  //       .post("/comply-tsfm/api/auth/addresses", { address })
  //       .then(({ status, data }) => {
  //         if (status === 200) {
  //           setFormattedAddresses(data);
  //           setAreAddressesLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log({ error });
  //         setAreAddressesLoading(false);

  //         showModal({ APIError: error, APIType: "APIEXCEPTION" });
  //       });
  //   }
  // }, [address]);

  const inputs: Array<IInputField & { col: number; name: string; rules: any }> =
    [
      {
        placeHolder: "First Name",
        endIcon: <FaUser color="grey" />,

        col: 6,

        name: "firstName",
        rules: {
          minLength: {
            value: 3,
            message: "First name must be at least 3 characters",
          },
          maxLength: {
            value: 20,
            message: "First name must be at most 20 characters",
          },
        },
      },
      {
        placeHolder: "Last Name",
        endIcon: <FaUser color="grey" />,
        col: 6,
        name: "lastName",
        rules: {
          minLength: {
            value: 3,
            message: "Last name must be at least 3 characters",
          },
          maxLength: {
            value: 20,
            message: "Last name must be at most 20 characters",
          },
        },
      },
      {
        placeHolder: "Address",
        endIcon: <MdLocationPin size={20} color="grey" />,

        col: 12,

        name: "address",
        rules: {
          minLength: {
            value: 3,
            message: "Address must be at least 3 characters",
          },
          maxLength: {
            value: 50,
            message: "Address must be at most 50 characters",
          },
        },
        isAddressInput: true,
      },
      {
        placeHolder: "Email",
        endIcon: <MdEmail color="grey" />,
        col: 12,
        name: "email",
        rules: {
          minLength: {
            value: 3,
            message: "Email must be at least 3 characters",
          },

          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        },
      },
      {
        placeHolder: "Password",
        endIcon: <FaKey color="grey" />,
        col: 12,
        name: "password",
        rules: {
          minLength: {
            value: 8,
            message: "password must be at least 8 characters",
          },
        },
      },
      {
        placeHolder: "Phone",
        endIcon: <BsFillTelephoneFill color="grey" />,
        col: 12,
        name: "phone",
        rules: {
          minLength: {
            value: 10,
            message: "Phone must be at least 10 characters",
          },
          maxLength: {
            value: 10,
            message: "Phone must be at most 10 characters",
          },
        },
      },
    ];

  // function validateEmail(email: string) {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // }

  function signUpAttempt(data: any) {
    setIsRegistering(true);
    axiosInstance
      .post(
        "/comply-tsfm/api/auth/signup",
        {
          ...data,
          token: referenceToken,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(({ data, status }) => {
        navigate("/signin");
      })
      .then(() => {
        setIsRegistering(false);
      })
      .catch((error) => {
        setIsRegistering(false);

        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }

  return (
    <>
      {/* <AddressModal
        show={isAddressModalOpen}
        areAddressesLoading={areAddressesLoading}
        formattedAddresses={formattedAddresses}
        onAddressSelect={(address) => {
          setAddress(address.address);
          setIsAddressModalOpen(false);
        }}
        onClose={() => {
          setIsAddressModalOpen(!isAddressModalOpen);
        }}
      /> */}
      <section className="overlaybg-sign-up">
        <Container>
          <Form
            onSubmit={handleSubmit(
              (data) => {
                signUpAttempt(data);
              },
              (error) => {}
            )}
          >
            <Row className="justify-content-center fullscreen">
              <Col lg={7} md={10} xs={10} className="my-auto">
                <div className=" text-center ">
                  <Text
                    heading
                    // light
                    dark
                    bold
                    color="#ffffff"
                    fontSize="55px"
                    className="text-center"
                  >
                    Sign Up
                  </Text>
                </div>

                <Row
                  className="align-items-center FormStyle mt-4"
                  style={{ backgroundColor: "whitesmoke " }}
                >
                  <Col lg={5} md={5} sm={5}>
                    <MediaQuery minWidth={575}>
                      <div className="text-center">
                        <img src={logo} style={{ maxWidth: "80%" }} />
                      </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={574}>
                      <div className="text-center p-2">
                        <img src={inlinelogo} style={{ maxWidth: "50%" }} />
                      </div>
                    </MediaQuery>
                  </Col>
                  <Col lg={7} md={7} sm={7} className="rightColStyle ">
                    <Row className="pb-3 pt-2">
                      {inputs.map((inputProps, i) => (
                        <Col lg={inputProps.col} className="mt-2" key={i}>
                          {/* <InputField
                          ref={inputProps.ref}
                          // inputClassName="p-3"
                          iconClassName="icons"
                          borderRadius="5px"
                          {...inputProps}
                          inputProps={{
                            ...inputProps.inputProps,
                            disabled:
                              (inputProps.placeHolder !== "Password" &&
                                referenceToken &&
                                referenceToken?.length > 0) ||
                              isRegistering,
                          }}
                        /> */}
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: `Please enter ${inputProps.placeHolder}`,
                              },
                              ...inputProps.rules,
                            }}
                            control={control}
                            name={inputProps.name as keyof SignUpPayload}
                            render={({
                              field: { name, onBlur, onChange, value },
                            }) => (
                              <TextInput
                                controlProps={{
                                  value,
                                  onBlur,
                                  onChange,
                                  className: "border border-end-0",
                                  style: {
                                    backgroundColor: "whitesmoke",
                                    height: "3rem",
                                  },
                                  type:
                                    inputProps.placeHolder === "Password"
                                      ? "password"
                                      : "text",
                                }}
                                placeholder={inputProps.placeHolder}
                                rightIcon={inputProps.endIcon}
                                rightIconProps={{
                                  style: {
                                    backgroundColor: "whitesmoke",
                                  },
                                  className: "border border-start-0",
                                }}
                                feedbackText={errors[name]?.message}
                                isValid={!errors[name]}
                                addressInput={
                                  inputProps.placeHolder === "Address"
                                }
                              />
                            )}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div className="text-center pt-4">
                      {/* <Button
                      disabled={isRegistering}
                      centeredText
                      textProps={{ bold: true }}
                      className="p-2"
                      fullWidth
                      noBorderRadius
                      fontSize="25px"
                      variant="dark_orange"
                      onClick={handleSubmit(
                        (data) => {
                          signUpAttempt(data);
                        },
                        (error) => {
                          console.log(error);
                        }
                      )}
                    >
                      SIGN UP
                    </Button> */}

                      <Button
                        size="lg"
                        className="w-100"
                        variant="dark_orange"
                        style={{ color: "white" }}
                        type="submit"
                      >
                        SIGN UP
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </>
  );
}
