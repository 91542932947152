import { Form } from "react-bootstrap";
import RSelect, { CSSObjectWithLabel, Props } from "react-select";
import { Colors } from "../../constants";
import Text, { IText } from "../text/text";
import "./select.scss";
export interface ISelect extends Props {
  label?: string;
  labelBold?: boolean;
  labelColor?: string;
  labelTextProps?: IText;
  backgroundColor?: string;
  controlStyle?: CSSObjectWithLabel;
}
export default function Select({
  label,
  labelBold,
  labelColor,
  labelTextProps,
  backgroundColor,
  ...restOfProps
}: ISelect) {
  return (
    <div style={{ width: "100%" }}>
      {label && (
        <Form.Label className="p-0 m-0 mb-1">
          <Text
            responsive
            bold={labelBold}
            color={labelColor}
            fontSize="12px"
            {...labelTextProps}
          >
            {label}
          </Text>
        </Form.Label>
      )}
      <RSelect classNamePrefix="QS-Select" {...restOfProps} />
    </div>
  );
}
