import { MouseEventHandler } from "react";
import {
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  SplitButton,
} from "react-bootstrap";
import Button from "./button/Button";
import Text from "./text/text";

interface IAddressModal {
  show: boolean;
  formattedAddresses?: Array<{
    country: string;
    address: string;
    longitude: string;
    latitude: string;
  }>;
  onAddressSelect: (address: {
    address: string;
    longitude: string;
    latitude: string;
    country: string;
  }) => void;
  areAddressesLoading: boolean;
  onClose?: Function;
}

function AddressModal({
  show,
  formattedAddresses,
  areAddressesLoading,
  onAddressSelect,
  onClose,
}: IAddressModal) {
  return (
    <Modal show={show} centered onHide={() => onClose && onClose()}>
      <Modal.Body>
        <Text bold heading centered className="mb-2">
          Select Address
        </Text>
        {formattedAddresses && formattedAddresses?.length > 0 ? (
          formattedAddresses.map((address, index) => (
            <Button
              key={index}
              onClick={() => {
                onAddressSelect({
                  address: address.address,
                  longitude: address.longitude,
                  latitude: address.latitude,
                  country: address.country,
                });
              }}
              className="mb-2"
            >
              {address?.address}
            </Button>
          ))
        ) : areAddressesLoading ? (
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs="auto">
                <Spinner animation="border" />
              </Col>
              <Col xs="auto">
                <Text bold heading>
                  Loading
                </Text>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>No address found</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="blue_color" onClick={() => onClose && onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddressModal;
