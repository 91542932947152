import GoogleMapReact from "google-map-react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Button from "../../../components/button/Button";
import DropdownButton from "../../../components/DropdownMenu/DropdownMenu";
import Text from "../../../components/text/text";
import { Colors, mapKey } from "../../../constants";

interface IStatusLocation {
  name: string;
  address: string;
  show: boolean;
  onClose: () => void;
}
export default function StatusLocation({
  address,
  name,
  onClose,
  show,
}: IStatusLocation) {
  return (
    <Modal show={show} centered size="lg" onHide={onClose}>
      <Modal.Header closeButton>
        <Text fontSize="20px" style={{ textAlign: "center" }}>
          {name}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <div style={{ height: "250px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: mapKey,
                  }}
                  defaultCenter={{ lat: -33.86882, lng: 151.20929 }}
                  defaultZoom={15}
                ></GoogleMapReact>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Text fontSize="20px" style={{ textAlign: "center" }}>
                Nobody is working here today
              </Text>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md="auto">
              <Button noBorderRadius variant="blue_color">
                Open in Google maps
              </Button>
            </Col>
            <Col xs={12} md="auto">
              <DropdownButton
                isSplitButton
                toggleVariant="cyan-12"
                noBorderRadius
                label="More Actions"
                variant="cyan-6"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Text
              fontSize="20px"
              color={Colors.HEX_TO_RGBA("#000000", 0.3)}
              style={{ textAlign: "center" }}
            >
              {address}
            </Text>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
