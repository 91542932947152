import { useCallback, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  FormControl,
  DropdownProps as BTDropdownProps,
  ButtonProps,
} from "react-bootstrap";
import { variants } from "../../components/button/Button";
import { IOption } from "../../interface";
import TextInput from "../input/input";
import "./dropdown.style.scss";

interface DropdownProps {
  label: string;
  menu: Array<IOption>;
  onChange: (option: IOption) => void;
  variant?: variants;
  textColor?: string;
  backgroundColor?: string;
  dropdownProps?: Omit<BTDropdownProps, "children">;
  searchable?: boolean;
  buttonClassName?: string;
  dropdownClassName?: string;
  toggleClassName?: string;
  buttonProps?: ButtonProps;
}
export default function DropDown({
  label,
  menu,
  onChange,
  variant = "cyan-12",
  textColor = "black",
  backgroundColor = "#e6e6e6",
  dropdownProps,
  searchable = false,
  buttonClassName = "",
  dropdownClassName = "",
  toggleClassName = "",
  buttonProps,
}: DropdownProps) {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [searchText, setSearchText] = useState("");

  const filteredMenu = useCallback(() => {
    return menu.filter((item) =>
      item.label?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, menu]);

  return (
    <Dropdown as={ButtonGroup} ref={dropdownRef} {...dropdownProps}>
      <Button
        size="sm"
        className={`truncate-text ${buttonClassName}`}
        variant={variant}
        style={{ width: "200px", color: textColor }}
        {...buttonProps}
      >
        {label}
      </Button>

      <Dropdown.Toggle
        variant={variant}
        split
        size="sm"
        style={{
          // backgroundColor,
          color: textColor,
        }}
        className={`${toggleClassName}`}
      />

      <Dropdown.Menu
        className={` QS-dropdown-menu ${dropdownClassName}`}
        style={{
          minWidth: dropdownRef.current?.clientWidth,
          //   backgroundColor,
        }}
      >
        {searchable && (
          <div className="border mx-2 rounded mb-1">
            <TextInput
              placeholder="Search"
              onChangeText={(text) => {
                setSearchText(text);
              }}
            />
            {/* <FormControl
              placeholder="Search"
              onChange={(e: any) => {
                setSearchText(e.target.value);
              }}
            /> */}
          </div>
        )}
        {filteredMenu().map((item, index) => {
          return (
            <Dropdown.Item
              disabled={item.disabled}
              key={index}
              className={`truncate-text ${item.disabled ? "text-muted" : ""}`}
              onClick={() => {
                onChange(item);
              }}
            >
              {item.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
