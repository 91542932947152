import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { removeEmptyOrNull } from "../../../api/general";
import InputField from "../../../components/InputField/InputField";

interface IEmergency {
  defaultValue: any;
  onUpdate: (data: any) => void;
}
export default function EmergencyContact({
  defaultValue,
  onUpdate,
}: IEmergency) {
  const [emergencyContactName, setEmergencyContactName] = useState<string>();
  const [emergencyContactPhone, setEmergencyContactPhone] = useState<string>();

  useEffect(() => {
    onUpdate({
      ...removeEmptyOrNull(defaultValue),
      ...removeEmptyOrNull({
        emergencyContactName,
        emergencyContactPhone,
      }),
    });
  }, [emergencyContactName, emergencyContactPhone]);
  return (
    <Container className="p-5">
      <Row>
        <Col xs="12" md="5">
          <InputField
            label="Emergency Contact Name"
            fontSize="12px"
            inputProps={{ defaultValue: defaultValue?.emergencyContactName }}
            onChangedText={setEmergencyContactName}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="5">
          <InputField
            label="Emergency Phone"
            fontSize="12px"
            inputProps={{ defaultValue: defaultValue?.emergencyContactPhone }}
            onChangedText={setEmergencyContactPhone}
          />
        </Col>
      </Row>
    </Container>
  );
}
