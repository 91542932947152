import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Col, Collapse, Container, Image, Row } from "react-bootstrap";
import Button, { ButtonProps } from "../../components/button/Button";
import CalenderButton from "../../components/calendar-button/calendar-button";
import PageBanner from "../../components/page-banner/page-banner";
import Text from "../../components/text/text";
import { Colors, localStorageKeys } from "../../constants";
import BannerImage from "./assets/1.png";
import "./dashboard.scss";
import { useUserProfile } from "../../hooks/user";
import UnscheduledShift from "./unscheduled-shift";
import UserProfileSidebar from "./user-profile-side-bar";
import BusinessSettings from "../business-settings/business-settings";
import TeamImage from "./assets/3.png";
import GreatJobImage from "./assets/2.png";
import UpcomingShifts from "./upcoming-shifts";
import AvailableShifts from "./available-shifts";
import { useBusinessProfile } from "../../hooks/business";

export default function DashBoard({}) {
  const business = useBusinessProfile();
  const [openDashboardSection, setOpenDashboardSection] = useState(true);
  const [openCalendarSection, setOpenCalendarSection] = useState(true);
  const [showUnscheduledShiftModal, setshowUnscheduledShiftModal] =
    useState(false);
  const [showUpcomingShiftModal, setshowUpcomingShiftModal] = useState(false);
  const [showAvailableShiftModal, setshowAvailableShiftModal] = useState(false);
  const userProfileBarRef = useRef<HTMLDivElement>(null);
  const startOfWeek = moment().startOf("isoWeek").toDate();
  const endOfWeek = moment().endOf("isoWeek").toDate();

  const [wholeWeek, setWholeWeek] = useState<any>();
  const [selectedPeriod, setSelectedPeriod] = useState<any>({
    startDate: startOfWeek,
    endDate: endOfWeek,
  });
  function calWholeWeek(startDate: any) {
    const week = [];
    for (let i = 0; i <= 6; i++) {
      week.push(moment(startDate).add(i, "day"));
    }
    return week;
  }

  const dashboardItems = [
    { title: "Needs Attention" },
    { title: "Needs Approval" },
    { title: "What's Happening" },
  ];
  const calenderBtns: Array<ButtonProps> = [
    {
      label: "No Upcoming Shifts",
      variant: "grey",
      backgroundColor: "grey",
      onClick: () => {
        setshowUpcomingShiftModal(true);
      },
    },
    {
      label: "Available Shifts",
      variant: "blue_color",
      onClick: () => {
        setshowAvailableShiftModal(true);
      },
    },
    {
      label: "Request Time",
      variant: "dark_orange",
      onClick: () => {
        if (userProfileBarRef.current) {
          userProfileBarRef.current.style.width = "100%";
          userProfileBarRef.current.style.opacity = "1";
        }
      },
    },
  ];
  const notes = [{}, {}, {}, {}, {}, {}, {}];
  const { title } = useUserProfile();
  const firstName = title?.split(" ")[0] ?? "";
  const lastName = title?.split(" ")[1] ?? "";
  useEffect(() => {
    setWholeWeek(calWholeWeek(selectedPeriod?.startDate));
  }, [selectedPeriod]);

  return (
    <div className="overflow-auto">
      <UpcomingShifts
        show={showUpcomingShiftModal}
        onClose={() => setshowUpcomingShiftModal(!showUpcomingShiftModal)}
      />
      <AvailableShifts
        show={showAvailableShiftModal}
        onClose={() => setshowAvailableShiftModal(!showAvailableShiftModal)}
      />

      <UserProfileSidebar
        ref={userProfileBarRef}
        onClose={() => {
          if (userProfileBarRef.current) {
            userProfileBarRef.current.style.opacity = "0";

            userProfileBarRef.current.style.width = "0%";
          }
        }}
      />
      <UnscheduledShift
        show={showUnscheduledShiftModal}
        onClose={() => setshowUnscheduledShiftModal(!showUnscheduledShiftModal)}
      />
      <Container fluid className="p-0 m-0">
        <Row className="justify-content-center d-flex p-0 m-0">
          <Col xs={11} className="p-4">
            <Row className="mt-2">
              <Col>
                <PageBanner
                  textColor="#4d4d4d"
                  containerClassName="border shadow-sm"
                  backgroundColor="#fff"
                  imageDimensions={{ height: "200px", width: "200px" }}
                  heading={`Welcome ${business?.businessName}`}
                  paragraph="This is your portal.  Utilise it to manage your services providers and know what is being delivered at  your sites."
                  image={business.logoPath || BannerImage}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Container fluid>
                <div
                  className="shadow-sm p-3 w-100 dashboard-border rounded-3"
                  style={{
                    backgroundColor: openDashboardSection ? "#fff" : "#F5F5F5",
                  }}
                >
                  <Button
                    variant="plain"
                    className="p-0 dashboard-section-heading "
                    fullWidth
                    onClick={() => {
                      setOpenDashboardSection(!openDashboardSection);
                    }}
                  >
                    <Text heading bold className="w-100" fontSize="22px">
                      Dashboard
                    </Text>
                  </Button>
                  <Collapse in={openDashboardSection}>
                    <Row className="mt-3">
                      <Col xs={12} md={4}>
                        <Text light heading>
                          Needs Attention
                        </Text>
                        <div className="child-container p-3">
                          <Text
                            color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                            heading
                            bold
                          >
                            No shifts
                          </Text>
                          <Text color={Colors.HEX_TO_RGBA("#000000", 0.5)}>
                            No shifts to assign today
                          </Text>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <Text light heading>
                          Needs Approval
                        </Text>
                        <div className="child-container p-3">
                          <Text
                            centered
                            heading
                            bold
                            color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                          >
                            Great job!
                          </Text>
                          <Text
                            centered
                            color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                          >
                            You are all clear, nothing to approve today
                          </Text>
                          <div
                            className="d-flex justify-content-center "
                            style={{ marginTop: "6rem" }}
                          >
                            <Image
                              src={GreatJobImage}
                              style={{ width: "170px", height: "120px" }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <Text light heading>
                          What's happening?
                        </Text>
                        <div className="child-container p-3">
                          <Text
                            centered
                            color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                            heading
                            bold
                          >
                            Dream Team
                          </Text>
                          <Text
                            centered
                            color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                          >
                            Your team is just awesome
                          </Text>
                          <div
                            className="d-flex justify-content-center "
                            style={{ marginTop: "6rem" }}
                          >
                            <Image
                              src={TeamImage}
                              style={{ width: "170px", height: "120px" }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
                <div
                  className="shadow-sm p-3 w-100 dashboard-border rounded-3 mt-3"
                  style={{
                    backgroundColor: openCalendarSection ? "#fff" : "#F5F5F5",
                  }}
                >
                  <Button
                    variant="plain"
                    className="p-0 dashboard-section-heading "
                    fullWidth
                    onClick={() => {
                      setOpenCalendarSection(!openCalendarSection);
                    }}
                  >
                    <Text heading bold className="w-100" fontSize="22px">
                      Calendar
                    </Text>
                  </Button>
                  <Collapse in={openCalendarSection}>
                    <div>
                      <Row>
                        <Col xs="auto">
                          <div className="mt-2">
                            <CalenderButton
                              onDateChanged={(changedDate: any) =>
                                setSelectedPeriod(changedDate)
                              }
                              mode="Week"
                              borderRadius="50px"
                              style={{ padding: "5px" }}
                            />
                          </div>
                        </Col>
                        {calenderBtns.map(({ label, ...restOfProps }, i) => (
                          <Col xs="auto" className="mt-2" key={i}>
                            <Button
                              small={true}
                              noBorderRadius={false}
                              textColor="white"
                              {...restOfProps}
                            >
                              {label!}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Text fontSize="20px" color="grey">
                            Total Hours: 0:00 Hours
                          </Text>
                        </Col>
                      </Row>
                      <Row className="mt-4 ">
                        {notes.map(({}, i) => (
                          <Col xs="auto">
                            <Text
                              fontSize="11px"
                              light
                              heading
                              bold
                              style={
                                wholeWeek &&
                                wholeWeek[i]?.isSame(moment().startOf("day"))
                                  ? {
                                      backgroundColor: Colors.DARK_ORANGE,
                                      color: "white",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      margin: "5px 0 5px 0",
                                      display: "inline-block",
                                    }
                                  : { margin: "5px 0 5px 0", padding: 5 }
                              }
                            >
                              {wholeWeek && wholeWeek[i]?.format("DD MMM YYYY")}
                            </Text>
                            <div className="calendar-container p-3">
                              <Text
                                color={Colors.HEX_TO_RGBA("#000000", 0.5)}
                                heading
                                bold
                              >
                                No shifts
                              </Text>
                              <Text color={Colors.HEX_TO_RGBA("#000000", 0.5)}>
                                No shifts to assign today
                              </Text>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
