import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StylesConfig } from "react-select";
import axiosInstance from "../../../api/axiosInstance";
import Countries from "../../../api/countries";
import { removeEmptyOrNull } from "../../../api/general";
import AddressModal from "../../../components/addressModal";
import InputField, {
  IInputField,
} from "../../../components/InputField/InputField";
import Select, { ISelect } from "../../../components/select/select";
import { Colors } from "../../../constants";
import useModal from "../../../hooks/modal";

interface IGeneral {
  onUpdate: (data: any) => void;
  defaultValue: any;
}
function General({ onUpdate, defaultValue }: IGeneral) {
  const selectStyle: StylesConfig = {
    control: (style, state) => ({
      ...style,
      border: `1px solid #ebebec`,
      minHeight: "34px",
      "&:hover": {
        border: `1px solid #ebebec`,
      },
    }),
    valueContainer: (style) => ({ ...style, fontSize: "12px" }),
    input: (style) => ({ ...style, fontSize: "12px" }),
    indicatorsContainer: (style) => ({ ...style, height: "34px" }),
  };

  const inputOps: IInputField = {
    labelTextProps: {
      fontSize: "12px",
      style: { fontWeight: "normal" },
    },
    borderRadius: "5px",
    backgroundColor: "white",
    fontSize: "12px",
  };
  const selectOps: ISelect = {
    labelBold: true,
    backgroundColor: "white",
    labelTextProps: { fontSize: "12px", style: { fontWeight: "normal" } },
    styles: selectStyle,
  };

  const [businessName, setBusinessName] = useState<string>(
    defaultValue?.businessName
  );
  const [address, setAddress] = useState<string>(defaultValue?.address || "");
  const [abn, setAbn] = useState<string>(defaultValue?.abn || "");
  const [acn, setAcn] = useState<string>(defaultValue?.acn || "");
  const [website, setWebsite] = useState<string>(defaultValue?.website || "");
  const [contactName, setContactName] = useState<string>(
    defaultValue?.contactName || ""
  );
  const [contactEmail, setContactEmail] = useState<string>(
    defaultValue?.contactEmail || ""
  );
  const [contactPhone, setContactPhone] = useState<string>(
    defaultValue?.contactPhone || ""
  );
  const [city, setCity] = useState<string>(defaultValue?.city || "");
  const [postCode, setPostCode] = useState<string>(
    defaultValue?.postCode || ""
  );
  const [country, setCountry] = useState<string>(defaultValue?.country || "");

  const inputs = [
    {
      label: "Business Name",
      type: "text",
      placeholder: "Enter Business Name",
      isSelect: false,
      onChange: (text: string) => setBusinessName(text),
      validation: (text: string) => {
        return text.length > 0;
      },
      validationMessage: "Required",
      inputProps: {
        defaultValue: defaultValue?.businessName,
        value: businessName,
      },
    },
    {
      label: "Address",
      type: "text",
      placeholder: "Enter Address",
      isSelect: false,
      onChange: (text: any) => {
        setAddress(text.address);
      },
      inputProps: {
        // value: address || "",

        defaultValue: defaultValue?.address || address,
        value: address,
      },
      isAddressInput: true,
    },
    {
      label: "ABN",
      type: "text",
      placeholder: "Enter ABN",
      isSelect: false,
      onChange: (text: string) => setAbn(text),
      inputProps: { defaultValue: defaultValue?.abn, value: abn },
      validation: (text: string) => {
        return text.length > 0 && text.length === 11;
      },
      validationMessage: "ABN is required and must be 11 digits",
    },
    {
      label: "ACN",
      type: "text",
      placeholder: "Enter ACN",
      isSelect: false,
      onChange: (text: string) => setAcn(text),
      inputProps: { defaultValue: defaultValue?.acn, value: acn },
      validation: (text: string) => {
        return abn.length === 11 && text.length === 9;
      },
      validationMessage:
        abn.length < 11 ? "ABN is required" : "ACN must be 9 digits",
    },
    {
      label: "Business Website",
      type: "text",
      placeholder: "Enter Business Website",
      isSelect: false,
      onChange: (text: string) => setWebsite(text),
      inputProps: { defaultValue: defaultValue?.website, value: website },
    },
    {
      label: "Contact Name",
      type: "text",
      placeholder: "Enter Contact Name",
      isSelect: false,
      onChange: (text: string) => setContactName(text),
      inputProps: {
        defaultValue: defaultValue?.contactName,
        value: contactName,
      },
    },
    {
      label: "Contact Email",
      type: "text",
      placeholder: "Enter Contact Email",
      isSelect: false,
      onChange: (text: string) => setContactEmail(text),
      inputProps: {
        defaultValue: defaultValue?.contactEmail,
        value: contactEmail,
      },
    },
    {
      label: "Contact Phone",
      type: "text",
      placeholder: "Enter Contact Phone",
      isSelect: false,
      onChange: (text: string) => setContactPhone(text),
      inputProps: {
        defaultValue: defaultValue?.contactPhone,
        value: contactPhone,
      },
    },
    {
      label: "City/Suburb",
      type: "text",
      placeholder: "Enter City/Suburb",
      isSelect: false,
      onChange: (text: string) => setCity(text),
      inputProps: { defaultValue: defaultValue?.city, value: city },
    },
    {
      label: "Postcode",
      type: "text",
      placeholder: "Enter Postcode",
      isSelect: false,
      onChange: (text: string) => setPostCode(text),
      inputProps: { defaultValue: defaultValue?.postCode, value: postCode },
    },
    {
      label: "Country",
      type: "text",
      placeholder: "Enter Country",
      isSelect: true,
      onChange: (text: string) => setCountry(text),
      selectProps: {
        defaultValue: defaultValue?.country
          ? {
              label: defaultValue?.country,
              value: defaultValue?.country,
            }
          : undefined,
        options: Countries,
        placeholder: "Select Country",
      },
    },
  ];
  // console.log(
  //   {
  //     businessName,
  //     address,
  //     abn,
  //     acn,
  //     website,
  //     contactName,
  //     contactEmail,
  //     contactPhone,
  //     city,
  //     postCode,
  //     country,
  //   },
  //   defaultValue
  // );
  useEffect(() => {
    onUpdate({
      ...removeEmptyOrNull(defaultValue),
      ...removeEmptyOrNull({
        businessName,
        address,
        abn,
        acn,
        website,
        contactName,
        contactEmail,
        contactPhone,
        city,
        postCode,
        country,
      }),
    });
  }, [
    businessName,
    address,
    abn,
    acn,
    website,
    contactName,
    contactEmail,
    contactPhone,
    city,
    postCode,
    country,
  ]);

  return (
    <>
      <Container
        style={{ backgroundColor: Colors["cyan-12"] }}
        className="h-100 overflow-auto"
      >
        {inputs.map(
          (
            {
              isSelect,
              label,
              placeholder,
              type,
              onChange,
              validation,
              validationMessage,
              inputProps,
              selectProps,
              isAddressInput,
            },
            i
          ) =>
            isSelect ? (
              <Row className="pt-3" key={i}>
                <Col>
                  <Select
                    label={label}
                    onChange={(e: any) => {
                      onChange(e.value);
                    }}
                    {...selectProps}
                    {...selectOps}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="pt-3" key={i}>
                <Col>
                  <InputField
                    label={label}
                    onChangedText={onChange}
                    placeHolder={placeholder}
                    validation={validation}
                    validationMessage={validationMessage}
                    inputProps={inputProps}
                    isAddressInput={isAddressInput}
                    {...inputOps}
                  />
                </Col>
              </Row>
            )
        )}
      </Container>
    </>
  );
}

export default General;
