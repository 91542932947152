import React, { MouseEventHandler } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "../button/Button";
import Text from "../text/text";
import { GrClose } from "react-icons/gr";

interface IVideoModal {
  title: string;
  url: string;
  additionalComponent?: React.ReactChild;
  onClose: MouseEventHandler<HTMLButtonElement>;
  show: boolean;
}
export default function VideoModal({
  title,
  url,
  additionalComponent,
  onClose,
  show,
}: IVideoModal) {
  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Row className="w-100 align-items-center">
          <Col xs="auto" style={{ flex: 1 }}>
            <Text heading style={{ fontWeight: "500", textAlign: "center" }}>
              {title}
            </Text>
          </Col>
          {/* <Col xs="auto">
            <Button bold variant="no_color" onClick={onClose}>
              <GrClose size={20} />
            </Button>
          </Col> */}
        </Row>
      </Modal.Header>
      <Modal.Body>
        <video
          width="100%"
          height="100%"
          src={url}
          controls
          style={{ borderRadius: "10px" }}
        >
          Unable to load video
        </video>
        {additionalComponent && additionalComponent}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="blue_color"
          // fontSize="12px"
          noBorderRadius={false}
          className="px-5"
          onClick={onClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
