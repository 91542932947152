const Countries = [
  "UNITED KINGDOM",
  "ARGENTINA",
  "AUSTRALIA",
  "BAHAMAS",
  "BELGIUM",
  "BRAZIL",
  "CANADA",
  "CHINA",
  "COLOMBIA",
  "CUBA",
  "DOMINICAN REPUBLIC",
  "ECUADOR",
  "EL SALVADOR",
  "FRANCE",
  "GERMANY",
  "GUATEMALA",
  "HAITI",
  "HONDURAS",
  "INDIA",
  "IRELAND",
  "ISRAEL",
  "ITALY",
  "JAPAN",
  "KOREA",
  "MEXICO",
  "NETHERLANDS",
  "PHILIPPINES",
  "SPAIN",
  "SWEDEN",
  "SWITZERLAND",
  "TAIWAN",
  "VENEZUELA",
  "VIET NAM",
  "AFGHANISTAN",
  "ALBANIA",
  "ALGERIA",
  "AMERICAN SAMOA",
  "ANDORRA",
  "ANGOLA",
  "ARMENIA",
  "AUSTRIA",
  "AZERBAIJAN",
  "BAHRAIN",
  "BANGLADESH",
  "BARBADOS",
  "BELARUS",
  "BELIZE",
  "BENIN",
  "BERMUDA",
  "BHUTAN",
  "BOLIVIA",
  "BOSNIA AND HERZEGOVINA",
  "BOTSWANA",
  "BULGARIA",
  "BURKINA FASO",
  "BURUNDI",
  "CAMBODIA",
  "CAMEROON",
  "CAPE VERDE ISLANDS",
  "CHAD",
  "CHILE",
  "CONGO",
  "COSTA RICA",
  "CROATIA",
  "CYPRUS",
  "CZECH REPUBLIC",
  "DENMARK",
  "DJIBOUTI",
  "DOMINICA",
  "EGYPT",
  "ERITREA",
  "ESTONIA",
  "ETHIOPIA",
  "FIJI",
  "FINLAND",
  "FRENCH POLYNESIA",
  "GABON",
  "GAMBIA",
  "GEORGIA",
  "GHANA",
  "GREECE",
  "GRENADA",
  "GUINEA",
  "GUYANA",
  "HUNGARY",
  "ICELAND",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "JAMAICA",
  "JORDAN",
  "KAZAKHSTAN",
  "KENYA",
  "KOREA",
  "KUWAIT",
  "LATVIA",
  "LEBANON",
  "LIBERIA",
  "LIBYA",
  "LITHUANIA",
  "LUXEMBOURG",
  "MADAGASCAR",
  "MALAWI",
  "MALAYSIA",
  "MALDIVES",
  "MALI",
  "MALTA",
  "MAURITANIA",
  "MAURITIUS",
  "MONACO",
  "MONGOLIA",
  "MONTENEGRO",
  "MOROCCO",
  "MOZAMBIQUE",
  "NAMIBIA",
  "NEPAL",
  "NEW ZEALAND",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NORWAY",
  "OMAN",
  "PAKISTAN",
  "PANAMA",
  "PAPUA NEW GUINEA",
  "PARAGUAY",
  "PERU",
  "POLAND",
  "PORTUGAL",
  "QATAR",
  "ROMANIA",
  "RWANDA",
  "SAUDI ARABIA",
  "SENEGAL",
  "SERBIA",
  "SIERRA LEONE",
  "SINGAPORE",
  "SLOVAKIA",
  "SLOVENIA",
  "SOLOMON ISLANDS",
  "SOMALIA",
  "SOUTH AFRICA",
  "SRI LANKA",
  "SUDAN",
  "SURINAME",
  "SWAZILAND",
  "TAJIKISTAN",
  "THAILAND",
  "TOGO",
  "TRINIDAD AND TOBAGO",
  "TUNISIA",
  "TURKEY",
  "TURKMENISTAN",
  "TUVALU",
  "UGANDA",
  "UKRAINE",
  "UNITED ARAB EMIRATES",
  "UNITED STATES",
  "URUGUAY",
  "UZBEKISTAN",
  "VANUATU",
  "YEMEN",
  "ZAMBIA",
].map((country) => ({ label: country, value: country }));
export default Countries;
