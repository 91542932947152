import { Col, Container, Modal, Row } from "react-bootstrap";
import { RiCloseLine } from "react-icons/ri";
import Button from "../../../../components/button/Button";
import Text from "../../../../components/text/text";
import { GiStarsStack } from "react-icons/gi";
import { AiFillYoutube } from "react-icons/ai";
import { MouseEventHandler } from "react";
import { Colors } from "../../../../constants";

interface IAutoScheduling {
  show: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

export default function AutoScheduling({ onClose, show }: IAutoScheduling) {
  return (
    <Modal show={show} centered size="lg">
      <Modal.Header className="p-0">
        <Container fluid>
          <Row className="align-items-center p-0 py-2 m-0">
            <Col className="p-0">
              <Text
                style={{ fontSize: "20px" }}
                className="d-flex align-items-center"
              >
                <GiStarsStack style={{ marginRight: 5 }} />
                Start Auto Scheduling
              </Text>
            </Col>
            <Col className="justify-content-end p-0 d-flex" xs="auto">
              <Button variant="plain" className="p-0" onClick={onClose}>
                <RiCloseLine size={30} />
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Text fontSize="13px">
          With a single click Auto-Scheduling will build you the perfect shift
          structure from scratch when fill it with the right people to best
          match your scheduling priorities.
        </Text>
        <Text color={Colors.BLUE} className="d-flex align-items-center mt-3">
          <AiFillYoutube size={20} />
          Learn more about Auto-Scheduling
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button noBorderRadius={false} variant="cyan-6">
                Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button noBorderRadius={false} variant="blue_color">
                Continue
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
