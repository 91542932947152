import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BASE_URL } from "../constants";
import useModal from "../hooks/modal";
import Spinner from "./../components/loader";

export const useAxios = (
  params: AxiosRequestConfig,
  dependencies?: Array<any>,
  callback?: (data: any, error: any) => void
) => {
  const { showModal } = useModal();
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function () {
      const user = localStorage.getItem("user");

      if (user) {
        const userJSON = JSON.parse(user);

        try {
          // showModal({ Component: <Text>Loading</Text> });
          const result = await axios
            .create({
              baseURL: BASE_URL,
              headers: {
                Authorization: `Bearer ${userJSON.token}`,
              },
            })
            .request({
              headers: {
                "Content-Type": "application/json",
              },
              ...params,
            });
          setResponse(result.data);
          callback && callback(result.data, undefined);
        } catch (error: any) {
          setError(error);
          callback && callback(undefined, error);
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        } finally {
          setLoading(false);
        }
      } else {
        setError("No Token Provided");
        setLoading(false);
      }
    })();
  }, dependencies || []);
  return { response, error, loading };
};
