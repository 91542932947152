import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "../../components/button/Button";
import { InputField } from "../../components/InputField/InputField";
import Link from "../../components/link/link";
import Text from "../../components/text/text";
import "./SignUp.scss";

export default function Verify({}) {
  return (
    <section className="overlaybg fullscreen">
      <Container fluid>
        <Row className=" justify-content-center  fullscreen">
          <Col xl={3} lg={3} md={6} sm={7} xs={10} className="my-auto">
            <Row>
              <Col className="justify-content-center">
                <Text
                  color="white"
                  style={{ textAlign: "center" }}
                  fontSize="40px"
                >
                  Verification
                </Text>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-center">
                <Text
                  color="white"
                  style={{ textAlign: "center" }}
                  fontSize="20px"
                >
                  Thanks for keeping your account secure
                </Text>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-center">
                <Text
                  color="white"
                  style={{ textAlign: "center" }}
                  fontSize="20px"
                >
                  Check your mobile number: (xxx)-xxx-0022
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputField
                  borderRadius="5px"
                  labelColor="white"
                  placeHolder="6-digit code"
                  label="Verification Code"
                  bottomlabel={
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Form.Check height={10} width={10} />
                      </Col>
                      <Col xs="auto" className="p-0 m-0">
                        <Text color="white">
                          This is a trusted device, do not ask again.
                          <span>
                            <Link className="ms-1 learn-more-link" to="#">
                              Learn more
                            </Link>
                          </span>
                        </Text>
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button fullWidth fontSize="20px" noBorderRadius>
                  Sign in Securely
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
