import { ChangeEvent, useEffect, useState } from "react";
import { Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { removeEmptyOrNull } from "../../../api/general";
import InputField from "../../../components/InputField/InputField";
import Text from "../../../components/text/text";
import facebooklogo from "./../assets/1.png";
import googleLogo from "./../assets/2.png";
interface IProfilePhoto {
  defaultValue: any;
  onUpdate: (data: any) => void;
}
export default function ProfilePhoto({
  defaultValue,
  onUpdate,
}: IProfilePhoto) {
  const [uploadedPhoto, setUploadedPhoto] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [photoBase64, setPhotoBase64] = useState<any>();
  const [photoFileName, setPhotoFileName] = useState<string>();

  useEffect(() => {
    onUpdate({
      ...removeEmptyOrNull(defaultValue),
      ...removeEmptyOrNull({ photoBase64, photoFileName }),
    });
  }, [photoBase64, photoFileName]);

  useEffect(() => {
    let url: string;
    if (uploadedPhoto) {
      setPhotoFileName(uploadedPhoto.name);
      const image = URL.createObjectURL(uploadedPhoto);
      setPreview(image);
      let reader = new FileReader();
      reader.readAsDataURL(uploadedPhoto);
      reader.onload = function () {
        const data = reader.result as string;
        setPhotoBase64(data.split("base64,")[1]);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    return () => URL.revokeObjectURL(url);
  }, [uploadedPhoto]);

  return (
    <Container>
      <Row className="pt-3">
        <Col>
          <Text>Change your profile picture</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Dropdown.Divider />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div style={{ width: 150, height: 150 }}>
            {preview || defaultValue?.photoUrl ? (
              <Image
                src={preview || defaultValue?.photoUrl}
                style={{ width: "100%", height: "100%" }}
                roundedCircle
              />
            ) : (
              <FaUserCircle color="grey" size="100%" />
            )}
          </div>
        </Col>
        <Col md="9">
          <Row>
            <Col md="5">
              <Form.Control
                type="file"
                accept=".png,.jpeg,.jpg"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  e.target.files && setUploadedPhoto(e.target.files[0])
                }
              />
              {/* <InputField
                backgroundColor="white"
                borderRadius="5px"
                fontSize={12}
                inputProps={{
                  type: "file",
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    e.target.files && setUploadedPhoto(e.target.files[0]),
                    accept:"image/*"
                }}
              /> */}
              <Text color="grey">JPEG, JPG, PNG</Text>
            </Col>
            <Row>
              <Col>
                <Dropdown.Divider />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs="auto">
                <Text>Import from</Text>
              </Col>
              <Col xs="auto">
                <Image src={facebooklogo} style={{ width: "60px" }} />
              </Col>
              <Col xs="auto">
                <Image src={googleLogo} style={{ width: "60px" }} />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
