import { useState, useCallback } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { IOption } from "../../interface";
import Text from "../text/text";
import { IInputField, InputField } from "./InputField";
import Autocomplete from "react-autocomplete";
interface IDropDownInput {
  menu: Array<IOption>;
  fullWidth?: boolean;
  inputProps?: IInputField;
  label?: string;
  onValueSelect: (value: string, label?: string) => void;
  autoCompleteProps?: Omit<
    Autocomplete.Props,
    "items" | "renderItem" | "getItemValue" | "value"
  >;
  defaultValue?: string;
}
export default function DropDownInput({
  menu,
  fullWidth,
  inputProps,
  label,
  defaultValue,
  onValueSelect,
}: IDropDownInput) {
  let [inputValue, setInputValue] = useState<string>(defaultValue || "");
  const [itemValue, setItemValue] = useState<string>();
  const filteredItems = useCallback(() => {
    return menu.filter(({ label, value }) =>
      label?.toLowerCase()?.includes(inputValue?.toLowerCase() || "")
    );
  }, [inputValue]);

  return (
    <Autocomplete
      wrapperStyle={{
        // width: "100%",
        position: "relative",
      }}
      menuStyle={{
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.9)",
        padding: "2px 0",
        fontSize: "75%",
        position: "fixed",
        overflow: "auto",
        maxHeight: "50%",
        zIndex: "998",
      }}
      getItemValue={(item) => String(item.value)}
      items={filteredItems()}
      renderItem={(item, isHighlighted) => (
        <div
          style={{
            background: isHighlighted ? "lightgray" : "white",
            padding: 10,
          }}
        >
          {item.label}
        </div>
      )}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onSelect={(val, item) => {
        setInputValue(item?.label);
        setItemValue(item?.value);
        onValueSelect(item?.value, item?.label);
      }}
      renderInput={(props: any) => (
        <InputField
          {...inputProps}
          inputProps={{ ...props, ...inputProps?.inputProps }}
          label={label}
        />
      )}
    />
  );
}
