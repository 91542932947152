import { Button, Modal } from "react-bootstrap";
import Text from "../../../components/text/text";
import EmployeeMainModalProps from "./modal-common.props";
import Select from "react-select";
import SelectTheme from "../../../components-v2/Select/select-theme";

export default function AddTrainingsModal({
  onHide,
  show,
}: EmployeeMainModalProps) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="white-container"
    >
      <Modal.Header closeButton>
        <Text fontSize={20}>Add Training</Text>
      </Modal.Header>
      <Modal.Body>
        <Text fontSize={16}>Training</Text>
        <Select
          styles={SelectTheme}
          options={[
            {
              label: "Training 1",
              value: "Training 1",
            },
            {
              label: "Training 2",
              value: "Training 2",
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="blue_color" size="sm" onClick={() => onHide()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
