import "./monthly-layout.scss";
import { Col, Container, Row, Table } from "react-bootstrap";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { generateDateRange } from "../../../../api/general";
import InputField from "../../../../components/InputField/InputField";
import { ImSearch } from "react-icons/im";
import { Colors } from "../../../../constants";
import Text from "../../../../components/text/text";
import { BsCaretDownFill, BsPlusSquareDotted } from "react-icons/bs";
import EmployeeBox from "../../../../components/employee-box/employee";
// import ShiftBox from "../../../../components/shift-box/shift-box1";
import Button from "../../../../components/button/Button";
import MultipleShiftBox from "../modals/multiple-shifts-box";
import { FaPaste } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../../hooks/user";
import ShiftBox from "../shift-box";
import Shifts, { IShift } from "../../../../modules/Shifts";
import { IEmployee } from "../../../../modules/Employee";
interface MonthlyLayoutProps {
  timePeriod: { startDate?: Moment; endDate?: Moment };
  staff: Array<IEmployee & { color?: string }>;
  shiftsEmployeeWise: any;
  onEditClick?: (date: Moment, shift: Shifts.ShiftReponse) => void;
  onAddClick?: (date: Moment) => void;
  onCopyShift?: Function;
  copiedShift?: Shifts.ShiftPayload;
  onPasteShift?: (shift: Shifts.ShiftPayload) => void;
  onSearchTextChanged?: (text: string) => void;
}
export default function MonthlyLayout({
  timePeriod,
  staff,
  shiftsEmployeeWise,
  onAddClick,
  onEditClick,
  copiedShift,
  onCopyShift,
  onPasteShift,
  onSearchTextChanged,
}: MonthlyLayoutProps) {
  const navigate = useNavigate();
  const user = useUserProfile();

  const tableRef = useRef<HTMLDivElement>(null);
  const [clickedCell, setClickedCell] =
    useState<{ date: Moment; index: number; empId: number }>();
  const [days, setDays] = useState<Array<Moment>>([]);

  useEffect(() => {
    timePeriod?.startDate &&
      setDays(
        generateDateRange(
          timePeriod?.startDate.clone(),
          timePeriod.startDate.daysInMonth()
        )
      );
  }, [timePeriod.startDate, timePeriod.endDate]);

  return (
    <div className="monthly-table-wrapper" ref={tableRef}>
      <Table responsive className="monthly-table overflow-auto">
        <colgroup>
          <col style={{ width: 200, minWidth: 200 }} />
          {Array.from({ length: timePeriod.startDate?.daysInMonth() || 0 }).map(
            (_, index) => (
              <col
                key={index}
                style={{
                  width:
                    (tableRef.current?.offsetWidth || 0) /
                      (timePeriod?.startDate
                        ? timePeriod.startDate?.daysInMonth()
                        : 1) -
                    200 /
                      (timePeriod.startDate
                        ? timePeriod.startDate?.daysInMonth()
                        : 1),
                  minWidth:
                    (tableRef.current?.offsetWidth || 0) /
                      (timePeriod?.startDate
                        ? timePeriod.startDate?.daysInMonth()
                        : 1) -
                    200 /
                      (timePeriod.startDate
                        ? timePeriod.startDate?.daysInMonth()
                        : 1),
                }}
              />
            )
          )}
        </colgroup>
        <thead>
          <tr className="align-items-center">
            <th>
              <InputField
                fontSize="14px"
                placeHolder="Type to Search"
                backgroundColor="white"
                borderRadius="5px"
                startIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
                wrapperClassName="mx-2 mb-2"
                inputClassName="p-1"
                onChangedText={onSearchTextChanged}
              />
            </th>
            {days.map((day, index) => (
              <th key={index}>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  <span>{day.format("ddd")}</span>
                  <br />
                  <span>{day.format("DD")}</span>
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {staff?.map(({ preferredTitle, id, color, profilePicture }, i) => (
            <tr style={{ height: 1 }}>
              <td className="first-col">
                <EmployeeBox
                  // image="https://picsum.photos/200/300"
                  preferredTitle={preferredTitle}
                  isScheduleModeMonthly={false}
                />
              </td>
              {days?.map((day, index) => (
                <td
                  key={index}
                  className="position-relative"
                  style={{
                    height: "inherit",
                    background:
                      clickedCell?.date.isSame(day, "day") &&
                      clickedCell.empId === id &&
                      clickedCell.index === index
                        ? "#e6e6e6"
                        : "",
                  }}
                  onClick={(e) => {
                    setClickedCell({ date: day, index, empId: id });
                  }}
                >
                  {shiftsEmployeeWise &&
                  shiftsEmployeeWise[id]?.hasOwnProperty(
                    day.format("YYYY-MM-DD")
                  ) ? (
                    shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]?.length >
                    1 ? (
                      <MultipleShiftBox
                        date={day}
                        backgroundColor={color}
                        shifts={
                          shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]
                        }
                        length={
                          shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]
                            ?.length
                        }
                        onShiftClicked={(shift) => {
                          onEditClick && onEditClick(day, shift);
                        }}
                        onCopyClicked={(shift) =>
                          onCopyShift && onCopyShift(shift)
                        }
                        onAddShiftClicked={(day) => {
                          onAddClick && onAddClick(day);
                        }}
                        copiedShift={copiedShift}
                        isMonthly
                      />
                    ) : (
                      shiftsEmployeeWise[id][day.format("YYYY-MM-DD")]?.map(
                        (shift: any, i: number) => (
                          <ShiftBox
                            copiedShift={copiedShift && true}
                            backgroundColor={color}
                            key={i}
                            startTime={moment.unix(Number(shift.startTime))}
                            endTime={moment.unix(Number(shift.endTime))}
                            variant="published"
                            areaName={shift?.area?.area}
                            locationName={shift?.location?.location}
                            time={shift.time}
                            scheduleMode="Month"
                            onEdit={() =>
                              onEditClick && onEditClick(day, shift)
                            }
                            onCopy={() => onCopyShift && onCopyShift(shift)}
                            onAdd={() => onAddClick && onAddClick(day)}
                          />
                        )
                      )
                    )
                  ) : (
                    <>
                      {!user.roles.includes("ROLE_CLIENT") && (
                        <Button
                          key={i}
                          variant="plain"
                          onClick={() => {
                            onAddClick && onAddClick(day);
                          }}
                          centeredText
                          className="p-0 h-100 d-flex justify-content-center w-100"
                        >
                          <BsPlusSquareDotted />
                        </Button>
                      )}
                      {copiedShift &&
                        clickedCell?.date.isSame(day, "day") &&
                        clickedCell.empId === id &&
                        clickedCell.index === index && (
                          <Button
                            variant="plain"
                            className="p-0 w-100 mt-1"
                            onClick={() => {
                              onPasteShift &&
                                onPasteShift({
                                  ...copiedShift,
                                  startDate: day.unix().toString(),
                                  employee: {
                                    id,
                                    employee: preferredTitle,
                                    userId: id,
                                  },
                                  startTime: moment
                                    .unix(Number(copiedShift.startTime))
                                    .set({
                                      date: day.date(),
                                    })
                                    .unix()
                                    .toString(),
                                  endTime: moment
                                    .unix(Number(copiedShift.endTime))
                                    .set({
                                      date: day.date(),
                                    })
                                    .unix()
                                    .toString(),
                                });
                            }}
                          >
                            <FaPaste />
                          </Button>
                        )}
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td>
              <div className="d-flex justify-content-center my-3 w-100">
                <Button
                  fontSize="12px"
                  startIcon={<IoMdPersonAdd size={25} />}
                  className="p-2 "
                  fullWidth
                  onClick={() => {
                    navigate(`/app/employee/`);
                  }}
                >
                  Add New Team Member
                </Button>
              </div>
            </td>
            <td colSpan={timePeriod.startDate?.daysInMonth()} />
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
