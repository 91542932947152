import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { removeEmptyOrNull } from "../../../api/general";
import InputField from "../../../components/InputField/InputField";
import RadioBtn from "../../../components/radio/radio";
interface IContractorInfo {
  onUpdate: (data: any) => void;
  defaultValue: any;
}
export default function ContractorInfo({
  defaultValue,
  onUpdate,
}: IContractorInfo) {
  const [contractorAbn, setcontractorAbn] = useState<string>("");
  const [contractorAcn, setcontractorAcn] = useState<string>("");
  const [contractorCompanyAddress, setcontractorCompanyAddress] =
    useState<string>("");
  const [contractorCompanyName, setcontractorCompanyName] =
    useState<string>("");
  const [contractorContactNumber, setcontractorContactNumber] =
    useState<string>("");
  const [contractorEmail, setcontractorEmail] = useState<string>("");
  const [contractorGstRegistered, setcontractorGstRegistered] =
    useState<string>("");
  const [contractorName, setcontractorName] = useState<string>("");

  useEffect(() => {
    onUpdate(
      removeEmptyOrNull({
        contractorAbn,
        contractorAcn,
        contractorCompanyAddress,
        contractorCompanyName,
        contractorContactNumber,
        contractorEmail,
        contractorGstRegistered,
        contractorName,
      })
    );
  }, [
    contractorAbn,
    contractorAcn,
    contractorCompanyAddress,
    contractorCompanyName,
    contractorContactNumber,
    contractorEmail,
    contractorGstRegistered,
    contractorName,
  ]);
  const contractorFields = [
    {
      placeholder: "Company Name",
      onChange: setcontractorCompanyName,
      defaultValue: defaultValue?.contractorCompanyName,
    },
    {
      placeholder: "ABN",
      onChange: setcontractorAbn,
      defaultValue: defaultValue?.contractorAbn,
    },
    {
      placeholder: "ACN",
      onChange: setcontractorAcn,
      defaultValue: defaultValue?.contractorAcn,
    },
    {
      placeholder: "Contact Name",
      onChange: setcontractorName,
      defaultValue: defaultValue?.contractorName,
    },
    {
      placeholder: "Contact Email",
      onChange: setcontractorEmail,
      defaultValue: defaultValue?.contractorEmail,
    },
    {
      placeholder: "Contact Phone",
      onChange: setcontractorContactNumber,
      defaultValue: defaultValue?.contractorContactNumber,
    },
    {
      placeholder: "Address",
      onChange: setcontractorCompanyAddress,
      isAddressInput: true,
      defaultValue: defaultValue?.contractorCompanyAddress,
    },
    {
      label: "Is registered for GST?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      isRadio: true,
      onChange: setcontractorGstRegistered,
      defaultValue: defaultValue?.contractorGstRegistered,
    },
  ];

  return (
    <Container>
      <Row>
        {contractorFields.map(
          (
            {
              onChange,
              placeholder,
              isRadio,
              label,
              options,
              isAddressInput,
              defaultValue,
            },
            i
          ) => (
            <Col
              xs="12"
              md="12"
              lg={placeholder === "Address" ? "12" : "4"}
              className="my-2"
              key={i}
            >
              {isRadio ? (
                <RadioBtn
                  options={options}
                  label={label}
                  inline
                  groupName={label}
                  onChange={onChange}
                  defaultValue={defaultValue}
                  labelProps={{ fontSize: 14 }}
                />
              ) : (
                <InputField
                  placeHolder={placeholder}
                  onChangedText={onChange}
                  backgroundColor="white"
                  isAddressInput={isAddressInput}
                  inputProps={{ defaultValue }}
                  fontSize="14px"
                />
              )}
            </Col>
          )
        )}
      </Row>
    </Container>
  );
}
