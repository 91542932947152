import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillMessage } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { ImSearch } from "react-icons/im";
import { IoMdWarning } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import Button from "../../components/button/Button";
import { InputField } from "../../components/InputField/InputField";
import PageBanner from "../../components/page-banner/page-banner";
import Text from "../../components/text/text";
import VideoModal from "../../components/video-modal/video-modal";
import { Colors } from "../../constants";
import BannerImage from "./assets/news-1.png";
import CreateNewsFeed from "./create-news-feed";

export default function NewsFeed({}) {
  const options = [
    { label: "All Posts", Icon: AiFillMessage },
    { label: "Important Posts", Icon: IoMdWarning },
    { label: "Your Posts", Icon: FaUserAlt },
  ];
  const [showVideo, setShowVideo] = useState(false);
  const [showCreateNews, setShowCreateNews] = useState(false);
  const [userLocations, setUserLocations] = useState([{ name: "EFS" }]);
  return (
    <>
      <VideoModal
        onClose={() => setShowVideo(!showVideo)}
        title="Learn how to use QSComply"
        url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        show={showVideo}
      />
      <CreateNewsFeed
        show={showCreateNews}
        onClose={() => setShowCreateNews(!showCreateNews)}
      />
      <Container fluid>
        <div
          className="white-container m-5"
          style={{ padding: "4rem 6rem 4rem 6rem" }}
        >
          <Row>
            <Col>
              <Text fontSize="35px">News Feed</Text>
            </Col>
            <Col className="justify-content-end align-items-center d-flex">
              <Button
                noBorderRadius
                className="px-4"
                onClick={() => setShowCreateNews(!showCreateNews)}
              >
                <Text bold fontSize="12px">
                  Create Post
                </Text>
              </Button>
            </Col>
          </Row>
          <Row>
            <div>
              <PageBanner
                heading="News Feed"
                image={BannerImage}
                paragraph="Location is deputy generally reflects a physical address"
                additionalComponent={
                  <Row>
                    <Col xs="auto" className="p-0">
                      <Button
                        variant="dark_orange"
                        bold
                        noBorderRadius
                        onClick={() => setShowVideo(!showVideo)}
                      >
                        <Text bold fontSize="12px">
                          Watch Video
                        </Text>
                      </Button>
                    </Col>
                  </Row>
                }
              />
            </div>
          </Row>
          <Row className="mt-3">
            <Col xs="12" md="3" className="h-100">
              <div className="blue-container px-5 py-3 h-100 ">
                {options.map(({ label, Icon }) => (
                  <Button
                    variant="no_color"
                    className="p-0 my-2"
                    textColor={Colors.BLUE}
                    startIcon={
                      <Icon size={23} style={{ marginRight: "5px" }} />
                    }
                  >
                    {label}
                  </Button>
                ))}
                <Text fontSize="20px" className="mt-3">
                  Locations
                </Text>
                {userLocations?.map(({ name }) => (
                  <Button className="p-0" variant="no_color">
                    {name}
                  </Button>
                ))}
              </div>
            </Col>
            <Col xs="12" md="9">
              <InputField
                backgroundColor={Colors["cyan-12"]}
                placeHolder="Type to search"
                startIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
              />
              <div className="blue-container p-3">
                <Text className="mb-3" bold>
                  Ways to keep in touch
                </Text>
                <Text>
                  To help you get started, here are some common examples shared
                  on the news feed.
                </Text>
                <div className="mt-2">
                  <Text>
                    <>
                      <MdOutlineMessage /> Hi Team, Please add your availability
                      and any leave you have planned into QSComply by today so I
                      can plan your schedules.
                    </>
                  </Text>
                  <Text className="mt-3">
                    <>
                      <MdOutlineMessage /> Please note that there are a couple
                      of changes to our COVID Safe requirements. I've attached
                      the current COVID safe plan, please review it when you
                      have a moment
                    </>
                  </Text>
                </div>
                <Button
                  noBorderRadius
                  className="mt-3"
                  onClick={() => setShowCreateNews(!showCreateNews)}
                >
                  Create a Post
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
