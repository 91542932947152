import React from "react";
import {
  ButtonGroup as BTButtonGroup,
  ButtonGroupProps,
} from "react-bootstrap";
import Button, { ButtonProps, variants } from "./Button";

interface IButtonGroup extends ButtonGroupProps {
  options: Array<{
    label: React.ReactElement | string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    buttonProps?: ButtonProps;
  }>;
  buttonProps?: ButtonProps;
  variant?: variants;
  buttonStyle?: ButtonProps["style"];
}
const ButtonGroup = React.forwardRef<HTMLDivElement, IButtonGroup>(
  ({ options, buttonProps, buttonStyle, variant, ...restOfProps }, ref) => {
    const numOfButtons = options?.length;
    return (
      <BTButtonGroup ref={ref} {...restOfProps}>
        {options?.map((option, i) => (
          <Button
            key={i}
            variant={
              option.buttonProps?.variant || buttonProps?.variant || variant
            }
            noBorderRadius={false}
            borderRadius={
              numOfButtons === 1
                ? "5px"
                : i === 0
                ? "5px 0px 0px 5px"
                : i === numOfButtons - 1
                ? "0px 5px 5px 0px"
                : "0px"
            }
            style={{ ...buttonStyle, height: "100%" }}
            {...buttonProps}
            onClick={option?.onClick}
          >
            {option.label}
          </Button>
        ))}
      </BTButtonGroup>
    );
  }
);

export default ButtonGroup;
