import { StylesConfig } from "react-select";
import { Colors } from "../../constants";

const SelectTheme: StylesConfig = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? Colors.BLUE : Colors.LIGHT_GREY,
    "&:hover": {
      borderColor: Colors.BLUE,
    },
    boxShadow: state.isFocused ? `0 0 0 1px ${Colors.BLUE}` : "none",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? Colors.BLUE : "transparent",
    "&:hover": {
      backgroundColor: Colors["cyan-12"],
      color: "black",
    },
  }),
};
export default SelectTheme;
