import moment from "moment-timezone";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { InputField } from "../../../components/InputField/InputField";
import Text from "../../../components/text/text";
import { Colors, mapKey } from "../../../constants";
import Button from "./../../../components/button/Button";
import GoogleMapReact from "google-map-react";
import { MouseEventHandler } from "react";
import { GrClose } from "react-icons/gr";

interface INewLocation {
  show: boolean;
  onClose: () => void;
}
export default function NewLocation({ onClose, show }: INewLocation) {
  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={onClose}
      contentClassName="py-2"
    >
      <Modal.Header closeButton>
        {/* <Button bold variant="no_color" onClick={onClose}>
          <GrClose size={20} />
        </Button> */}
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs="12" md="6">
              <Text>New Location</Text>
              <Text bold fontSize="20px">
                Add New Location
              </Text>
              <Text>
                A Location is a place where business operations take place.
              </Text>
              <InputField
                label="Location Name"
                backgroundColor={Colors["cyan-6"]}
                borderRadius="25px"
                fontSize="15px"
              />
              <InputField
                label="Location Address"
                backgroundColor={Colors["cyan-6"]}
                borderRadius="25px"
                fontSize="15px"
              />
              <InputField
                inputClassName="new-location-input"
                label="This Location's Week Starts On"
                backgroundColor={Colors["cyan-6"]}
                borderRadius="25px"
                fontSize="15px"
              />
              <Row className="align-items-center">
                <Col xs="auto">
                  <Text>{`Looks like your timezone is ${moment.tz.guess()}.`}</Text>
                </Col>
                <Col xs="auto">
                  <Button
                    textColor={Colors.HIGHLIGHTED}
                    fontSize="12px"
                    variant="no_color"
                  >
                    Change
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="6">
              <div style={{ height: "100%", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: mapKey,
                  }}
                  defaultCenter={{ lat: -33.86882, lng: 151.20929 }}
                  defaultZoom={15}
                ></GoogleMapReact>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end mt-3">
            <Col xs="12" md="auto">
              <Button variant="blue_color" fullWidth>
                Back
              </Button>
            </Col>
            <Col xs="12" md="auto">
              <Button variant="blue_color" fullWidth>
                Next
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div
          style={{
            width: 15,
            height: 15,
            background: Colors.HIGHLIGHTED,
            borderRadius: 7,
          }}
        ></div>
        <div
          style={{
            width: 15,
            height: 15,
            background: Colors.HEX_TO_RGBA(Colors.HIGHLIGHTED, 0.2),
            borderRadius: 7,
          }}
        ></div>
        <div
          style={{
            width: 15,
            height: 15,
            background: Colors.HEX_TO_RGBA(Colors.HIGHLIGHTED, 0.2),
            borderRadius: 7,
          }}
        ></div>
      </Modal.Footer>
    </Modal>
  );
}
