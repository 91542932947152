import { AxiosError } from "axios";
import axiosInstance from "../api/axiosInstance";
import { checkRequiredFields } from "../api/general";
export interface IBusiness {
  id: number;
  businessName: string;
  active?: boolean;
  numberOfPeople?: number;
  numberOfLocations?: number;
  logoPath: string;
  branding: null | string;
  abn?: string;
  acn?: string;
  address?: string;
  city?: string;
  contactEmail?: string;
  contactEmailVerified?: boolean;
  contactName?: string;
  contactPhone?: string;
  contactPhoneVerified?: boolean;
  country?: string;
  postCode?: string;
  website?: string;
  displayPreferredPronouns?: boolean;
  displayGender?: boolean;
  customBranding?: string;
  leaveAllowBalanceView?: boolean;
  leaveAllowUpdate?: boolean;
}
module Business {
  const requiredCreateKeys = [
    { key: "businessName", label: "Business Name" },
    { key: "address", label: "Address" },
    { key: "city", label: "City" },
    { key: "country", label: "Country" },
    { key: "postCode", label: "Postcode" },
    { key: "contactName", label: "Contact Name" },
    { key: "contactPhone", label: "Contact Phone" },
    { key: "contactEmail", label: "Contact Email" },
    { key: "abn", label: "ABN" },
    { key: "acn", label: "ACN" },
    { key: "website", label: "Website" },
  ];
  export function FetchBusinesses(
    success: (data: Array<IBusiness>) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get("/comply-tsfm/api/business")
      .then(({ data }) => success(data))
      .catch(error);
  }
  export function FetchBusinessById(
    id: number,
    success: (data: IBusiness) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get(`/comply-tsfm/api/business/${id}`)
      .then(({ data }) => success(data))
      .catch(error);
  }
  export function CreateBusiness(
    dataToBeUpdated: IBusiness,
    success: (data: IBusiness) => void,
    error: (error: AxiosError) => void,
    missingData: (missingData: Array<string>) => void
  ) {
    const fieldCheck = checkRequiredFields(requiredCreateKeys, dataToBeUpdated);

    if (fieldCheck.allFilled) {
      axiosInstance
        .post("/comply-tsfm/api/business/", dataToBeUpdated)
        .then(({ data }) => success(data))
        .catch(error);
    } else {
      missingData(fieldCheck.missingekeys);
    }
  }
  export function UpdateBusiness(
    id: number,
    dataToBeUpdated: IBusiness,
    success: (data: IBusiness) => void,
    error: (error: AxiosError) => void,
    missingData: (missingData: Array<string>) => void
  ) {
    const fieldCheck = checkRequiredFields(requiredCreateKeys, dataToBeUpdated);

    if (fieldCheck.allFilled) {
      axiosInstance
        .put(`/comply-tsfm/api/business/${id}`, dataToBeUpdated)
        .then(({ data }) => success(data))
        .catch(error);
    } else {
      missingData(fieldCheck.missingekeys);
    }
  }
}
export default Business;
