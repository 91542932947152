import React from "react";
import { Colors } from "../../constants";
export interface IText {
  children?: React.ReactChild[] | React.ReactChild | string | undefined;
  fontSize?: string | number;
  color?: string;
  bold?: boolean;
  rightAlign?: boolean;
  style?: React.CSSProperties;
  dark?: boolean;
  light?: boolean;
  heading?: boolean;
  responsive?: boolean;
  className?: string;
  highlighted?: boolean;
  icon?: any;
  centered?: boolean;
}

const Text = React.forwardRef<HTMLDivElement, IText>(
  (
    {
      children,
      icon,
      bold,
      color,
      fontSize,
      rightAlign,
      style,
      dark,
      light,
      responsive,
      heading,
      className,
      highlighted,
      centered,
    },
    ref
  ) => (
    <div
      ref={ref}
      className={className}
      style={{
        fontSize: fontSize
          ? fontSize
          : responsive
          ? heading
            ? "2vw"
            : "0.80vw"
          : heading
          ? "18px"
          : "12px",
        color:
          color &&
          (dark
            ? Colors.HEX_TO_RGBA(color)
            : light
            ? Colors.HEX_TO_RGBA(color, 0.5)
            : highlighted
            ? Colors.HIGHLIGHTED
            : color),
        textAlign: centered ? "center" : rightAlign ? "right" : "left",
        fontWeight: bold ? "bold" : "normal",
        wordWrap: "break-word",
        ...style,
      }}
    >
      {icon && <span className="me-1">{icon}</span>}
      {children}
    </div>
  )
);

export default Text;
