import moment from "moment";
import { Container } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";

interface TimesheetTableProps {
  tableHeight: number;
}
export default function HistoryTab({ tableHeight }: TimesheetTableProps) {
  const columns: ColumnDescription<any, any>[] = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "user",
      text: "User",
    },
    {
      dataField: "field",
      text: "Field",
    },
    {
      dataField: "oldValue",
      text: "Old Value",
    },
    {
      dataField: "newValue",
      text: "New Value",
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row) => {
        const date = moment.unix(Number(cell));
        return `${date.fromNow()} at ${date.format("hh:mm A")}`;
      },
    },
  ];
  const data = Array.from({ length: 30 }).map((_, index) => ({
    id: index,
    user: "John Doe",
    field: "Status",
    oldValue: "Pending",
    newValue: "Approved",
    date: "1654407807",
  }));
  return (
    <div
      style={{ height: (tableHeight || 500) - 100 }}
      className="overflow-auto"
    >
      <BootstrapTable
        condensed
        bordered={false}
        striped
        hover
        columns={columns}
        data={data}
        keyField="id"
        headerClasses="shadow-sm position-sticky"
      />
    </div>
  );
}
