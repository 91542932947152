import { Badge, Button, ListGroup, Modal } from "react-bootstrap";
import SelectTheme from "../../../components-v2/Select/select-theme";
import Text from "../../../components/text/text";
import EmployeeMainModalProps from "./modal-common.props";
import Select from "react-select";
import { TiInfo } from "react-icons/ti";
import { useState } from "react";
import { MdDelete } from "react-icons/md";

export default function SetPayRatesModal({
  onHide,
  show,
}: EmployeeMainModalProps) {
  const [showLeaveDropdown, setShowLeaveDropdown] = useState(false);
  const [leaveEntitlements, setLeaveEntitlements] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Text fontSize={20}>Set Pay Rates</Text>
      </Modal.Header>
      <Modal.Body>
        <Badge
          bg="cyan-12"
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: "15px",
            marginBottom: "10px",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <span style={{ marginRight: 10 }} className="badge-icon-color">
            <TiInfo />
          </span>
          Pay rates help track wage costs and can be exported to ensure team
          members are paid correctly. Select from our pay rates library below.
        </Badge>
        <Text fontSize={16}>Pay Rates</Text>
        <Select
          styles={SelectTheme}
          options={[
            {
              label: "Pay Rates 1",
              value: "Pay Rates 1",
            },
          ]}
        />
        <Text fontSize={16} className="mt-2">
          Leave Entitlements
        </Text>

        <ListGroup className="mb-2">
          {leaveEntitlements.map((item) => (
            <ListGroup.Item
              key={item.value}
              className="d-flex align-items-center justify-content-between p-1"
              style={{ fontSize: "14px" }}
            >
              {item.label}
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  setLeaveEntitlements(
                    leaveEntitlements.filter((i) => i.value !== item.value)
                  );
                }}
              >
                <MdDelete color="white" />
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Button
          variant="blue_color"
          size="sm"
          className="mb-2"
          onClick={() => setShowLeaveDropdown(!showLeaveDropdown)}
        >
          <Text fontSize={11}>Add Leave Entitlements</Text>
        </Button>
        {showLeaveDropdown && (
          <Select
            isSearchable
            styles={SelectTheme}
            options={[
              {
                label: "Leave Entitlements 1",
                value: "Leave Entitlements 1",
              },
              {
                label: "Leave Entitlements 2",
                value: "Leave Entitlements 2",
              },
            ]}
            onChange={(value: any) => {
              setLeaveEntitlements((prev) => {
                const index = prev.findIndex(
                  (item) => item.value === value.value
                );
                if (index > -1) {
                  return prev;
                }
                return [...prev, value];
              });
              setShowLeaveDropdown(false);
            }}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="blue_color" size="sm" onClick={() => onHide()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
