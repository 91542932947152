import { Button, Modal } from "react-bootstrap";
import ScheduleModalProps from "./modal.props";
import Select from "react-select";
import SelectTheme from "../../../../components-v2/Select/select-theme";
import Text from "../../../../components/text/text";
interface SaveShiftTemplateProps extends ScheduleModalProps {
  onCreateNew: Function;
}
export default function LoadTemplate({
  onClose,
  show,
  onCreateNew,
}: SaveShiftTemplateProps) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Load Shift Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select styles={SelectTheme} />
        <Text>
          There is no template saved yet, would you like to{" "}
          <span
            style={{
              cursor: "pointer",
              color: "--blue_color",
            }}
            onClick={() => {
              onClose();
              onCreateNew();
            }}
          >
            create one
          </span>
          ?
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cyan-12" onClick={onClose}>
          Close
        </Button>
        <Button variant="blue_color" onClick={onClose}>
          Load Template
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
