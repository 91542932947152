import { Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import General from "./tabs/general";
import "./business-settings.scss";
import { Colors } from "../../constants";
import Text from "../../components/text/text";
import { MouseEventHandler, useEffect, useState } from "react";
import Button from "../../components/button/Button";
import Profile from "./tabs/profile";
import Branding from "./tabs/branding";
import Schedule from "./tabs/schedule";
import Timesheets from "./tabs/timesheets";
import CustomTerms from "./tabs/custom-terms";
import Leave from "./tabs/leave";
import axiosInstance from "../../api/axiosInstance";
import useModal from "../../hooks/modal";
import { checkRequiredFields } from "../../api/general";
import moment from "moment";
import Business from "../../modules/Business";

interface IBusinessSetting {
  show: boolean;
  onClose: (data?: any) => void;
  modalType?: "Create" | "Update";
  updateId?: number;
  defaultValue?: any;
}
export default function BusinessSettings({
  show,
  onClose,
  modalType = "Create",
  updateId,
  defaultValue,
}: IBusinessSetting) {
  const [activeTab, setActiveTab] = useState<string | null>("general");
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const { showModal } = useModal();
  const [businessSettings, setBusinessSettings] = useState<{
    [key: string]: { data: any };
  }>(
    defaultValue || {
      general: {
        data: {},
      },
      branding: {
        data: {},
      },
    }
  );

  const [generalSettings, setGeneralSettings] = useState<any>({});
  const [brandingSettings, setBrandingSettings] = useState<any>({});

  const TabTitle = ({ title, eventKey }: any) => {
    return (
      <div>
        <Text fontSize="14px" color={activeTab === eventKey ? "black" : "grey"}>
          {title}
        </Text>
      </div>
    );
  };

  const tabs = [
    {
      title: "General",
      eventKey: "general",
      Component: General,
      onUpdateValues: (data: any) => {
        // console.log("Data is ", { ...defaultValue?.general?.data, ...data });
        // setBusinessSettings({
        //   ...businessSettings,
        //   general: {
        //     ...defaultValue?.general?.data,
        //     ...businessSettings?.general,
        //     data,
        //   },
        // });
        setGeneralSettings((prevState: any) => ({ ...prevState, data }));
      },
      defaultValue: defaultValue?.general.data,
    },
    // {
    //   title: "Profile",
    //   eventKey: "profile",
    //   Component: Profile,
    //   onUpdateValues: (data: any) => {
    //     setBusinessSettings({
    //       ...businessSettings,
    //       profile: { ...businessSettings?.profile, data },
    //     });
    //   },
    //   defaultValue: businessSettings?.profile?.data,
    // },
    {
      title: "Branding",
      eventKey: "branding",
      Component: Branding,
      onUpdateValues: (data: any) => {
        // setBusinessSettings({
        //   ...businessSettings,
        //   branding: {
        //     ...defaultValue?.branding?.data,
        //     ...businessSettings?.branding,
        //     data,
        //   },
        // });
        setBrandingSettings((prevState: any) => ({ ...prevState, data }));
      },
      defaultValue: defaultValue?.branding?.data,
    },
    // {
    //   title: "Schedule",
    //   eventKey: "schedule",
    //   Component: Schedule,
    //   onUpdateValues: (data: any) => {
    //     console.log(data);
    //   },
    // },
    // {
    //   title: "Timesheets",
    //   eventKey: "timesheets",
    //   Component: Timesheets,
    //   onUpdateValues: (data: any) => {
    //     console.log(data);
    //   },
    // },
    // {
    //   title: "Custom Terms",
    //   eventKey: "custom-terms",
    //   Component: CustomTerms,
    //   onUpdateValues: (data: any) => {
    //     console.log(data);
    //   },
    // },
    // {
    //   title: "Leave",
    //   eventKey: "leave",
    //   Component: Leave,

    //   onUpdateValues: (data: any) => {
    //     setBusinessSettings({
    //       ...businessSettings,
    //       leave: { ...businessSettings?.leave, data },
    //     });
    //   },
    //   defaultValue: businessSettings?.leave?.data,
    // },
  ];
  const neededKeys = [
    { key: "businessName", label: "Business Name" },
    { key: "address", label: "Address" },
    { key: "city", label: "City" },
    { key: "country", label: "Country" },
    { key: "postCode", label: "Postcode" },
    { key: "contactName", label: "Contact Name" },
    { key: "contactPhone", label: "Contact Phone" },
    { key: "contactEmail", label: "Contact Email" },
    { key: "abn", label: "ABN" },
    { key: "acn", label: "ACN" },
    { key: "website", label: "Website" },
  ];
  function handleCreate() {
    setIsRegistering(true);

    let dataToBeUpdated = {
      ...generalSettings?.data,
      ...brandingSettings?.data,
    };

    Business.CreateBusiness(
      dataToBeUpdated,
      (data) => {
        onClose(data);
        setIsRegistering(false);
      },
      (error) => {
        showModal({ APIType: "APIEXCEPTION", APIError: error });
        setIsRegistering(false);
      },
      (missing) => {
        setIsRegistering(false);
        showModal({
          Component: <Text>Fill required fields: {missing.join(", ")}</Text>,
        });
      }
    );
    // const fieldCheck = checkRequiredFields(neededKeys, dataToBeUpdated);

    // if (fieldCheck.allFilled) {
    //   axiosInstance
    //     .post("/comply-tsfm/api/business/", dataToBeUpdated)
    //     .then(({ data, status }) => {
    //       if (status === 200 || status === 201) {
    // onClose(data);
    //       }
    // setIsRegistering(false);
    //     })
    //     .catch((error) => {
    // showModal({ APIType: "APIEXCEPTION", APIError: error });
    // setIsRegistering(false);
    //     });
    // } else {
    // setIsRegistering(false);
    // showModal({
    //   Component: (
    //     <Text>Fill required fields: {fieldCheck.missingekeys.join(",")}</Text>
    //   ),
    // });
    // }
  }
  function handleUpdate() {
    setIsRegistering(true);
    // console.log({
    //   ...businessSettings?.general?.data,
    //   // ...businessSettings?.profile?.data,
    //   ...businessSettings?.branding?.data,
    //   // ...businessSettings?.leave?.data,
    // });
    let dataToBeUpdated = {
      ...generalSettings?.data,
      ...brandingSettings?.data,
    };

    Business.UpdateBusiness(
      defaultValue?.general?.data?.id,
      dataToBeUpdated,
      (data) => {
        onClose(data);
        setIsRegistering(false);
      },
      (error) => {
        showModal({ APIType: "APIEXCEPTION", APIError: error });
        setIsRegistering(false);
      },
      (missing) => {
        setIsRegistering(false);
        showModal({
          Component: <Text>Fill required fields: {missing.join(", ")}</Text>,
        });
      }
    );

    // console.log("Updating", dataToBeUpdated);

    // const fieldCheck = checkRequiredFields(neededKeys, dataToBeUpdated);

    // if (fieldCheck.allFilled) {
    //   axiosInstance
    //     .put(
    //       `/comply-tsfm/api/business/${defaultValue?.general?.data?.id}`,
    //       dataToBeUpdated
    //     )
    //     .then(({ data, status, request }) => {
    //       if (status === 200 || status === 201) {
    //         onClose(data);
    //       }
    // setIsRegistering(false);
    //     })
    //     .catch((error) => {
    // showModal({ APIType: "APIEXCEPTION", APIError: error });
    // setIsRegistering(false);
    //     });
    // } else {
    // setIsRegistering(false);
    // showModal({
    //   Component: (
    //     <Text>
    //       Fill required fields: {fieldCheck.missingekeys.join(", ")}
    //     </Text>
    //   ),
    // });
    // }
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      contentClassName="buss-setting-modal-bg"
      onHide={onClose}
    >
      <Modal.Body className="pt-2 overflow-hidden business-settings-tabs">
        <Tabs
          onSelect={(k) => setActiveTab(k)}
          style={{ border: "none", overflow: "auto" }}
        >
          {tabs.map(
            (
              { Component, eventKey, title, onUpdateValues, defaultValue },
              i
            ) => (
              <Tab
                key={i}
                className="setting-tab"
                eventKey={eventKey}
                title={<TabTitle title={title} eventKey={eventKey} />}
                tabClassName={`h-100 ${
                  activeTab === eventKey
                    ? "active-setting-tab"
                    : "inactive-setting-tab"
                }`}
              >
                <Component
                  onUpdate={onUpdateValues}
                  defaultValue={defaultValue}
                />
              </Tab>
            )
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button variant="blue_color" onClick={() => onClose()}>
              Close
            </Button>
          </Col>
          <Col>
            <Button
              variant="blue_color"
              onClick={
                !isRegistering
                  ? defaultValue
                    ? handleUpdate
                    : handleCreate
                  : () => {}
              }
            >
              {isRegistering
                ? "Working..."
                : defaultValue
                ? "Update"
                : "Create"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
