import React, { useEffect, useImperativeHandle, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ITimesheet } from "../../../modules/Timesheet";
import CommentsTab from "./bottom-tabs/comments-tab";
import HistoryTab from "./bottom-tabs/history-tab";
import NewTimesheetForm from "./new-timesheet-form";
import TimesheetDetail from "./timesheet-detail";
interface TimesheetBottomTabsProps {
  onTimesheetSubmit: (data: any) => void;
  selectedTimeSheet: ITimesheet;
  resetSelectedTimeSheet: Function;
  bottomTabsHeight: number;
}
export type TimesheetBottomTabsRefProps = {
  resetTimeSheetForm: Function;
};
const TimesheetBottomTabs = React.forwardRef<
  TimesheetBottomTabsRefProps,
  TimesheetBottomTabsProps
>(
  (
    {
      onTimesheetSubmit,
      selectedTimeSheet,
      resetSelectedTimeSheet,
      bottomTabsHeight,
    },
    ref
  ) => {
    const [resetTimeSheetFormCount, setResetTimeSheetFormCount] = useState(0);

    const [activeTab, setActiveTab] = useState("timesheet");

    const [defaultTimesheet, setDefaultTimesheet] = useState<ITimesheet>();

    function resetTimeSheetForm() {
      setResetTimeSheetFormCount(resetTimeSheetFormCount + 1);
      setDefaultTimesheet(undefined);
    }
    useImperativeHandle(ref, () => ({
      resetTimeSheetForm,
    }));

    return (
      <Tabs
        onSelect={(eventKey) => {
          eventKey && setActiveTab(eventKey);
        }}
        activeKey={activeTab}
        defaultActiveKey="timesheet"
        className="mb-3"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Tab eventKey="timesheet" title="Timesheet" className="mx-3">
          {selectedTimeSheet ? (
            <TimesheetDetail
              timesheet={selectedTimeSheet}
              onUnapproveTime={() => {
                setDefaultTimesheet(selectedTimeSheet);

                resetSelectedTimeSheet();
              }}
            />
          ) : (
            <NewTimesheetForm
              key={resetTimeSheetFormCount.toString()}
              defaultValues={defaultTimesheet}
              onTimesheetSubmit={(data) => {
                onTimesheetSubmit(data);
                resetTimeSheetForm();
              }}
            />
          )}
        </Tab>
        <Tab eventKey="history" title="History" className="mx-3 h-100">
          <HistoryTab tableHeight={bottomTabsHeight} />
        </Tab>
        <Tab eventKey="comments" title="Comments" className="mx-3">
          {!selectedTimeSheet && <CommentsTab tableHeight={bottomTabsHeight} />}
        </Tab>
      </Tabs>
    );
  }
);
export default TimesheetBottomTabs;
