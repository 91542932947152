import moment from "moment";
import { MouseEventHandler, useState } from "react";
import {
  Container,
  Modal,
  Col,
  Row,
  Dropdown,
  FormControl,
  Button,
} from "react-bootstrap";
import DateInput from "../../../../components-v2/input/date-input";
import TextInput from "../../../../components-v2/input/input";
import TimeInput from "../../../../components-v2/input/time-input";
// import Button from "../../../components/button/Button";
import DropdownButton from "../../../../components/DropdownMenu/DropdownMenu";
import Text from "../../../../components/text/text";
import { useEmployees } from "../../../../hooks/employee";
import { useLocations } from "../../../../hooks/location";

interface IUpdateBulkShifts {
  show: boolean;
  onClose: () => void;
  numberOfShifts: number;
}
export default function UpdateBulkShifts({
  onClose,
  show,
  numberOfShifts,
}: IUpdateBulkShifts) {
  const [selected, setSelected] = useState<{
    label: string;
    value: string;
  }>();
  const { locations } = useLocations();
  const { employees } = useEmployees();

  const [selectedLocation, setSelectedLocation] = useState<{
    label: string;
    value: number;
  }>();
  const [selectedEmployee, setSelectedEmployee] = useState<{
    label: string;
    value: number;
  }>();
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton>
        <Text fontSize="18px">{`Bulk Update ${numberOfShifts} shifts`}</Text>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0">
          <Row>
            <Col>
              <Text>This changes will apply to all shifts visible</Text>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="auto">
              <Dropdown>
                <Dropdown.Toggle variant="cyan-12" size="sm">
                  {selected?.label || "Select Field"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    {
                      label: "Start Time",
                      value: "startTime",
                      onClick: () => {
                        setSelected({
                          label: "Start Time",
                          value: "startTime",
                        });
                      },
                    },
                    {
                      label: "End Time",
                      value: "endTime",
                      onClick: () => {
                        setSelected({
                          label: "End Time",
                          value: "endTime",
                        });
                      },
                    },
                    {
                      label: "Meal Break",
                      value: "mealBreak",
                      onClick: () => {
                        setSelected({
                          label: "Meal Break",
                          value: "mealBreak",
                        });
                      },
                    },
                    {
                      label: "Rest Break",
                      value: "restBreak",
                      onClick: () => {
                        setSelected({
                          label: "Rest Break",
                          value: "restBreak",
                        });
                      },
                    },
                    {
                      label: "Location",
                      value: "location",
                      onClick: () => {
                        setSelected({
                          label: "Location",
                          value: "location",
                        });
                      },
                    },
                    {
                      label: "Comment",
                      value: "comment",
                      onClick: () => {
                        setSelected({
                          label: "Comment",
                          value: "comment",
                        });
                      },
                    },
                    {
                      label: "Team Member",
                      value: "teamMember",
                      onClick: () => {
                        setSelected({
                          label: "Team Member",
                          value: "teamMember",
                        });
                      },
                    },
                  ].map((op, i) => (
                    <Dropdown.Item key={i} onClick={op.onClick}>
                      {op.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col>
              {(selected?.value === "startTime" ||
                selected?.value === "endTime") && (
                <TimeInput
                  onTimeChanged={() => {}}
                  timepickerProps={{ placeholder: selected.label }}
                />
              )}
              {(selected?.value === "mealBreak" ||
                selected?.value === "restBreak") && (
                <TextInput
                  controlProps={{
                    type: "number",
                  }}
                />
              )}
              {selected?.value === "location" && (
                <Dropdown>
                  <Dropdown.Toggle variant="cyan-12" size="sm">
                    {selectedLocation?.label || "Select Location"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {locations &&
                      locations?.map((loc, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            setSelectedLocation({
                              label: loc.name,
                              value: loc.id,
                            });
                          }}
                        >
                          {loc.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {selected?.value === "comment" && (
                <TextInput
                  placeholder="Comment"
                  controlProps={{
                    as: "textarea",
                  }}
                />
              )}
              {selected?.value === "teamMember" && (
                <Dropdown>
                  <Dropdown.Toggle variant="cyan-12" size="sm">
                    {selectedLocation?.label || "Select Team Member"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {employees &&
                      employees?.map((emp, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            setSelectedEmployee({
                              label: emp.preferredTitle,
                              value: emp.id,
                            });
                          }}
                        >
                          {emp.preferredTitle}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {/* <Container>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button noBorderRadius={false} variant="cyan-6" onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button noBorderRadius={false} variant="blue_color">
                Save
              </Button>
            </Col>
          </Row>
        </Container> */}

        <Button variant="cyan-12" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="blue_color">Update</Button>
      </Modal.Footer>
    </Modal>
  );
}
