import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {localStorageKeys} from "../constants";
import BusinessModule, {IBusiness} from "../modules/Business";
// import { IBusiness } from "../interface";
interface BusinessHookProps extends IBusiness {
  setBusiness: Function;
  businesses: Array<IBusiness>;
}
const BusinessContext = React.createContext<BusinessHookProps>({
  setBusiness: () => {},
  businessName: "",
  id: 0,
  logoPath: "",
  branding: "",
  businesses: [],
});

export function BusinessProfileProvider({children}: any) {
  const [Business, setBusiness] = useState<IBusiness>({
    businessName: "",
    id: 0,
    logoPath: "",
    branding: "",
  });
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);

  useEffect(() => {
    BusinessModule.FetchBusinesses(
      (businesses) => {
        setBusinesses(businesses);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const Business = localStorage.getItem(localStorageKeys.BUSINESS);
    if (Business) {
      setBusiness(JSON.parse(Business));
    } else {
      // navigate("/app/business-selection");
    }
  }, []);

  return (
    <BusinessContext.Provider value={{...Business, setBusiness, businesses}}>
      {children}
    </BusinessContext.Provider>
  );
}

export const useBusinessProfile = () =>
  React.useContext<BusinessHookProps>(BusinessContext);
