import { MouseEventHandler } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Button from "../../components/button/Button";
import Text from "../../components/text/text";

interface IAvailableShifts {
  show: boolean;
  onClose: () => void;
}
export default function AvailableShifts({ onClose, show }: IAvailableShifts) {
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton>
        <Text heading style={{ fontWeight: "500", textAlign: "center" }}>
          Available Shifts
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Text>You have no upcoming shifts.</Text>
              <Text>Feel free to start unscheduled shift.</Text>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button
                onClick={() => onClose()}
                variant="blue_color"
                noBorderRadius={false}
              >
                Done
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
