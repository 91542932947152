import moment from "moment";
import { useState } from "react";
import BootstrapTable, {
  ColumnDescription,
  RowEventHandler,
} from "react-bootstrap-table-next";
import "../timesheet.styles.scss";
export type TimesheetTableProps = Array<{
  date: string;
  status: React.ReactNode;
  progress: string;
  areaOfWork: string;
  time: string;
  hours: string;
  statusText: string;
}>;
interface TimesheetProps {
  data: TimesheetTableProps;
  // onRowEvent?: Partial<{
  //   onClick: RowEventHandler<any>;
  //   onDoubleClick: RowEventHandler<any>;
  //   onMouseEnter: RowEventHandler<any>;
  //   onMouseLeave: RowEventHandler<any>;
  //   onContextMenu: RowEventHandler<any>;
  // }>;
  onSelectRow?: (row: any, isSelect: boolean, rowIndex: number, e: any) => void;
}

export default function TimesheetTable({ data, onSelectRow }: TimesheetProps) {
  const columns: ColumnDescription<any, any>[] = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "progress",
      text: "Progress",
    },
    {
      dataField: "areaOfWork",
      text: "Area of Work",
    },
    {
      dataField: "time",
      text: "Time",
    },
    {
      dataField: "hours",
      text: "Hours",
    },
  ];
  // const Row=()
  return (
    <div style={{ height: "inherit", overflow: "auto" }}>
      <BootstrapTable
        hover
        // condensed
        bordered={false}
        columns={columns}
        keyField="id"
        data={data}
        headerClasses="timesheet-table__header"
        rowClasses="timesheet-table__row"
        // rowEvents={onRowEvent}
        selectRow={{
          mode: "radio",
          clickToSelect: true,

          onSelect: onSelectRow,
          hideSelectColumn: true,
        }}
      />
    </div>
  );
}
