import moment from "moment";
import { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import TextInput from "../../../../components-v2/input/input";
import Text from "../../../../components/text/text";
import { Colors } from "../../../../constants";
import { TimesheetComment } from "../../../../modules/Timesheet";
interface CommentsTabProps {
  tableHeight: number;
}
export default function CommentsTab({ tableHeight }: CommentsTabProps) {
  const [comments, setComments] = useState<Array<TimesheetComment>>(
    Array.from({ length: 20 }).map((_, i) => ({
      comment: "My comment" + i,
      datetime: "1654494774",
      employeeName: "John Doe",
      profilePicture: "https://picsum.photos/200/300",
    }))
  );
  return (
    <Container
      className=" p-3 overflow-auto position-relative"
      style={{ height: (tableHeight || 500) - 100 }}
      fluid
    >
      {comments.map(
        ({ comment, datetime, employeeName, profilePicture }, index) => {
          let datetimeMoment = moment.unix(Number(datetime));
          return (
            <Row key={index} className="my-3">
              <Col xs="auto" className="pe-0">
                {profilePicture ? (
                  <Image
                    src={profilePicture}
                    roundedCircle
                    fluid
                    style={{ height: 35, width: 35 }}
                  />
                ) : (
                  <div
                    className="user-initials p-0 m-0 d-flex justify-content-center align-items-center "
                    style={{ backgroundColor: Colors.BLUE }}
                  >
                    {employeeName.charAt(0)}
                  </div>
                )}
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Text fontSize="14px" bold>
                      Saoud
                    </Text>
                  </Col>
                  <Col xs="auto">
                    <Text>
                      {datetimeMoment.fromNow()} at{" "}
                      {datetimeMoment.format("hh:mm A")}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text fontSize="14px">{comment}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
      )}

      <Row>
        <Col>
          <TextInput
            placeholder="Comments"
            controlProps={{
              as: "textarea",
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-end">
          <Button variant="blue_color" size="sm">
            Submit
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
