import {
  Container,
  Nav,
  Image,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useBusinessProfile } from "../../hooks/business";
import HeaderLogo from "../../assets/logo-horizontal.svg";
import "./header.styles.scss";
import { useEffect, useState } from "react";
import { MdDashboard, MdOutlineLocationCity } from "react-icons/md";
import { BsPersonBadgeFill } from "react-icons/bs";
import {
  FaCalendarAlt,
  FaClipboardCheck,
  FaFilePrescription,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../components/text/text";
import { IoMdArrowDropdown } from "react-icons/io";
import { useUserProfile } from "../../hooks/user";
import { getHeight } from "../../api/components";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { HiSwitchHorizontal } from "react-icons/hi";

function Header({}) {
  const browserLocation = useLocation();
  const business = useBusinessProfile();
  const { title, profileUrl, token } = useUserProfile();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  let topMenu = [
    {
      title: "Dashboard",
      url: "/app/dashboard",
      Icon: MdDashboard,
    },
    // {
    //   title: "News Feed",
    //   url: "/app/feed",
    // },
    // {
    //   title: "Tasks",
    //   url: "",
    // },
    {
      title: "Location",
      url: "/app/location",
      Icon: MdOutlineLocationCity,
    },
    {
      title: "Employee",
      url: "/app/employee",
      Icon: BsPersonBadgeFill,
    },
    {
      title: "Schedule",
      url: "/app/schedule",
      Icon: FaCalendarAlt,
    },
    // {
    //   title: "Timesheet",
    //   url: "/app/timesheet",
    //   Icon: MdAccessTimeFilled,
    //   isDropDown: true,
    //   subMenu: [
    //     { title: "Approve Timesheet", url: "/app/timesheet" },
    //     { title: "Export Timesheet", url: "/app/export-timesheet" },
    //   ],
    // },
    {
      title: "Reports",
      url: "/app/report",
      Icon: FaFilePrescription,
      callback: undefined,
    },
    {
      title: "Audit",
      url: "#",
      Icon: FaClipboardCheck,
      callback: () => {
        // window.location.href = `${process.env.REACT_APP_AUDITOR_URL}/app/?token=${token}&business=${business.id}`;
        window.open(
          `${process.env.REACT_APP_AUDITOR_URL}/?token=${token}&business=${business.id}`
        );
      },
    },
  ];

  const [shrinkNavbar, setShrinkNavbar] = useState(false);

  useEffect(() => {
    function scrollLisenter() {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > getHeight("navbar")) {
        setShrinkNavbar(true);
      } else {
        setShrinkNavbar(false);
      }
    }
    window.addEventListener("scroll", scrollLisenter);
    return () => {
      window.removeEventListener("scroll", scrollLisenter);
    };
  }, []);

  return (
    <Navbar
      expand="md"
      fixed="top"
      className="shadow-sm p-0 "
      bg="white"
      expanded={isSidebarOpen}
    >
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={business.logoPath || HeaderLogo}
            style={{
              height: "55px",
              objectFit: "cover",
              aspectRatio: "auto",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header
            closeButton
            onHide={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <Offcanvas.Title>
              <ProfileButton />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="justify-content-between align-items-center">
            <Nav className="justify-content-center" style={{ flex: 1 }}>
              {topMenu.map(({ title, url, Icon, callback }, index) => (
                <Nav.Link
                  key={index}
                  href={url}
                  className={`nav-option ${
                    browserLocation.pathname === url ? "active" : ""
                  }`}
                  onClick={callback}
                >
                  <Icon className="nav-option-icon" size={20} />

                  <div
                    className={`nav-option-text ${
                      isSidebarOpen
                        ? ""
                        : shrinkNavbar
                        ? "magic-nav-text scrolled"
                        : "magic-nav-text unscrolled"
                    }`}
                  >
                    {title}
                  </div>
                </Nav.Link>
              ))}
            </Nav>

            {!isSidebarOpen && <ProfileButton />}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;

function ProfileButton({}) {
  const { title, profileUrl } = useUserProfile();
  const business = useBusinessProfile();
  const navigate = useNavigate();
  return (
    <OverlayTrigger
      rootClose
      placement="bottom"
      trigger="click"
      overlay={
        <Popover>
          <div className="white-container">
            <Container>
              <Row>
                <Col>
                  <Text fontSize="1.2vw" color="grey">
                    {title}
                  </Text>
                </Col>
              </Row>
              {/* <Row>
                <Col xs="auto" className="me-0 pe-0">
                  <TiClipboard />
                </Col>
                <Col>
                  <Text fontSize="14px">
                    Account Overview{" "}
                    <div style={{ fontSize: "12px" }}>
                      Edit your global profile and manage billing.
                    </div>
                  </Text>
                </Col>
              </Row> */}
              {business.businessName && (
                <Row className="mt-2">
                  <Col>
                    <Text>{business.businessName}</Text>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col>
                  <Link to="/app/profile" className="popover-button">
                    <AiOutlineUser /> Profile
                  </Link>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Link to="/app/business-selection" className="popover-button">
                    <HiSwitchHorizontal /> Switch Business
                  </Link>
                </Col>
              </Row>
              {/* <Row className="mt-2">
                <Col>
                  <Link to="/app/business-selection" className="popover-button">
                    <CgLink /> Integrations
                  </Link>
                </Col>
              </Row> */}
              {/* <Dropdown.Divider /> */}

              <Row className="mt-2">
                <Col>
                  <Button
                    size="sm"
                    className="w-100"
                    onClick={() => {
                      localStorage.removeItem("user");
                      localStorage.removeItem("business");
                      navigate("/");
                    }}
                    variant="cyan-6"
                  >
                    Logout
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </Popover>
      }
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          cursor: "pointer",
        }}
      >
        <div className="me-2">
          {profileUrl ? (
            <Image
              src={profileUrl}
              style={{ height: 30, width: 30 }}
              roundedCircle
            />
          ) : (
            <div
              className="user-initials p-0 m-0 d-flex justify-content-center align-items-center "
              style={{ height: 30, width: 30 }}
            >
              {title && title.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <Text fontSize="14px" color="grey">
          Hello, {title.split(" ")[0]}
          <IoMdArrowDropdown />
        </Text>
      </div>
    </OverlayTrigger>
  );
}
