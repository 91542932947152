import moment, { Moment } from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { CalenderMode } from "../../../../components/calendar-button/calendar-button";
import { useLocations } from "../../../../hooks/location";
import ShiftItem from "./shift-item";
import "./area-schedule.scss";
import Text from "../../../../components/text/text";
import Shifts, { IShift } from "../../../../modules/Shifts";

interface AreaScheduleDayProps {
  timeperiod: {
    startDate: Moment;
    endDate: Moment;
  };
  shifts: {
    [key: number]: {
      [key: number]: { [key: string]: Array<Shifts.ShiftPayload> };
    };
  };
  onAddClicked: (
    date: Moment,
    location: { id: number; name: string },
    area: { id: number; name: string }
  ) => void;
  onEditClicked: (date: Moment, shift: Shifts.ShiftPayload) => void;
  onCopyClicked: (shift: Shifts.ShiftPayload) => void;
}
export default function AreaScheduleDay({
  timeperiod,
  shifts = [],
  onAddClicked,
  onCopyClicked,
  onEditClicked,
}: AreaScheduleDayProps) {
  const { locations } = useLocations();
  const [open, setOpen] = useState<{
    [locationId: number]: { isOpen: boolean; [areaId: number]: boolean };
  }>({});

  const dayHeaderRef = useRef<HTMLDivElement>(null);

  const dayFormat = "dddd";

  const isToday = moment().isSame(timeperiod.startDate, "day");
  const [hours, setHours] = useState<Array<Moment>>([]);
  useEffect(() => {
    if (timeperiod) {
      let hours: Array<Moment> = [];
      for (let i = 0; i < 24; i++) {
        hours.push(timeperiod.startDate.clone().hour(i).minute(0).second(0));
      }
      setHours(hours);
    }
  }, [timeperiod]);

  const hoursRowRef = useRef<HTMLDivElement>(null);
  const hourColumnWidth = (hoursRowRef.current?.offsetWidth || 0) / (24 * 60);

  const mainContainerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (mainContainerRef.current) {
  //     mainContainerRef.current.addEventListener("scroll", () => {
  //       if (
  //         mainContainerRef?.current &&
  //         mainContainerRef?.current.scrollTop > 60
  //       ) {
  //         hoursRowRef.current?.classList.add("position-fixed");
  //       } else {
  //         hoursRowRef.current?.classList.remove("position-fixed");
  //       }
  //     });
  //   }
  // }, [mainContainerRef.current]);

  const PLUS_ICON_HEIGHT = 25;
  const SHIFT_ITEM_HEIGHT = 35;

  return (
    <Container
      fluid
      className="border-start border-end border-bottom overflow-auto position-relative"
      style={{ height: "93%" }}
      ref={mainContainerRef}
    >
      <Row
        ref={dayHeaderRef}
        className="flex-nowrap "
        style={{ zIndex: 1, backgroundColor: "white" }}
      >
        <Col className="">
          <div
            className="area-schedule-weekly-days-header"
            style={{
              borderBottom: `2px solid ${
                isToday ? "var(--bs-blue_color)" : "grey"
              } `,
              color: isToday ? "var(--bs-blue_color)" : "grey",
              fontSize: "16px",
            }}
          >
            {timeperiod.startDate.format(dayFormat)}{" "}
            <span
              style={{
                fontSize: "12px",
              }}
            >
              {timeperiod.startDate.format("Do")}
            </span>
          </div>

          {/* <div key="Day-Schedule-Row-Fragment" ref={hoursRowRef}>
            <hr
              style={{
                marginBottom: "0px",
              }}
            /> */}
          <Row className="flex-nowrap" ref={hoursRowRef}>
            {hours.map((hour, hourIndex) => (
              <Col key={hourIndex}>
                <div className="vr"></div>
                <Text>{hour.format("HH:mm")}</Text>
              </Col>
            ))}
          </Row>
          {/* </div> */}
        </Col>
      </Row>
      <div>
        {locations?.map((location, locationIndex) => (
          <Row className="border-bottom " key={locationIndex}>
            <Col>
              <div
                className={`area-schedule-collapse-container-title py-2 `}
                onClick={() => {
                  setOpen({
                    ...open,
                    [location.id]: {
                      ...open[location.id],
                      isOpen: !open[location.id]?.isOpen,
                    },
                  });
                }}
              >
                <span>
                  {open[location.id]?.isOpen ?? true ? (
                    <IoIosArrowDown />
                  ) : (
                    <IoIosArrowForward />
                  )}
                </span>
                {location.name}
              </div>
              <Collapse
                in={open[location.id]?.isOpen ?? true}
                className="area-schedule-collapse-container"
              >
                <div>
                  {location !== undefined ? (
                    location.areas?.map((area, areaIndex) => (
                      <Fragment key={areaIndex}>
                        <div
                          className="area-schedule-collapse-container-area-title px-2 mt-2"
                          onClick={() => {
                            setOpen({
                              ...open,
                              [location.id]: {
                                ...open[location.id],

                                [area.id]: !open[location.id]?.[area.id],
                              },
                            });
                          }}
                        >
                          <span>
                            {open[location.id]?.[area.id] ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowForward />
                            )}
                          </span>
                          {area.name} -{" "}
                          <span style={{ fontSize: 12 }}>{location.name}</span>
                        </div>
                        <Collapse in={open[location.id]?.[area.id] ?? true}>
                          <Row
                            className={`
                          flex-nowrap
                          ${
                            areaIndex < (location?.areas?.length || 0) - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          >
                            <Col
                              className="position-relative"
                              style={{
                                // width: `${hourColumnWidth * 60}px`,
                                minHeight:
                                  (shifts[location.id]?.[area.id]?.[
                                    timeperiod.startDate.format("YYYY-MM-DD")
                                  ]?.length ?? 0) *
                                    SHIFT_ITEM_HEIGHT +
                                  PLUS_ICON_HEIGHT +
                                  10, // Number of shift * SHIFT_ITEM_HEIGHT + PLUS_ICON_HEIGHT + extra padding
                              }}
                            >
                              {shifts[location.id]?.[area.id]?.[
                                timeperiod.startDate.format("YYYY-MM-DD")
                              ]?.map((shiftObj, shiftIndex) => {
                                const startTimeMoment = moment.unix(
                                  Number(shiftObj.startTime)
                                );
                                const endTimeMoment = moment.unix(
                                  Number(shiftObj.endTime)
                                );
                                const minutes = endTimeMoment.diff(
                                  startTimeMoment,
                                  "minutes"
                                );
                                const skippedMinutes = startTimeMoment.diff(
                                  timeperiod.startDate.clone().startOf("day"),
                                  "minutes"
                                );

                                return (
                                  <div
                                    className="position-absolute"
                                    style={{
                                      width: `${minutes * hourColumnWidth}px`,
                                      left: `${
                                        skippedMinutes * hourColumnWidth + 12
                                      }px`,
                                      top: `${
                                        shiftIndex * SHIFT_ITEM_HEIGHT +
                                        PLUS_ICON_HEIGHT
                                      }px`,
                                    }}
                                  >
                                    <ShiftItem
                                      scheduleMode="Day"
                                      startTime={startTimeMoment}
                                      endTime={endTimeMoment}
                                      employeeName={shiftObj.employee?.employee}
                                      onCopyClicked={() =>
                                        onCopyClicked(shiftObj)
                                      }
                                      onEditClicked={() =>
                                        onEditClicked(
                                          timeperiod.startDate,
                                          shiftObj
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                              <div
                                className="d-flex justify-content-center p-1 area-schedule-plus-icon my-1"
                                onClick={() => {
                                  onAddClicked(
                                    timeperiod.startDate.clone(),
                                    { id: location.id, name: location.name },
                                    { id: area.id, name: area.name }
                                  );
                                }}
                              >
                                <GoPlus size={12} className="" />
                              </div>
                            </Col>
                          </Row>
                        </Collapse>
                      </Fragment>
                    ))
                  ) : (
                    <div>No Areas Found</div>
                  )}
                </div>
              </Collapse>
            </Col>
          </Row>
        ))}
      </div>
    </Container>
  );
}
