import { Modal, Container, Row, Col } from "react-bootstrap";
import Text from "./../../components/text/text";
import Button from "./../../components/button/Button";
import Select from "./../../components/select/select";
import { MouseEventHandler } from "react";
interface IUnscheduledShift {
  show: boolean;
  onClose: () => void;
}
export default function UnscheduledShift({ onClose, show }: IUnscheduledShift) {
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton>
        <Text heading style={{ fontWeight: "500", textAlign: "center" }}>
          Start Unscheduled Shift
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Select placeholder="Select an area" />
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button
                onClick={onClose}
                variant="no_color"
                backgroundColor="lightgrey"
                noBorderRadius={false}
              >
                Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button noBorderRadius={false} variant="blue_color">
                Start Shift
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
