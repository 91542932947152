import { Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Text from "../../components/text/text";
import Button from "../../components/button/Button";

import { useState } from "react";
import PageBanner from "../../components/page-banner/page-banner";
import { Colors } from "../../constants";
import axiosInstance from "../../api/axiosInstance";
import { checkRequiredFields, removeEmptyOrNull } from "../../api/general";
import useModal from "../../hooks/modal";
import EmployeeInfo from "./new-empoyee-tabs/employee-info";
import ContractorInfo from "./new-empoyee-tabs/contractor-info";
import "./employee.scss";

interface INewEmployee {
  show: boolean;
  onClose: Function;
  defaultValue?: any;
}

export default function NewEmployee({
  onClose,
  show,
  defaultValue,
}: INewEmployee) {
  const { showModal, closeModal } = useModal();

  function handleSubmit() {
    const neededKeys = [
      { label: "First name", key: "firstName" },
      { label: "Last name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Address", key: "address" },
      { label: "Position", key: "position" },
      { label: "Date of birth", key: "dateOfBirth" },
      { label: "Start date", key: "startDate" },
      // { label: "End date", key: "endDate" },
      // { label: "TFN", key: "tfn" },
      // { label: "ABN", key: "abn" },
      { label: "Access", key: "access" },
      { label: "Fully trained", key: "fullyTrained" },
      { label: "Contact Number", key: "phone" },
      { label: "Modules", key: "modules" },
    ];

    const fields = checkRequiredFields(
      neededKeys,
      removeEmptyOrNull({
        ...employeeDetails?.contractorInfo,
        ...employeeDetails?.employeeInfo,
      })
    );

    if (fields.allFilled) {
      axiosInstance
        .post(
          "/comply-tsfm/api/employee",
          removeEmptyOrNull({
            ...employeeDetails?.contractorInfo,
            ...employeeDetails?.employeeInfo,
          })
        )
        .then(({ data, status }) => {
          if (status === 200) {
            setEmployeeDetails(undefined);

            onClose();
          }
        })
        .catch((error) => {
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    } else {
      showModal({
        Component: <Text>Please fill: {fields.missingekeys.join(", ")}</Text>,
      });
    }
  }
  function handleUpdate() {
    const neededKeys = [
      { label: "First name", key: "firstName" },
      { label: "Last name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Address", key: "address" },
      { label: "Position", key: "position" },
      { label: "Date of birth", key: "dateOfBirth" },
      { label: "Start date", key: "startDate" },
      // { label: "End date", key: "endDate" },
      // { label: "TFN", key: "tfn" },
      // { label: "ABN", key: "abn" },
      { label: "Access", key: "access" },
      { label: "Fully trained", key: "fullyTrained" },
      { label: "Contact Number", key: "phone" },
      { label: "Modules", key: "modules" },
    ];
    // console.log(
    //   removeEmptyOrNull({
    //     ...defaultValue?.contractorInfo,
    //     ...defaultValue?.employeeInfo,
    //     ...employeeDetails?.contractorInfo,
    //     ...employeeDetails?.employeeInfo,
    //   })
    // );
    const fields = checkRequiredFields(
      neededKeys,
      removeEmptyOrNull({
        ...defaultValue?.contractorInfo,
        ...defaultValue?.employeeInfo,
        ...employeeDetails?.contractorInfo,
        ...employeeDetails?.employeeInfo,
      })
    );

    if (fields.allFilled && defaultValue?.employeeInfo?.id) {
      axiosInstance
        .put(
          `/comply-tsfm/api/employee/${defaultValue?.employeeInfo?.id}`,
          removeEmptyOrNull({
            ...defaultValue?.contractorInfo,
            ...defaultValue?.employeeInfo,
            ...employeeDetails?.contractorInfo,
            ...employeeDetails?.employeeInfo,
          })
        )
        .then(({ data, status, request }) => {
          if (status === 200) {
            setEmployeeDetails(undefined);

            onClose();
          }
        })
        .catch((error) => {
          showModal({ APIError: error, APIType: "APIEXCEPTION" });
        });
    } else {
      showModal({
        Component: <Text>Please fill: {fields.missingekeys.join(", ")}</Text>,
      });
    }
  }

  function handleDelete(id: number) {
    axiosInstance
      .delete(`/comply-tsfm/api/employee/${id}`)
      .then(({ data, status }) => {
        if (data) {
          showModal({
            Component: (
              <Text heading bold>
                Deleted Employee Successfully
              </Text>
            ),
            FooterComponent: (
              <Button
                onClick={() => {
                  closeModal();
                  onClose();
                }}
              >
                Close
              </Button>
            ),
          });
        }
      })
      .catch((error) => {
        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }

  const [employeeDetails, setEmployeeDetails] =
    useState<{ employeeInfo?: any; contractorInfo?: any }>();
  const [activeTab, setActiveTab] = useState<string | null>("new-employee");

  const tabs = [
    // {
    //   title: "Login Information",
    //   eventKey: "login-info",
    //   Component: LoginInfo,
    //   onUpdateValues: (data: any) => {
    //     setEmployeeDetails({
    //       ...employeeDetails,
    //       loginInfo: data,
    //     });
    //   },
    //   defaultValue: employeeDetails?.loginInfo,
    // },
    {
      title: "Add new employee",
      eventKey: "new-employee",
      Component: EmployeeInfo,
      onUpdateValues: (data: any) => {
        setEmployeeDetails({
          ...employeeDetails,
          employeeInfo: data,
        });
      },
      defaultValue: defaultValue?.employeeInfo || employeeDetails?.employeeInfo,
    },
    {
      title: "Contractor",
      eventKey: "contractor",
      Component: ContractorInfo,
      onUpdateValues: (data: any) => {
        setEmployeeDetails({
          ...employeeDetails,
          contractorInfo: data,
        });
      },
      defaultValue:
        defaultValue?.contractorInfo || employeeDetails?.contractorInfo,
    },
  ];

  return (
    <Modal
      show={show}
      onHide={() => {
        setEmployeeDetails(undefined);
        onClose();
      }}
      size="xl"
      centered
    >
      <Modal.Body>
        <Container style={{ backgroundColor: "white" }}>
          <Row className="">
            <PageBanner
              heading="Invite with a unique link"
              paragraph="Share the unique link below to get your team onto your QSCOMPLY workplace faster. To keep things secured, manager has to approve the request."
              additionalComponent={
                <Container fluid className="p-0">
                  <Row>
                    <Col xs="auto">
                      <Button children="Generate Link" />
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="no_color"
                        textColor="white"
                        // backgroundColor="transparent"
                        children="How invite Links work"
                      />
                    </Col>
                  </Row>
                </Container>
              }
              additionalComponentWrapperStyle={{ padding: 0 }}
            />
          </Row>
          <Row className="mt-4">
            <Tabs
              onSelect={(k) => setActiveTab(k)}
              style={{ border: "none", overflow: "auto" }}
              className="employee-tabs"
            >
              {tabs.map(
                (
                  { Component, eventKey, title, onUpdateValues, defaultValue },
                  i
                ) => (
                  <Tab
                    key={i}
                    className="setting-tab"
                    eventKey={eventKey}
                    title={<TabTitle title={title} eventKey={eventKey} />}
                    tabClassName={`h-100 ${
                      activeTab === eventKey
                        ? "employee-active-tab"
                        : "employee-inactive-tab"
                    }`}
                  >
                    <Component
                      onUpdate={onUpdateValues}
                      defaultValue={defaultValue}
                    />
                  </Tab>
                )
              )}
            </Tabs>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {defaultValue && (
          <Button
            variant="danger"
            noBorderRadius={false}
            onClick={() => {
              showModal({
                Component: (
                  <Text
                    heading
                    bold
                  >{`Do you really want to delete "${defaultValue?.employeeInfo?.firstName} ${defaultValue?.employeeInfo?.lastName}"?`}</Text>
                ),
                FooterComponent: (
                  <Container>
                    <Row className="justify-content-end">
                      <Col xs="auto">
                        <Button
                          onClick={() => {
                            handleDelete(defaultValue?.employeeInfo?.id);
                          }}
                        >
                          Yes
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button onClick={() => closeModal()}>No</Button>
                      </Col>
                    </Row>
                  </Container>
                ),
              });
            }}
          >
            Delete
          </Button>
        )}
        <Button
          variant="no_color"
          noBorderRadius={false}
          backgroundColor={Colors.OUTLINE}
          onClick={defaultValue ? handleUpdate : handleSubmit}
        >
          {defaultValue ? "Update Employee" : "Add New Employee"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const TabTitle = ({ title, eventKey }: { title: string; eventKey: string }) => {
  return <Text centered>{title}</Text>;
};
