import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Countries from "../../../api/countries";
import { removeEmptyOrNull } from "../../../api/general";
import InputField from "../../../components/InputField/InputField";
import Select from "../../../components/select/select";
import { Colors } from "../../../constants";
interface IAddress {
  defaultValue: any;
  onUpdate: (data: any) => void;
}
export default function Address({ defaultValue, onUpdate }: IAddress) {
  const [country, setCountry] = useState<string>();
  const [city, setCity] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [postCode, setPostCode] = useState<string>();

  useEffect(() => {
    onUpdate({
      ...removeEmptyOrNull(defaultValue),
      ...removeEmptyOrNull({
        country,
        city,
        address,
        postCode,
      }),
    });
  }, [country, city, address, postCode]);
  return (
    <Container className="p-5">
      <Row>
        <Col>
          <InputField
            isAddressInput
            label="Address"
            fontSize="12px"
            inputProps={{ defaultValue: defaultValue?.address }}
            onChangedText={setAddress}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            label="City"
            fontSize="12px"
            inputProps={{ defaultValue: defaultValue?.city }}
            onChangedText={setCity}
          />
        </Col>
        <Col>
          <Select
            label="Country"
            styles={{
              control: (style: any) => ({
                ...style,
                borderRadius: "30px",
                backgroundColor: "whitesmoke",
                fontSize: "15px",
              }),
            }}
            options={Countries}
            defaultValue={
              defaultValue?.country && {
                label: defaultValue?.country,
                value: defaultValue?.country,
              }
            }
            onChange={(value: any) => {
              setCountry(value.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" xs="12">
          <InputField
            label="Postcode"
            fontSize="12px"
            inputProps={{ defaultValue: defaultValue?.postCode }}
            onChangedText={setPostCode}
          />
        </Col>
      </Row>
    </Container>
  );
}
