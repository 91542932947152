import { Moment } from "moment";
import { Fragment } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { GoPlusSmall } from "react-icons/go";
import { IoMdCopy } from "react-icons/io";
import { RiEditFill } from "react-icons/ri";
import { CalenderMode } from "../../../../components/calendar-button/calendar-button";
import Text from "../../../../components/text/text";
import "./area-schedule.scss";

interface ShiftItemProps {
  scheduleMode: CalenderMode;
  startTime: Moment;
  endTime: Moment;
  employeeName?: string;
  onEditClicked: Function;
  onCopyClicked: Function;
}
export default function ShiftItem({
  scheduleMode,
  endTime,
  startTime,
  employeeName,
  onCopyClicked,
  onEditClicked,
}: ShiftItemProps) {
  function OperationsOverlay({}) {
    if (scheduleMode === "Month") {
      return (
        <div
          className="position-absolute schedule-shift-item-overlay d-flex flex-column  justify-content-center"
          style={{
            backgroundColor: "var(--bs-blue_color)",
            padding: "0px 2px",
          }}
        >
          <div className="shift-item-monthly-icon d-flex justify-content-center">
            <GoPlusSmall />
          </div>
          <div
            className="shift-item-monthly-icon d-flex justify-content-center my-1"
            onClick={() => onCopyClicked()}
          >
            <IoMdCopy />
          </div>
          <div
            className="shift-item-monthly-icon d-flex justify-content-center"
            onClick={() => onEditClicked()}
          >
            <RiEditFill />
          </div>
        </div>
      );
    } else {
      return (
        <div className="position-absolute schedule-shift-item-overlay ">
          <div className="schedule-shift-item-overlay-content d-flex justify-content-center">
            {scheduleMode !== "Day" && (
              <div
                className="mx-1 p-1 pointer-cursor"
                onClick={() => onCopyClicked()}
              >
                Copy
              </div>
            )}
            <div
              className="mx-1 p-1 pointer-cursor"
              onClick={() => onEditClicked()}
            >
              Edit
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div
      className={`schedule-shift-item p-1 my-1 ${
        scheduleMode === "Day" ? "d-flex flex-row justify-content-between" : ""
      }`}
      style={{
        height: scheduleMode === "Month" ? "70px" : "auto",
        border: `1px solid ${
          scheduleMode === "Month" ? "var(--bs-blue_color)" : "black"
        }`,
        backgroundColor: scheduleMode === "Month" ? "rgba(0,0,0,0.1)" : "white",
      }}
    >
      <OperationsOverlay />
      {scheduleMode === "Month" || scheduleMode === "2 Weeks" ? (
        <Fragment>
          <Text fontSize={8} bold>
            {startTime.format("h:mma")}
          </Text>
          <Text fontSize={8} bold>
            {endTime.format("h:mma")}
          </Text>
        </Fragment>
      ) : (
        <Text fontSize={10} bold>
          {startTime.format("h:mma")} - {endTime.format("h:mma")}
        </Text>
      )}
      {scheduleMode !== "Month" && (
        <div
          className={`${
            scheduleMode === "Day" ? "" : "mt-2"
          } d-flex justify-content-end`}
        >
          <Badge
            bg="cyan-12"
            style={{ color: "var(--bs-blue_color)", transform: "scale(0.8)" }}
            className="text-truncate"
          >
            {employeeName}
          </Badge>
        </div>
      )}
    </div>
  );
}
