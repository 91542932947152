import moment, {Moment} from "moment";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {AiTwotoneCalendar} from "react-icons/ai";
import Select from "react-select";
import TextInput from "../../../../components-v2/input/input";
import SelectTheme from "../../../../components-v2/Select/select-theme";
import Text from "../../../../components/text/text";
import {useLocations} from "../../../../hooks/location";
import useModal from "../../../../hooks/modal";
import Shifts, {IShift} from "../../../../modules/Shifts";
import "./../../schedule.scss";
import ScheduleModalProps from "./modal.props";
interface CopyShiftsProps extends ScheduleModalProps {
  timeperiod: {
    startDate: Moment;
    endDate: Moment;
  };
  copiedShift?: Shifts.ShiftPayload;
  onShiftsAdded: (shifts: Array<Shifts.ShiftReponse>) => void;
}
type CopyShiftsPayload = Pick<
  IShift,
  // | "areaId"
  | "employeeId"
  | "endTime"
  // | "locationId"
  | "mealBreak"
  | "remarks"
  | "restBreak"
  | "startTime"
  | "areas"
> & {
  startDate: Array<string>;
  numOfWeeks: string;
  areaId: number;
  locationId: number;
};
export default function CopyShift({
  onClose,
  show,
  timeperiod,
  copiedShift,
  onShiftsAdded,
}: CopyShiftsProps) {
  const [selectedWeeksIndexes, setSelectedWeeksIndexes] = useState<number[]>(
    []
  );
  const [weeks, setWeeks] = useState<
    Array<{
      startDate: Moment;
      endDate: Moment;
    }>
  >([]);
  const form = useForm<Shifts.BulkShiftsPayload & {numOfWeeks: string}>({
    defaultValues: {
      ...copiedShift,
      areaList: copiedShift?.areas?.map((area) => area.areaId),
      employeeId: copiedShift?.employee?.id,
      locationId: copiedShift?.location?.id,
      startDate: [],
    },
  });

  const {locations} = useLocations();
  const {showModal, closeModal} = useModal();

  const [copyTo, setCopyTo] = useState<"nextWeek" | "otherWeeks">();
  const dateFormat = "ddd Do MMM";
  const [numOfWeeks, setNumOfWeeks] = useState<number>(0);

  useEffect(() => {
    if (numOfWeeks > 0) {
      const listOfWeeks = Array.from({length: numOfWeeks}).map((_, i) => {
        const indexOfDay = moment
          .unix(Number(copiedShift?.startDate))
          .isoWeekday();

        let startDate = timeperiod.startDate.clone().add(i, "week");

        if (i === 0) {
          startDate.add(indexOfDay, "days");
        }

        const endDate = timeperiod.endDate.clone().add(i, "week");
        return {startDate, endDate};
      });
      setWeeks(listOfWeeks);
    } else {
      if (weeks.length > 0) {
        setSelectedWeeksIndexes([]);
      }
    }

    return () => {
      setWeeks([]);
      setSelectedWeeksIndexes([]);
    };
  }, [numOfWeeks]);

  useEffect(() => {
    if (selectedWeeksIndexes.length > 0) {
      form.setValue(
        "startDate",
        selectedWeeksIndexes
          .map((index) => {
            return getDateArray(weeks[index]);
          })
          .reduce((prev, curr) => {
            return prev.concat(curr);
          }, [])
      );
    }
  }, [selectedWeeksIndexes]);

  function getDateArray(obj: {startDate: Moment; endDate: Moment}) {
    var start = obj.startDate.clone();
    var end = obj.endDate.clone();
    var res = [];
    while (start.isBefore(end)) {
      res.push(start.unix().toString());

      start.add(1, "d");
    }
    return res;
  }

  const AreaSelectRef = useRef<React.ComponentRef<Select>>(null);

  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Copy Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text fontSize="1rem" className="mb-4">
          Copied Shift:
          <span className="fw-bold ms-2">
            {`${copiedShift?.location?.location} - ${moment
              .unix(Number(copiedShift?.startTime))
              .format("hh:mm A")} to ${moment
              .unix(Number(copiedShift?.endTime))
              .format("hh:mm A")}`}
          </span>
        </Text>
        {copyTo !== "otherWeeks" && (
          <Container className="w-75">
            {/* <Row>
              <Col>
                <Button
                  variant="cyan-12"
                  className="text-start w-100"
                  onClick={() => setCopyTo("nextWeek")}
                >
                  <span>
                    <GiBackwardTime size={30} />
                  </span>{" "}
                  Copy to next week
                </Button>
              </Col>
            </Row> */}
            {/* <Row>
              <Col>
                <div
                  style={{
                    background: "#e6e6e6",
                    height: "1px",
                    width: "100%",
                  }}
                  className="my-2"
                />
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Button
                  variant="cyan-12"
                  className="text-start w-100"
                  onClick={() => setCopyTo("otherWeeks")}
                >
                  <span>
                    <AiTwotoneCalendar size={30} />
                  </span>{" "}
                  Copy to other week(s)
                </Button>
              </Col>
            </Row>
          </Container>
        )}
        {copyTo === "nextWeek" && (
          <Fragment>
            <Text className="mt-5">You will copy shifts from</Text>
            <Text fontSize={14} color="var(--bs-blue_color)">
              {timeperiod.startDate.format(dateFormat)} -{" "}
              {timeperiod.endDate.format(dateFormat)}{" "}
              <span style={{color: "black"}}>to</span>{" "}
              {timeperiod.startDate.clone().add(1, "week").format(dateFormat)} -{" "}
              {timeperiod.endDate.clone().add(1, "week").format(dateFormat)}
            </Text>
          </Fragment>
        )}
        {copyTo === "otherWeeks" && (
          <Fragment>
            {/* <Select
              styles={SelectTheme}
              placeholder="Select Location"
              options={
                locations &&
                locations?.map((location) => ({
                  value: location.id,
                  label: location.name,
                }))
              }
              onChange={(value: any) => {
                form.setValue("locationId", value?.value);
                AreaSelectRef.current?.clearValue();
                field.onChange(op);
                            setValue("locationId", op.id);
                            setValue("areas", []);
              }}
              defaultValue={{
                value: copiedShift?.location?.id,
                label: copiedShift?.location?.location,
              }}
            /> */}
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Locations is required",
                },
              }}
              control={form.control}
              name="location"
              render={({field}) => (
                <Fragment>
                  <Select
                    styles={SelectTheme}
                    placeholder="Select Location"
                    options={locations.map((loc) => ({
                      id: loc.id,
                      location: loc.name,
                    }))}
                    getOptionLabel={(op: any) => op.location}
                    getOptionValue={(op: any) => String(op.id)}
                    {...field}
                    onChange={(op: any) => {
                      field.onChange(op);
                      form.setValue("locationId", op.id);
                      form.setValue("areas", []);
                      // setValue("areaId", 0);
                    }}
                  />
                  {form.formState.errors.location && (
                    <Text
                      color="red"
                      fontSize="0.8rem"
                      style={{minHeight: "0.3rem", marginTop: "0.5rem"}}
                    >
                      {form.formState.errors.location?.message}
                    </Text>
                  )}
                </Fragment>
              )}
            />
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Area is required",
                },
                validate: (value) => {
                  return (value?.length ?? 0) > 0 || "Select Atleast One Area";
                },
              }}
              control={form.control}
              name="areas"
              render={({field}) => (
                <Fragment>
                  <Select
                    isMulti
                    styles={SelectTheme}
                    placeholder="Select Area"
                    noOptionsMessage={() =>
                      form.getValues("location") !== undefined
                        ? "No Areas Found"
                        : "Select Location First"
                    }
                    options={locations
                      .find((l) => l.id === form.getValues("locationId"))
                      ?.areas?.map(
                        ({id, name}) =>
                          ({
                            areaId: id,
                            area: name,
                          } as unknown as typeof field["value"])
                      )}
                    getOptionLabel={(op: any) => op.area}
                    getOptionValue={(op: any) => String(op.areaId)}
                    {...field}
                    onChange={(op: any) => {
                      field.onChange(op);
                      form.setValue(
                        "areaList",
                        op?.map((o: any) => o.areaId)
                      );
                    }}
                  />
                  {form.formState.errors.areas && (
                    <Text
                      color="red"
                      fontSize="0.8rem"
                      style={{minHeight: "0.3rem", marginTop: "0.5rem"}}
                    >
                      {form.formState.errors.areas?.message}
                    </Text>
                  )}
                </Fragment>
              )}
            />

            <Controller
              control={form.control}
              name="numOfWeeks"
              rules={{
                required: {
                  value: true,
                  message: "Please enter number of weeks",
                },
              }}
              render={({field: {onChange, ...rest}}) => (
                <TextInput
                  label="Number of weeks to copy"
                  className="w-50 "
                  leftIcon={<AiTwotoneCalendar size={20} />}
                  controlProps={{
                    type: "number",
                    placeholder: "Number of weeks",
                    size: "sm",
                    ...rest,
                  }}
                  onChangeText={(text) => {
                    onChange(text);
                    setNumOfWeeks(Math.abs(Number(text)));
                  }}
                />
              )}
            />

            <Container>
              <Row className="align-items-center">
                <Col>
                  <Text bold>
                    Select the dates you want to copy these shifts into
                  </Text>
                </Col>
                <Col xs="auto">
                  <Row className="align-items-center">
                    <Col xs="auto" className="p-0">
                      <Text bold>Select:</Text>
                    </Col>
                    <Col xs="auto">
                      <ButtonGroup size="sm">
                        <a
                          className="copy-shift-modal-link px-1"
                          onClick={() => {
                            setSelectedWeeksIndexes(weeks.map((_, i) => i));
                          }}
                        >
                          All
                        </a>
                        <a
                          className="copy-shift-modal-link px-1"
                          onClick={() => {
                            setSelectedWeeksIndexes([]);
                          }}
                        >
                          None
                        </a>
                        <a
                          className="copy-shift-modal-link px-1"
                          onClick={() => {
                            const newIndexes = weeks
                              .map((_, i) =>
                                !selectedWeeksIndexes.includes(i) ? i : -1
                              )
                              .filter((index, i) => index > -1);

                            setSelectedWeeksIndexes(newIndexes);
                          }}
                        >
                          Inverse
                        </a>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <ListGroup style={{maxHeight: "300px"}} className="overflow-auto">
              {numOfWeeks > 0 &&
                weeks.map(({endDate, startDate}, i) => {
                  const isSelected = selectedWeeksIndexes.includes(i);
                  return (
                    <ListGroup.Item
                      key={i}
                      className={`copy-shift-list-item ${
                        isSelected ? "active" : ""
                      }`}
                    >
                      <Form.Check
                        checked={isSelected}
                        onChange={() => {
                          if (isSelected) {
                            setSelectedWeeksIndexes(
                              selectedWeeksIndexes.filter(
                                (index) => index !== i
                              )
                            );
                          } else {
                            setSelectedWeeksIndexes([
                              ...selectedWeeksIndexes,
                              i,
                            ]);
                          }
                        }}
                        className="copy-shift-time-item"
                        label={
                          startDate.format("ddd Do MMM yyyy") +
                          " - " +
                          endDate.format("ddd Do MMM yyyy")
                        }
                      />
                    </ListGroup.Item>
                  );
                })}
            </ListGroup>
          </Fragment>
        )}
        {form.formState.errors && (
          <Text>
            {Object.values(form.formState.errors).map((v) => v.message ?? "")}
          </Text>
        )}
      </Modal.Body>
      {copyTo && (
        <Modal.Footer>
          {copyTo === "otherWeeks" && (
            <Button
              variant="cyan-12"
              onClick={() => {
                setCopyTo(undefined);
              }}
              size="sm"
            >
              Back
            </Button>
          )}
          <Button
            disabled={selectedWeeksIndexes.length < 1}
            variant="blue_color"
            onClick={form.handleSubmit(
              (data) => {
                if (data.startDate.length > 0) {
                  Shifts.bulkShifts(
                    data,
                    (shifts) => {
                      onClose();
                      onShiftsAdded(shifts);
                    },
                    (error) => {
                      showModal({
                        APIType: "APIEXCEPTION",
                        APIError: error,
                      });
                    }
                  );
                } else {
                  showModal({});
                }
              },
              (errors) => {
                console.log(errors);
              }
            )}
            size="sm"
          >
            Start Copying
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
