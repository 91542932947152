import { Form, InputGroup, FormControlProps } from "react-bootstrap";
import "./InputField.css";

import React, { useEffect, useState } from "react";
import Text, { IText } from "../text/text";
import AddressModal from "../addressModal";
import useModal from "../../hooks/modal";
import axiosInstance from "../../api/axiosInstance";

export interface IInputField {
  fontSize?: any;
  placeHolder?: any;
  color?: any;
  label?: any;
  startIcon?: React.ReactChild;
  endIcon?: React.ReactChild;
  borderRadius?: string;
  bottomlabel?: string | React.ReactChild;
  inputClassName?: string;
  iconClassName?: string;
  onChangedText?: Function;
  validation?: Function;
  validationMessage?: string;
  inputProps?: FormControlProps;
  labelBold?: boolean;
  bottomLabelColor?: string;
  labelColor?: string;
  labelTextProps?: IText;
  backgroundColor?: string;
  fullwidth?: boolean;
  subLabel?: string | React.ReactChild;
  subLabelColor?: string;
  subLabelBold?: boolean;
  subLabelTextProps?: IText;
  isAddressInput?: boolean;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
}
export const InputField = React.forwardRef<HTMLInputElement, IInputField>(
  (
    {
      placeHolder,
      color,
      fontSize = "18px",
      label,
      endIcon,
      startIcon,
      borderRadius = "30px",
      bottomlabel,
      inputClassName,
      iconClassName,
      onChangedText,
      validation,
      validationMessage,
      inputProps,
      labelBold,
      bottomLabelColor,
      labelColor,
      labelTextProps,
      backgroundColor = "whitesmoke",
      fullwidth,
      subLabel,
      subLabelColor,
      subLabelBold,
      subLabelTextProps,
      isAddressInput,
      wrapperStyle,
      wrapperClassName,
    }: IInputField,
    ref
  ) => {
    const isBackgroundWhite =
      backgroundColor === "#ffffff" ||
      backgroundColor === "white" ||
      backgroundColor === "whitesmoke";
    const [isValid, setIsValid] = React.useState(true);

    const [showAddressModal, setshowAddressModal] = React.useState(false);
    const [areAddressesLoading, setAreAddressesLoading] = React.useState(true);
    const [addresses, setAddresses] = React.useState<Array<any>>([]);
    const [addressInput, setAddressInput] = React.useState(
      inputProps?.defaultValue || ""
    );
    const [selectedAddress, setSelectedAddress] = React.useState<any>(
      inputProps?.defaultValue || ""
    );

    const { showModal } = useModal();
    const fetchAddresses = React.useCallback(() => {
      if (
        addressInput &&
        typeof addressInput === "string" &&
        addressInput.length > 3
      ) {
        setshowAddressModal(true);
        setAreAddressesLoading(true);

        axiosInstance
          .post("/comply-tsfm/api/utility/addresses", { address: addressInput })
          .then(({ status, data }) => {
            if (status === 200) {
              setAddresses(data);
              setAreAddressesLoading(false);
            }
          })
          .catch((error) => {
            setAreAddressesLoading(false);

            showModal({ APIError: error, APIType: "APIEXCEPTION" });
          });
      }
    }, [addressInput]);

    return (
      <>
        {isAddressInput && (
          <AddressModal
            show={showAddressModal}
            areAddressesLoading={areAddressesLoading}
            formattedAddresses={addresses}
            onAddressSelect={(address) => {
              if (onChangedText) {
                onChangedText(address);
                setSelectedAddress(address.address);
                setshowAddressModal(false);
              }
            }}
            onClose={() => {
              setshowAddressModal(false);
            }}
          />
        )}
        <div
          style={{ width: fullwidth ? "100%" : "auto", ...wrapperStyle }}
          className={wrapperClassName}
        >
          {label && (
            <Form.Label className="p-0 m-0">
              <Text
                responsive
                bold={labelBold}
                color={labelColor || "black"}
                fontSize="12px"
                {...labelTextProps}
              >
                {label}
              </Text>
            </Form.Label>
          )}
          {subLabel && (
            <>
              <br />
              <Form.Label className="p-0 m-0">
                <Text
                  responsive
                  bold={subLabelBold}
                  color={subLabelColor || "black"}
                  fontSize="10px"
                  className="p-0 m-0"
                  {...subLabelTextProps}
                >
                  {subLabel}
                </Text>
              </Form.Label>
            </>
          )}
          <Form.Group
            className="inputGroupForm my-1"
            style={{
              borderRadius,
              backgroundColor,
              ...(!isValid && { border: "1px solid red" }),
            }}
          >
            <InputGroup
              className="m-0 p-0"
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius,
                border: isBackgroundWhite ? `1px solid #ebebec` : "none",
              }}
            >
              {startIcon && (
                <div
                  style={{
                    color: isBackgroundWhite ? "black" : "white",
                  }}
                  className={`startIcon ${inputClassName || ""} ${
                    iconClassName || ""
                  }`}
                >
                  {startIcon}
                </div>
              )}

              <Form.Control
                ref={ref}
                onBlur={(e: any) => {
                  validation && setIsValid(validation(e.currentTarget.value));
                  isAddressInput && fetchAddresses();
                }}
                className={`${
                  inputClassName || ""
                } QS-placeholder border-0 noShadow`}
                style={{
                  fontSize,
                  borderRadius,
                  paddingRight: endIcon ? "5px" : "10px",
                  paddingLeft: startIcon ? "5px" : "10px",
                  backgroundColor,
                  // color: isBackgroundWhite ? "black" : "white",
                }}
                color={color}
                // id="inlineFormInputGroupUsername"
                placeholder={placeHolder || label}
                onChange={(e: any) => {
                  onChangedText && onChangedText(e.currentTarget.value);
                  if (isAddressInput) {
                    setAddressInput(e.currentTarget.value);
                    setSelectedAddress(e.currentTarget.value);
                  }
                }}
                value={
                  isAddressInput
                    ? selectedAddress
                    : inputProps?.value
                    ? inputProps.value
                    : inputProps?.defaultValue
                }
                {...inputProps}
              />

              {/* {endbutton && <ActionButton children="Add" />} */}
              {endIcon && (
                <div
                  style={{
                    color: isBackgroundWhite ? "black" : "white",
                  }}
                  className={`endIcon ${inputClassName || ""} ${
                    iconClassName || ""
                  }`}
                >
                  {endIcon}
                </div>
              )}
            </InputGroup>
          </Form.Group>
          {!isValid && <Text color={"red"}>{validationMessage}</Text>}
          {typeof bottomlabel === "string" ? (
            <Text color={!isValid ? "red" : bottomLabelColor}>
              {!isValid ? validationMessage : bottomlabel}
            </Text>
          ) : (
            bottomlabel
          )}
        </div>
      </>
    );
  }
);
export default InputField;
// export default function InputField({
//   placeHolder,
//   color,
//   fontSize = "18px",
//   label,
//   withCheckBox = false,
//   checkBoxLabel,
//   endIcon,
//   startIcon,
//   borderRadius = "10px",
//   bottomlabel,
//   inputClassName,
//   iconClassName,
//   onChangedText,
//   type,
//   validation,
//   inputProps,
//   labelBold,
//   bottomLabelColor,
//   labelColor,
//   labelTextProps,
// }: IInputField) {
//   return (
//     <div>
//       {label && (
//         <Form.Label className="p-0 m-0">
//           <Text
//             responsive
//             bold={labelBold}
//             color={labelColor || "white"}
//             fontSize="12px"
//             children={label}
//             {...labelTextProps}
//           />
//         </Form.Label>
//       )}
//       <Form.Group className="inputGroupForm my-1">
//         <InputGroup
//           className="m-0 p-0"
//           style={{
//             justifyContent: "center",
//             alignItems: "center",
//             borderRadius: 10,
//           }}
//         >
//           {startIcon && (
//             <div
//               className={`startIcon ${inputClassName || ""} ${
//                 iconClassName || ""
//               }`}
//             >
//               {startIcon}
//             </div>
//           )}

//           <FormControl
//             onBlur={(e) => {
//               validation && validation(e.currentTarget.value);
//             }}
//             className={`${inputClassName} `}
//             style={{
//               fontSize,
//               borderRadius,
//               paddingRight: endIcon ? "5px" : "10px",
//               paddingLeft: startIcon ? "5px" : "10px",
//             }}
//             type={type}
//             color={color}
//             // id="inlineFormInputGroupUsername"
//             placeholder={placeHolder}
//             onChange={(e) =>
//               onChangedText && onChangedText(e.currentTarget.value)
//             }
//             {...inputProps}
//           />

//           {/* {endbutton && <ActionButton children="Add" />} */}
//           {endIcon && (
//             <div
//               className={`endIcon ${inputClassName || ""} ${
//                 iconClassName || ""
//               }`}
//             >
//               {endIcon}
//             </div>
//           )}
//         </InputGroup>
//         {/* {withCheckBox && (
//           <Form.Check
//             type="checkbox"
//             label={checkBoxLabel}
//             className="pt-2"
//             style={{ fontSize: "14px", color: "white" }}
//           />
//         )} */}
//       </Form.Group>
//       <Text color={bottomLabelColor}>{bottomlabel}</Text>
//     </div>
//   );
// }
