import { useEffect, useRef, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { removeEmptyOrNull } from "../../../api/general";
import Button from "../../../components/button/Button";
import InputField from "../../../components/InputField/InputField";
import Text from "../../../components/text/text";
import { Colors } from "../../../constants";
import QSLogo from "./../../../assets/logo-horizontal.svg";
interface IBranding {
  onUpdate: (data: any) => void;
  defaultValue: any;
}
export default function Branding({ defaultValue, onUpdate }: IBranding) {
  const [uploadedLogo, setUploadedLogo] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [selectedColorIndex, setSelectedColorIndex] = useState<number>();
  const [selectedColor, setSelectedColor] = useState<string>(
    defaultValue?.customBranding || ""
  );
  const [base64Image, setBase64Image] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const colors = [
    "#FFA133",
    "#FF5E5E",
    "#FF0000",
    "#DA4671",
    "#945567",
    "#BC51FF",
    "#40406D",
    "#445BFF",
    "#64C6FF",
    "#339195",
    "#205A4D",
    "#54C354",
    "#FFFFFF",
  ];
  const logoInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let url: string;
    if (uploadedLogo) {
      setFileName(uploadedLogo.name);
      const image = URL.createObjectURL(uploadedLogo);
      setPreview(image);
      let reader = new FileReader();
      reader.readAsDataURL(uploadedLogo);
      reader.onload = function () {
        const data = reader.result as string;
        setBase64Image(data.split("base64,")[1]);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    return () => URL.revokeObjectURL(url);
  }, [uploadedLogo]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => setUploadedLogo(files[0]),
    multiple: false,
  });

  useEffect(() => {
    onUpdate({
      ...defaultValue,
      ...removeEmptyOrNull({
        customBranding: selectedColor,
        logoFileName: fileName,
        logoBase64: base64Image,
      }),
    });
  }, [base64Image, fileName, selectedColor]);

  return (
    <Container>
      <Row
        {...getRootProps({ className: "dropzone white-container mt-3 p-4" })}
      >
        <Col>
          <Text fontSize="20px" color={Colors.HIGHLIGHTED} bold centered>
            Drag and drop your logo here
          </Text>
          <Text color="grey" centered>
            Max size is 2MB: PNG, JPG, GIF supported
          </Text>
          <div className="d-flex justify-content-center mt-3">
            {!isDragActive && (
              <Button
                noBorderRadius={false}
                variant="blue_color"
                onClick={() => {
                  logoInputRef.current?.click();
                }}
              >
                Choose file
              </Button>
            )}
            <input type="file" hidden ref={logoInputRef} {...getInputProps()} />
          </div>
        </Col>
      </Row>

      <Row className="mt-3 justify-content-center">
        <Col
          xs="12"
          md="auto"
          className="justify-content-center align-items-center"
        >
          <Text centered color="grey" fontSize="15px">
            Current Logo
          </Text>
          <div className="d-flex justify-content-center align-items-center p-2">
            <div
              className="white-container d-flex justify-content-center align-items-center "
              style={{
                minWidth: "100px",
                minHeight: "50px",
                width: "200px",
                height: "75px",
              }}
            >
              <Image src={QSLogo} style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
        </Col>
        <Col xs="12" md="auto">
          <Text centered color="grey" fontSize="15px">
            Preview
          </Text>
          <div className="d-flex justify-content-center align-items-center p-2">
            <div
              className="white-container d-flex justify-content-center align-items-center"
              style={{
                minWidth: "100px",
                minHeight: "50px",
                width: "200px",
                height: "75px",
              }}
            >
              {
                <Image
                  src={preview || defaultValue?.logoPath}
                  style={{ width: "100%", height: "100%" }}
                />
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Text centered fontSize="15px">
            Choose your theme color
          </Text>
          <Row className="justify-content-center">
            {colors.map((color, i) => (
              <Col xs="auto" className="p-1 m-0" key={i}>
                <Button
                  variant="no_color"
                  className="p-0"
                  style={{ border: "none" }}
                  backgroundColor="transparent"
                  onClick={() => {
                    setSelectedColor(color);
                  }}
                >
                  <div
                    onMouseOver={() => setSelectedColorIndex(i)}
                    onMouseLeave={() => setSelectedColorIndex(undefined)}
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: color,
                      borderRadius: "50%",
                      boxShadow:
                        selectedColorIndex === i
                          ? `0px 0px 5px ${color}`
                          : "none",
                    }}
                  />
                </Button>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col xs="12" md="auto">
              <Text fontSize="18px">Custom</Text>
            </Col>
            <Col xs="12" md="auto">
              <InputField
                backgroundColor="white"
                borderRadius="5px"
                placeHolder="#FFFFFF"
                inputProps={{
                  value: selectedColor,
                  defaultValue: selectedColor || defaultValue?.customBranding,
                }}
                onChangedText={(text: string) => setSelectedColor(text)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
