import { Col, Container, Row, Spinner } from "react-bootstrap";
import Text from "./text/text";
interface LoaderProps {
  message?: string;
}
export default function Loader({ message }: LoaderProps) {
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center">
        <Col xs="auto">
          <Spinner animation="border" variant="dark_orange" />
        </Col>
        <Col xs="auto">
          <Text bold heading>
            {message || "Loading"}
          </Text>
        </Col>
      </Row>
    </Container>
  );
}
