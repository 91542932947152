import { Button, Modal } from "react-bootstrap";
import Text from "../../../../components/text/text";
import ScheduleModalProps from "./modal.props";
import Select from "react-select";
import SelectTheme from "../../../../components-v2/Select/select-theme";

export default function AutoBuildShifts({ onClose, show }: ScheduleModalProps) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Auto Build Shifts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text bold>Shift Lengths</Text>
        <Text>
          Only create shifts of these lengths. The order of choices will
          determine their priority
        </Text>
        <Select
          styles={SelectTheme}
          isMulti
          components={{ DropdownIndicator: null }}
        />
        <Text bold className="mt-3">
          Shift Requirements
        </Text>
        <Text>Set how auto build determines how many shifts are needed</Text>
        <Select
          styles={SelectTheme}
          options={[
            { label: "Based on previous schedules", value: 1 },
            { label: "Minimum Possible", value: 2 },
            { label: "Required Staff", value: 3 },
          ]}
        />
        <Text bold className="mt-3">
          Shift Start Times
        </Text>
        <Text>Shorter intervals create more optimised schedules</Text>
        <Select
          styles={SelectTheme}
          options={[
            { label: "Shifts can start at :00", value: 1 },
            { label: "Shifts can start at :00, :30", value: 2 },
            { label: "Shifts can start at :00, :15, :30, :45", value: 3 },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cyan-12" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
