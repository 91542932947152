import { Badge, Button, Modal } from "react-bootstrap";
import { TiInfo } from "react-icons/ti";
import TextInput from "../../../components-v2/input/input";
import Text from "../../../components/text/text";
import EmployeeMainModalProps from "./modal-common.props";

export default function SetStandardHours({
  onHide,
  show,
}: EmployeeMainModalProps) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Set Standard Hours</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Badge
          bg="cyan-12"
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: "15px",
            marginBottom: "10px",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <span style={{ marginRight: 10 }} className="badge-icon-color">
            <TiInfo />
          </span>
          Number of hours payable for a 'Day of leave'
        </Badge>
        <Text bold fontSize={16}>
          Hours
        </Text>
        <TextInput
          placeholder="Number of hours e.g. 8"
          controlProps={{
            type: "number",
          }}
        />
        <Text fontSize={14} className="mt-2">
          Not applicable to 2 Team members selected as they do not have a pay
          rate assigned to their profile
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="blue_color" size="sm" onClick={() => onHide()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
