import moment, { Moment } from "moment-timezone";
import { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Button from "../../../../components/button/Button";
import Text from "../../../../components/text/text";
import { useUserProfile } from "../../../../hooks/user";
import "./../../schedule.scss";
import { FiCopy } from "react-icons/fi";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BiPaste } from "react-icons/bi";
import { RiListCheck } from "react-icons/ri";
import Shifts, { IShift } from "../../../../modules/Shifts";
interface MultipleShiftBoxProps {
  length: number;
  shifts: Array<Shifts.ShiftPayload>;
  date: Moment;
  onShiftClicked?: (shift: Shifts.ShiftReponse) => void;
  onCopyClicked?: (shift: IShift) => void;
  onAddShiftClicked?: (day: Moment) => void;
  isMonthly?: boolean;
  onMultipleShiftCopy?: (shifts: Array<Shifts.ShiftPayload>) => void;
  copiedShift?: Shifts.ShiftPayload;
  onPasteClicked?: () => void;
  backgroundColor?: string;
}
export default function MultipleShiftBox({
  length,
  shifts,
  date,
  onShiftClicked,
  onCopyClicked,
  onAddShiftClicked,
  isMonthly,
  onMultipleShiftCopy,
  copiedShift,
  onPasteClicked,
  backgroundColor,
}: MultipleShiftBoxProps) {
  const [showShiftsListModal, setShowShiftsListModal] =
    useState<boolean>(false);
  const user = useUserProfile();
  return (
    <div
      className="h-100 w-100 shift-box-wrapper"
      style={{ backgroundColor: backgroundColor || "green" }}
    >
      <Modal
        show={showShiftsListModal}
        onHide={() => {
          setShowShiftsListModal(false);
        }}
      >
        <Modal.Header closeButton>
          {date.format("dddd, Do MMM YYYY")}
        </Modal.Header>
        <Modal.Body>
          {shifts.map((shift: any, i) => (
            <Row key={i}>
              <Col xs="auto" style={{}}>
                <Button
                  key={shift.id}
                  className="multiple-shift-item mb-2"
                  onClick={() =>
                    !user.roles.includes("ROLE_CLIENT") &&
                    onShiftClicked &&
                    onShiftClicked(shift)
                  }
                >
                  {user.roles.includes("ROLE_CLIENT")
                    ? `${shift.location?.location}`
                    : `${moment
                        .unix(Number(shift.startTime))
                        .format("hh:mm a")} - ${moment
                        .unix(Number(shift.endTime))
                        .format("hh:mm a")} - ${shift.location?.location}`}
                </Button>
              </Col>
              {!user.roles.includes("ROLE_CLIENT") && (
                <Col xs="auto">
                  <Button
                    variant="no_color"
                    className="p-0"
                    onClick={() => onCopyClicked && onCopyClicked(shift)}
                  >
                    <FiCopy />
                  </Button>
                </Col>
              )}
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Row className="w-100">
            {!user.roles.includes("ROLE_CLIENT") && (
              <Col>
                <Button
                  onClick={() => {
                    onAddShiftClicked && onAddShiftClicked(date);
                  }}
                >
                  Add New Shift
                </Button>
              </Col>
            )}
            <Col className="d-flex justify-content-end">
              <Button
                className="align-self-end"
                onClick={() => {
                  setShowShiftsListModal(false);
                }}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Container
        fluid
        className="p-0 shift-box shift-box-wrapper d-flex justify-content-center align-items-center position-relative"
        style={{ backgroundColor: backgroundColor || "green" }}
      >
        {!user.roles.includes("ROLE_CLIENT") && (
          <div
            className={`shift-box-overlay ${isMonthly ? "flex-column" : ""}`}
            style={{ backgroundColor: backgroundColor || "green" }}
          >
            <div onClick={() => onAddShiftClicked && onAddShiftClicked(date)}>
              <AiOutlinePlusSquare className="shift-box-icon" />
            </div>
            <div
              onClick={() => {
                setShowShiftsListModal(true);
              }}
            >
              <RiListCheck className="shift-box-icon" />
            </div>
            <div
              onClick={() => onMultipleShiftCopy && onMultipleShiftCopy(shifts)}
            >
              <FiCopy className="shift-box-icon" />
            </div>
            {copiedShift && (
              <div onClick={() => onPasteClicked && onPasteClicked()}>
                <BiPaste className="shift-box-icon" />
              </div>
            )}
          </div>
        )}
        <div
          onClick={() => {
            setShowShiftsListModal(true);
          }}
        >
          <Text centered>{length} Shifts</Text>
        </div>
      </Container>
    </div>
  );
}
