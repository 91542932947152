import GoogleMapReact from "google-map-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { GrAttachment } from "react-icons/gr";
import { RiAttachmentFill } from "react-icons/ri";
import axiosInstance from "../../../../api/axiosInstance";
import Button from "../../../../components/button/Button";
import TimePicker from "rc-time-picker";
import {
  IInputField,
  InputField,
} from "../../../../components/InputField/InputField";
import Select, { ISelect } from "../../../../components/select/select";
import Text from "../../../../components/text/text";
import { Colors, mapKey } from "../../../../constants";
import useModal from "../../../../hooks/modal";
import {
  extractBase64Only,
  removeEmptyOrNull,
  toBase64,
} from "../../../../api/general";
import Industries from "../../../../api/industries";
import { IAvailability } from "../../../../interface";
import { MdDeleteForever } from "react-icons/md";

interface IGeneral {
  onUpdate: (data: any) => void;
  defaultValue: any;
}
export default function General({ defaultValue, onUpdate }: IGeneral) {
  const { showModal, closeModal } = useModal();
  const fileRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>();
  const [code, setCode] = useState<string>();
  const [timezone, setTimezone] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [industry, setIndustry] = useState<string>();
  const [longitude, setLongitude] = useState<string>(defaultValue?.longitude);
  const [latitude, setLatitude] = useState<string>(defaultValue?.latitude);
  const [contactName, setContactName] = useState<string>();
  const [contactEmail, setContactEmail] = useState<string>();
  const [contactPhone, setContactPhone] = useState<string>();
  const [attachments, setAttachments] = useState<
    Array<{
      fileBase64?: string;
      fileName: string;
      fileKey?: string;
    }>
  >(defaultValue?.attachment || defaultValue?.attachments);

  const [deletedAttachmentKeys, setDeletedAttachmentKeys] = useState<
    Array<string>
  >([]);

  const [workHours, setWorkHours] = useState<IAvailability | undefined>(
    defaultValue?.schedule || {
      Monday: { startTime: "", endTime: "", isOpen: true },
      Tuesday: { startTime: "", endTime: "", isOpen: true },
      Wednesday: { startTime: "", endTime: "", isOpen: true },
      Thursday: { startTime: "", endTime: "", isOpen: true },
      Friday: { startTime: "", endTime: "", isOpen: true },
      Saturday: { startTime: "", endTime: "", isOpen: true },
      Sunday: { startTime: "", endTime: "", isOpen: true },
    }
  );
  const inputFields: Array<
    IInputField & {
      type?: "select" | "input";
      selectProps?: ISelect;
      inputProps?: IInputField;
    }
  > = [
    {
      label: "Name",
      onChangedText: setName,
      type: "input",
      inputProps: {
        value: name,
        defaultValue: defaultValue?.name,
      },
    },
    {
      label: "Location code",
      onChangedText: setCode,
      type: "input",
      inputProps: { value: code, defaultValue: defaultValue?.code },
    },
    {
      label: "Address",
      onChangedText: (address: any) => {
        setAddress(address.address);
        setLongitude(address.longitude);
        setLatitude(address.latitude);
      },

      inputProps: {
        // onBlur: fetchAddresses,
        // value: address || "",
        // onFocus: () => {
        //   setFormattedAddresses(undefined);
        // },
        defaultValue: defaultValue?.address,
      },
      type: "input",
      isAddressInput: true,
    },
    {
      label: "Contact Name",
      onChangedText: setContactName,
      inputProps: {
        value: contactName,
        defaultValue: defaultValue?.contactName,
      },
      type: "input",
    },
    {
      label: "Contact Email",
      onChangedText: setContactEmail,
      inputProps: {
        value: contactEmail,
        defaultValue: defaultValue?.contactEmail,
      },
      type: "input",
    },
    {
      label: "Contact Phone",
      onChangedText: setContactPhone,
      inputProps: {
        value: contactPhone,
        defaultValue: defaultValue?.contactPhone,
      },
      type: "input",
    },
    {
      label: "Timezone",

      type: "select",
      selectProps: {
        options: moment.tz
          .names()
          .map((timezone) => ({ label: timezone, value: timezone })),
        onChange: (value: any) => {
          setTimezone(value.value);
        },
        defaultValue: {
          label: defaultValue?.timezone,
          value: defaultValue?.timezone,
        },
      },
    },
    {
      label: "Industry",

      type: "select",
      selectProps: {
        options: Industries,
        onChange: (value: any) => {
          setIndustry(value.value);
        },

        defaultValue: defaultValue?.industry
          ? {
              label: defaultValue?.industry,
              value: defaultValue?.industry,
            }
          : undefined,
      },
    },
  ];
  useEffect(() => {
    onUpdate({
      ...defaultValue,
      ...removeEmptyOrNull({
        name,
        code,
        timezone,
        address,
        industry,
        contactName,
        contactEmail,
        contactPhone,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        attachments,
        deletedAttachmentKeys,
        longitude,
        latitude,
      }),
      schedule: workHours
        ? Object.entries(workHours).map(([day, values]) => ({
            day,
            startTime: values.startTime || "",
            endTime: values.endTime || "",
          }))
        : undefined,
    });

    if (defaultValue && !longitude && !latitude) {
      showModal({
        Component: (
          <Text bold heading>
            Warning! Your location Coordinates are missing. Please type your
            location again and save location.
          </Text>
        ),
      });
    }
  }, [
    name,
    code,
    timezone,
    address,
    industry,
    contactName,
    contactEmail,
    contactPhone,
    workHours,
    attachments,
    deletedAttachmentKeys,
    longitude,
    latitude,
  ]);

  return (
    <Container fluid>
      <Row>
        <Col xs="7">
          {inputFields.map((props, i) => (
            <Row key={i}>
              {props.type === "input" ? (
                <InputField
                  backgroundColor="white"
                  fontSize="13px"
                  fullwidth
                  {...props}
                />
              ) : (
                <Select
                  label={props.label}
                  onChange={(value) => {
                    // console.log({ value });
                  }}
                  styles={{
                    control: (style) => ({
                      ...style,
                      borderRadius: "30px",
                      fontSize: "13px",
                    }),
                  }}
                  {...props.selectProps}
                />
              )}
            </Row>
          ))}
          <Row className="mt-2">
            <Col>
              <Text fontSize={12}>Timings</Text>
            </Col>
          </Row>
          {workHours &&
            Object.entries(workHours).map(([dayName, value]: any, i) => (
              <Row
                // className="mt-2"
                className="d-flex align-items-center mt-2"
                key={i}
              >
                <Col lg={4}>
                  <Form.Check
                    inline
                    label={dayName}
                    style={{ fontSize: "12px" }}
                    checked={value?.isOpen}
                    onChange={(e: any) => {
                      setWorkHours((prevState) => {
                        let obj = prevState;
                        if (obj) {
                          obj[dayName as keyof IAvailability].isOpen =
                            e.target.checked;
                          obj[dayName as keyof IAvailability].startTime = "";
                          obj[dayName as keyof IAvailability].endTime = "";
                          return { ...obj };
                        }
                      });
                    }}
                  />
                </Col>
                {workHours[dayName as keyof IAvailability].isOpen ? (
                  <>
                    <Col lg={4}>
                      <TimePicker
                        showSecond={false}
                        use12Hours
                        placeholder="Start Time"
                        className="location-time-picker"
                        defaultValue={
                          workHours[dayName as keyof IAvailability].startTime
                            ? moment.unix(
                                Number(
                                  workHours[dayName as keyof IAvailability]
                                    .startTime
                                )
                              )
                            : undefined
                        }
                        onChange={(time) => {
                          setWorkHours((prevState) => {
                            let obj = prevState;
                            if (obj) {
                              obj[dayName as keyof IAvailability].startTime =
                                moment(time).unix().toString();
                              return { ...obj };
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <TimePicker
                        showSecond={false}
                        use12Hours
                        placeholder="End Time"
                        className="location-time-picker"
                        defaultValue={
                          workHours[dayName as keyof IAvailability].endTime
                            ? moment.unix(
                                Number(
                                  workHours[dayName as keyof IAvailability]
                                    .endTime
                                )
                              )
                            : undefined
                        }
                        onChange={(time) => {
                          setWorkHours((prevState) => {
                            let obj = prevState;
                            if (obj) {
                              obj[dayName as keyof IAvailability].endTime =
                                moment(time).unix().toString();
                              return { ...obj };
                            }
                          });
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <Col lg={8}>
                    <Text fontSize="12px">CLOSED</Text>
                  </Col>
                )}
              </Row>
            ))}

          {/* {timingField.map((label, i) => (
              <Row className="align-items-center my-3" key={i}>
                <Col xs="4">
                  <Row xs="auto">
                    <Col xs="auto">
                      <Form.Check
                        className="time-checkbox"
                        color="black"
                        size={5}
                        onChange={(e) => {
                          setSchedule((prevState) => {
                            prevState[label.toUpperCase()].isOpen =
                              e.target.checked;
                            return { ...prevState };
                          });
                        }}
                        // checked={locTimings[label.toUpperCase()].isOpen}
                      />
                    </Col>
                    <Col xs="auto">
                      <Text>{label}</Text>
                    </Col>
                  </Row>
                </Col>
                {schedule[label.toUpperCase()].isOpen ? (
                  <>
                    <Col xs="4">
                      <CalendarInput
                        inputProps={{
                          fontSize: "10px",
                          placeHolder: "From",
                          backgroundColor: "white",
                        }}
                        calendarProps={{
                          timeFormat: true,
                          dateFormat: false,
                          onChange: (date: any) => {
                            setSchedule((prevState) => {
                              prevState[label.toUpperCase()].from =
                                date as Moment;
                              return { ...prevState };
                            });
                          },
                        }}
                      />
                    </Col>
                    <Col xs="4">
                      <CalendarInput
                        inputProps={{
                          fontSize: "10px",
                          placeHolder: "To",
                          backgroundColor: "white",
                        }}
                        calendarProps={{
                          timeFormat: true,
                          dateFormat: false,
                          onChange: (date: any) =>
                            setSchedule((prevState) => {
                              prevState[label.toUpperCase()].to =
                                date as Moment;
                              return { ...prevState };
                            }),
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <Col>
                    <Text>CLOSED</Text>
                  </Col>
                )}
              </Row>
            ))} */}
        </Col>
        <Col xs="5">
          <div style={{ width: "300px", height: "300px" }} className="w-100">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: mapKey,
              }}
              defaultCenter={{
                lat: -24.861701536988424,
                lng: 132.5725617679321,
              }}
              center={{
                lat: +latitude || -24.861701536988424,
                lng: +longitude || 132.5725617679321,
              }}
              defaultZoom={1}
              zoom={longitude?.length > 0 ? 15 : 1}
            ></GoogleMapReact>
          </div>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <div className="border border-grey rounded p-2">
            <Row>
              <Text>
                Useful information that will appear in the location notes for
                every shift
              </Text>
            </Row>
            <Row className="my-3">
              {attachments?.map(({ fileName, fileKey }, i) => (
                <Col xs="2" key={i}>
                  <MdDeleteForever
                    onClick={() => {
                      setDeletedAttachmentKeys((prevState) => {
                        let obj = prevState || [];

                        fileKey && obj.push(fileKey);
                        setAttachments((prevState) => {
                          let obj = prevState;
                          if (obj) {
                            obj.splice(i, 1);
                            return [...obj];
                          }

                          return obj;
                        });
                        return [...obj];
                      });
                    }}
                  />
                  <Button
                    variant="no_color"
                    className="border border_dark"
                    noBorderRadius
                    fontSize="25px"
                    style={{ marginTop: "-10px" }}
                    onClick={() => {
                      fileKey &&
                        axiosInstance
                          .get(
                            `/comply-tsfm/api/location/attachment/${fileKey}`,
                            { responseType: "blob" }
                          )
                          .then((fileResponse) => {
                            //Create a Blob from the PDF Stream
                            //Build a URL from the file
                            const fileURL = URL.createObjectURL(
                              fileResponse.data
                            );
                            //Open the URL on new Window
                            window.open(fileURL);
                            // pdfWindow && (pdfWindow.location.href = fileURL);
                          })
                          .catch((error) => {
                            showModal({
                              APIError: error,
                              APIType: "APIEXCEPTION",
                            });
                          });
                    }}
                  >
                    <RiAttachmentFill />
                  </Button>
                  <Text
                    style={{
                      height: "40px",
                      overflow: "hidden",
                    }}
                  >
                    {fileName}
                  </Text>
                </Col>
              ))}
            </Row>
            <Row
              style={{ backgroundColor: "rgb(0,0,0,10%)" }}
              className="align-items-center"
            >
              <Col xs="auto">
                <input
                  ref={fileRef}
                  onChange={(e) => {
                    const files = e.target.files;
                    files &&
                      toBase64(files[0])
                        .then((base64) => {
                          setAttachments((prevState) => {
                            let obj = prevState || [];

                            obj?.push({
                              fileName: files[0].name,
                              fileBase64: extractBase64Only(String(base64)),
                            });
                            return [...obj];
                          });
                        })
                        .catch((error) => {
                          showModal({
                            Component: (
                              <Text>
                                Coudn't encode attached file. Please try again
                                or different format.
                              </Text>
                            ),
                            FooterComponent: (
                              <Button onClick={() => closeModal()}>OK</Button>
                            ),
                          });
                        });
                  }}
                  accept=".pdf,.png,.jpeg,.jpg"
                  className="d-none"
                  type="file"
                />
                <Button
                  variant="no_color"
                  textColor={Colors.HIGHLIGHTED}
                  noBorderRadius
                  startIcon={<GrAttachment className="me-2" />}
                  onClick={() => fileRef?.current && fileRef?.current.click()}
                >
                  Attachment
                </Button>
              </Col>
              <Col>
                <Text>
                  Accepted file format: JPEG, PNG, PDF MAX 5 files, 10MB per
                  file
                </Text>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
