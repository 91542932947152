import React from "react";
import { Col, Container, Image, ImageProps, Row } from "react-bootstrap";
import Text from "../text/text";
import "./page-banner.css";

interface IPageBanner {
  heading: string;
  paragraph?: React.ReactChild;
  image?: string;
  additionalComponent?: React.ReactChild;
  backgroundColor?: string;
  containerStyle?: React.CSSProperties;
  imageDimensions?: { height: number | string; width: number | string };
  additionalComponentWrapperStyle?: React.CSSProperties;
  containerClassName?: string;
  textColor?: string;
}
export default function PageBanner({
  heading,
  image,
  paragraph,
  additionalComponent,
  backgroundColor,
  containerStyle,
  additionalComponentWrapperStyle,
  imageDimensions,
  containerClassName,
  textColor,
}: IPageBanner) {
  return (
    // <div className="mw-100 d-flex justify-content-center my-3">
    <Container
      className={`banner-parent-container py-3 px-5 ${containerClassName}`}
      fluid
      style={{ backgroundColor, ...containerStyle }}
    >
      <Row>
        <Col xs={9} className="my-auto">
          <Text
            style={{ fontWeight: "500" }}
            fontSize="22px"
            color={textColor || "white"}
          >
            {heading}
          </Text>
          {paragraph && (
            <Text
              className="mt-2"
              color={textColor || "white"}
              fontSize="16px"
              style={{ paddingBottom: "9px" }}
            >
              {paragraph}
            </Text>
          )}
          <div style={{ padding: "9px", ...additionalComponentWrapperStyle }}>
            {additionalComponent && additionalComponent}
          </div>
        </Col>
        {image && (
          <Col
            xs={3}
            className=" d-flex justify-content-center"
            // style={{ backgroundColor: "red" }}
          >
            <Image
              src={image}
              className="img-fluid shadow-4"
              style={{
                width: imageDimensions?.width || "100%",
                height: imageDimensions?.height || "100%",
                objectFit: "contain",
              }}
              rounded
            />
          </Col>
        )}
      </Row>
    </Container>
    // </div>
  );
}
