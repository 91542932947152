import React, { useEffect, useState } from "react";
import { Colors } from "../../constants";
import Text from "../text/text";
import "./custom-switch.scss";
import RSwitch from "react-switch";
export interface ISwitch {
  checkedValue?: string;
  unCheckedValue?: string;
  onChange?: (isChecked: boolean) => void;
}
export default function Switch({
  checkedValue = "yes",
  unCheckedValue = "no",
  onChange,
}: ISwitch) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const SwitchOverley = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        width: "10px",
      }}
    ></div>
  );
  const SwtichValue = ({ value }: any) => (
    <div
      className="align-items-center justify-content-center d-flex"
      style={{ height: "100%" }}
    >
      <Text fontSize="15px" color="white">
        {value?.toUpperCase()}
      </Text>
    </div>
  );
  useEffect(() => {
    onChange && onChange(isChecked);
  }, [isChecked]);

  return (
    <RSwitch
      checked={isChecked}
      onChange={(isCheckedP, e, id) => setIsChecked(isCheckedP)}
      handleDiameter={28}
      offColor="#000000"
      onColor={Colors.LIGHT_ORANGE}
      offHandleColor="#ffffff"
      onHandleColor="#ffffff"
      height={20}
      width={50}
      borderRadius={6}
      activeBoxShadow="0px 0px 1px 2px #fffc35"
      uncheckedIcon={<SwtichValue value={unCheckedValue} />}
      checkedIcon={<SwtichValue value={checkedValue} />}
      uncheckedHandleIcon={<SwitchOverley />}
      checkedHandleIcon={<SwitchOverley />}
      className="react-switch"
      id="small-radius-switch"
    />
  );
}
