import { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { RiUserSearchFill } from "react-icons/ri";
import Calendar from "../../components-v2/Calendar/calendar";
import DropDown from "../../components-v2/dropdown/dropdown";
// import CalendarButton from "../../components/calendar-button/calendar-button";
import DropdownButton from "../../components/DropdownMenu/DropdownMenu";
import EmployeeBox from "../../components/employee-box/employee";
import { useBusinessProfile } from "../../hooks/business";
import { useEmployees } from "../../hooks/employee";
import "./timesheet.styles.scss";
import { Resizable, ResizableBox } from "react-resizable";
import { ImMenu2 } from "react-icons/im";
import BootstrapTable from "react-bootstrap-table-next";
import TimesheetTable, {
  TimesheetTableProps,
} from "./components/timesheet-table";
import TimesheetBottomTabs, {
  TimesheetBottomTabsRefProps,
} from "./components/timesheet-bottom-tabs";
import { useLocations } from "../../hooks/location";
import TextInput from "../../components-v2/input/input";
import moment from "moment";
import { ITimesheet } from "../../modules/Timesheet";
import { IOption } from "../../interface";

export default function TimeSheet({}) {
  const { businesses } = useBusinessProfile();
  const { employees } = useEmployees();
  const { locations } = useLocations();
  const [employeeSearchText, setEmployeeSearchText] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<number>();
  const [selectedTimesheet, setSelectedTimesheet] = useState<any>();
  const [selectedLocation, setSelectedLocation] = useState<{
    label?: string;
    value?: string | number;
  }>({
    label: "Select Location",
    value: "",
  });

  const [timesheetWrapperSize, setTimesheetWrapperSize] = useState({
    width: 0,
    height: 0,
  });
  const bottomTabsRef = useRef<TimesheetBottomTabsRefProps>(null);
  const timesheetWrapperRef = useRef<HTMLDivElement>(null);
  const [timesheetWrapperColHeight, setTimesheetWrapperColHeight] = useState(0);
  useEffect(() => {
    if (timesheetWrapperRef.current) {
      setTimesheetWrapperColHeight(timesheetWrapperRef.current.clientHeight);
      setTimesheetWrapperSize({
        height: timesheetWrapperRef.current.clientHeight,
        width: timesheetWrapperRef.current.clientWidth,
      });
    }
  }, []);

  //Filter EMployees
  const filteredEmployees = useCallback(() => {
    return employees?.filter((employee) =>
      employee.preferredTitle
        ?.toLowerCase()
        .includes(employeeSearchText.toLowerCase())
    );
  }, [employeeSearchText, employees]);

  // Timesheet Table
  const [timesheetTableData, setTimesheetTableData] =
    useState<TimesheetTableProps>([]);

  const [timesheets, setTimesheets] = useState<Array<ITimesheet>>();

  useEffect(() => {
    // Get Random positive number

    if (timesheets) {
      setTimesheetTableData(
        timesheets
          .filter(
            ({ employeeId, locationId }) =>
              employeeId === selectedEmployee &&
              locationId === selectedLocation.value
          )
          .map(
            ({
              id,
              breaks,
              date,
              employeeId,
              endTime,
              locationId,
              startTime,
              totalMealBreak,
              totalRestBreak,
              employeeName,
              locationName,
              progress,
              status,
            }) => ({
              id,
              areaOfWork: locationName,
              date: moment.unix(Number(date)).format("YYYY-MM-DD"),
              hours: `${moment.unix(Number(startTime)).format("h:mm A")}`,

              time: `${moment
                .unix(Number(startTime))
                .format("h:mm A")} - ${moment
                .unix(Number(endTime))
                .format("h:mm A")}`,
              status: (
                <Badge bg={status === "Approved" ? "success" : "dark_orange"}>
                  {status}
                </Badge>
              ),
              statusText: status,
              progress,
            })
          )
      );
    }
  }, [timesheets?.length, selectedEmployee, selectedLocation.value]);

  const [headerApproveBtnOptions, setHeaderApproveBtnOptions] = useState<
    Array<IOption>
  >([
    {
      label: "Approve All",
      disabled: true,
    },
    {
      label: "Unapprove All",
      disabled: true,
    },
  ]);
  useEffect(() => {
    if (timesheetTableData && timesheetTableData?.length > 0) {
      setHeaderApproveBtnOptions((prev) => {
        const anyApproved =
          timesheetTableData.length > 0 &&
          timesheetTableData.some(
            ({ statusText }) => statusText === "Approved"
          );
        const anyPending =
          timesheetTableData.length > 0 &&
          timesheetTableData.some(({ statusText }) => statusText === "Pending");
        return [
          {
            label: "Approve All",
            disabled: !anyPending,
          },
          {
            label: "Unapprove All",
            disabled: !anyApproved,
          },
        ];
      });
    } else {
      setHeaderApproveBtnOptions([
        {
          label: "Approve All",
          disabled: true,
        },
        {
          label: "Unapprove All",
          disabled: true,
        },
      ]);
    }
  }, [timesheetTableData?.length]);

  return (
    <Container fluid className="timesheet-container">
      <Row className="border-bottom py-2">
        <Col>
          <Row>
            <Col xs="auto">
              <DropDown
                label={selectedLocation?.label || "Select Location"}
                menu={
                  locations?.map((location) => ({
                    label: location.name,
                    value: location.id,
                  })) || []
                }
                onChange={(option) => {
                  setSelectedLocation(option);
                }}
              />
            </Col>
            <Col xs="auto">
              <Calendar
                mode="Week"
                backgroundColor="#e6e6e6"
                onDateChanged={(date) => {
                  console.log(date);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                variant="blue_color"
                style={{ color: "white" }}
                size="sm"
                onClick={() => {
                  setSelectedTimesheet(null);
                  bottomTabsRef.current?.resetTimeSheetForm();
                }}
              >
                Add Timesheet
              </Button>
            </Col>
            <Col xs="auto">
              <DropDown
                key={String(
                  headerApproveBtnOptions?.[0].disabled &&
                    headerApproveBtnOptions?.[1].disabled
                )}
                dropdownProps={{ style: { width: "160px" } }}
                label="Approve All & Next"
                buttonProps={{
                  disabled: !(
                    timesheetTableData.length > 0 &&
                    timesheetTableData.some(
                      ({ statusText }) => statusText === "Pending"
                    )
                  ),
                }}
                menu={headerApproveBtnOptions}
                onChange={() => {}}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Timesheet Body */}

      <Row className="h-100">
        <Col className="border-end p-0" xs="2">
          <TextInput
            leftIcon={<RiUserSearchFill />}
            noBorderRadius
            placeholder="Search"
            onChangeText={(text) => {
              setEmployeeSearchText(text);
            }}
          />

          {filteredEmployees()?.map(
            ({ preferredTitle, profilePicture, id }) => (
              <div
                className="px-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedEmployee(id);
                  setTimesheetTableData([]);
                }}
              >
                <EmployeeBox
                  isSelected={id === selectedEmployee}
                  preferredTitle={preferredTitle}
                  image={profilePicture}
                />
              </div>
            )
          )}
        </Col>
        <Col
          xs="10"
          ref={timesheetWrapperRef}
          className="p-0 h-100 overflow-hidden position-relative"
        >
          <ResizableBox
            axis="y"
            width={timesheetWrapperRef.current?.clientWidth || 100}
            height={timesheetWrapperColHeight / 2}
            draggableOpts={{}}
            minConstraints={[
              timesheetWrapperRef.current?.clientWidth || 100,
              timesheetWrapperColHeight / 4,
            ]}
            maxConstraints={[
              timesheetWrapperRef.current?.clientWidth || 100,
              timesheetWrapperColHeight / 1.5,
            ]}
            onResize={(e, { size }) => {
              setTimesheetWrapperSize(size);
            }}
          >
            <TimesheetTable
              data={timesheetTableData}
              onSelectRow={(row) => {
                let timesheet = timesheets?.filter(
                  (timehseet) => timehseet?.id === row.id
                );
                setSelectedTimesheet(timesheet?.[0]);
              }}
            />
          </ResizableBox>

          <div
            className="employee-timesheet-wrapper"
            style={{
              minHeight:
                (timesheetWrapperRef.current?.clientHeight || 100) -
                timesheetWrapperSize.height,
              height: "100%",
            }}
          >
            <TimesheetBottomTabs
              ref={bottomTabsRef}
              selectedTimeSheet={selectedTimesheet}
              resetSelectedTimeSheet={() => {
                setSelectedTimesheet(null);
              }}
              bottomTabsHeight={
                (timesheetWrapperRef.current?.clientHeight || 100) -
                timesheetWrapperSize.height
              }
              onTimesheetSubmit={(data) => {
                setTimesheets((prev) => {
                  if (prev) {
                    return [...prev, data];
                  } else {
                    return [data];
                  }
                });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
