import {
  Col,
  Collapse,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import Text from "../../components/text/text";
import Divider from "./../../components/divider";
import "./report.scss";
import SampleAsset from "./../../assets/smalllogo.png";
import { useNavigate } from "react-router-dom";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdArrowDropdown,
  IoMdArrowDropright,
  IoMdArrowDropup,
} from "react-icons/io";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getHeight } from "../../api/components";
import Button from "../../components/button/Button";
import AddReport from "./temporary/add-report";

interface IReportOptions {
  title: string;
  subTile: string;
  subLinks: Array<{ title: string; url: string }>;
  toggleMethod: (state: boolean) => void;
  isOpen: boolean;
}

export default function Report() {
  const navigate = useNavigate();
  const [openCheckInReportSection, setOpenCheckInReportSection] =
    useState<boolean>(true);
  const [openAuditReportSection, setOpenAuditReportSection] =
    useState<boolean>(true);

  const [openWHSSection, setOpenWHSSection] = useState<boolean>(true);
  const [openCONUSESection, setOpenCONUSESection] = useState<boolean>(true);
  const [openInvoiceSection, setOpenInvoiceSection] = useState<boolean>(true);
  const [openComplianceSection, setOpenComplianceSection] =
    useState<boolean>(true);

  const options: Array<IReportOptions> = [
    {
      title: "Check-in Report",
      subTile: "Check-in Report",
      subLinks: [
        { title: "Daily Activity Report", url: "/app/report/check-in" },
      ],
      toggleMethod: (state: boolean) => setOpenCheckInReportSection(state),
      isOpen: openCheckInReportSection,
    },
    {
      title: "Audit Reports",
      subTile: "View Audit Reports (Monthly, Quarterly, Yearly)",
      subLinks: [
        {
          title: "Audit Report",
          url: "/app/report/generic-report?mode=VIEW&reportType=AUDIT",
        },
        {
          title: "Upload Report",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=AUDIT",
        },
      ],
      toggleMethod: (state: boolean) => setOpenAuditReportSection(state),
      isOpen: openAuditReportSection,
    },
    {
      title: "Work, Health and Safety Reports",
      subTile: "Work, Health and Safety Reports",
      subLinks: [
        {
          title: "View WMS",
          url: "/app/report/generic-report?mode=VIEW&reportType=WMS",
        },
        {
          title: "Upload WMS",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=WMS",
        },
        {
          title: "View Risk Assessments Site by Site",
          url: "/app/report/generic-report?mode=VIEW&reportType=RISK_ASSESSMENTS",
        },
        {
          title: "Upload Risk Assessments Site by Site",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=RISK_ASSESSMENTS",
        },
        {
          title: "View Scope of Work Site by Site",
          url: "/app/report/generic-report?mode=VIEW&reportType=SOW_SBS",
        },
        {
          title: "Upload Scope of Work Site by Site",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=SOW_SBS",
        },
      ],
      toggleMethod: (state: boolean) => setOpenWHSSection(state),
      isOpen: openWHSSection,
    },
    {
      title: "Consumable Usage",
      subTile: "Consumable Usage",
      subLinks: [
        {
          title: "View Consumable Usage",
          url: "/app/report/generic-report?mode=VIEW&reportType=CONSUMABLE_USAGE",
        },
        {
          title: "Upload Consumable Usage",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=CONSUMABLE_USAGE",
        },
      ],
      toggleMethod: (state: boolean) => setOpenCONUSESection(state),
      isOpen: openCONUSESection,
    },
    {
      title: "Invoice Site by Site",
      subTile: "Invoice Site by Site",
      subLinks: [
        {
          title: "View Invoice Site by Site",
          url: "/app/report/generic-report?mode=VIEW&reportType=INVOICE_SBS",
        },
        {
          title: "Upload Invoice Site by Site",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=INVOICE_SBS",
        },
      ],
      toggleMethod: (state: boolean) => setOpenInvoiceSection(state),
      isOpen: openInvoiceSection,
    },
    {
      title: "Compliance/Insurances",
      subTile: "Compliance/Insurances",
      subLinks: [
        {
          title: "View Compliance/Insurances",
          url: "/app/report/generic-report?mode=VIEW&reportType=COMPLIANCE_INSURANCE",
        },
        {
          title: "Upload Compliance/Insurances",
          url: "/app/report/generic-report?mode=UPLOAD&reportType=COMPLIANCE_INSURANCE",
        },
      ],
      toggleMethod: (state: boolean) => setOpenComplianceSection(state),
      isOpen: openComplianceSection,
    },
  ];

  // Apply Pagination in sublinks

  const paginateSubLinks = (subLinks: any[]) => {
    const subLinksPerPage =
      subLinks.length <= 4 ? 4 : Math.ceil(subLinks.length / 3);
    const subLinksPages = Math.ceil(subLinks.length / subLinksPerPage);
    const subLinksPagesArray = [];
    for (let i = 0; i < subLinksPages; i++) {
      subLinksPagesArray.push(
        subLinks.slice(i * subLinksPerPage, (i + 1) * subLinksPerPage)
      );
    }
    return subLinksPagesArray;
  };

  const listenToScroll = () => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > getHeight("sticky-title-container")) {
      document
        .getElementById("sticky-title-container")
        ?.classList.add("report-title-header-sticky");
    } else {
      document
        .getElementById("sticky-title-container")
        ?.classList.remove("report-title-header-sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  return (
    <Container className="px-3 pb-3 mt-3" fluid style={{ minWidth: "500px" }}>
      {/* <AddReport /> */}
      <Row
        className="mb-3 px-5 report__header shadow-sm align-items-center "
        id="sticky-title-container"
      >
        <Col>
          <Text fontSize={24} bold>
            Reports
          </Text>
        </Col>
      </Row>

      {options.map(
        ({ title, subLinks, isOpen, toggleMethod, subTile }, index) => (
          <div key={index} className="mb-3  mx-5 shadow-sm border rounded">
            <Row
              className="py-3 m-0 option-heading-container"
              style={{ backgroundColor: isOpen ? "#f5f5f5" : "#fff" }}
              onClick={() => {
                toggleMethod && isOpen !== undefined && toggleMethod(!isOpen);
              }}
            >
              <Col xs="auto">
                <Text bold fontSize={20}>
                  {isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </Text>
              </Col>
              <Col xs="auto">
                <Text bold fontSize={17}>
                  {title}
                </Text>
                <Text fontSize={15}>{subTile}</Text>
              </Col>
            </Row>

            <Collapse
              // className="collapse-container"
              in={isOpen}
            >
              <Container fluid className=" px-5 my-3">
                <Row>
                  {subLinks &&
                    paginateSubLinks(subLinks).map(
                      (subLinkArr, subLinkIndex) => (
                        <Col
                          xs="12"
                          md="6"
                          lg="4"
                          as={ListGroup}
                          key={subLinkIndex}
                          className="my-1"
                        >
                          {subLinkArr?.map((subLink, index) => (
                            <ListGroup.Item
                              className="report-sublink-list-item"
                              key={index}
                            >
                              <Link to={subLink?.url}>{subLink?.title}</Link>
                            </ListGroup.Item>
                          ))}
                        </Col>
                      )
                    )}
                </Row>
              </Container>
            </Collapse>
          </div>
        )
      )}
    </Container>
  );
}
