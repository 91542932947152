import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Text from "../../components/text/text";
import "./location.scss";
import { Colors } from "../../constants";
import Button from "../../components/button/Button";
import { MdDashboard } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DropdownButton from "../../components/DropdownMenu/DropdownMenu";
import { FaShareAltSquare } from "react-icons/fa";
import General from "./components/edit/general";
import Area from "./components/edit/area";
import axiosInstance from "../../api/axiosInstance";
import useModal from "../../hooks/modal";
import Divider from "../../components/divider";
import { useUserProfile } from "../../hooks/user";

function EditLocation({}) {
  const { id, view } =
    useParams<{ id: string; view: "edit" | "create" | "view" }>();
  const { roles } = useUserProfile();

  const { state }: any = useLocation();

  const [browserState, setBrowserState] = useState<any>(state);
  const { showModal } = useModal();
  // const [locationDetails, setLocationDetails] = useState<{
  //   [key: string]: any;
  // }>({});

  const [generalSettings, setGeneralSettings] = useState<any>(
    browserState || {}
  );
  const [areaSettings, setAreaSettings] = useState<any>(
    browserState?.area || []
  );

  // const [updatedValue, setUpdatedValue] = useState<{
  //   [key: string]: any;
  // }>({});

  const sideMenuOptions = [
    {
      label: "General",
      eventkey: "general",
      component: (
        <General
          onUpdate={(data: any) => {
            // setLocationDetails({
            //   ...locationDetails,

            //   general: data,
            // });

            setGeneralSettings(data);
          }}
          defaultValue={browserState}
        />
      ),

      sidemenuIcon: <MdDashboard className="sidemenuIcon" />,
    },
    {
      label: "Areas",
      eventkey: "areas",
      component: (
        <Area
          locationName={generalSettings?.name}
          locationId={generalSettings?.id}
          locationAddress={generalSettings?.address}
          onUpdate={(data: any) => {
            // setLocationDetails({
            //   ...locationDetails,
            //   areas: data,
            // });

            setAreaSettings(data);
          }}
          defaultValue={browserState?.area}
        />
      ),
      sidemenuIcon: <FaShareAltSquare className="sidemenuIcon" />,
    },

    // {
    //   label: "Scheduling",
    //   key: 1,
    //   component: <Scheduling />,
    //   sidemenuIcon: <BsFillCalendarDateFill className="sidemenuIcon" />,
    // },
    // {
    //   label: "TimesSheets",
    //   key: 0,
    //   component: <TimeSheet />,
    //   sidemenuIcon: <IoIosTime className="sidemenuIcon" />,
    // },
    // {
    //   label: "Payroll",
    //   key: 0,
    //   // component: <GeneralInfo />,
    //   sidemenuIcon: <FaFileInvoiceDollar className="sidemenuIcon" />,
    // },

    // {
    //   label: "Notificatios & Integrations",
    //   key: 2,
    //   component: <NotificationsInegrations />,
    //   sidemenuIcon: <IoMdNotifications className="sidemenuIcon" />,
    // },
  ];
  const [activeOption, setActiveOptios] = useState(0);
  const navigate = useNavigate();

  function createLocation() {
    axiosInstance

      .post("/comply-tsfm/api/location", {
        ...generalSettings,
        area: areaSettings,
      })
      .then(({ data, status }) => {
        if (status === 200) {
          navigate(-1);
        }
      })
      .catch((error) => {
        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }

  useEffect(() => {
    if (view === "edit" && !state) {
      navigate(-1);
    }
    // if (id) {
    //   axiosInstance
    //     .get(`/comply-tsfm/api/location/${id}`)
    //     .then(({ data, status }) => {
    //       if (status === 200) {
    //         setLocationDetails({ general: data, areas: data.area });
    //       }
    //     })
    //     .catch((error) => {
    //       showModal({ APIError: error, APIType: "APIEXCEPTION" });
    //     });
    // }
  }, []);

  function updateLocation() {
    let data = {
      ...browserState,
      ...generalSettings,
      area: areaSettings || browserState.area,
    };
    axiosInstance
      .put(`/comply-tsfm/api/location/${state?.id}`, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setBrowserState(data);
          showModal({
            Component: (
              <Text heading bold centered>
                Location Details Updated!
              </Text>
            ),
          });
        }
      })
      .catch((error) => {
        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }
  return (
    <Container className="mt-5 p-4">
      <Row className="justify-content-end">
        <Col xs={12} md="auto" className="p-1">
          <Button
            variant="plain"
            fontSize="13px"
            noBorderRadius
            onClick={() => navigate("/app/location")}
          >
            Back
          </Button>
        </Col>
        {!roles.includes("ROLE_CLIENT") && (
          <>
            <Col xs={12} md="auto" className="p-1">
              <DropdownButton
                // labelColor="black"
                variant="plain"
                toggleVariant="plain"
                className="archive-btn"
                buttonProps={{ fontSize: "13px" }}
                noBorderRadius
                isSplitButton
                label="Archive"
              />
            </Col>
            <Col xs={12} md="auto" className="p-1">
              <Button
                noBorderRadius
                fontSize="13px"
                onClick={view === "edit" ? updateLocation : createLocation}
              >
                Save
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col lg={9} md={12} sm={12} className="mt-3 mb-3  ">
          <div className="white-container">
            <Row>
              <Col lg={12} md={12}>
                <div className="white-container">
                  {sideMenuOptions[activeOption].component}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col lg={3} md={3} sm={12} className="mt-3 mb-3 p-0"></Col> */}
        <Col lg={3} md={12} sm={12} className="mt-3 mb-3 resSidemenu ">
          <div className="white-container border rounded">
            <div className="pt-2 pb-2">
              <Text bold responsive fontSize="20px">
                {generalSettings?.name}
              </Text>
            </div>

            {sideMenuOptions.map(({ label, sidemenuIcon }, i) => (
              <a
                key={i}
                // href="#"
                style={{ textDecoration: "none" }}
                onClick={(e) => setActiveOptios(i)}
              >
                <Text
                  icon={sidemenuIcon}
                  key={i}
                  className={` ${
                    activeOption === i ? "edit-location-option-active" : ""
                  } ${"p-1 mt-3 edit-location-option"}`}
                  fontSize="14px"
                >
                  {label}
                </Text>
                <Divider />
              </a>
            ))}
            <div className="grey-container mt-3">
              <Text
                fontSize="13px"
                bold
                children="Looking for integrations and & payroll"
              />
              <Text
                className="pt-1 pb-1"
                fontSize="12px"
                children="integrations & payroll configurations has moved to the new integrations directory."
              />
              <Text
                fontSize="12px"
                color={Colors.HIGHLIGHTED}
                children="go to the integrations directory"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditLocation;
