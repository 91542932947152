const Industries = [
  "Aged care industry",
  "Agricultural industry",
  "Airline operations",
  "Airport operations",
  "Aluminium industry",
  "Ambulance and patient transport",
  "Amusement, events and recreation industry",
  "Animal care and veterinary services",
  "Aquaculture",
  "Asphalt industry",
  "Australian Capital Territory",
  "Banking, finance and insurance industry",
  "Broadcasting and recorded entertainment industry",
  "Building services",
  "Building, metal and civil construction industries",
  "Business equipment industry",
  "Cement and concrete products",
  "Cemetery operations",
  "Children's services",
  "Christmas Island",
  "Cleaning services",
  "Clerical industry",
  "Clothing industry",
  "Coal export terminals",
  "Coal industry",
  "Cocos (Keeling) Islands",
  "Commercial sales",
  "Commonwealth employment",
  "Contract call centre industry",
  "Corrections and detentions",
  "Diving services",
  "Dredging industry",
  "Dry cleaning and laundry services",
  "Educational services",
  "Electrical contracting industry",
  "Electrical power industry",
  "Fast food industry",
  "Federal police operations",
  "Fire fighting services",
  "Food, beverages and tobacco manufacturing industry",
  "Funeral directing services",
  "Gardening services",
  "Grain handling industry",
  "Graphic arts",
  "Hair and beauty",
  "Health and welfare services",
  "Hospitality industry",
  "Indigenous organisations and services",
  "Journalism",
  "Licensed and registered clubs",
  "Live performance industry",
  "Local government administration",
  "Mannequins and modelling",
  "Manufacturing and associated industries",
  "Marine tourism and charter vessels",
  "Market and business consultancy services",
  "Meat industry",
  "Mining industry",
  "Miscellaneous",
  "Norfolk Island",
  "Northern Territory",
  "Nursery industry",
  "Passenger vehicle transport (non rail) industry",
  "Pet food manufacturing",
  "Pharmaceutical industry",
  "Pharmacy operations",
  "Plumbing industry",
  "Port authorities",
  "Postal services",
  "Poultry processing",
  "Publishing industry",
  "Quarrying industry",
  "Racing industry",
  "Rail industry",
  "Real estate industry",
  "Restaurants",
  "Retail industry",
  "Road transport industry",
  "Rubber, plastic and cable making industry",
  "Salt industry",
  "Scientific services",
  "Seafood processing",
  "Security services",
  "Social, community, home care and disability services",
  "Sporting organisations",
  "State and Territory government administration",
  "Stevedoring industry",
  "Storage services",
  "Sugar industry",
  "Tasmania",
  "Technical services",
  "Telecommunications services",
  "Textile industry",
  "Timber and paper products industry",
  "Tourism industry",
  "Uranium mining (incl. construction)",
  "Vehicle industry",
  "Waste management industry",
  "Water, sewerage and drainage services",
  "Wine industry",
  "Wool storage, sampling and testing industry",
].map((industry) => ({ label: industry, value: industry }));
export default Industries;
