import {
  Dropdown,
  Row,
  Col,
  ButtonGroup,
  DropdownProps,
} from "react-bootstrap";
import { IOption } from "../../interface";
import Button, { ButtonProps, variants } from "../button/Button";
import Text from "../text/text";
import { ImLocation } from "react-icons/im";
import "./DropdownMenu.css";

interface IDropdown {
  menu?: Array<IOption>;
  onSelectedOption?: (option: IOption) => void;
  label?: string;
  withClearSelection?: boolean;
  doNotChangeLabel?: boolean;
  labelColor?: string;
  defaultValue?: number | string;
  fullWidth?: boolean;
  labelBold?: boolean;
  placeHolder?: string;
  withLabel?: boolean;
  arrowOnRight?: boolean;
  variant?: variants;
  toggleVariant?: variants;
  isSplitButton?: boolean;
  buttonProps?: ButtonProps;
  as?: DropdownProps["as"];
  size?: ButtonProps["size"];
  noBorderRadius?: boolean;
  className?: string;
  toggleButtonOn?: "right" | "left";
  indicatorIcon?: any;
}

export default function DropdownButton({
  label = "Label me",
  menu = [],
  fullWidth,
  isSplitButton,
  as = Button,
  buttonProps,
  size = "sm",
  variant = "dark_orange",
  noBorderRadius,
  className = "",
  labelColor,
  toggleVariant,
  toggleButtonOn = "right",
  indicatorIcon,
  onSelectedOption,
}: IDropdown) {
  const ToggleButton = () => (
    <Dropdown.Toggle
      className={`${indicatorIcon ? "qs-toggle" : ""} ${className}`}
      size={size}
      split
      variant={toggleVariant || variant}
      id="dropdown-split-basic"
      style={{
        borderRadius: noBorderRadius
          ? toggleButtonOn === "right"
            ? `0px 5px 5px 0px`
            : `5px 0px 0px 5px`
          : toggleButtonOn === "right"
          ? `0px 50px 50px 0px`
          : `50px 0px 0px 50px`,
        fontSize: buttonProps?.fontSize,
      }}
      // {...buttonProps}
    >
      {indicatorIcon}
    </Dropdown.Toggle>
  );
  return (
    <Dropdown
      className="customDropdown"
      as={as || isSplitButton ? ButtonGroup : undefined}
    >
      {isSplitButton ? (
        <>
          {toggleButtonOn === "left" && <ToggleButton />}
          <Button
            className={className}
            borderRadius={
              noBorderRadius
                ? toggleButtonOn === "right"
                  ? `0px 5px 5px 0px`
                  : `0px 0px 0px 0px`
                : toggleButtonOn === "right"
                ? `0px 50px 50px 0px`
                : `50px 0px 0px 50px`
            }
            textColor={labelColor}
            variant={variant}
            size={size}
            fullWidth={fullWidth}
            {...buttonProps}
          >
            {label}
          </Button>
          {toggleButtonOn === "right" && <ToggleButton />}
        </>
      ) : (
        <Dropdown.Toggle
          className={` ${className}`}
          as={as}
          fullWidth={fullWidth}
          size={size}
          noBorderRadius={noBorderRadius}
          variant={variant}
          // style={{ maxWidth: "150px" }}
          {...buttonProps}
        >
          <Text className="truncate-text">{label}</Text>
        </Dropdown.Toggle>
      )}
      {menu?.length > 0 && (
        <Dropdown.Menu
          style={{ width: fullWidth ? "100%" : "auto", zIndex: 10000 }}
        >
          {menu?.map((item, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                item.callback
                  ? item.callback(item)
                  : onSelectedOption && onSelectedOption(item);
              }}
            >
              <Row className="d-flex align-items-center px-1">
                {item.Icon && (
                  <Col
                    className="p-0 m-0 d-flex justify-content-center align-items-center"
                    xs={2}
                  >
                    {item.Icon()}
                  </Col>
                )}

                <Col xs={item.Icon ? "10" : "12"} className="p-0 m-0">
                  <Text
                    style={{
                      fontSize: "1.2vh",
                      wordWrap: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {item.label}
                  </Text>
                </Col>
              </Row>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}
