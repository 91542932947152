import moment, {Moment} from "moment";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row, Table} from "react-bootstrap";
import {ImSearch} from "react-icons/im";
import {useLocation} from "react-router-dom";
import {generateDateRange} from "../../../api/general";
import EmployeeBox from "../../../components/employee-box/employee";
import InputField from "../../../components/InputField/InputField";
import Text from "../../../components/text/text";
import {Colors} from "../../../constants";
import {useLocations} from "../../../hooks/location";
import {IEmployee} from "../../../modules/Employee";
import Button from "../../../components/button/Button";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {useBusinessProfile} from "../../../hooks/business";
import Logo from "../../../assets/logo-horizontal.svg";
interface WeeklyReportProps {
  staff: Array<IEmployee>;
  checkinsEmployeeWise: any;
  dayCount: number;
  locationId: number;
  dateRange: {startDate: Moment; endDate: Moment};
  areas?: Array<{
    id: number;
    name: string;
  }>;
  searchText: string;
}

export default function WeeklyReport({
  staff,
  checkinsEmployeeWise,
  dayCount,
  locationId,
  dateRange,
  areas,
  searchText,
}: WeeklyReportProps) {
  const browserState = useLocation();
  const tableParentDivRef = useRef<HTMLDivElement>(null);
  const [numOfDays, setNumOfDays] = useState(7);
  const [timePeriod, setTimePeriod] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({startDate: moment(), endDate: moment()});
  const [days, setDays] = useState<Array<Moment>>([]);

  // const [areas, setAreas] = useState<any>({});

  const {locations} = useLocations();
  const {businessName} = useBusinessProfile();

  const filteredEmployees = useCallback(() => {
    if (searchText) {
      return Object.values(staff).filter((employee) =>
        employee?.preferredTitle
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }
    return staff;
  }, [searchText, staff]);

  useEffect(() => {
    dateRange?.startDate &&
      setDays(generateDateRange(dateRange?.startDate.clone(), dayCount));
    if (dayCount) {
      setNumOfDays(dayCount);
    }
  }, [dateRange.startDate, dateRange.endDate, dayCount]);

  // if (locationRequest.loading) {
  //   return <Loader />;
  // }
  const columnWidth =
    (tableParentDivRef.current?.offsetWidth || 0) /
      (dateRange?.startDate ? numOfDays : 1) -
    250 / (dateRange?.startDate ? numOfDays : 1);

  return (
    <div className="" ref={tableParentDivRef}>
      <Table responsive className="weekly-table" id="weekly-print">
        <colgroup>
          <col style={{width: 200, minWidth: 200}} />
          {/* <col style={{ width: 100, minWidth: 100 }} /> */}
          {Array.from({length: numOfDays || 0}).map((_, index) => (
            <col
              style={{
                width: columnWidth,
                minWidth: columnWidth,
              }}
            />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th colSpan={numOfDays + 1}>
              <div style={{width: "100%"}} className="d-flex">
                <Image
                  src={Logo}
                  style={{width: 200, height: 100, alignSelf: "flex-end"}}
                />
              </div>
              <div style={{width: "100%"}}>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Text className="fw-bold fs-3">
                    Attendance Report by Location
                  </Text>
                  <Text className="fs-5">{businessName}</Text>
                </div>
                <Text fontSize={16} className="mb-2">
                  <span>
                    Location:{" "}
                    {locations?.find(({id}) => id === locationId)?.name}
                  </span>
                </Text>
                <Text fontSize={16} className="mb-2">
                  <span>
                    Period: {days?.[0]?.format("DD/MM/YYYY")} -{" "}
                    {days?.[days?.length - 1]?.format("DD/MM/YYYY")}
                  </span>
                </Text>
                <Text fontSize={16} className="mb-2">
                  <span>Report: Weekly</span>
                </Text>
              </div>
            </th>
          </tr>
          <tr className="align-items-center">
            <th>
              <Text centered fontSize="11px" bold color={Colors.BLUE}>
                Employees
              </Text>
            </th>
            {/* <th>
              <Text centered fontSize="11px" bold color={Colors.BLUE}>
                Area
              </Text>
            </th> */}
            {days.map((day, index) => (
              <th key={index}>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {day.format("ddd")}
                </Text>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {day.format("Do")}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {areas?.map((area, i) => (
            <>
              <tr style={{height: 1}}>
                <td colSpan={numOfDays + 1} className="area-name-td">
                  {area?.name}
                </td>
              </tr>
              {filteredEmployees()
                .filter(
                  ({id}) => checkinsEmployeeWise?.[area.id]?.[id] !== undefined
                )
                .map(({preferredTitle, id}, empI) => (
                  <tr key={empI}>
                    <td className="first-col">
                      <EmployeeBox
                        // image="https://picsum.photos/200/300"
                        preferredTitle={preferredTitle}
                        isScheduleModeMonthly={false}
                      />
                    </td>

                    {days?.map((day, index) => {
                      const checkins: {
                        checkins: Array<any>;
                        startTime: string;
                        endTime: string;
                      } =
                        checkinsEmployeeWise?.[area.id]?.[id]?.[
                          day.format("DD/MM/YYYY")
                        ] ?? {};

                      // const shiftDuration = moment.duration(
                      //   moment
                      //     .unix(Number(checkins?.endTime))
                      //     .diff(moment.unix(Number(checkins?.startTime)))
                      // );

                      return (
                        <td
                          key={index}
                          style={{height: "inherit"}}
                          className="p-2"
                        >
                          {checkins?.checkins === undefined &&
                            day.isBefore(moment(), "date") && (
                              <Text className="text-center fs-4">-</Text>
                            )}

                          {checkins?.checkins?.length > 0 && (
                            <Text className="mb-1">
                              Check-In:{" "}
                              {moment
                                .unix(Number(checkins?.startTime))
                                .format("hh:mm a")}
                            </Text>
                          )}
                          {/* {checkins?.checkins?.map(
                            (checkin: any, i, checkinArr: Array<any>) => {
                              return (
                                <Text>{`${i + 1}. ${moment
                                  .unix(Number(checkin?.startTime))
                                  .format("hh:mm a")} - ${
                                  checkinArr?.[i + 1]?.startTime
                                    ? moment
                                        .unix(
                                          Number(checkinArr?.[i + 1]?.startTime)
                                        )
                                        .format("hh:mm a")
                                    : checkinArr?.length - 1 === i
                                    ? moment
                                        .unix(Number(checkins?.endTime))
                                        .format("hh:mm a")
                                    : "N/A"
                                }`}</Text>
                              );
                            }
                          )} */}
                          {checkins?.checkins?.length > 0 && (
                            <div className="mt-1">
                              <Text>
                                Check-out:{" "}
                                {checkins?.endTime
                                  ? moment
                                      .unix(Number(checkins?.endTime))
                                      .format("hh:mm a")
                                  : "--"}
                              </Text>
                              {/* <Text className="mt-1 fw-bold">
                                Total Min(s): {shiftDuration.minutes()} Mins{" "}
                                {shiftDuration.seconds()} Sec
                              </Text> */}
                            </div>
                          )}
                        </td>
                      );
                    })}

                    {/* {checkinsEmployeeWise.hasOwnProperty(areaId) &&
                      checkinsEmployeeWise[areaId].hasOwnProperty(id) &&
                      Object.values(checkinsEmployeeWise[areaId][id])?.map(
                        (checkin: any) => (
                          <td style={{ height: "inherit" }}>
                            <Text>{JSON.stringify(checkin)}</Text>
                          </td>
                        )
                      )} */}
                  </tr>
                ))}
            </>
          ))}

          {/* {Object.entries(staff)?.map(([key, { preferredTitle, id }], i) => (
            <tr style={{ height: 1 }}>
              <td className="first-col">
                <EmployeeBox
                  image="https://picsum.photos/200/300"
                  preferredTitle={preferredTitle}
                  isScheduleModeMonthly={false}
                />
              </td>

              {days?.map((day, index) => (
                <td key={index} style={{ height: "inherit" }}>
                  {checkinsEmployeeWise.hasOwnProperty(id) &&
                    Object.entries(checkinsEmployeeWise[id])?.map(
                      ([key, value]) => <Text>{key}</Text>
                    )}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </Table>
    </div>
  );
}
