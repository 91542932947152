import { useEffect, useState } from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import Text, { IText } from "../../components/text/text";
import "./radio.css";
interface IRadioBtn {
  label?: string;
  groupName?: string;
  inline?: boolean;
  options?: Array<{ label: string; value: any }>;
  onChange?: (value: any) => void;
  defaultValue?: string;
  labelProps?: IText;
  radioProps?: FormCheckProps;
  isCheckbox?: boolean;
}

export default function RadioBtn({
  label,
  onChange,
  groupName,
  inline,
  options,
  defaultValue,
  labelProps,
  radioProps,
}: IRadioBtn) {
  // console.log(label, value === defaultValue);
  return (
    <>
      <Text heading {...labelProps}>
        {label}
      </Text>
      <Form>
        {options?.map(({ label, value }, i) => (
          <Form.Check
            key={i}
            inline={inline}
            type={"radio"}
            id={`${groupName}-${i}`}
            style={{ fontSize: 14 }}
            label={label}
            name={groupName}
            onClick={(e) => {
              onChange && onChange(value);
            }}
            defaultChecked={value === defaultValue}
            {...radioProps}
          />
        ))}
      </Form>
    </>
  );
}

interface CheckBoxesProps {
  label?: string;
  groupName?: string;
  inline?: boolean;
  options?: Array<{ label: string; value: any }>;
  onChange?: (values: any) => void;
  defaultValue?: Array<string>;
  labelProps?: IText;
  checkProps?: FormCheckProps;
}
export function CheckBoxes({
  label,
  onChange,
  groupName,
  inline,
  options,
  defaultValue,
  labelProps,
  checkProps,
}: CheckBoxesProps) {
  const [values, setValues] = useState<Array<string>>(defaultValue ?? []);

  useEffect(() => {
    onChange && onChange(values);
  }, [values]);

  // console.log({ defaultValue });
  return (
    <>
      <Text heading {...labelProps}>
        {label}
      </Text>
      <Form>
        {options?.map(({ label, value }, i) => (
          <Form.Check
            key={i}
            inline={inline}
            type={"checkbox"}
            id={`${groupName}-${i}`}
            style={{ fontSize: 14 }}
            label={label}
            name={groupName}
            // onClick={(e) => {
            //   onChange && onChange(value);
            // }}
            onChange={(e) => {
              // onChange&&onChange(e.currentTarget.checked)
              if (e.currentTarget.checked) {
                setValues([...values, value]);
              } else {
                setValues(values.filter((v) => v !== value));
              }
            }}
            defaultChecked={values?.includes(value)}
            {...checkProps}
          />
        ))}
      </Form>
    </>
  );
}
