import { IInputField, InputField } from "../InputField/InputField";
// import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import "./calendar-input.scss";

import Calendar, {
  DayValue,
  CalendarProps,
  Calendar as DateCalendar,
} from "@hassanmojab/react-modern-calendar-datepicker";
import { useState } from "react";
import moment from "moment";
import { Colors } from "../../constants";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

interface ICalendarInput {
  inputProps?: IInputField;
  calendarProps?: Omit<CalendarProps<DayValue>, "value">;
  onDateChanged?: any;
}
export default function CalendarInput({
  inputProps,
  calendarProps,
  onDateChanged,
}: ICalendarInput) {
  const [date, setDate] = useState<string>();

  return (
    <OverlayTrigger
      trigger="click"
      // placement={placement}
      placement="auto"
      rootClose
      overlay={
        <Popover className="calendar-popover">
          <DateCalendar
            onChange={(date) => {
              const dateMoment = moment({
                day: date?.day,
                month: date?.month ? date?.month - 1 : 0,
                year: date?.year,
              });
              onDateChanged && onDateChanged(dateMoment);
              setDate(dateMoment.format("YYYY-MM-DD"));
              document.body.click();
            }}
          />
        </Popover>
      }
    >
      <span>
        <InputField
          {...inputProps}
          inputProps={{
            value:
              date || inputProps?.inputProps?.defaultValue?.toString() || "",
          }}
        />
      </span>
    </OverlayTrigger>
    // <DateTime
    //   {...calendarProps}
    //   renderInput={(props, open, close) => (
    //     <InputField calenderProps={{ props, open, close }} {...inputProps} />
    //   )}
    // />

    // <div className="calendar-input">

    /* <DateCalendar */

    // wrapperClassName={`${inputProps?.fullwidth ? "w-100" : ""}`}
    // onChange={(date) => {
    //   const dateMoment = moment({
    //     day: date?.day,
    //     month: date?.month ? date?.month - 1 : 0,
    //     year: date?.year,
    //   });
    //   onDateChanged && onDateChanged(dateMoment);
    //   setDate(dateMoment.format("YYYY-MM-DD"));
    // }}
    // renderInput={({ ref }: any, context) => (
    //   <InputField
    //     ref={ref}
    //     {...inputProps}
    //     inputProps={{
    //       value:
    //         date || inputProps?.inputProps?.defaultValue?.toString() || "",
    //     }}
    //   />
    // )}
    // calendarClassName="calendar-input"
    // colorPrimary={Colors["cyan-12"]}
    // colorPrimaryLight={Colors["cyan-6"]}
    // calendarPopperPosition="auto"
    // minimumDate={{year:moment().subtract(18,"yea")}}

    // {...calendarProps}
    //   />
    // </div>
  );
}
