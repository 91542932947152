import { useEffect, useState } from "react";
import "./calendar-button.css";
import moment, { Moment } from "moment";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { OverlayTrigger, Popover, ButtonGroup } from "react-bootstrap";
import Button, { variants } from "../button/Button";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiTwotoneCalendar } from "react-icons/ai";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {
  Calendar,
} from "@hassanmojab/react-modern-calendar-datepicker";
export type CalenderMode = "Day" | "Week" | "2 Weeks" | "Month";
interface ICalendarButton {
  onDateChanged?: ({ startDate, endDate }: any) => void;
  mode?: CalenderMode;
  fullWidth?: boolean;
  fontSize?: string;
  borderRadius?: string;
  variant?: variants;
  backgroundColor?: string;

  style?: React.CSSProperties;
}
export default function CalendarButton({
  onDateChanged,
  mode,
  fullWidth,
  borderRadius = "50px",
  fontSize,
  variant = "no_color",
  backgroundColor,
  style,
}: ICalendarButton) {
  const [currentStartDate, setCurrentStartDate] = useState<
    Moment | undefined
  >();
  // moment({ hour: 0, minute: 0, second: 0 })
  const [currentEndDate, setCurrentEndDate] = useState<Moment | undefined>();
  // moment({ hour: 23, minute: 59, second: 59 })
  const [CalendarDateSelected, setCalendarDateSelected] = useState<
    Moment | undefined
  >(moment());
  const unit =
    mode === "Day"
      ? "d"
      : mode === "Week" || mode === "2 Weeks"
      ? "isoWeek"
      : "M";
  function calculateTimePeriod() {
    if (mode === "Day") {
      return `${currentStartDate?.format("DD MMM YYYY")}`;
    } else {
      return `${moment(currentStartDate).format("DD MMM")} - ${moment(
        currentEndDate
      ).format("DD MMM")}`;
    }
  }
  useEffect(() => {
    if (currentStartDate && currentEndDate && onDateChanged) {
      onDateChanged({ startDate: currentStartDate, endDate: currentEndDate });
    }
  }, [currentStartDate, currentEndDate]);

  useEffect(() => {
    if (mode === "Day") {
      setCurrentStartDate(
        CalendarDateSelected?.clone().set({ hour: 0, minute: 0, second: 0 })
      );
      setCurrentEndDate(
        CalendarDateSelected?.clone().set({ hour: 23, minute: 59, second: 59 })
      );
    }
    if (mode === "Week") {
      setCurrentStartDate(moment(CalendarDateSelected).startOf("isoWeek"));
      setCurrentEndDate(moment(CalendarDateSelected).endOf("isoWeek"));
    }
    if (mode === "2 Weeks") {
      setCurrentStartDate(moment(CalendarDateSelected).startOf("isoWeek"));
      setCurrentEndDate(
        moment(CalendarDateSelected).add(1, "week").endOf("isoWeek")
      );
    }
    if (mode === "Month") {
      setCurrentStartDate(moment(CalendarDateSelected).startOf("month"));
      setCurrentEndDate(moment(CalendarDateSelected).endOf("month"));
    }
  }, [mode, CalendarDateSelected]);

  const popover = (
    <Popover className="shadow-sm border-0 mw-100">
      {/* <Calendar
        onChange={(date: any) => {
          setCalendarDateSelected(date);
          document.body.click();
        }}
      /> */}
      <Calendar
        shouldHighlightWeekends
        onChange={(date) => {
          setCalendarDateSelected(
            moment({ year: date?.year, month: date!.month - 1, day: date?.day })
          );
          document.body.click();
        }}
      />
    </Popover>
  );
  return (
    <ButtonGroup size="sm" style={{ width: fullWidth ? "100%" : "auto" }}>
      <Button
        borderRadius={`${borderRadius} 0px 0px ${borderRadius}`}
        variant={variant}
        fontSize={fontSize}
        style={{ height: "100%", ...style }}
        // className="w-25"
        backgroundColor={backgroundColor}
        onClick={() => {
          setCurrentStartDate(
            currentStartDate
              ?.clone()
              .subtract(
                mode === "2 Weeks" ? 2 : 1,
                mode === "Day"
                  ? "d"
                  : mode === "Week" || mode === "2 Weeks"
                  ? "w"
                  : "M"
              )
              .startOf(unit)
          );
          setCurrentEndDate(
            currentEndDate
              ?.clone()
              .subtract(
                mode === "2 Weeks" ? 2 : 1,
                mode === "Day"
                  ? "d"
                  : mode === "Week" || mode === "2 Weeks"
                  ? "w"
                  : "M"
              )
              .endOf(unit)
          );
        }}
      >
        <MdOutlineArrowBackIos style={{ fontWeight: "bold" }} />
      </Button>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <span>
          <Button
            fontSize={fontSize}
            borderRadius="0px"
            variant={variant}
            backgroundColor={backgroundColor}
            style={{ height: "100%", ...style }}
            // className="w-100"
          >
            <div className="d-flex justify-content-center align-items-center">
              <AiTwotoneCalendar size={fontSize} />
              <div className="mx-2">{calculateTimePeriod()}</div>
              <IoMdArrowDropdown />
            </div>
          </Button>
        </span>
      </OverlayTrigger>
      <Button
        variant={variant}
        fontSize={fontSize}
        // borderRadius={`0px ${borderRadius} ${borderRadius} 0px`}
        backgroundColor={backgroundColor}
        style={{ height: "100%", ...style }}
        // className="w-25"
        onClick={() => {
          setCurrentStartDate(
            currentStartDate
              ?.clone()
              .add(
                mode === "2 Weeks" ? 2 : 1,
                mode === "Day"
                  ? "d"
                  : mode === "Week" || mode === "2 Weeks"
                  ? "w"
                  : "M"
              )
              .startOf(unit)
          );
          setCurrentEndDate(
            currentEndDate
              ?.clone()
              .add(
                mode === "2 Weeks" ? 2 : 1,
                mode === "Day"
                  ? "d"
                  : mode === "Week" || mode === "2 Weeks"
                  ? "w"
                  : "M"
              )
              .endOf(unit)
          );
        }}
      >
        <MdOutlineArrowForwardIos style={{ fontWeight: "bold" }} />
      </Button>
    </ButtonGroup>
  );
}
