import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import { IUser } from "../interface";

const UserContext = React.createContext<IUser & { setUser?: Function }>({
  email: "",
  setUser: () => {},
  id: "",
  roles: [],
  token: "",
  title: "",
  username: "",
  refreshToken: "",
  type: "Bearer",
  profileUrl: "",
});

export function UserProfileProvider({ children }: any) {
  const [user, setUser] = useState<IUser>({
    email: "",
    id: "",
    roles: [],
    token: "",
    title: "",
    username: "",
    refreshToken: "",
    type: "Bearer",
    profileUrl: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    } else {
      navigate("/");
    }
  }, []);

  return (
    <UserContext.Provider value={{ ...user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserProfile = () =>
  React.useContext<IUser & { setUser?: Function }>(UserContext);
