import { Col, Container, Row } from "react-bootstrap";
import Button from "../../../components/button/Button";
import Text from "../../../components/text/text";
import { Colors } from "../../../constants";
import { useUserProfile } from "../../../hooks/user";

interface IPersonal {
  onEditClicked?: Function;
  defaultValue?: any;
}
export default function Personal({ onEditClicked, defaultValue }: IPersonal) {
  const NOT_SPECIFIED = "Not Specified";
  const user = useUserProfile();
  return (
    <Container>
      <Row>
        <Col>
          <Text heading bold>
            Personal
          </Text>
        </Col>
        {/* {!user?.roles?.includes("ROLE_EMPLOYER") && ( */}
        <Col className="d-flex justify-content-end">
          {!user.roles.includes("ROLE_CLIENT") && (
            <Button
              variant="blue_color"
              onClick={() => {
                onEditClicked && onEditClicked();
              }}
            >
              Edit
            </Button>
          )}
        </Col>
        {/* )} */}
      </Row>
      {/* <Row>
        <Col xs="auto" className="my-2">
          <Button>Message</Button>
        </Col>
        <Col xs="auto" className="my-2">
          <Button>Send Login Details</Button>
        </Col>
        <Col xs="auto" className="my-2">
          <Button>Archive Team Member</Button>
        </Col>
        <Col xs="auto" className="my-2">
          <Button>Onboard Team Member</Button>
        </Col>
      </Row> */}
      <Row className="mt-3">
        <Col>
          <Text heading bold>
            Personal Details
          </Text>
        </Col>
      </Row>
      <Row
        style={{ backgroundColor: Colors.HEX_TO_RGBA(Colors.HIGHLIGHTED, 0.1) }}
        className="p-3 rounded mt-3"
      >
        <Col>
          <Text>Name</Text>
          <Text
            bold
          >{`${defaultValue?.firstName} ${defaultValue?.lastName}`}</Text>
        </Col>
        <Col>
          <Text>Preferred Name</Text>
          <Text bold>Not Specified</Text>
        </Col>
        <Col>
          <Text>Preferred Pronouns</Text>
          <Text bold>Not Specified</Text>
        </Col>
        <Col>
          <Text>Date of Birth</Text>
          <Text bold>{defaultValue?.dateOfBirth}</Text>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Text heading bold>
            Contact
          </Text>
        </Col>
      </Row>
      <Row
        style={{ backgroundColor: Colors.HEX_TO_RGBA(Colors.HIGHLIGHTED, 0.1) }}
        className="p-3 rounded mt-3"
      >
        <Col>
          <Text>Email</Text>
          <Text bold>{defaultValue?.email || NOT_SPECIFIED}</Text>
        </Col>
        <Col>
          <Text>Mobile</Text>
          <Text bold>{defaultValue?.contactNumber || NOT_SPECIFIED}</Text>
        </Col>
        <Col>
          <Text>Address</Text>
          <Text bold>{defaultValue?.address || NOT_SPECIFIED}</Text>
        </Col>
        <Col>
          <Text>Emergency Contact Name</Text>
          <Text bold>
            {defaultValue?.emergencyContactName || NOT_SPECIFIED}
          </Text>
        </Col>
        <Col>
          <Text>Emergency Contact Details</Text>
          <Text bold>
            {defaultValue?.emergencyContactPhone || NOT_SPECIFIED}
          </Text>
        </Col>
      </Row>
    </Container>
  );
}
