import {Button, Col, Container, Form, Row} from "react-bootstrap";
import logo from "../../assets/logo-horizontal-white.png";
// import Button from "../../components/button/Button";
import Text from "../../components/text/text";
import MediaQuery from "react-responsive";
import "./SignIn.scss";
import {useEffect, useState} from "react";
import axiosInstance from "../../api/axiosInstance";
import {useUserProfile} from "../../hooks/user";
import {useNavigate} from "react-router-dom";
import {MdEmail} from "react-icons/md";
import {FaKey} from "react-icons/fa";
import Link from "../../components/link/link";
import useQuery from "../../hooks/query";
import useModal from "../../hooks/modal";
import {Controller, useForm} from "react-hook-form";
import TextInput from "../../components-v2/input/input";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";

export default function SignIn() {
  const query = useQuery();
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      referenceToken: query.get("referenceToken"),
    },
    mode: "onBlur",
  });

  const {showModal} = useModal();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  // const [email, setEmail] = useState<string>();
  // const [password, setPassword] = useState<string>();
  // const [message, setMessage] = useState<string>();
  const {setUser} = useUserProfile();
  function loginAttempt(
    email: string,
    password: string,
    referenceToken: string | null
  ) {
    axiosInstance
      .post(
        "/comply-tsfm/api/auth/signin",
        {
          username: email,
          password,
          token: referenceToken,
        },
        {headers: {"Content-Type": "application/json"}}
      )
      .then(({data, status}) => {
        localStorage.setItem("user", JSON.stringify(data));
        setUser && setUser(data.user);

        const returnURL = query.get("returnURL");

        if (returnURL) {
          // window.location.href = `${returnURL}?token=${data?.token}`;
          navigate("/app/business-selection", {
            state: {
              returnURL: returnURL
                ? `${returnURL}?token=${data?.token}`
                : undefined,
            },
          });
        } else {
          navigate("/app/business-selection");
        }
      })
      .catch((error) => {
        showModal({APIError: error, APIType: "APIEXCEPTION"});
      });
  }

  useEffect(() => {
    if (query.get("token")) {
      axiosInstance
        .post("/comply-tsfm/api/auth/confirmEmail", {
          token: query.get("token"),
        })
        .then((response) => {
          if (response.status === 200) {
            showModal({APIType: "SUCESS", APIResponse: response});
          }
        })
        .catch((error) => {
          showModal({APIError: error, APIType: "APIEXCEPTION"});
        });
    }
  }, []);

  function renderPasswordVisibleIcon() {
    let props = {
      className: "sign-in-input-icon",
      style: {cursor: "pointer"},
      onClick: () => {
        setIsPasswordVisible(!isPasswordVisible);
      },
    };
    if (isPasswordVisible) {
      return <AiFillEyeInvisible {...props} />;
    } else {
      return <AiFillEye {...props} />;
    }
  }

  return (
    <section className="overlaybg">
      <Container fluid>
        <Row className="justify-content-center  fullscreen">
          <Col lg={8} md={10} sm={10} xs={10} className="my-auto">
            <MediaQuery minWidth={767}>
              <div className="text-center">
                <img src={logo} style={{maxWidth: "50%"}} />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={766}>
              <div className="text-center">
                <img src={logo} style={{maxWidth: "80%"}} />
              </div>
            </MediaQuery>
            <Form
              onSubmit={handleSubmit(
                (data) => {
                  loginAttempt(data.email, data.password, data.referenceToken);
                },
                (err) => {
                  console.log(err);
                }
              )}
            >
              <Row className="justify-content-center">
                <Col lg="4" md={6} sm="12">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter email",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    }}
                    control={control}
                    name="email"
                    render={({field: {onBlur, onChange, value, name}}) => (
                      <TextInput
                        controlProps={{value, onBlur, onChange}}
                        isValid={!errors[name]}
                        label="Email"
                        labelProps={{style: {color: "white"}}}
                        leftIcon={<MdEmail className="sign-in-input-icon" />}
                        wrapperClassName="sign-in"
                        bottomLabel={
                          <Form.Check
                            label="Remember Me"
                            style={{
                              color: "white",
                              minHeight: 0,
                            }}
                          />
                        }
                        feedbackText={errors[name] && errors?.[name]?.message}
                      />
                    )}
                  />
                </Col>
                <Col md={6} sm="12" lg="4">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter password",
                      },
                    }}
                    control={control}
                    name="password"
                    render={({field: {onBlur, onChange, value, name}}) => (
                      <TextInput
                        label="Password"
                        labelProps={{style: {color: "white"}}}
                        leftIcon={<FaKey className="sign-in-input-icon" />}
                        controlProps={{
                          type: isPasswordVisible ? "text" : "password",
                          value,
                          onBlur,
                          onChange,
                        }}
                        isValid={!errors[name]}
                        rightIcon={renderPasswordVisibleIcon()}
                        wrapperClassName="sign-in"
                        bottomLabel={
                          <Link
                            to="/forgotpassword"
                            className="sign-in__forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        }
                        feedbackText={errors[name] && errors?.[name]?.message}
                      />
                    )}
                  />
                </Col>
                <Col md={4} sm="12" lg="4">
                  <Button
                    type="submit"
                    className="w-100 sign-in__login-btn"
                    variant="dark_orange"
                    style={{
                      marginTop: "2rem",
                      height: "2.5rem",
                    }}
                  >
                    Login
                  </Button>
                </Col>
              </Row>
            </Form>
            {/* <Form>
              <Row
                className="align-items-center FormStyle"
                style={{ margin: "6rem 0rem 4rem 0rem" }}
              >
                <Col>
                  <InputField
                    inputClassName="p-2"
                    placeHolder="Enter your email"
                    label="Email"
                    fontSize="12px"
                    labelColor="white"
                    borderRadius="5px"
                    startIcon={<MdEmail color="lightgrey" size={25} />}
                    onChangedText={setEmail}
                    bottomlabel={
                      <Row className="align-items-center">
                        <Col xs="auto" className="">
                          <Form.Check className="sign-in-check-input m-0 p-0" />
                        </Col>
                        <Col xs="auto" className="p-0">
                          <Text color="white">Remember me</Text>
                        </Col>
                      </Row>
                    }
                  />
                </Col>
                <Col>
                  <InputField
                    inputClassName="p-2"
                    placeHolder="Enter your password"
                    label="Password"
                    fontSize="12px"
                    borderRadius="5px"
                    labelColor="white"
                    // checkBoxLabel="Remember me"
                    bottomlabel={
                      <Link to="/forgotpassword">
                        <Text color="white">Forgot Password?</Text>
                      </Link>
                    }
                    bottomLabelColor="white"
                    inputProps={{ type: "password" }}
                    onChangedText={setPassword}
                    startIcon={<FaKey color="lightgrey" size={25} />}
                  />
                </Col>
                <Col>
                  <Button
                    noBorderRadius
                    fullWidth
                    className="p-2 h-100 m-auto"
                    centeredText
                    fontSize="20px"
                    textProps={{ bold: true }}
                    onClick={loginAttempt}
                  >
                    LOGIN
                  </Button>
                </Col>
              </Row>
              {message && (
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Text fontSize="16px" color="white">
                      {message}
                    </Text>
                  </Col>
                </Row>
              )}
            </Form> */}

            <Row className="justify-content-center">
              <Col xs="auto">
                <Link to="/signup">
                  <Text fontSize="2rem" color="white">
                    Dont have an account? Sign Up!
                  </Text>
                </Link>
              </Col>
              {/* <Col xs="auto">
                <Text fontSize="2vw" bold color={Colors.BLUE}>
                  Sign Up
                </Text>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
