import { Col, Container, Modal, Row } from "react-bootstrap";
import { BsCalendarFill } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { Colors } from "../../constants";
import Button from "../button/Button";
import InputField from "../InputField/InputField";
import Text from "../text/text";

interface ISyncCalendarProps {
  show: boolean;
  onClose: () => void;
}

export default function SyncCalendar({ show, onClose }: ISyncCalendarProps) {
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header closeButton className="px-4 py-3">
        <Text fontSize="18px">Sync QSComply to Calendar</Text>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Container className="p-0">
          <Row>
            <Col>
              <Text>
                You can easily sync QSComply shifts with your personal calendar.
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>Weblink:</Text>
            </Col>
          </Row>
          <Row className="align-items-center mt-2 mb-3">
            <Col>
              <InputField
                inputProps={{ disabled: true }}
                fontSize="14px"
                backgroundColor={Colors["cyan-12"]}
              />
            </Col>
            <Col xs="auto">
              <Button variant="blue_color" noBorderRadius={false}>
                Copy Link
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12">
              <Text bold icon={<BsCalendarFill className="me-1" />}>
                Google Calendar:
              </Text>
            </Col>
            {[
              `Click "Copy Link" above`,
              "Open Google Calendar",
              `On the left side, find "Other Calendars" and click the down arrow`,
              `Select "Add by URL"`,
              "Paste the calendars address in the field provided",
              `Click "Add Calendar"`,
            ].map((item, index) => (
              <Col xs="12" key={index}>
                <Text icon={<GoPrimitiveDot className="mt-1 me-1" size={18} />}>
                  {item}
                </Text>
              </Col>
            ))}
          </Row>
          <Row className="align-items-center">
            <Col>
              <div
                style={{
                  backgroundColor: "lightgrey",
                  height: "2px",
                }}
              />
            </Col>
            <Col xs="auto">
              <Text>OR</Text>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "lightgrey",
                  height: "2px",
                }}
              />
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col>
              <Text>Sync shifts with Mail/Calendar application:</Text>
              <Text>
                Apple Calendar, Microsoft Outlook, Lotus Notes, Cloud Magic etc.
              </Text>
            </Col>
            <Col xs="auto">
              <Button variant="blue_color" noBorderRadius={false}>
                Subscribe
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
