import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
// import Header from "./components/Header/Header";
import DashBoard from "./pages/dashboard/dashboard";
import Employee from "./pages/employee/employee";
import EditLocation from "./pages/location/edit-location";
import Location from "./pages/location/location";
import Schedule from "./pages/schedule/schedule";
import ForgetPassword from "./pages/SignIn/ForgetPassword";
import SignIn from "./pages/SignIn/SignIn";
import Signup from "./pages/SignUp/SignUp";
import VerificationMethod from "./pages/SignUp/verification-method";
import NewPassword from "./pages/SignIn/NewPassword";
import {UserProfileProvider} from "./hooks/user";
import BusinessSelection from "./pages/businesss-selection/business-selection";
import NewsFeed from "./pages/news-feed/news-feed";
import Verify from "./pages/SignUp/verify";
import Profile from "./pages/profile/profile";
import {ModalProvider} from "./hooks/modal";
import Report from "./pages/report/report";
import DailyActivityReport from "./pages/report/daily-activity-report";
import {BusinessProfileProvider} from "./hooks/business";
import GenericReport from "./pages/report/report-types/generic-report";
import TimeSheet from "./pages/timesheet/timesheet";
import {EmployeeProvider} from "./hooks/employee";
import ExportTimesheet from "./pages/timesheet/export-timesheet";
import {LocationProvider} from "./hooks/location";
import Header from "./components-v2/header/header";

export default function App() {
  return (
    <div className="body">
      <ModalProvider>
        <Router>
          <Routes>
            <Route path="/app/*" element={<ProtectedRoutes />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:token" element={<Signup />} />
            <Route path={`/newpassword`} element={<NewPassword />} />
            <Route path={`/verify-method`} element={<VerificationMethod />} />
            <Route path={`/verify`} element={<Verify />} />
            <Route path={`/forgotpassword`} element={<ForgetPassword />} />
            <Route path="/" element={<SignIn />} />
            <Route path="/:token" element={<SignIn />} />
            <Route path="/getAuth" element={<>Auth</>} />
          </Routes>
        </Router>
      </ModalProvider>
    </div>
  );
}

function ProtectedRoutes({}) {
  const location = useLocation();
  const excludedHeaderPaths = ["/app/business-selection", "/app/profile"];

  function ReportRoutes({}) {
    return (
      <Routes>
        <Route path="/" element={<Report />} />
        <Route path="/check-in" element={<DailyActivityReport />} />
        <Route path="/generic-report" element={<GenericReport />} />
      </Routes>
    );
  }

  return (
    <>
      <UserProfileProvider>
        <BusinessProfileProvider>
          <EmployeeProvider>
            <LocationProvider>
              {excludedHeaderPaths.every(
                (path) => path !== location.pathname
              ) && <Header />}
              <div
                style={{
                  marginTop: excludedHeaderPaths.every(
                    (path) => path !== location.pathname
                  )
                    ? 66
                    : 0,
                }}
              >
                <Routes>
                  <Route
                    path="/business-selection"
                    element={<BusinessSelection />}
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/timesheet" element={<TimeSheet />} />
                  <Route
                    path="/export-timesheet"
                    element={<ExportTimesheet />}
                  />
                  <Route path={`/dashboard`} element={<DashBoard />} />
                  <Route path={`/schedule`} element={<Schedule />} />
                  <Route path={`/location/:view/`} element={<EditLocation />} />
                  <Route
                    path={`/location/:view/:id`}
                    element={<EditLocation />}
                  />
                  <Route path={`/location`} element={<Location />} />
                  <Route path={`/employee`} element={<Employee />} />
                  {/* <Route path={`/createbusiness`} element={<CreateBusiness />} /> */}
                  <Route path={`/feed`} element={<NewsFeed />} />
                  <Route path="/report/*" element={<ReportRoutes />} />
                </Routes>
              </div>
            </LocationProvider>
          </EmployeeProvider>
        </BusinessProfileProvider>
      </UserProfileProvider>
    </>
  );
}
