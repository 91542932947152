import { Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../components-v2/input/input";
import Text from "../../../../components/text/text";
import ScheduleModalProps from "./modal.props";
interface SaveShiftTemplateProps extends ScheduleModalProps {
  timeperiod: string;
}
export default function SaveShiftTemplate({
  onClose,
  show,
  timeperiod,
}: SaveShiftTemplateProps) {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Save Shift Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>
          {`You are about to save the shifts between ${timeperiod} as template.`}
        </Text>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Please enter a name for the template",
            },
          }}
          control={control}
          name="name"
          render={({ field }) => {
            return (
              <TextInput
                bottomLabel="Saving template with the same name will overwrite the previous template."
                label="Name"
                labelProps={{
                  className: "fw-bold fs-6",
                }}
                placeholder="Enter name"
                controlProps={field}
              />
            );
          }}
        />
        <Controller
          rules={{
            required: false,
          }}
          control={control}
          name="description"
          render={({ field }) => {
            return (
              <TextInput
                label="Description (Optional)"
                labelProps={{
                  className: "fw-bold fs-6 mt-3",
                }}
                placeholder="Enter Description"
                controlProps={{ ...field, as: "textarea" }}
              />
            );
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="cyan-12" size="sm" onClick={onClose}>
          Close
        </Button>
        <Button variant="blue_color" size="sm" onClick={onClose}>
          Save Template
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
