import { Col, Container, Row, Table } from "react-bootstrap";
import { BsFillImageFill } from "react-icons/bs";
import { IoMdMenu } from "react-icons/io";
import { ImRoad, ImSearch } from "react-icons/im";
import { GoPrimitiveDot } from "react-icons/go";
import { VscLocation } from "react-icons/vsc";
import Button from "../../components/button/Button";
import ButtonGroup from "../../components/button/ButtonGroup";
import PageBanner from "../../components/page-banner/page-banner";
import Text from "../../components/text/text";
import bannerImage from "./assets/1.png";
import { InputField } from "../../components/InputField/InputField";
import DropdownButton from "../../components/DropdownMenu/DropdownMenu";
import "./location.scss";
import { Colors, mapKey } from "../../constants";
import VideoModal from "../../components/video-modal/video-modal";
import { useCallback, useState } from "react";
import NewLocation from "./components/new-location";
import GoogleMapReact, { fitBounds } from "google-map-react";
import StatusLocation from "./components/status-location";
import { useAxios } from "../../api/useAxios";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import useModal from "../../hooks/modal";
import axiosInstance from "../../api/axiosInstance";
import { useUserProfile } from "../../hooks/user";
import { TiLocationOutline } from "react-icons/ti";
import TextInput from "../../components-v2/input/input";

export default function Location({}) {
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();
  const user = useUserProfile();
  const bannerContent = {
    heading: "Introduction to Locations and Areas",
    paragraph: (
      <div>
        Locations in QSComply generally reflect a physical address, department
        or client in your business.
        <br />A Single Location will have one or multiple areas within it. Areas
        are the teams, physical areas,
        <br /> or roles that team members are scheduled into and work in, under
        that location
      </div>
    ),
  };
  // Modal States
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showNewLocation, setShowNewLocation] = useState<boolean>(false);
  const [showLocationStatus, setShowLocationStatus] = useState<boolean>(false);

  //Modal Information States
  const [locationStatusIndex, setLocationStatusIndex] = useState<number>();

  //Fetched Data States
  const [searchInput, setSearchInput] = useState<string>("");
  const [locations, setLocations] = useState<Array<any>>([]);

  const [dataView, setDataView] = useState<"Table" | "Map">("Table");

  const locationFetch = useAxios(
    {
      url: "/comply-tsfm/api/location",
      method: "GET",
    },
    [],
    (data, error) => {
      if (data) {
        setLocations(data);
      }
    }
  );

  function deleteLocation(id: number) {
    if (id) {
      axiosInstance
        .delete(`/comply-tsfm/api/location/${id}`)
        .then(({ data, status }) => {
          if (status === 200) {
            showModal({
              Component: (
                <Text heading bold>
                  Location has been deleted successfully!
                </Text>
              ),
            });
            setLocations(locations.filter((location) => location.id !== id));
          }
        })
        .catch((error) => {
          showModal({
            APIError: error,
            APIType: "APIEXCEPTION",
          });
        });
    }
  }

  const filteredLocations = useCallback(() => {
    if (searchInput) {
      return locations.filter((location) => {
        return (
          location?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          location?.address.toLowerCase().includes(searchInput.toLowerCase()) ||
          location?.code?.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    return locations;
  }, [searchInput, locations]);
  // console.log(filteredLocations());

  function editDetails(id: number | string) {
    axiosInstance
      .get(`/comply-tsfm/api/location/${id}`)
      .then(({ data, status }) => {
        if (status === 200) {
          // setLocationDetails({ general: data, areas: data.area });

          let scheduleObject: any = {};
          for (let day of data?.schedule) {
            scheduleObject[day.day] = {
              startTime: day.startTime ? Number(day.startTime) : "",
              endTime: day.endTime ? Number(day.endTime) : "",
              isOpen: day.startTime && day.endTime ? true : false,
            };
          }

          navigate("/app/location/edit/", {
            state: { ...data, schedule: scheduleObject },
          });
        }
      })
      .catch((error) => {
        showModal({ APIError: error, APIType: "APIEXCEPTION" });
      });
  }

  function renderData() {
    const openLocationStatusModal = (indexOfLocationArr: number) => {
      setShowLocationStatus((locationStatusState) => {
        setLocationStatusIndex(indexOfLocationArr);
        return !locationStatusState;
      });
    };
    if (dataView === "Table") {
      return (
        <Table className="mt-2 locations-table">
          <thead>
            <tr>
              <td>
                <Text bold fontSize={14}>
                  Location Name
                </Text>
              </td>
              <td>
                <Text bold fontSize={14}>
                  Location Address
                </Text>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody className="border-top border-grey">
            {locationFetch.loading ? (
              <tr>
                <td colSpan={3}>
                  <Loader />
                </td>
              </tr>
            ) : (
              filteredLocations()?.map(
                ({ name, address, id }: any, i: number) => (
                  <tr key={i}>
                    <td>
                      <Button
                        textRef={(node) => {
                          if (node) {
                            node.style.setProperty(
                              "color",
                              Colors.NAVY_BLUE,
                              "important"
                            );
                          }
                        }}
                        className="p-0 m-0"
                        fontSize="12px"
                        variant="plain"
                        bold
                        startIcon={<GoPrimitiveDot color={Colors.NAVY_BLUE} />}
                        onClick={() => {
                          editDetails(id);

                          // openLocationStatusModal(i);
                        }}
                      >
                        {name}
                      </Button>
                    </td>
                    <td>
                      <Button
                        textRef={(node) => {
                          if (node) {
                            node.style.setProperty(
                              "color",
                              Colors.NAVY_BLUE,
                              "important"
                            );
                          }
                        }}
                        className="p-0 m-0"
                        fontSize="12px"
                        variant="plain"
                        startIcon={<VscLocation color={Colors.NAVY_BLUE} />}
                        onClick={() => {
                          editDetails(id);

                          // openLocationStatusModal(i);
                        }}
                      >
                        {address}
                      </Button>
                    </td>
                    {!user.roles.includes("ROLE_CLIENT") && (
                      <td className="d-flex justify-content-end">
                        <DropdownButton
                          buttonProps={{
                            fontSize: "10px",
                            onClick: () => {
                              // navigate(`/app/location/edit/${id}`);
                              editDetails(id);
                            },
                          }}
                          variant="cyan-6"
                          toggleVariant="cyan-12"
                          className="location-dropdown"
                          isSplitButton
                          label="Edit Settings"
                          noBorderRadius
                          menu={[
                            {
                              label: "View Details",
                              callback: () => {
                                navigate(`/app/location/edit/${id}`);
                              },
                            },
                            {
                              label: "Duplicate",
                              callback: () => {
                                navigate(`/app/location/create/${id}`);
                              },
                            },
                            {
                              label: "Schedule",
                              callback: () => {
                                navigate(`/app/schedule`);
                              },
                            },
                            // { label: "Approve Timesheets" },
                            // { label: "Message All" },
                            {
                              label: "Delete",
                              callback: () => {
                                showModal({
                                  Component: (
                                    <Text
                                      heading
                                      bold
                                    >{`Do you really want to delete "${name}"?`}</Text>
                                  ),
                                  FooterComponent: (
                                    <Container>
                                      <Row className="justify-content-end">
                                        <Col xs="auto">
                                          <Button
                                            onClick={() => {
                                              deleteLocation(id);
                                            }}
                                          >
                                            Yes
                                          </Button>
                                        </Col>
                                        <Col xs="auto">
                                          <Button onClick={() => closeModal()}>
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ),
                                });
                              },
                            },
                          ]}
                        />
                      </td>
                    )}
                  </tr>
                )
              )
            )}
          </tbody>
        </Table>
      );
    }
    if (dataView === "Map") {
      const Marker = ({}: any) => {
        return <TiLocationOutline color="red" size={40} />;
      };
      return (
        <div style={{ width: "100%", height: "400px" }} className="mt-3">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: mapKey,
            }}
            defaultCenter={{ lat: -33.86882, lng: 151.20929 }}
            defaultZoom={1}
          />
          {/* {filteredLocations()?.map(
              ({ name, address, id, latitude, longitude }: any, i: number) => (
                <Marker
                  key={i}
                  lat={Number(latitude)}
                  lng={Number(longitude)}
                />
              )
            )} */}
          {/* </GoogleMapReact> */}
        </div>
      );
    }
  }

  return (
    <>
      {locations.length > 0 && locationStatusIndex !== undefined && (
        <StatusLocation
          key={locations[locationStatusIndex].name}
          name={locations[locationStatusIndex].name}
          address={locations[locationStatusIndex].address}
          show={showLocationStatus}
          onClose={() => setShowLocationStatus(!showLocationStatus)}
        />
      )}
      <NewLocation
        // key={moment().unix()}
        show={showNewLocation}
        onClose={() => setShowNewLocation(!showNewLocation)}
      />
      <VideoModal
        onClose={() => setShowVideo(!showVideo)}
        title="Learn how to use QSComply"
        url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        show={showVideo}
      />
      <Container fluid>
        <Row className="p-4">
          <Col
            md={9}
            xs="12"
            // style={{ background: "red" }}
          >
            <Row>
              <Col>
                <Text
                  fontSize="28px"
                  className="ms-2"
                  bold
                  color={Colors.NAVY_BLUE}
                >
                  Locations
                </Text>
              </Col>
              <Col className="justify-content-end align-items-center d-flex">
                {!user.roles.includes("ROLE_CLIENT") && (
                  <Button
                    noBorderRadius
                    className="px-4"
                    variant="blue_color"
                    onClick={() => {
                      navigate("/app/location/create/");
                      // setShowNewLocation(!showNewLocation);
                    }}
                  >
                    <Text bold fontSize="12px">
                      Add Location
                    </Text>
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="my-3">
              <div>
                <PageBanner
                  heading={bannerContent.heading}
                  image={bannerImage}
                  paragraph={bannerContent.paragraph}
                  additionalComponent={
                    <Row>
                      <Col xs="auto" className="p-0">
                        <Button
                          variant="dark_orange"
                          bold
                          noBorderRadius
                          onClick={() => setShowVideo(!showVideo)}
                        >
                          <Text bold fontSize="12px">
                            Watch Video
                          </Text>
                        </Button>
                      </Col>
                      <Col xs="auto" className="p-0">
                        <Button noBorderRadius variant="no_color">
                          <div style={{ color: "white" }}>
                            About the News Feed
                          </div>
                        </Button>
                      </Col>
                    </Row>
                  }
                />
              </div>
            </Row>
            <div className="white-container mt-3 py-3 px-3">
              <Row className="align-items-center">
                <Col md="auto" xs={12}>
                  <ButtonGroup
                    buttonStyle={{
                      padding: "5px 50px",
                    }}
                    options={[
                      {
                        label: <IoMdMenu size={20} />,
                        buttonProps: { variant: "danger" },
                        onClick: () => setDataView("Table"),
                      },
                      {
                        label: <BsFillImageFill />,
                        buttonProps: { variant: "dark_orange" },
                        onClick: () => setDataView("Map"),
                      },
                      {
                        label: <ImRoad />,
                        buttonProps: { variant: "blue_color" },
                        onClick: () => setDataView("Table"),
                      },
                    ]}
                  />
                </Col>
                <Col className="d-flex justify-content-center ">
                  {/* <InputField
                    startIcon={
                      <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                    }
                    endIcon={
                      <Button
                        variant="blue_color"
                        fontSize="10px"
                        noBorderRadius={false}
                      >
                        Search
                      </Button>
                    }
                    placeHolder="Type to search"
                    fullwidth
                    fontSize="12px"
                    backgroundColor="white"
                    onChangedText={setSearchInput}
                  /> */}
                  <TextInput
                    placeholder="Type to Search"
                    wrapperClassName="location-main w-100"
                    rightIcon={
                      <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                    }
                    onChangeText={setSearchInput}
                  />
                </Col>
              </Row>
              {renderData()}
            </div>
          </Col>
          <Col
            md={3}
            xs="12"
            // style={{ background: "yellow" }}
            // className="ps-5"
          >
            <div className="white-container p-4">
              <Row>
                <Text heading bold>
                  We are here for you
                </Text>
              </Row>
              <Row>
                <Text>Get the help you need every step of the way.</Text>
              </Row>
              <Row>
                <InputField
                  startIcon={
                    <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                  }
                  placeHolder="Type to search"
                  fullwidth
                  fontSize="12px"
                  backgroundColor="white"
                />
              </Row>
              <Row>
                <Button
                  variant="blue_color"
                  fontSize="14px"
                  fullWidth
                  // style={{ margin: "0px 12px" }}
                >
                  Search
                </Button>
              </Row>
              <Row className=" mt-2">
                <Col xs="auto" className="p-0">
                  <Button
                    fontSize="11px"
                    startIcon={<GoPrimitiveDot color={Colors.HIGHLIGHTED} />}
                    variant="no_color"
                    textRef={(node) => {
                      if (node) {
                        node.style.setProperty(
                          "color",
                          Colors.HIGHLIGHTED,
                          "important"
                        );
                      }
                    }}
                  >
                    Visit Help Center
                  </Button>
                </Col>
                <Col xs="auto" className="p-0">
                  <Button
                    fontSize="11px"
                    startIcon={<GoPrimitiveDot color={Colors.HIGHLIGHTED} />}
                    variant="no_color"
                    textRef={(node) => {
                      if (node) {
                        node.style.setProperty(
                          "color",
                          Colors.HIGHLIGHTED,
                          "important"
                        );
                      }
                    }}
                  >
                    Hire a professional
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
