import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import Text from "../../components/text/text";
import { Colors } from "../../constants";
import Personal from "./employee-details-tab/personal";
import "./employee.scss";

interface IEmployeeDetails {
  show: boolean;
  onClose: () => void;
  defaultValue?: any;
  onEditClicked?: Function;
}
export default function EmployeeDetails({
  onClose,
  show,
  defaultValue,
  onEditClicked,
}: IEmployeeDetails) {
  const tabLink = {
    Profile: [
      {
        title: "Personal",
        eventKey: "profile",
        Component: (
          <Personal
            onEditClicked={onEditClicked}
            defaultValue={defaultValue?.employeeInfo}
          />
        ),
      },
      // {
      //   title: "Employement",
      //   eventKey: "employement",
      //   Component: <Text>Employement</Text>,
      // },
      // {
      //   title: "HR Documents",
      //   eventKey: "hr-docs",
      //   Component: <Text>Documents</Text>,
      // },
    ],
    // Schedule: [
    //   { title: "Shifts", eventKey: "shifts", Component: <Text>Shifts</Text> },
    //   { title: "Leave", eventKey: "leave", Component: <Text>Leave</Text> },
    //   {
    //     title: "Unavailability",
    //     eventKey: "unavailability",
    //     Component: <Text>Unavailability</Text>,
    //   },
    // ],
    // Activity: [
    //   {
    //     title: "News Feed",
    //     eventKey: "news-feeds",
    //     Component: <Text>News</Text>,
    //   },
    // ],
  };

  return (
    <Modal show={show} onHide={onClose} centered size="xl">
      <Modal.Header closeButton />
      <Modal.Body>
        <Tab.Container defaultActiveKey="profile">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Col className="align-middle">
                  <FaRegUserCircle style={{ width: "10vw", height: "10vh" }} />
                  <Text heading bold centered>
                    {`${defaultValue?.employeeInfo?.firstName} ${defaultValue?.employeeInfo?.lastName}`}
                  </Text>
                  <Text centered>{defaultValue?.employeeInfo?.access}</Text>
                </Col>

                {Object.entries(tabLink).map(([key, value], i) => (
                  <span key={i}>
                    <Text color={Colors.DARK_ORANGE}>{key.toUpperCase()}</Text>
                    {value.map(({ eventKey, title }, i) => (
                      <Nav.Item key={i}>
                        <Nav.Link
                          eventKey={eventKey}
                          className="employee-details-tab"
                        >
                          <Text>{title}</Text>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </span>
                ))}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                {Object.entries(tabLink).map(([key, value], i) =>
                  value.map(({ eventKey, Component }, i) => (
                    <Tab.Pane key={i} eventKey={eventKey}>
                      {Component}
                    </Tab.Pane>
                  ))
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}
