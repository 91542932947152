import { useState } from "react";
import { Badge, Button, Dropdown, Modal } from "react-bootstrap";
import { TiInfo } from "react-icons/ti";
import Text from "../../../components/text/text";
import EmployeeMainModalProps from "./modal-common.props";
import "./modal.styles.scss";
import Select from "react-select";
import SelectTheme from "../../../components-v2/Select/select-theme";
interface StessProfileModalProps extends EmployeeMainModalProps {}
export default function StessProfileModal({
  onHide,
  show,
}: StessProfileModalProps) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    "24/7"
  );

  const options = [
    {
      label: "24/7",
      eventKey: "1",
    },
    {
      label: "2 days per week",
      eventKey: "2",
    },
    {
      label: "CA Overtime 40hrs per week, 8hrs per day, max 6 days per week",
      eventKey: "3",
    },
    {
      label: "Max 20 hours per week",
      eventKey: "4",
    },
    {
      label: "Normal 38 hours per week",
      eventKey: "5",
    },
    {
      label: "Standard 40 hours, 8 hours per day",
      eventKey: "6",
    },
    {
      label: "Standard 40 hours, All day",
      eventKey: "7",
    },
  ];
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName="white-container"
    >
      <Modal.Header closeButton>
        <Text fontSize={20}>Set Stress Profile</Text>
      </Modal.Header>
      <Modal.Body>
        <Badge
          bg="cyan-12"
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: "15px",
            marginBottom: "10px",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <span style={{ marginRight: 10 }} className="badge-icon-color">
            <TiInfo />
          </span>
          Stress profiles are scheduling rules such as minimum and maximum
          hours. Use our templates below
        </Badge>
        <Text fontSize={16}>Stress Profile</Text>
        <Select
          styles={SelectTheme}
          options={options.map(({ label }) => ({
            label,
            value: label,
          }))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="blue_color"
          size="sm"
          onClick={() => {
            onHide();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
