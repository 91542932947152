import moment from "moment";
import { useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import { FcDownload } from "react-icons/fc";
import { MdOutlineDelete } from "react-icons/md";
import axiosInstance from "../../../api/axiosInstance";
import { useAxios } from "../../../api/useAxios";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader";
import Text from "../../../components/text/text";
import useModal from "../../../hooks/modal";
import useQuery from "../../../hooks/query";
import { ILocation } from "../../../interface";
import ReportFilters from "../components/filters";

interface IGenericReportProps {}
export type ReportTypes =
  | "WMS"
  | "AUDIT"
  | "CONSUMABLE_USAGE"
  | "INVOICE_SBS"
  | "COMPLIANCE_INSURANCE"
  | "RISK_ASSESSMENTS"
  | "SOW_SBS";

export default function GenericReport({}: IGenericReportProps) {
  const query = useQuery();
  const { showModal, closeModal } = useModal();
  const [resetFiltersCount, setResetFiltersCount] = useState(0);
  const [locations, setLocations] = useState<Array<ILocation>>([]);
  const [mode, setMode] = useState<"VIEW" | "UPLOAD">(
    (query.get("mode") as "VIEW" | "UPLOAD") || "VIEW"
  );

  const [reportType, setReportType] = useState<ReportTypes>(
    (query.get("reportType") as ReportTypes) || "SWIMS"
  );

  const titles = {
    WMS: "Work, Health and Safety Reports",
    AUDIT: "Audit Report",
    CONSUMABLE_USAGE: "Consumable Usage",
    INVOICE_SBS: "Invoice Site by Site",
    COMPLIANCE_INSURANCE: "Compliance/Insurances",
    RISK_ASSESSMENTS: "Risk Assessments Site by Site",
    SOW_SBS: "Scope of Work Site by Site",
  };

  let tableHeaderItems = ["Location", "Duration", "Date", "Description"];

  let [tableData, setTableData] = useState<
    Array<{
      location: string;
      duration: string;
      type: "AUDIT" | "SWIMS";
      reportDate: string;
      description: string;
      attachmentKey: string;
    }>
  >([]);

  const locationRequest = useAxios(
    { url: "/comply-tsfm/api/location" },
    [],
    (data, error) => {
      if (data) {
        setLocations(data);
      }
    }
  );

  const reportsRequest = useAxios(
    { url: `/comply-tsfm/api/locationReports?reportType=${reportType}` },
    [resetFiltersCount],
    (data, error) => {
      if (data) {
        setTableData(data);
      }
    }
  );

  return (
    <Container fluid>
      <Row className="mb-3  swims__header shadow-sm align-items-center ">
        <Col>
          <Text fontSize={24} bold color="#515151">
            {titles[reportType]}
          </Text>
        </Col>
      </Row>
      <Container fluid className="swims__body mt-5">
        {!locationRequest.loading && (
          <ReportFilters
            reportType={reportType}
            locations={locations}
            handleAs={mode === "VIEW" ? "FILTER" : "UPLOAD"}
            uploadCallback={(payload, callback) => {
              showModal({ Component: <Loader message="Uploading Report" /> });
              axiosInstance
                .post("/comply-tsfm/api/locationReports", payload)
                .then((res) => {
                  if (res.data) {
                    setTableData([...tableData, res.data]);
                  }
                  closeModal();
                  callback && callback();
                })
                .catch((err) => {
                  callback && callback();

                  showModal({ APIError: err, APIType: "APIEXCEPTION" });
                });
            }}
            filterCallback={(payload, callback) => {
              if (Object.keys(payload).length > 0) {
                showModal({ Component: <Loader message="Fetching Reports" /> });

                let data = Object.entries(payload)
                  .map(([key, value]) => {
                    return `${key}=${value}`;
                  })
                  .join("&");

                axiosInstance

                  .get(`/comply-tsfm/api/locationReports?${data}`)
                  .then((res) => {
                    if (res.data) {
                      setTableData(res.data);
                    }
                    closeModal();
                    callback && callback();
                  })
                  .catch((err) => {
                    callback && callback();

                    showModal({ APIError: err, APIType: "APIEXCEPTION" });
                  });
              } else {
                showModal({
                  Component: (
                    <Text bold heading>
                      Please use atleast one filter.
                    </Text>
                  ),
                });
              }
            }}
          />
        )}

        <div className=" d-flex justify-content-center mt-5">
          <div style={{ width: "55%" }}>
            <Table className="report-table">
              <thead>
                <tr>
                  {tableHeaderItems.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                  <th>Attachments</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.location}</td>
                    <td>{item.duration}</td>
                    <td>
                      {moment
                        .unix(Number(item.reportDate))
                        .format("DD-MM-YYYY")}
                    </td>
                    <td>{item.description}</td>
                    <td>
                      <Button
                        variant="cyan-12"
                        className="p-1"
                        onClick={() => {
                          axiosInstance
                            .get(
                              `/comply-tsfm/api/locationReports/attachment/${item.attachmentKey}`,
                              { responseType: "blob" }
                            )
                            .then((fileResponse) => {
                              //Create a Blob from the PDF Stream
                              //Build a URL from the file
                              const fileURL = URL.createObjectURL(
                                fileResponse.data
                              );
                              //Open the URL on new Window
                              window.open(fileURL);
                              // pdfWindow && (pdfWindow.location.href = fileURL);
                            })
                            .catch((error) => {
                              showModal({
                                APIError: error,
                                APIType: "APIEXCEPTION",
                              });
                            });
                        }}
                      >
                        <FaFilePdf />
                      </Button>
                    </td>
                    <td>
                      <Button
                        // backgroundColor="transparent"
                        variant="cyan-12"
                        className="p-1"
                        // textColor="white"
                        onClick={() => {
                          showModal({
                            Component: (
                              <Text bold heading>
                                Are you sure you want to delete this report?
                              </Text>
                            ),
                            FooterComponent: (
                              <>
                                <Button
                                  // className="p-1"
                                  onClick={() => {
                                    closeModal();
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="danger"
                                  // className="p-1"
                                  onClick={() => {
                                    axiosInstance
                                      .delete(
                                        `/comply-tsfm/api/locationReports/${item.attachmentKey}`
                                      )
                                      .then((res) => {
                                        setTableData(
                                          tableData.filter(
                                            (i) =>
                                              i.attachmentKey !==
                                              item.attachmentKey
                                          )
                                        );
                                        closeModal();
                                      })

                                      .catch((error) => {
                                        showModal({
                                          APIError: error,
                                          APIType: "APIEXCEPTION",
                                        });
                                      });
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            ),
                          });
                        }}
                      >
                        <MdOutlineDelete />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    </Container>
  );
}
