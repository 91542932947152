import moment, {Moment} from "moment-timezone";
import {useCallback, useEffect, useRef, useState} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Row,
} from "react-bootstrap";
import {CSVLink} from "react-csv";
import {IoMdCopy} from "react-icons/io";
import {RiSettings5Fill} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import {useAxios} from "../../api/useAxios";
import Calendar from "../../components-v2/Calendar/calendar";
import {CalenderMode} from "../../components/calendar-button/calendar-button";
import SyncCalendar from "../../components/sync-calendar/sync-calendar";
import Text from "../../components/text/text";
import {ShiftTypeColors} from "../../constants";
import {useEmployees} from "../../hooks/employee";
import {useLocations} from "../../hooks/location";
import useModal from "../../hooks/modal";
import Shifts from "../../modules/Shifts";
import AreaSchedule from "./components/area-schedule/area-schedule";
import AreaScheduleDay from "./components/area-schedule/area-schedule-day";
import DailyLayout from "./components/daily/daily-layout";
import AutoBuildShifts from "./components/modals/auto-build";
import AutoScheduling from "./components/modals/auto-scheduling";
import CopyShift from "./components/modals/copy-shifts";
import LoadTemplate from "./components/modals/load-template";
import NewShiftModal from "./components/modals/new-shift";
import RemoveAllShifts from "./components/modals/remove-all-shifts";
import RemoveBlankShifts from "./components/modals/remove-blank-shifts";
import RemoveTeamMembers from "./components/modals/remove-team-members";
import SaveShiftTemplate from "./components/modals/save-shift-template";
import UpdateBulkShifts from "./components/modals/update-bulk-shifts";
import MonthlyLayout from "./components/monthly/monthly-layout";
import WeeklyLayout from "./components/weekly/weekly-layout";
import "./schedule.scss";

export type ShiftType =
  | "unallocated"
  | "published"
  | "unpublished"
  | "openshift"
  | "warning"
  | "leave-approved"
  | "leave-pending"
  | "people-unavailable";

export default function Schedule({}) {
  const {showModal} = useModal();
  const navigate = useNavigate();
  const footerItems = [
    {label: "Unallocated", value: 0, iconColor: "#e6e5e8"},
    {label: "Published", value: 0, iconColor: "#2ea147"},
    {label: "Unpublished", value: 0, iconColor: "#89878e"},
    {label: "Open Shifts", value: 0, iconColor: "#ba4993"},
    {label: "Warnings", value: 0, iconColor: "#e0281c"},
    {label: "Leave Approved", value: 0, iconColor: "#b1160b"},
    {label: "Leave Pending", value: 0, iconColor: "#da928c"},
    {label: "People Unavailable", value: 0, iconColor: "#4a4463"},
  ];
  const [shiftToUpdate, setShiftToUpdate] = useState<Shifts.ShiftPayload>();
  const [staffSearchInput, setStaffSearchInput] = useState<string>("");
  const [copiedShift, setCopiedShift] = useState<Shifts.ShiftPayload>();
  const [scheduleMode, setScheduleMode] = useState<CalenderMode>("Week");
  const [scheduleView, setScheduleView] = useState<"Employee" | "Area">(
    "Employee"
  );
  const [timeperiod, setTimeperiod] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({startDate: moment(), endDate: moment().add(6, "days")});
  const [openNewShiftModal, setOpenNewShiftModal] = useState(false);
  const [newShiftModalBehaviour, setNewShiftModalBehaviour] = useState<
    "CREATE" | "UPDATE"
  >("CREATE");
  const [newShiftDate, setNewShiftDate] = useState<Moment>(moment());
  const [autoSchedulingShow, setAutoSchedulingShow] = useState<boolean>(false);
  const [showDeleteTeamMemberModal, setShowDeleteTeamMemberModal] =
    useState<boolean>(false);
  const [showRemoveBlankShifts, setShowRemoveBlankShifts] =
    useState<boolean>(false);
  const [showRemoveAllShifts, setShowRemoveAllShifts] =
    useState<boolean>(false);
  const [showUpdateBulkShifts, setShowUpdateBulkShifts] =
    useState<boolean>(false);
  const [showSyncCalendar, setShowSyncCalendar] = useState<boolean>(false);
  const [showSaveShiftTemplate, setShowSaveShiftTemplate] =
    useState<boolean>(false);
  const [showLoadShiftTemplate, setShowLoadShiftTemplate] =
    useState<boolean>(false);
  const [showCopyShift, setShowCopyShift] = useState<boolean>(false);
  const [showAutoBuildShifts, setShowAutoBuildShifts] =
    useState<boolean>(false);

  // const [staff, setStaff] = useState<Array<IStaff>>([]);
  // const [locations, setLocations] = useState<Array<ILocation>>([]);
  const {locations} = useLocations();
  const staff = useEmployees().employees;

  const [createShiftDefaultValues, setCreateShiftDefaultValues] =
    useState<Partial<Shifts.ShiftPayload>>();

  const [shifts, setShifts] = useState<Array<Shifts.ShiftReponse>>([
    // {
    //   id: 328,
    //   location: {
    //     id: 35,
    //     location: "Coalsite",
    //   },
    //   area: {
    //     id: 62,
    //     area: "abc",
    //   },
    //   employee: {
    //     userId: 3,
    //     id: 86,
    //     employee: "Saoud Mirza test test 2",
    //   },
    //   startDate: "1657116000",
    //   startTime: "1657129658",
    //   endTime: "1657172860",
    //   mealBreak: 0,
    //   restBreak: 0,
    //   remarks: "abc",
    // },
  ]);

  // const staffRequest = useAxios(
  //   { url: "/comply-tsfm/api/employee" },
  //   [],
  //   (data, error) => {
  //     if (data) {
  //       setStaff(data);
  //     }
  //   }
  // );
  // const locationRequest = useAxios(
  //   { url: "/comply-tsfm/api/location" },
  //   [],
  //   (data, error) => {
  //     if (data) {
  //       setLocations(data);
  //     }
  //   }
  // );

  const shiftRequest = useAxios(
    {
      url: `/comply-tsfm/api/schedule?endDate=${timeperiod?.endDate?.format(
        "yyyy-MM-DD"
      )}&startDate=${timeperiod?.startDate?.format("yyyy-MM-DD")}`,
      method: "GET",
    },
    [timeperiod.endDate, timeperiod.startDate],
    (data, error) => {
      if (data) {
        setShifts(data);
      }
    }
  );

  const randomNumber = (min: number = 0, max: number = 7) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const filterStaff = useCallback(() => {
    const allStaff = staff
      ?.map((staff) => ({...staff, color: ShiftTypeColors[randomNumber()]}))
      .filter(
        (staffItem) =>
          staffItem.preferredTitle
            .toLowerCase()
            .includes(staffSearchInput.toLowerCase()) || false
      );
    return allStaff && allStaff?.length > 0 ? allStaff : [];
  }, [staffSearchInput, staff]);

  // const filteredShifts = useCallback(() => {
  //   if (selectedLocationId) {
  //     let filteredShifts: any = {};
  //     for (let shift in shifts) {
  //       filteredShifts[shift] = [];

  //       let selectedShiftArr = shifts[shift] as Array<IShift>;
  //       for (let selectedShift in selectedShiftArr) {
  //         let shiftObj = selectedShiftArr[selectedShift];
  //         let key = shift;

  //         if (
  //           typeof shiftObj.locationId !== "string" &&
  //           shiftObj.locationId?._id === selectedLocationId
  //         ) {
  //           filteredShifts[key]?.push(selectedShiftArr[selectedShift]);
  //         }
  //       }
  //     }
  //     return Object.entries(filteredShifts);
  //   } else {
  //     return Object.entries(shifts);
  //   }
  // }, [locations, selectedLocationId, timeperiod, shifts]);

  const csvRef = useRef<any>(null);
  const [csvProps, setCsvProps] = useState<any>();
  async function generateCSV() {
    const headers = [
      {label: "Location Name", key: "locationName"},
      {label: "Area Name", key: "areaName"},
      {label: "Employee", key: "employee"},
      {label: "Start Date", key: "startDate"},
      {label: "Start Time", key: "startTime"},
      {label: "End time", key: "endTime"},
      {label: "Meal Break", key: "mealBreak"},
      {label: "Rest Break", key: "restBreak"},
      {label: "Total Hours", key: "totalHours"},
      {label: "Comments", key: "comments"},
      {label: "Status", key: ""},
      {label: "Cost", key: ""},
    ];
    const shiftToBeExported = shifts
      .filter(
        (shift) =>
          moment
            .unix(Number(shift.startDate))
            ?.isSameOrAfter(timeperiod.startDate, "day") &&
          moment
            .unix(Number(shift.startDate))
            ?.isSameOrBefore(timeperiod.endDate, "day")
      )
      .map(
        ({
          employee,
          startDate,
          startTime,
          endTime,
          mealBreak,
          restBreak,
          remarks,

          location,
          areas,
        }) => ({
          // areaName: area.area,
          locationName: location?.location,
          employee: employee?.employee,
          startDate: moment.unix(Number(startDate))?.format("DD-MM-YYYY"),
          startTime: moment.unix(Number(startTime)).format("hh:mm A"),
          endTime: moment.unix(Number(endTime)).format("hh:mm A"),
          mealBreak,
          restBreak,
          totalHours: moment
            .duration(
              moment.unix(Number(endTime)).diff(moment.unix(Number(startTime)))
            )
            .asHours(),
          comments: remarks,
        })
      );

    setCsvProps({
      headers,
      data: shiftToBeExported,
      filename: `${timeperiod.startDate.format(
        "DD-MM-YYYY"
      )}-${timeperiod.endDate.format("DD-MM-YYYY")}`,
    });
  }

  const [shiftsEmployeeWise, setShiftsEmployeeWise] = useState<any>({});
  const [shiftsLocationWise, setShiftsLocationWise] = useState<any>({});

  function generateShiftsEmployeeWise() {
    let shiftsEmployeeWise: any = {};
    for (let shift of shifts) {
      let shiftDate: string | undefined = moment
        .unix(Number(shift.startDate))
        ?.format("YYYY-MM-DD");

      if (
        shift.employee &&
        shift.startDate &&
        shiftsEmployeeWise
          ?.hasOwnProperty(shift.employee.id)
          ?.hasOwnProperty(shiftDate)
      ) {
        shiftDate &&
          shiftsEmployeeWise[shift.employee.id][shiftDate].push(shift);
      } else {
        if (shift.employee && shiftDate) {
          shiftsEmployeeWise[shift.employee.id] = {
            [shiftDate]: [],

            ...shiftsEmployeeWise[shift.employee.id],
          };
          shiftsEmployeeWise[shift.employee.id][shiftDate].push(shift);
        }
      }
    }
    return shiftsEmployeeWise;
  }

  function generateShiftsLocationWise(
    shiftsArr: Array<Shifts.ShiftReponse> = shifts
  ) {
    let shiftsLocationWise: any = {};
    for (let shift of shiftsArr) {
      let shiftDate: string = moment
        .unix(Number(shift.startDate))
        ?.format("YYYY-MM-DD");

      if (!shiftsLocationWise.hasOwnProperty(shift.location!.id)) {
        shiftsLocationWise[shift.location!.id] = {};
      }
      shiftsLocationWise[shift.location!.id] = {
        ...shiftsLocationWise[shift.location!.id],
        [shift.areas?.[0]?.areaId as any]: {
          ...shiftsLocationWise[shift.location!.id][
            shift.areas?.[0]?.areaId as any
          ],
          [shiftDate]: (
            shiftsLocationWise[shift.location!.id]?.[
              shift.areas?.[0]?.areaId as any
            ]?.[shiftDate] ?? []
          ).concat(shift),
        },
      };
    }

    return shiftsLocationWise;
  }

  // function createShift(
  //   shift: any,
  //   responseCallback: (responseShift: any) => void
  // ) {
  //   const neededFields = [
  //     { key: "startDate", label: "Date" },
  //     { key: "startTime", label: "Start Time" },
  //     { key: "endTime", label: "End Time" },
  //     { key: "employeeId", label: "Employee" },
  //     { key: "areaId", label: "Area" },
  //     { key: "locationId", label: "Location" },
  //     { key: "mealBreak", label: "Meal Break" },
  //     { key: "restBreak", label: "Rest Break" },
  //     { key: "remarks", label: "Remarks" },
  //   ];
  //   const data = removeEmptyOrNull({
  //     ...shift,
  //     startDate: moment
  //       .unix(Number(shift.startDate))
  //       .set({ hour: 0, minute: 0, second: 0 })
  //       .unix()
  //       .toString(),
  //     employeeId: shift?.employee?.id,
  //     areaId: shift?.area?.id,
  //     locationId: shift?.location?.id,
  //   });
  //   const requiredFields = checkRequiredFields(neededFields, data);

  //   if (requiredFields.allFilled) {
  //     axiosInstance
  //       .post("/comply-tsfm/api/schedule", data)
  //       .then(({ data, status }) => {
  //         if (status === 200) {
  //           responseCallback(data);
  //         }
  //       })
  //       .catch((APIError) => {
  //         showModal({ APIError, APIType: "APIEXCEPTION" });
  //       });
  //   } else {
  //     showModal({
  //       Component: (
  //         <Text>{requiredFields?.missingekeys?.join(", ")} are required</Text>
  //       ),
  //     });
  //   }
  // }

  useEffect(() => {
    if (scheduleView === "Employee") {
      setShiftsEmployeeWise(() => generateShiftsEmployeeWise());
    }
    if (scheduleView === "Area") {
      setShiftsLocationWise(() => generateShiftsLocationWise());
    }
  }, [shifts, scheduleView]);

  // function UpdateLocationWiseShift(data: any) {
  //   setShiftsLocationWise(() => {
  //     const startDateMoment = moment
  //       .unix(Number(data.startDate))
  //       .format("YYYY-MM-DD");
  //     if (!shiftsLocationWise.hasOwnProperty(data.location.id)) {
  //       shiftsLocationWise[data.location.id] = {};
  //     }
  //     if (!shiftsLocationWise[data.location.id].hasOwnProperty(data.area.id)) {
  //       shiftsLocationWise[data.location.id][data.area.id] = {};
  //     }

  //     if (
  //       createShiftDefaultValues &&
  //       shiftsLocationWise[createShiftDefaultValues!.location!.id]?.[
  //         createShiftDefaultValues!.area!.id
  //       ]?.[startDateMoment]
  //     ) {
  //       shiftsLocationWise[createShiftDefaultValues!.location!.id][
  //         createShiftDefaultValues!.area!.id
  //       ][startDateMoment] = shiftsLocationWise[
  //         createShiftDefaultValues!.location!.id
  //       ][createShiftDefaultValues!.area!.id][startDateMoment].filter(
  //         (shift: any) => shift.id !== data.id
  //       );
  //     }

  //     return {
  //       ...shiftsLocationWise,
  //       [data.location.id]: {
  //         ...shiftsLocationWise?.[data.location.id],
  //         [data.area.id]: {
  //           ...shiftsLocationWise?.[data.location.id]?.[data.area.id],
  //           [startDateMoment]: [
  //             ...(
  //               shiftsLocationWise?.[data.location.id]?.[data.area.id]?.[
  //                 startDateMoment
  //               ] ?? []
  //             )?.filter((shift: IShift) => shift.id !== data.id),
  //             data,
  //           ],
  //         },
  //       },
  //     };
  //   });
  // }

  // function addLocationWiseShift(data: any) {
  //   setShiftsLocationWise(() => {
  //     const startDateMoment = moment
  //       .unix(Number(data.startDate))
  //       .format("YYYY-MM-DD");

  //     return {
  //       ...shiftsLocationWise,
  //       [data.location.id]: {
  //         ...shiftsLocationWise?.[data.location.id],
  //         [data.area.id]: {
  //           ...shiftsLocationWise?.[data.location.id]?.[data.area.id],
  //           [startDateMoment]: (
  //             shiftsLocationWise?.[data.location.id]?.[data.area.id]?.[
  //               startDateMoment
  //             ] ?? []
  //           ).concat(data),
  //         },
  //       },
  //     };
  //   });
  // }

  // function addEmployeeWiseShift(data: any) {
  //   const startDateMoment = moment
  //     .unix(Number(data.startDate))
  //     .format("YYYY-MM-DD");

  //   setShiftsEmployeeWise(() => {
  //     return {
  //       ...shiftsEmployeeWise,
  //       [data.employee.id]: {
  //         ...shiftsEmployeeWise?.[data.employee.id],
  //         [startDateMoment]: (
  //           shiftsEmployeeWise?.[data.employee.id]?.[startDateMoment] ?? []
  //         ).concat(data),
  //       },
  //     };
  //   });
  // }

  // function updateEmployeeWiseShift(data: any) {
  //   const startDateMoment = moment
  //     .unix(Number(data.startDate))
  //     .format("YYYY-MM-DD");
  //   if (!shiftsEmployeeWise.hasOwnProperty(data.employee.id)) {
  //     shiftsEmployeeWise[data.employee.id] = {};
  //   }
  //   if (
  //     shiftsEmployeeWise[createShiftDefaultValues!.employee!.id].hasOwnProperty(
  //       startDateMoment
  //     )
  //   ) {
  //     shiftsEmployeeWise[createShiftDefaultValues!.employee!.id][
  //       startDateMoment
  //     ] = shiftsEmployeeWise[createShiftDefaultValues!.employee!.id][
  //       startDateMoment
  //     ].filter((shift: any) => shift.id !== data.id);
  //   }
  //   setShiftsEmployeeWise(() => ({
  //     ...shiftsEmployeeWise,
  //     [data.employee.id]: {
  //       ...shiftsEmployeeWise?.[data.employee.id],
  //       [startDateMoment]: [
  //         ...(shiftsEmployeeWise?.[data.employee.id]?.[startDateMoment] ?? []),
  //         data,
  //       ],
  //     },
  //   }));
  // }

  function deleteEmployeeWiseShift(shiftId: number) {
    setShiftsEmployeeWise(() => ({
      ...shiftsEmployeeWise,
      [createShiftDefaultValues!.employee!.id]: {
        ...shiftsEmployeeWise?.[createShiftDefaultValues!.employee!.id],
        [moment
          .unix(Number(createShiftDefaultValues!.startDate))
          .format("YYYY-MM-DD")]: shiftsEmployeeWise?.[
          createShiftDefaultValues!.employee!.id
        ]?.[
          moment
            .unix(Number(createShiftDefaultValues!.startDate))
            .format("YYYY-MM-DD")
        ].filter((shift: any) => shift.id !== shiftId),
      },
    }));
  }

  function deleteLocationWiseShift(shiftId: number) {
    let date = moment
      .unix(Number(createShiftDefaultValues!.startDate))
      .format("YYYY-MM-DD");
    setShiftsLocationWise(() => ({
      ...shiftsLocationWise,
      [createShiftDefaultValues!.location!.id]: {
        ...shiftsLocationWise?.[createShiftDefaultValues!.location!.id],
        [createShiftDefaultValues!.areas![0].areaId]: {
          ...shiftsLocationWise?.[createShiftDefaultValues!.location!.id]?.[
            createShiftDefaultValues!.areas![0].areaId
          ],
          [date]: shiftsLocationWise?.[
            createShiftDefaultValues!.location!.id
          ]?.[createShiftDefaultValues!.areas![0].areaId]?.[date].filter(
            (shift: any) => shift.id !== shiftId
          ),
        },
      },
    }));
  }

  return (
    <div className="d-none d-lg-block">
      {csvProps?.data?.length > 0 && <CSVLink ref={csvRef} {...csvProps} />}
      {/* Modals */}
      <NewShiftModal
        key={`${newShiftDate.toString()}-${newShiftModalBehaviour}-${
          createShiftDefaultValues?.employeeId
        }-${createShiftDefaultValues?.areas?.[0].id}`}
        show={openNewShiftModal}
        onClose={({action, data, shiftId}) => {
          setOpenNewShiftModal(false);
          setCreateShiftDefaultValues(undefined);
          if (action === "CREATE") {
            setShifts([...shifts, data]);
            if (scheduleView === "Area") {
              // addLocationWiseShift(data);
            } else {
              // addEmployeeWiseShift(data);
            }
          }
          if (action === "UPDATE") {
            setShifts([
              ...shifts.filter((shift: any) => shift.id !== shiftId),
              data,
            ]);
            // if (scheduleView === "Area") {
            //   UpdateLocationWiseShift(data);
            // } else {
            //   updateEmployeeWiseShift(data);
            // }
          }
          if (action === "DELETE") {
            // if (scheduleView === "Area") {
            //   deleteLocationWiseShift(shiftId!);
            // } else {
            // deleteEmployeeWiseShift(shiftId!);
            setShifts((prev) => prev.filter(({id}) => id !== shiftId));
            // }
          }
        }}
        date={newShiftDate}
        defaultValues={createShiftDefaultValues}
        action={newShiftModalBehaviour}
      />
      {/* <NewShiftModal
        // key={moment().unix()}
        staffList={staff.map(({ preferredTitle, id }) => ({
          label: preferredTitle,
          value: id,
        }))}
        locationList={locations.map(({ name, id }) => ({
          label: name,
          value: id,
        }))}
        date={newShiftDate}
        closeCallback={({ action, addShift, shiftId }) => {
          setOpenNewShiftModal(false);

          if (action === "CREATE" && addShift) {
            setShifts((shifts) => {
              return [...shifts, addShift];
            });
          }

          if (action === "UPDATE" && addShift) {
            setShifts((shifts) => [
              ...shifts.filter((shift) => shift.id !== addShift.id),
              addShift,
            ]);
          }
          if (action === "DELETE" && shiftId) {
            setShifts((shifts) =>
              shifts.filter((shift) => shift.id !== shiftId)
            );
          }

          shiftToUpdate && setShiftToUpdate(undefined);
          createShiftDefaultValues && setCreateShiftDefaultValues(undefined);
        }}
        show={openNewShiftModal}
        // updateId={shiftIdToUpdate}
        shiftToUpdate={shiftToUpdate}
        defaultValues={createShiftDefaultValues}
      /> */}
      <AutoScheduling
        show={autoSchedulingShow}
        onClose={() => setAutoSchedulingShow(!autoSchedulingShow)}
      />
      <RemoveTeamMembers
        show={showDeleteTeamMemberModal}
        onClose={() => setShowDeleteTeamMemberModal(!showDeleteTeamMemberModal)}
      />
      <RemoveBlankShifts
        show={showRemoveBlankShifts}
        onClose={() => setShowRemoveBlankShifts(!showRemoveBlankShifts)}
      />

      <RemoveAllShifts
        show={showRemoveAllShifts}
        onClose={() => setShowRemoveAllShifts(!showRemoveAllShifts)}
      />
      <UpdateBulkShifts
        numberOfShifts={shifts.length}
        show={showUpdateBulkShifts}
        onClose={() => setShowUpdateBulkShifts(!showUpdateBulkShifts)}
      />
      <SaveShiftTemplate
        show={showSaveShiftTemplate}
        onClose={() => setShowSaveShiftTemplate(!showSaveShiftTemplate)}
        timeperiod={`${timeperiod.startDate.format(
          "DD-MM-YYYY"
        )} - ${timeperiod.endDate.format("DD-MM-YYYY")}`}
      />
      <LoadTemplate
        show={showLoadShiftTemplate}
        onClose={() => setShowLoadShiftTemplate(!showLoadShiftTemplate)}
        onCreateNew={() => setShowSaveShiftTemplate(true)}
      />
      <CopyShift
        key={copiedShift?.id}
        show={showCopyShift}
        onClose={() => setShowCopyShift(!showCopyShift)}
        timeperiod={timeperiod}
        copiedShift={copiedShift}
        onShiftsAdded={(addShifts) => {
          setShifts(shifts.concat(addShifts));
          setCopiedShift(undefined);
        }}
      />
      <AutoBuildShifts
        show={showAutoBuildShifts}
        onClose={() => setShowAutoBuildShifts(!showAutoBuildShifts)}
      />
      <SyncCalendar
        show={showSyncCalendar}
        onClose={() => setShowSyncCalendar(!showSyncCalendar)}
      />

      <div className="p-2 overflow-auto" style={{minWidth: "900px"}}>
        <Container
          fluid
          className=" p-0 m-0 overflow-hidden justify-content-center align-items-center"
          style={{height: "90vh"}}
        >
          <Row className="p-0 m-0 align-items-center mb-3">
            <Col md="auto" className="p-1">
              <Calendar
                variant="cyan-12"
                calenderOnly
                mode={scheduleMode}
                onDateChanged={(timeperiod) => {
                  setTimeperiod(timeperiod);
                }}
              />
            </Col>
            <Col md="auto" className="p-1">
              <Dropdown>
                <Dropdown.Toggle variant="cyan-12" size="sm">
                  {`View: ${scheduleView}|${scheduleMode}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header>View by Team Member</Dropdown.Header>
                  {[
                    {
                      label: "Day",
                      value: "Day",
                      callback: () => {
                        setScheduleMode("Day");
                        setScheduleView("Employee");
                      },
                    },
                    {
                      label: "Week",
                      value: "Week",
                      callback: () => {
                        setScheduleMode("Week");
                        setScheduleView("Employee");
                      },
                    },
                    {
                      label: "2 Weeks",
                      value: "2 Weeks",
                      callback: () => {
                        setScheduleMode("2 Weeks");
                        setScheduleView("Employee");
                      },
                    },
                    {
                      label: "Month",
                      value: "Month",
                      callback: () => {
                        setScheduleMode("Month");
                        setScheduleView("Employee");
                      },
                    },
                  ].map((op, i) => (
                    <Dropdown.Item key={i} onClick={op.callback}>
                      {op.label}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.Header>View by Area</Dropdown.Header>
                  {[
                    {
                      label: "Day",
                      value: "Day",
                      callback: () => {
                        setScheduleMode("Day");
                      },
                    },
                    {
                      label: "Week",
                      value: "Week",
                      callback: () => {
                        setScheduleMode("Week");
                      },
                    },
                    {
                      label: "2 Weeks",
                      value: "2 Weeks",
                      callback: () => setScheduleMode("2 Weeks"),
                    },
                    {
                      label: "Month",
                      value: "Month",
                      callback: () => setScheduleMode("Month"),
                    },
                  ].map((op, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => {
                        op.callback();
                        setScheduleView("Area");
                      }}
                    >
                      {op.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="d-flex justify-content-end">
              <Row className="justify-content-end">
                {/* <Col md="auto" className="p-1">
                  <Dropdown as={ButtonGroup}>
                    <Button variant="cyan-6" size="sm">
                      <span className="me-1">
                        <MdAutoAwesome />
                      </span>
                      Auto
                    </Button>
                    <Dropdown.Toggle
                      size="sm"
                      variant="cyan-6"
                      className="border-start"
                    />

                    <Dropdown.Menu>
                      {[
                        {
                          label: "Auto-schedule agreed hours",
                          callback: () => {},
                        },
                        {
                          label: "Auto-build shift structure",
                          callback: () => {
                            setShowAutoBuildShifts(true);
                          },
                        },
                        {
                          label: "Auto-fill empty shifts",
                          callback: () => {},
                        },
                      ].map((op, i) => (
                        <Dropdown.Item key={i} onClick={op.callback}>
                          {op.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col> */}

                <Col md="auto" className="p-1">
                  <Dropdown as={ButtonGroup}>
                    <Button
                      variant="cyan-6"
                      size="sm"
                      onClick={() => {
                        setShowCopyShift(true);
                      }}
                      disabled={!copiedShift}
                    >
                      <span className="me-1">
                        <IoMdCopy />
                      </span>
                      Copy Shifts
                    </Button>
                    <Dropdown.Toggle size="sm" variant="cyan-6" />

                    <Dropdown.Menu>
                      {[
                        {
                          label: "Copy Shifts",
                          callback: () => {
                            setShowCopyShift(true);
                          },
                          disabled: false,
                        },
                        // {
                        //   label: "Import Shifts",
                        //   callback: () => {},
                        // },
                        // {
                        //   label: "Save Template",
                        //   callback: () => {
                        //     setShowSaveShiftTemplate(true);
                        //   },
                        // },
                        // {
                        //   label: "Load Template",
                        //   callback: () => {
                        //     setShowLoadShiftTemplate(true);
                        //   },
                        // },
                        // {
                        //   label: "Copy Selected Shift",
                        //   callback: () => {},
                        //   disabled: true,
                        // },
                        // {
                        //   label: "Paste Selected Shift",
                        //   callback: () => {},
                        //   disabled: true,
                        // },
                      ].map((op, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={op.callback}
                          disabled={op.disabled}
                        >
                          {op.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                {/* <Col md="auto" className="p-1">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="cyan-6">
                      <span className="me-1">
                        <ImStatsBars />
                      </span>
                      Insights
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        {
                          label: "Give Feedback",
                          callback: () => {},
                        },
                        {
                          label: "Show coverage in areas",
                          callback: () => {},
                        },
                        {
                          label: "show stats panel",
                          callback: () => {},
                        },
                      ].map((op, i) => (
                        <Dropdown.Item key={i} onClick={op.callback}>
                          {op.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col> */}
                {/* <Col md="auto" className="p-1">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="cyan-6">
                      <span className="me-1">
                        <BiExport />
                      </span>
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        {
                          label: "Print by Area",
                          Icon: () => <AiFillPrinter color="red" />,
                        },
                        {
                          label: "Print by Area (Excel)",
                          Icon: () => <RiFileExcel2Fill color="green" />,
                        },
                        {
                          label: "Print by Team Member",
                          Icon: () => <AiFillPrinter color="red" />,
                        },
                        {
                          label: "Print by Team Member (Excel)",
                          Icon: () => <RiFileExcel2Fill color="green" />,
                        },
                        {
                          label: "Download as CSV",
                          Icon: () => <GrDownload />,
                          callback: () => {
                            generateCSV()
                              .then(() => {
                                csvRef.current?.link?.click();
                              })
                              .catch((error) => {
                                // showModal({})
                              });
                          },
                        },
                        {
                          label: "Sync to Calendar",
                          Icon: () => <BsCalendar3 />,
                          callback: () => setShowSyncCalendar(true),
                        },
                      ].map((op, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={op.callback}
                          className="align-bottom"
                        >
                          {op.Icon !== undefined && <span>{op.Icon()}</span>}{" "}
                          {op.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col> */}
                <Col md="auto" className="p-1">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="cyan-6">
                      <span className="me-1">
                        <RiSettings5Fill />
                      </span>
                      Options
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        // {
                        //   label: "Remove All Team Members",
                        //   callback: () => {
                        //     setShowDeleteTeamMemberModal(true);
                        //   },
                        // },
                        // {
                        //   label: "Remove Empty Shifts",
                        //   callback: () => {
                        //     setShowRemoveBlankShifts(true);
                        //   },
                        // },
                        // {
                        //   label: "Remove All Shifts",
                        //   callback: () => {
                        //     setShowRemoveAllShifts(true);
                        //   },
                        // },
                        // { label: "Mark Empty Shifts as Open" },
                        // {
                        //   label: "Bulk Update",
                        //   callback: () => setShowUpdateBulkShifts(true),
                        // },
                        {
                          label: "Edit Locations and Area",
                          callback: () => navigate("/app/location"),
                        },
                        // { label: "Enter Sales" },
                        // { label: "Expand/Collapse areas" },
                      ].map((op, i) => (
                        <Dropdown.Item key={i} onClick={op.callback}>
                          {op.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {/* <Col md="auto" className="p-1">
                  <DropdownMenu variant="cyan-6" label="No Shifts" />
                </Col> */}
              </Row>
            </Col>
          </Row>
          {/* Start Scheduling Here */}
          {/* {scheduleMode !== "Day" ? (
            <WeeklyCalendar
              scheduleMode={scheduleMode}
              shiftsEmployeeWise={shiftsEmployeeWise}
              staff={staff}
              timePeriod={timeperiod}
            />
          ) : (
            <DailySchedule
              staff={staff}
              timePeriod={timeperiod}
              shiftsEmployeeWise={shiftsEmployeeWise}
              scheduleMode="Day"
            />
          )} */}

          {(scheduleMode === "Week" || scheduleMode === "2 Weeks") &&
            scheduleView === "Employee" && (
              <WeeklyLayout
                onSearchTextChanged={setStaffSearchInput}
                onCopyShift={(shift: any) => {
                  setCopiedShift(shift);
                }}
                copiedShift={copiedShift}
                onPasteShift={(shift) => {
                  Shifts.createShift(
                    {
                      ...shift,
                      areaList: shift.areas?.map((a) => a.areaId),
                      locationId: shift.location?.id,
                      employeeId: shift.employee?.id,
                    },
                    (responseShift) => {
                      setShifts([...shifts, responseShift]);
                      setCopiedShift(undefined);
                    },
                    (error) => {
                      showModal({
                        APIError: error,
                        APIType: "APIEXCEPTION",
                        Component: <Text>{error.response?.data?.message}</Text>,
                      });
                    }
                  );
                }}
                timePeriod={timeperiod}
                staff={filterStaff()}
                shiftsEmployeeWise={shiftsEmployeeWise}
                isFortnight={scheduleMode === "2 Weeks"}
                onAddClick={(date) => {
                  setOpenNewShiftModal(() => {
                    setNewShiftDate(date);
                    setNewShiftModalBehaviour("CREATE");
                    return true;
                  });
                }}
                onEditClick={(date, shift) => {
                  setOpenNewShiftModal(() => {
                    setNewShiftDate(date);

                    setNewShiftModalBehaviour("UPDATE");
                    setCreateShiftDefaultValues({
                      ...shift,
                      // areaObj: { label: shift.area.area, value: shift.area.id },

                      location: {
                        location: shift.location!.location,
                        id: shift.location!.id,
                      },
                      employee: {
                        employee: shift.employee!.employee,
                        id: shift.employee!.id,
                        userId: shift.employee!.id,
                      },
                      // areaId: shift.area.id,
                      locationId: shift?.location?.id,
                      employeeId: shift.employee?.id,
                    });
                    return true;
                  });
                }}
              />
            )}
          {scheduleMode === "Month" && scheduleView === "Employee" && (
            <MonthlyLayout
              onSearchTextChanged={setStaffSearchInput}
              onCopyShift={setCopiedShift}
              copiedShift={copiedShift}
              onPasteShift={(shift) => {
                Shifts.createShift(
                  {
                    ...shift,
                    areaList: shift.areas?.map((a) => a.areaId),
                    locationId: shift.location?.id,
                    employeeId: shift.employee?.id,
                  },
                  (responseShift) => {
                    setShifts([...shifts, responseShift]);
                    setCopiedShift(undefined);
                  },
                  (error) => {
                    showModal({
                      APIError: error,
                      APIType: "APIEXCEPTION",
                      Component: <Text>{error.response?.data?.message}</Text>,
                    });
                  }
                );
              }}
              timePeriod={timeperiod}
              staff={filterStaff()}
              shiftsEmployeeWise={shiftsEmployeeWise}
              onAddClick={(date) => {
                setOpenNewShiftModal(() => {
                  setNewShiftDate(date);

                  setNewShiftModalBehaviour("CREATE");
                  return true;
                });
              }}
              onEditClick={(date, shift) => {
                setOpenNewShiftModal(() => {
                  setNewShiftDate(date);
                  // setShiftToUpdate(shift);
                  setNewShiftModalBehaviour("UPDATE");
                  setCreateShiftDefaultValues({
                    ...shift,

                    location: {
                      location: shift.location!.location,
                      id: shift.location!.id,
                    },
                    employee: {
                      employee: shift.employee!.employee,
                      id: shift.employee!.id,
                      userId: shift.employee!.id,
                    },

                    locationId: shift?.location?.id,
                    employeeId: shift.employee?.id,
                  });

                  return true;
                });
              }}
            />
          )}
          {scheduleMode === "Day" && scheduleView === "Employee" && (
            <DailyLayout
              onSearchTextChanged={setStaffSearchInput}
              timePeriod={timeperiod}
              staff={filterStaff()}
              shiftsEmployeeWise={shiftsEmployeeWise}
              onAddClick={(date) => {
                setOpenNewShiftModal(() => {
                  setNewShiftDate(date);
                  setCreateShiftDefaultValues(undefined);

                  setNewShiftModalBehaviour("CREATE");
                  return true;
                });
              }}
              onEditClick={(date, shift) => {
                setOpenNewShiftModal(() => {
                  setNewShiftDate(date);
                  setNewShiftModalBehaviour("UPDATE");
                  setCreateShiftDefaultValues({
                    ...shift,

                    location: {
                      location: shift.location!.location,
                      id: shift.location!.id,
                    },
                    employee: {
                      employee: shift.employee!.employee,
                      id: shift.employee!.id,
                      userId: shift.employee!.id,
                    },

                    locationId: shift?.location?.id,
                    employeeId: shift.employee?.id,
                  });
                  return true;
                });
              }}
            />
          )}
          {scheduleView === "Area" && scheduleMode !== "Day" && (
            <AreaSchedule
              scheduleMode={scheduleMode}
              timeperiod={timeperiod}
              shifts={shiftsLocationWise}
              onAddClicked={(date, location, area) => {
                setOpenNewShiftModal(() => {
                  setNewShiftModalBehaviour("CREATE");
                  setNewShiftDate(date);
                  setCreateShiftDefaultValues({
                    location: {location: location.name, id: location.id},
                    areas: [{area: area.name, areaId: area.id, id: area.id}],
                    locationId: location?.id,
                  });
                  return true;
                });
              }}
              onEditClicked={(date, shift) => {
                setOpenNewShiftModal(() => {
                  setNewShiftModalBehaviour("UPDATE");
                  setNewShiftDate(date);
                  setCreateShiftDefaultValues({
                    ...shift,

                    location: {
                      location: shift.location!.location,
                      id: shift.location!.id,
                    },
                    employee: {
                      employee: shift.employee!.employee,
                      id: shift.employee!.id,
                      userId: shift.employee!.id,
                    },

                    locationId: shift?.location?.id,
                    employeeId: shift.employee?.id,
                  });

                  return true;
                });
              }}
              onCopyClicked={setCopiedShift}
              copiedShift={copiedShift}
              onPasteShift={(shift) => {
                setShifts([...shifts, shift]);
              }}
            />
          )}

          {scheduleView === "Area" && scheduleMode === "Day" && (
            <AreaScheduleDay
              timeperiod={timeperiod}
              shifts={shiftsLocationWise}
              onAddClicked={(date, location, area) => {
                setOpenNewShiftModal(() => {
                  setNewShiftModalBehaviour("CREATE");

                  setNewShiftDate(date);
                  setCreateShiftDefaultValues({
                    location: {location: location.name, id: location.id},
                    areas: [{area: area.name, areaId: area.id, id: area.id}],
                    locationId: location.id,
                  });

                  return true;
                });
              }}
              onEditClicked={(date, shift) => {
                setOpenNewShiftModal(() => {
                  setNewShiftModalBehaviour("UPDATE");
                  setNewShiftDate(date);
                  setCreateShiftDefaultValues({
                    ...shift,

                    location: {
                      location: shift.location!.location,
                      id: shift.location!.id,
                    },
                    employee: {
                      employee: shift.employee!.employee,
                      id: shift.employee!.id,
                      userId: shift.employee!.id,
                    },

                    locationId: shift?.location?.id,
                    employeeId: shift.employee?.id,
                  });

                  return true;
                });
              }}
              onCopyClicked={setCopiedShift}
            />
          )}
        </Container>
      </div>
      {/* <Container
        style={{ background: "white", height: "8vh" }}
        fluid
        className="align-items-center justify-content-center d-flex"
      >
        <Row className="justify-content-center ">
          {footerItems.map(({ label, value, iconColor }, i) => (
            <Col md="auto" key={i}>
              <Text
                fontSize="14px"
                responsive
                className="d-flex align-items-center"
              >
                <GoPrimitiveDot color={iconColor} size={20} /> {value} {label}
              </Text>
            </Col>
          ))}
        </Row>
      </Container> */}
    </div>
  );
}
