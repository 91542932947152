import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import { removeEmptyOrNull } from "../../../../api/general";
import Button from "../../../../components/button/Button";
import { InputField } from "../../../../components/InputField/InputField";
import Text from "../../../../components/text/text";
import { Colors } from "../../../../constants";
import AreaQRCode from "../area-qr";
interface IArea {
  onUpdate: (data: any) => void;
  defaultValue: any;
  locationName?: string;
  locationId?: string;
  locationAddress?: string;
}
export default function Area({
  defaultValue,
  onUpdate,
  locationName,
  locationId,
  locationAddress,
}: IArea) {
  const [showQRCode, setShowQRCode] = useState(false);
  const [selectedArea, setSelectedArea] = useState<{
    locationName?: string;
    areaName: string;
    locationId?: string;
    areaId?: number;
    locationAddress?: string;
  }>();
  const [newArea, setNewArea] = useState<string>("");
  const [area, setArea] = useState<
    Array<{ branding: string; id?: number; name: string }>
  >(defaultValue || []);
  useEffect(() => {
    onUpdate([...area]);
  }, [area]);

  useEffect(() => {
    if (defaultValue) {
      setArea(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <AreaQRCode
        show={showQRCode}
        onClose={() => {
          setShowQRCode(!showQRCode);
        }}
        // key={moment().unix()}
        data={selectedArea}
      />
      <Container>
        {/* <Row
          className="rounded border border-highlighted p-2 align-items-center area-container"
          style={{ background: Colors.HEX_TO_RGBA(Colors.HIGHLIGHTED, 0.2) }}
        >
          <Col xs="auto" className="p-0">
            <AiFillInfoCircle color={Colors.HIGHLIGHTED} />
          </Col>
          <Col xs="auto">
            <Text color={Colors.HIGHLIGHTED}>
              Drag the area to change the order of how they arranged on the
              scheduling screen.
            </Text>
          </Col>
        </Row> */}
        <Row className="align-items-center mt-2">
          <Col className="p-0" xs="11">
            <InputField
              placeHolder="New Area name"
              fullwidth
              backgroundColor="white"
              borderRadius="10px 0px 0px 10px"
              fontSize="12px"
              inputProps={{ value: newArea }}
              onChangedText={setNewArea}
            />
          </Col>
          <Col className="p-0" xs="1">
            <Button
              borderRadius="0px 10px 10px 0px"
              fullWidth
              variant="cyan-6"
              onClick={() => {
                if (newArea.length > 0) {
                  setArea((prev) => {
                    let newAreas = [...prev];
                    newAreas.push({
                      name: newArea,
                      branding:
                        "#" +
                        ((Math.random() * 0xffffff) << 0)
                          .toString(16)
                          .padStart(6, "0"),
                    });
                    return [...newAreas];
                  });
                }
                setNewArea("");
              }}
            >
              <Text fontSize="15px">Add</Text>
            </Button>
          </Col>
        </Row>

        {area &&
          area?.map(({ name, branding, id }, i) => (
            <Row
              className="border-grey border p-2 my-2 rounded justify-content-between align-items-center area-container"
              key={i}
            >
              <Col xs="auto">
                <div
                  style={{ backgroundColor: branding }}
                  className="p-2 rounded px-3"
                >
                  <Text color="white">{name}</Text>
                </div>
              </Col>
              <Col xs="auto">
                <Row>
                  {id && (
                    <Col className="p-0">
                      <Button
                        variant="cyan-6"
                        noBorderRadius
                        onClick={() => {
                          setShowQRCode((prev) => {
                            setSelectedArea({
                              areaName: name,
                              locationName,
                              locationId,
                              areaId: id,
                              locationAddress,
                            });
                            return !prev;
                          });
                        }}
                      >
                        <Text>View</Text>
                      </Button>
                    </Col>
                  )}
                  <Col className="p-0">
                    <Button
                      noBorderRadius
                      variant="no_color"
                      onClick={() => {
                        setArea(area.filter((_, index) => index !== i));
                      }}
                    >
                      <Text color="red">Delete</Text>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </Container>
    </>
  );
}
