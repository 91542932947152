import moment, { Moment } from "moment";

export const removeEmptyOrNull = (obj: any) => {
  if (obj) {
    Object.entries(obj).forEach(
      ([key, val]) =>
        (val && typeof val === "object" && removeEmptyOrNull(val)) ||
        ((val === null || val === "" || val === undefined) && delete obj[key])
    );
  }
  return obj || {};
};
export const removeUndefinedAndNull = (obj: any) => {
  if (obj) {
    Object.entries(obj).forEach(
      ([key, val]) =>
        (val && typeof val === "object" && removeEmptyOrNull(val)) ||
        ((val === null || val === undefined) && delete obj[key])
    );
  }
  return obj || {};
};

export const capilatizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.substr(1);
};

export const checkRequiredFields = (
  neededKeys: Array<{ label: string; key: string }>,
  obj: any
) => {
  return {
    allFilled: neededKeys.every((key) => Object.keys(obj).includes(key.key)),
    missingekeys: neededKeys
      .filter((key) => !Object.keys(obj).includes(key.key))
      .map((key) => key.label),
  };
};

export function generateDateRange(startDate: Moment, numOfDays: number) {
  let dateRange: Array<Moment> = [];
  for (var i = 0; i <= numOfDays - 1; i++) {
    let date = startDate.add(i !== 0 ? 1 : 0, "days");

    dateRange.push(date.clone());
  }
  return dateRange;
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function extractBase64Only(base64: string) {
  return base64.split("base64,")[1];
}

export function generateRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
