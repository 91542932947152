import { AxiosError, AxiosResponse } from "axios";
import React, { MouseEventHandler, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Button from "../components/button/Button";
import Text from "../components/text/text";

type ModalTypes = {
  show?: boolean;
  APIType?: "APIEXCEPTION" | "SUCESS" | undefined;
  Component?: React.ReactNode;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  APIError?: AxiosError;
  APIResponse?: AxiosResponse<any>;
  FooterComponent?: React.ReactNode;
};
type ModalProps = {
  showModal: (options: ModalTypes) => void;
  closeModal: Function;
};
export const ModalContext = React.createContext<ModalProps>({
  showModal: () => {},
  closeModal: () => {},
});

export function ModalProvider({ children }: any) {
  const [show, setShow] = useState<boolean>(false);
  const [APIType, setAPIType] = useState<ModalTypes["APIType"]>("SUCESS");
  const [Component, setComponent] =
    useState<ModalTypes["Component"]>(undefined);
  const [onClose, setOnClose] = useState<ModalTypes["onClose"]>(undefined);
  const [APIError, setAPIError] = useState<ModalTypes["APIError"]>(undefined);
  const [APIResponse, setAPIResponse] =
    useState<ModalTypes["APIResponse"]>(undefined);
  const [FooterComponent, setFooterComponent] =
    useState<ModalTypes["FooterComponent"]>(undefined);

  const APIModal = () => {
    if (APIType === "APIEXCEPTION") {
      return (
        <Container>
          <Row>
            <Col>
              <Text bold heading>
                Some error occured
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                Status:{" "}
                {`${APIError?.response?.status ?? "Server Unavailable"}`}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                Error code:{" "}
                {`${APIError?.response?.data?.message ?? "Server Unavailable"}`}
              </Text>
            </Col>
          </Row>
        </Container>
      );
    }
    if (APIType === "SUCESS") {
      return (
        <Container>
          <Row>
            <Col>
              <Text bold heading>
                Message: {`${APIResponse?.data?.message}`}
              </Text>
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };
  function showModal({
    APIType,
    Component,
    onClose,
    APIError,
    APIResponse,
    FooterComponent,
  }: ModalTypes) {
    setShow(true);
    setAPIType(APIType);
    setComponent(Component);
    setOnClose(onClose);
    setAPIError(APIError);
    setAPIResponse(APIResponse);
    setFooterComponent(FooterComponent);
  }
  function closeModal() {
    setShow(false);
    setAPIType(undefined);
    setComponent(undefined);
    setOnClose(undefined);
    setAPIError(undefined);
    setAPIResponse(undefined);
    setFooterComponent(undefined);
  }
  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      <Modal show={show}>
        <Modal.Body>{APIType ? <APIModal /> : Component}</Modal.Body>
        <Modal.Footer>
          {FooterComponent ? (
            FooterComponent
          ) : (
            <Button
              onClick={(e) => {
                onClose ? onClose(e) : closeModal();
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </ModalContext.Provider>
  );
}

const useModal = () => React.useContext<ModalProps>(ModalContext);
export default useModal;
